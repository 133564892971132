import React from 'react';

export default class FilterView extends React.Component {

  static defaultProps = {
    width: '17%' ,
    type: 'text',
    placeholder: '',
    className: 'form-control',
    isGroup: false
	}

  render () {
    const {
      width,
      type,
      placeholder,
      className,
      children,
      isGroup
    } = this.props;
    if (isGroup) {
      return (
        <th className={className} style={{ width: width }}>
          <div className="input-group">
            <input
              type={type}
              className={className}
              placeholder={placeholder}
            />
            { children }
          </div>
        </th>
      )
    }
    return (
      <th className={{className}} style={{ width: width }}>
        <input
          type={type}
          className={className}
          placeholder={placeholder}
        />
      </th>
    )
  }
}

import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNDataUploadManager = Loadable({
  loader: () => import("./SDNDataUploadManager"),
  loading: Loading
});

const SDNMetricalConfig = Loadable({
  loader: () => import("./SDNMetricalConfig"),
  loading: Loading
});

const SDNDataUploadRecord = Loadable({
  loader: () => import("./SDNDataUploadRecord"),
  loading: Loading
});

const SDNDataUploadRecordDetail = Loadable({
  loader: () => import("./components/SDNDataUploadRecordDetail"),
  loading: Loading
});

const SDNMetricalCheck = Loadable({
  loader: () => import("./SDNMetricalCheck"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNDataUpload/SDNDataUploadManager",
    exact: true,
    component: SDNDataUploadManager,
    name: "SDNDataUploadManager"
  },
  {
    path: "/SDNDataUpload/SDNMetricalConfig",
    exact: true,
    component: SDNMetricalConfig,
    name: "SDNMetricalConfig"
  },
  {
    path: "/SDNDataUpload/SDNDataUploadRecord",
    exact: true,
    component: SDNDataUploadRecord,
    name: "SDNDataUploadRecord"
  },
  {
    path: "/SDNDataUpload/SDNDataUploadRecordDetail",
    exact: true,
    component: SDNDataUploadRecordDetail,
    name: "SDNDataUploadRecordDetail"
  },
  {
    path: "/SDNDataUpload/SDNMetricalCheck",
    exact: true,
    component: SDNMetricalCheck,
    name: "SDNMetricalCheck"
  }
];

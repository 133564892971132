import React from 'react';

export default class SDNProgressBar extends React.Component {

  static defaultProps = {
    title: '',
    value: 0,
    width: 0,
    barColor: 'black'
  }

  render () {
    const {
      title,
      value,
      width,
      barColor
    } = this.props;
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <span className="text"> {title}
          <span className="pull-right">{value}</span> </span>
          <div className="progress">
              <div
                className="progress-bar"
                style={{
                  background: barColor,
                  width:width
                }}
              >
              </div>
          </div>
      </div>
    )
  }
}

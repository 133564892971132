
//菜单管理
export const SUBMIT_ADD_MENU = 'SUBMIT_ADD_MENU';
export const ADD_MENU_SUCCESS = 'ADD_MENU_SUCCESS';
export const SUBMIT_EDIT_MENU = 'SUBMIT_EDIT_MENU';
export const EDIT_MENU_SUCCESS = 'EDIT_MENU_SUCCESS';
export const GET_ALL_MENU = 'GET_ALL_MENU';
export const GET_ALL_MENU_SUCCESS = 'GET_ALL_MENU_SUCCESS';
export const GET_MENU = 'GET_MENU';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const REMOVE_MENU = 'REMOVE_MENU';
export const REMOVE_MENU_SUCCESS = 'REMOVE_MENU_SUCCESS';
export const SHOW_EDIT_MENU = 'SHOW_EDIT_MENU';
//角色管理
export const INIT_PAGE = 'INIT_PAGE';
export const SUBMIT_EDIT_ROLE_SUCCESS = 'SUBMIT_EDIT_ROLE_SUCCESS';
export const SUBMIT_ADD_ROLE_SUCCESS = 'SUBMIT_ADD_ROLE_SUCCESS';
export const GET_ROLE_WITH_FUN = 'GET_ROLE_WITH_FUN';
export const SET_ROLE_WITH_FUN = 'SET_ROLE_WITH_FUN';
export const SUBMIT_ADD_ROLE = 'SUBMIT_ADD_ROLE';
export const SUBMIT_EDIT_ROLE = 'SUBMIT_EDIT_ROLE';
export const REMOVE_ROLE = 'REMOVE_ROLE';
export const ROLE_REMOVED = 'ROLE_REMOVED';
export const SHOW_EDIT_ROLE = 'SHOW_EDIT_ROLE';
//数据字典
export const SUBMIT_ADD_DATA_DICTIONARY = 'SUBMIT_ADD_DATA_DICTIONARY';
export const ADD_DATA_DICTIONARY_SUCCESS = 'ADD_DATA_DICTIONARY_SUCCESS';
export const SUBMIT_EDIT_DATA_DICTIONARY = 'SUBMIT_EDIT_DATA_DICTIONARY';
export const EDIT_DATA_DICTIONARY_SUCCESS = 'EDIT_DATA_DICTIONARY_SUCCESS';
export const REMOVE_DATA_DICTIONARY = 'REMOVE_DATA_DICTIONARY';
export const REMOVE_DATA_DICTIONARY_SUCCESS = 'REMOVE_DATA_DICTIONARY_SUCCESS';
//数据字典配置
export const SUBMIT_ADD_DATA_DIC_CONFIG = 'SUBMIT_ADD_DATA_DIC_CONFIG';
export const ADD_DATA_DIC_CONFIG_SUCCESS = 'ADD_DATA_DIC_CONFIG_SUCCESS';
export const SUBMIT_EDIT_DATA_DIC_CONFIG = 'SUBMIT_EDIT_DATA_DIC_CONFIG';
export const EDIT_DATA_DIC_CONFIG_SUCCESS = 'EDIT_DATA_DIC_CONFIG_SUCCESS';
export const REMOVE_DATA_DIC_CONFIG = 'REMOVE_DATA_DIC_CONFIG';
export const REMOVE_DATA_DIC_CONFIG_SUCCESS = 'REMOVE_DATA_DIC_CONFIG_SUCCESS';
export const SHOW_EDIT_DATA_DIC_CONFIG = 'SHOW_EDIT_DATA_DIC_CONFIG';
export const SHOW_EDIT_DATA_DICTIONARY = 'SHOW_EDIT_DATA_DICTIONARY';
//账号管理
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
export const SHOW_EDIT_ACCOUNT = 'SHOW_EDIT_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const RESET_ACCOUNT_PWD = 'RESET_ACCOUNT_PWD';
export const RESET_ACCOUNT_PWD_SUCCESS = 'RESET_ACCOUNT_PWD_SUCCESS';
export const SHOW_RESET_ACCOUNT_PWD = 'SHOW_RESET_ACCOUNT_PWD';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS';

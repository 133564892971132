import { BaseService } from '../SDNRequest';

const user = {
	    //hxyUser 计量	    
        UserAmmeterService :{
			
			//获取监控点的历史数据 
			ammeterHistorDatas : function(dataAmmeterId, onePreps, startTime, endTime, interval) {
				return BaseService.get('/api/hxyUser/userAmmeter/ammeterHistorDatas',{
						'dataAmmeterId':dataAmmeterId, 'onePreps':onePreps, 'startTime':startTime, 'endTime':endTime, 'interval':interval
					});
			},
		},
	    //hxyUser 用户	    
        UserConsumerService :{
			
			//根据用户名称模糊查询
			getAssociationByName : function(consumerId, inputName) {
				return BaseService.get('/api/hxyUser/userConsumer/getAssociationByName',{
						'consumerId':consumerId, 'inputName':inputName
					});
			},
			
			//根据用户的ID, 区域内的用户分布
			getConsumerByArea : function(consumerId) {
				return BaseService.get('/api/hxyUser/userConsumer/getConsumerByArea',{
						'consumerId':consumerId
					});
			},
			
			//用户分布，根据用户的ID， 统计有用户区域的用户数
			getConsumerDistribution : function(consumerId) {
				return BaseService.get('/api/hxyUser/userConsumer/getConsumerDistribution',{
						'consumerId':consumerId
					});
			},
			
			//获取单个用户的统计信息
			getOneConsumerStatis : function(consumerId) {
				return BaseService.get('/api/hxyUser/userConsumer/oneConsumerStatis',{
						'consumerId':consumerId
					});
			},
		},
	    //hxyUser告警记录	    
        UserAlarmRecordService :{
			
			//告警批量确认
			alarmConfirm : function(ids, procInfo) {
				return BaseService.post('/api/hxyUser/userAlarmRecord/alarmConfirm',{
						'ids':ids, 'procInfo':procInfo
					});
			},
			
			//确认和取消确认
			editState : function(id, fieldName, fieldValue) {
				return BaseService.post('/api/hxyUser/userAlarmRecord/editState',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue
					});
			},
			
			//数据总览-概况总览-告警信息
			getAlarmRecordByConsumerId : function(consumerId) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getAlarmRecordByConsumerId',{
						'consumerId':consumerId
					});
			},
			
			//获取用户下状态的告警记录
			getAlarmRecordByState : function(consumerId, state) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getAlarmRecordByState',{
						'consumerId':consumerId, 'state':state
					});
			},
			
			//告警数量统计
			getCount : function(consumerId, siteId, beginDate, endDate) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getCount',{
						'consumerId':consumerId, 'siteId':siteId, 'beginDate':beginDate, 'endDate':endDate
					});
			},
			
			//DataTable 查询告警记录
			getDatatable : function(dataTable, consumerId, siteId, beginDate, endDate, state, alarmType, alarmSubType) {
				return BaseService.post('/api/hxyUser/userAlarmRecord/getDatatable',{
						'dataTable':dataTable, 'consumerId':consumerId, 'siteId':siteId, 'beginDate':beginDate, 'endDate':endDate, 'state':state, 'alarmType':alarmType, 'alarmSubType':alarmSubType
					});
			},
			
			//综合监控页面，日告警与月告警数
			getDayAndMonthAlarmCnt : function(consumerId) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getDayAndMonthAlarmCnt',{
						'consumerId':consumerId
					});
			},
			
			//按天统计告警数，越限数和变位数
			getListByDt : function(consumerId, monitorObject, beginDate, endDate) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getListByDt',{
						'consumerId':consumerId, 'monitorObject':monitorObject, 'beginDate':beginDate, 'endDate':endDate
					});
			},
			
			//用户下的站点
			getSiteList : function(consumerId) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getSiteList',{
						'consumerId':consumerId
					});
			},
			
			//取未处理的告警记录数
			getUntreatedAlarmCnt : function(consumerId) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/getUntreatedAlarmCnt',{
						'consumerId':consumerId
					});
			},
			
			//站点下最近的告警记录 
			lastestAlarmOfSite : function(siteId) {
				return BaseService.get('/api/hxyUser/userAlarmRecord/lastestAlarmOfSite',{
						'siteId':siteId
					});
			},
		},
	    //hxyUser 监控视频	    
        UserIVideoControlService :{
			
			//光圈加
			apertureAdd : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/apertureAdd',{
						'cameraId':cameraId
					});
			},
			
			//光圈减
			apertureMinus : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/apertureMinus',{
						'cameraId':cameraId
					});
			},
			
			//变焦加
			findTurningAdd : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/findTurningAdd',{
						'cameraId':cameraId
					});
			},
			
			//变焦减
			findTurningMinus : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/findTurningMinus',{
						'cameraId':cameraId
					});
			},
			
			//向下
			turnDown : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnDown',{
						'cameraId':cameraId
					});
			},
			
			//向左
			turnLeft : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnLeft',{
						'cameraId':cameraId
					});
			},
			
			//向左下
			turnLeftDown : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnLeftDown',{
						'cameraId':cameraId
					});
			},
			
			//向左上
			turnLeftUp : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnLeftUp',{
						'cameraId':cameraId
					});
			},
			
			//向右
			turnRight : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnRight',{
						'cameraId':cameraId
					});
			},
			
			//向右下
			turnRightDown : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnRightDown',{
						'cameraId':cameraId
					});
			},
			
			//向右上
			turnRightUp : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnRightUp',{
						'cameraId':cameraId
					});
			},
			
			//向上
			turnUp : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/turnUp',{
						'cameraId':cameraId
					});
			},
			
			//变倍加
			zoomAdd : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/zoomAdd',{
						'cameraId':cameraId
					});
			},
			
			//变倍减
			zoomMinus : function(cameraId) {
				return BaseService.post('/api/hxyUser/userIVideo/zoomMinus ',{
						'cameraId':cameraId
					});
			},
		},
	    //hxyUser 监控图	    
        UserMonitorDiagramService :{
			
			//站点下的监控图
			getBySiteId : function(siteId) {
				return BaseService.get('/api/hxyUser/userMonitorDiagram/getBySiteId',{
						'siteId':siteId
					});
			},
		},
	    //hxyUser 监控对像	    
        UserMonitorObjectService :{
			
			//监控站下的监控对象
			getBySiteId : function(siteId) {
				return BaseService.get('/api/hxyUser/userMonitorObject/getBySiteId',{
						'siteId':siteId
					});
			},
			
			//DataTable 用户设备查询 
			getDataTable : function(dataTable, consumerId) {
				return BaseService.post('/api/hxyUser/userMonitorObject/getdatatable',{
						'dataTable':dataTable, 'consumerId':consumerId
					});
			},
			
			//获取最新的监控对象的数据
			getLatestObjDatas : function(monitorObjId) {
				return BaseService.get('/api/hxyUser/userMonitorObject/getLatestObjDatas',{
						'monitorObjId':monitorObjId
					});
			},
			
			//获取实时数据的参数
			getRealDataParams : function(siteId, objCodes, entityUrls) {
				return BaseService.post('/api/hxyUser/userMonitorObject/getRealDataParams',{
						'siteId':siteId, 'objCodes':objCodes, 'entityUrls':entityUrls
					});
			},
			
			//获取实时数据
			getRealDatas : function(dataParamsJsonStr) {
				return BaseService.post('/api/hxyUser/userMonitorObject/getRealDatas',{
						'dataParamsJsonStr':dataParamsJsonStr
					});
			},
			
			//获取监控对象的简略详细信息
			objDetails : function(objId) {
				return BaseService.get('/api/hxyUser/userMonitorObject/objDetails',{
						'objId':objId
					});
			},
		},
	    //用户端登录	    
        UserLoginService :{
			
			//修改员工对象的字段
			editEmpfield : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hxyUser/userLogin/editEmpfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据联系人ID获得所有用户
			getConsumerListByEmp : function(empId) {
				return BaseService.get('/api/hxyUser/userLogin/getConsumerListByEmp',{
						'empId':empId
					});
			},
			
			//获取账号信息
			getPersonalInfo : function(empId) {
				return BaseService.get('/api/hxyUser/userLogin/getPersonalInfo',{
						'empId':empId
					});
			},
			
			//根据天气编码获得天气
			getWeatherByCode : function(weatherCode, dt) {
				return BaseService.get('/api/hxyUser/userLogin/getWeatherByCode',{
						'weatherCode':weatherCode, 'dt':dt
					});
			},
			
			//根据联系人ID获得所有用户
			getWeatherListByCode : function(weatherCode, fromDt, toDt) {
				return BaseService.get('/api/hxyUser/userLogin/getWeatherListByCode',{
						'weatherCode':weatherCode, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//慧效云用户端账号密码登录
			loginHxyUser : function(userName, password, appCode) {
				return BaseService.post('/api/hxyUser/userLogin/loginHxyUser',{
						'userName':userName, 'password':password, 'appCode':appCode
					});
			},
			
			//修改密码-密码加密
			updatePassword : function(id, oldPsw, newPsw) {
				return BaseService.post('/api/hxyUser/userLogin/updatePassword',{
						'id':id, 'oldPsw':oldPsw, 'newPsw':newPsw
					});
			},
		},
	    //hxyUser 监控站	    
        UserMonitorSiteService :{
			
			//根据输入的名称联想相关的站点
			getAssociationByName : function(consumerId, inputName) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getAssociationByName',{
						'consumerId':consumerId, 'inputName':inputName
					});
			},
			
			//获取用户下的站点列表
			getByConsumerId : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getByConsumerId',{
						'consumerId':consumerId
					});
			},
			
			//综合监控页面，日告警与月告警数
			getDayAndMonthAlarm : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getDayAndMonthAlarm',{
						'consumerId':consumerId
					});
			},
			
			//监控总览-今日电量与本月电量,今日最大负荷与本月最大负荷 
			getElecAmount : function(consumerId, dataAmmeterId, dataCenterArguments) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getElecAmount',{
						'consumerId':consumerId, 'dataAmmeterId':dataAmmeterId, 'dataCenterArguments':dataCenterArguments
					});
			},
			
			//综合监控页面，环境监测点与变压器总数
			getEnviromentMonitorCount : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getEnviromentMonitorCount',{
						'consumerId':consumerId
					});
			},
			
			//监控总览-获取企业下的站点列表和站点的告警数量、二次设备数和用电量
			getListSiteAndCnt : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getListSiteAndCnt',{
						'consumerId':consumerId
					});
			},
			
			//区域下的监控站
			getMonitorSiteByArea : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getMonitorSiteByArea',{
						'consumerId':consumerId
					});
			},
			
			//获取用户下的站点列表
			getMonitorSiteDetails : function(siteId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getMonitorSiteDetails',{
						'siteId':siteId
					});
			},
			
			//获取某个用户监控站地图分布统计
			getMonitorSiteDistribution : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getMonitorSiteDistribution',{
						'consumerId':consumerId
					});
			},
			
			//根据MonitorType获取监控对象
			getObjectByType : function(consumerId, monitorType) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getObjectByType',{
						'consumerId':consumerId, 'monitorType':monitorType
					});
			},
			
			//监控总览-获取企业下的站点数和监控点数
			getSiteCntAndItemCnt : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getSiteCntAndItemCnt',{
						'consumerId':consumerId
					});
			},
			
			//综合监控页面，站点数量与监控设备数
			getSiteCount : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getSiteCount',{
						'consumerId':consumerId
					});
			},
			
			//监控总览-获取企业下的站点的变压器和、额定容量和 
			getTransformerCnt : function(consumerId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/getTransformerCnt',{
						'consumerId':consumerId
					});
			},
			
			//获取单个站点下的统计信息
			oneMonitorSiteStatis : function(monitorSiteId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/oneMonitorSiteStatis',{
						'monitorSiteId':monitorSiteId
					});
			},
			
			//获取站点下最近30天的用电量
			siteElecEnergy : function(siteId) {
				return BaseService.get('/api/hxyUser/userMonitorSite/siteElecEnergy',{
						'siteId':siteId
					});
			},
		},
	    //数据总览	    
        UserCompreMonitorService :{
			
			//用电统计
			getElecStatistics : function(consumerId, interval, type) {
				return BaseService.get('/api/hxyUser/userCompreMonitor/getElecStatistics',{
						'consumerId':consumerId, 'interval':interval, 'type':type
					});
			},
			
			//用电趋势
			getElecTrend : function(ammeterId, interval, beginDate, endDate, dateType) {
				return BaseService.get('/api/hxyUser/userCompreMonitor/getElecTrend',{
						'ammeterId':ammeterId, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate, 'dateType':dateType
					});
			},
			
			//本期和上期的电量电费
			getElectric : function(ammeterId, dataAmmeterId, interval) {
				return BaseService.get('/api/hxyUser/userCompreMonitor/getElectric',{
						'ammeterId':ammeterId, 'dataAmmeterId':dataAmmeterId, 'interval':interval
					});
			},
			
			//获取实时数据
			getRealData : function(ammeterId, dataCodeList) {
				return BaseService.get('/api/hxyUser/userCompreMonitor/getRealData',{
						'ammeterId':ammeterId, 'dataCodeList':dataCodeList
					});
			},
		},
	    //工单	    
        UserWorkOrderService :{
			
			//查询工单和运维需求的工单检查项目 
			getByDemandAndWorkOrder : function(objectDemandId, workOrderId) {
				return BaseService.get('/api/hxyUser/userWorkOrder/getByDemandAndWorkOrder',{
						'objectDemandId':objectDemandId, 'workOrderId':workOrderId
					});
			},
			
			//工单统计
			getCount : function(consumerId) {
				return BaseService.get('/api/hxyUser/userWorkOrder/getCount',{
						'consumerId':consumerId
					});
			},
			
			//DataTable 工单列表 
			getDataTableByConsumer : function(dataTable, consumerId, type) {
				return BaseService.post('/api/hxyUser/userWorkOrder/getDataTableByConsumer',{
						'dataTable':dataTable, 'consumerId':consumerId, 'type':type
					});
			},
			
			//查询工单的运维需求
			getObjectDemandByWorkOrder : function(workOrderId) {
				return BaseService.get('/api/hxyUser/userWorkOrder/getObjectDemandByWorkOrder',{
						'workOrderId':workOrderId
					});
			},
			
			//查询工单的运维方案下的运维策略和对应检查项
			getTacticsBySchemaAndWorkOrder : function(objectSchemaId, workOrderId) {
				return BaseService.get('/api/hxyUser/userWorkOrder/getTacticsBySchemaAndWorkOrder',{
						'objectSchemaId':objectSchemaId, 'workOrderId':workOrderId
					});
			},
			
			//数据总览-站点概况-运维工单
			getWorkOrderCount : function(consumerId) {
				return BaseService.get('/api/hxyUser/userWorkOrder/getWorkOrderCount',{
						'consumerId':consumerId
					});
			},
			
			//根据ID获取运维工单对象
			getWorkOrderInfo : function(id) {
				return BaseService.get('/api/hxyUser/userWorkOrder/getWorkOrderInfo',{
						'id':id
					});
			},
			
			//DataTable 工单列表 
			getWorkOrderList : function(consumerId, type) {
				return BaseService.post('/api/hxyUser/userWorkOrder/getWorkOrderList',{
						'consumerId':consumerId, 'type':type
					});
			},
			
			//查询工单和运维需求的工单检查项目 
			recentWorkOrderOfSite : function(siteId) {
				return BaseService.get('/api/hxyUser/userWorkOrder/recentWorkOrderOfSite',{
						'siteId':siteId
					});
			},
		},
	    //用户端知识库	    
        UserNewsService :{
			
			//根据ID获取知识对象
			getByNewsType : function(codes) {
				return BaseService.get('/api/hxyUser/userNews/getByNewsType',{
						'codes':codes
					});
			},
			
			//根据ID获取知识对象
			getNew : function(id) {
				return BaseService.get('/api/hxyUser/userNews/getNew',{
						'id':id
					});
			},
			
			//DataTable知识库按分类code查询
			getNewsDataTableByCode : function(dataTable, code) {
				return BaseService.post('/api/hxyUser/userNews/getNewsDataTableByCode',{
						'dataTable':dataTable, 'code':code
					});
			},
		},
	    //台账查询	    
        UserArchiveService :{
			
			//增加设备文档
			addDeviceFile : function(deviceFile, upFile) {
				return BaseService.upload('/api/hxyUser/userArchive/addDeviceFile',{
						'deviceFile':deviceFile, 'upFile':upFile
					});
			},
			
			//增加设备事件跟踪对象
			addDeviceTrace : function(mapParams) {
						return BaseService.post('/api/hxyUser/userArchive/addDeviceTrace',mapParams);
			},
			
			//修改设备扩展属性对象
			addOrEditDeviceExtend : function(mapParams) {
						return BaseService.post('/api/hxyUser/userArchive/addOrEditDeviceExtend',mapParams);
			},
			
			//增加供应商对象
			addSupplier : function(mapParams) {
						return BaseService.post('/api/hxyUser/userArchive/addSupplier',mapParams);
			},
			
			//删除设备文档对象
			delDeviceFile : function(id) {
				return BaseService.post('/api/hxyUser/userArchive/delDeviceFile',{
						'id':id
					});
			},
			
			//删除设备事件跟踪对象
			delDeviceTrace : function(deviceTraceId) {
				return BaseService.post('/api/hxyUser/userArchive/delDeviceTrace',{
						'deviceTraceId':deviceTraceId
					});
			},
			
			//修改设备对象的字段
			editDevice : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hxyUser/userArchive/editDevice',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改设备对象
			editDeviceObj : function(mapParams) {
						return BaseService.post('/api/hxyUser/userArchive/editDeviceObj',mapParams);
			},
			
			//修改设备事件跟踪对象
			editDeviceTrace : function(mapParams) {
						return BaseService.post('/api/hxyUser/userArchive/editDeviceTrace',mapParams);
			},
			
			//修改监控对象对象的字段
			editMonitorObject : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hxyUser/userArchive/editMonitorObject',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改供应商对象
			editSupplier : function(mapParams) {
						return BaseService.post('/api/hxyUser/userArchive/editSupplier',mapParams);
			},
			
			//DataTable查询监控对象的告警事件记录
			getAlarmRecord : function(dataTable, monitorObjectId) {
				return BaseService.post('/api/hxyUser/userArchive/getAlarmRecord',{
						'dataTable':dataTable, 'monitorObjectId':monitorObjectId
					});
			},
			
			//查询监控对象的所有采集设备
			getCjObjectByObject : function(monitorObjectId) {
				return BaseService.get('/api/hxyUser/userArchive/getCjObjectByObject',{
						'monitorObjectId':monitorObjectId
					});
			},
			
			//获取用电性质通过节点
			getDataClassifyListByOrgId : function(orgId) {
				return BaseService.get('/api/hxyUser/userArchive/getDataClassifyListByOrgId',{
						'orgId':orgId
					});
			},
			
			//查询 监控对象（采集设备）下的数据项数据
			getDataItemsByObject : function(monitorObjectId, orgId) {
				return BaseService.get('/api/hxyUser/userArchive/getDataItemsByObject',{
						'monitorObjectId':monitorObjectId, 'orgId':orgId
					});
			},
			
			//DataTable 用户设备查询 
			getDataTable : function(dataTable, consumerId) {
				return BaseService.post('/api/hxyUser/userArchive/getdatatable',{
						'dataTable':dataTable, 'consumerId':consumerId
					});
			},
			
			//条件监控对象设备
			getDevice : function(orgId) {
				return BaseService.get('/api/hxyUser/userArchive/getDevice',{
						'orgId':orgId
					});
			},
			
			//查询设备文档和设备型号技术文档
			getDeviceAndModelFile : function(deviceId) {
				return BaseService.get('/api/hxyUser/userArchive/getDeviceAndModelFile',{
						'deviceId':deviceId
					});
			},
			
			//获取设备信息
			getDeviceById : function(id) {
				return BaseService.get('/api/hxyUser/userArchive/getDeviceById',{
						'id':id
					});
			},
			
			//查询设备配置信息和扩展字段
			getDeviceExtendList : function(deviceId, monitorObjectTypeId) {
				return BaseService.get('/api/hxyUser/userArchive/getDeviceExtendList',{
						'deviceId':deviceId, 'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//查询设备型号通过监控对象类型
			getDeviceModelList : function(monitorObjectTypeId) {
				return BaseService.get('/api/hxyUser/userArchive/getDeviceModelList',{
						'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//根据ID获取设备事件跟踪对象
			getDeviceTrace : function(deviceTraceId) {
				return BaseService.get('/api/hxyUser/userArchive/getDeviceTrace',{
						'deviceTraceId':deviceTraceId
					});
			},
			
			//条件查询设备事件跟踪对象
			getDeviceTraceList : function(deviceId, orderBy) {
				return BaseService.get('/api/hxyUser/userArchive/getDeviceTraceList',{
						'deviceId':deviceId, 'orderBy':orderBy
					});
			},
			
			//根据ID获取监控对象对象
			getMonitorObject : function(id) {
				return BaseService.get('/api/hxyUser/userArchive/getMonitorObject',{
						'id':id
					});
			},
			
			//DataTable查询监控对象的运维需求
			getObjectDemand : function(dataTable, monitorObjectId) {
				return BaseService.post('/api/hxyUser/userArchive/getObjectDemand',{
						'dataTable':dataTable, 'monitorObjectId':monitorObjectId
					});
			},
			
			//条件监控对象设备
			getSupplier : function(supplierId) {
				return BaseService.get('/api/hxyUser/userArchive/getSupplier',{
						'supplierId':supplierId
					});
			},
			
			//查询  通讯管理机的采集设备
			searchAmmeters : function(orgId) {
				return BaseService.get('/api/hxyUser/userArchive/searchAmmeters',{
						'orgId':orgId
					});
			},
		},
	    //hxyUser 监控点	    
        UserMonitorItemService :{
			
			//获取监控点的历史数据 
			getHistoryDatas : function(monitorItemId, dataType, startTime, endTime, interval) {
				return BaseService.get('/api/hxyUser/userMonitorItem/getHistoryDatas',{
						'monitorItemId':monitorItemId, 'dataType':dataType, 'startTime':startTime, 'endTime':endTime, 'interval':interval
					});
			},
			
			//通过监控对象 查询监控点
			getListMonitorItems : function(orgId, monitorType) {
				return BaseService.get('/api/hxyUser/userMonitorItem/getListMonitorItems',{
						'orgId':orgId, 'monitorType':monitorType
					});
			},
			
			//视频历史记录 
			videoHistoryDatas : function(monitorItemIds, dataTypes, currMonitorItemId) {
				return BaseService.get('/api/hxyUser/userMonitorItem/videoHistoryDatas',{
						'monitorItemIds':monitorItemIds, 'dataTypes':dataTypes, 'currMonitorItemId':currMonitorItemId
					});
			},
		},
	    //hxyUser 遥控	    
        UserIRemoteControlService :{
			
			//发送控制命令
			sendRelayControl : function(monitorItemId, displayMpNo, mpNos_status) {
				return BaseService.post('/api/hxyUser/userIRemoteControl/sendRelayControlOld',{
						'monitorItemId':monitorItemId, 'displayMpNo':displayMpNo, 'mpNos_status':mpNos_status
					});
			},
			
			//发送控制命令
			sendRelayControlIOT : function(monitorItemId, displayMpNo, mpNos_status, isRead) {
				return BaseService.post('/api/hxyUser/userIRemoteControl/sendRelayControl',{
						'monitorItemId':monitorItemId, 'displayMpNo':displayMpNo, 'mpNos_status':mpNos_status, 'isRead':isRead
					});
			},
		},
 
}

export default user;
import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const HNDataUploadManager = Loadable({
  loader: () => import("./SDNDataUploadManager"),
  loading: Loading
});

const HNMetricalConfig = Loadable({
  loader: () => import("./SDNMetricalConfig"),
  loading: Loading
});

const HNBasicInfoUpload = Loadable({
  loader: () => import("./SDNBasicInfoUpload"),
  loading: Loading
});

const HNDataUploadRecord = Loadable({
  loader: () => import("./SDNDataUploadRecord"),
  loading: Loading
});

const HNDataUploadRecordDetail = Loadable({
  loader: () => import("./components/SDNDataUploadRecordDetail"),
  loading: Loading
});

export const routes = [
  {
    path: "/HunanDataUpload/SDNDataUploadManager",
    exact: true,
    component: HNDataUploadManager,
    name: "HNDataUploadManager"
  },
  {
    path: "/HunanDataUpload/SDNMetricalConfig",
    exact: true,
    component: HNMetricalConfig,
    name: "HNMetricalConfig"
  },
  {
    path: "/HunanDataUpload/SDNBasicInfoUpload",
    exact: true,
    component: HNBasicInfoUpload,
    name: "HNBasicInfoUpload"
  },
  {
    path: "/HunanDataUpload/SDNDataUploadRecord",
    exact: true,
    component: HNDataUploadRecord,
    name: "HNDataUploadRecord"
  },
  {
    path: "/HunanDataUpload/SDNDataUploadRecordDetail",
    exact: true,
    component: HNDataUploadRecordDetail,
    name: "HNDataUploadRecordDetail"
  },

];

export const INIT_PAGE = 'INIT_PAGE';
/* 监控点管理 */
export const SITE_TREE = 'SITE_TREE';
export const SITE_TREE_SUCCESS = 'SITE_TREE_SUCCESS';
export const ENTERPRISE_LIST = 'ENTERPRISE_LIST';
export const ENTERPRISE_LIST_SUCCESS = 'ENTERPRISE_LIST_SUCCESS';
export const SELECT_GROUP = "SELECT_GROUP";
export const SELECT_GROUP_SUCCESS = 'SELECT_GROUP_SUCCESS';
export const SELET_SITE = "SELET_SITE";
export const SITE_LIST_BY_ID = "SITE_LIST_BY_ID";
export const SITE_LIST_BY_ID_SUCCESS = "SITE_LIST_BY_ID_SUCCESS";
export const GET_DATATYPE = 'GET_DATATYPE';
export const GET_DATATYPE_SUCCESS = 'GET_DATATYPE_SUCCESS';
export const GET_PRIMARY_BY_SITE = 'GET_PRIMARY_BY_SITE';
export const GET_PRIMARY_BY_SITE_SUCCESS = 'GET_PRIMARY_BY_SITE_SUCCESS';
export const SEL_PRIMARYITEM = 'SEL_PRIMARYITEM';
export const SEL_PRIMARY_SUCCESS = 'SEL_PRIMARY_SUCCESS';
export const SELECT_PRIMARY = 'SELECT_PRIMARY';
export const DATAITEM_BY_PRIMARY = 'DATAITEM_BY_PRIMARY';
export const DATAITEM_BY_PRIMARY_SUCCESS = 'DATAITEM_BY_PRIMARY_SUCCESS';
export const ADD_RELATION = 'ADD_RELATION';
export const ADD_RELATION_SUCCESS = 'ADD_RELATION_SUCCESS';
export const DEL_DITE = 'DEL_DITE';
export const DEL_DITE_SUCCESS = 'DEL_DITE_SUCCESS';
export const SELECT_DATAITEM = 'SELECT_DATAITEM';
export const SELECT_DATAITEM_SUCCESS = 'SELECT_DATAITEM_SUCCESS';
export const ADD_SITE ='ADD_SITE';
export const ADD_SITE_SUCCESS ='ADD_SITE_SUCCESS';
export const EDIT_SITE = 'EDIT_SITE';
export const EDIT_SITE_SUCCESS = 'EDIT_SITE_SUCCESS';
export const SHOW_BATCH_ADD_MONITOR_SITES = 'SHOW_BATCH_ADD_MONITOR_SITES';
export const BATCH_ADD_MONITOR_SITES = 'BATCH_ADD_MONITOR_SITES';
export const BATCH_CHECKED_MONITOR_SITES = 'BATCH_CHECKED_MONITOR_SITES';
export const ADDING_BATCH_MONITOR_SITES = 'ADDING_BATCH_MONITOR_SITES';
/* 台账管理 */
export const BASE_INFO = 'BASE_INFO';
export const BASE_INFO_SUCCESS = 'BASE_INFO_SUCCESS';
export const EXTEND_INFO = 'EXTEND_INFO';
export const EXTEND_INFO_SUCCESS = 'EXTEND_INFO_SUCCESS';
export const TITILE_INFO = 'TITILE_INFO';
export const TITILE_INFO_SUCCESS = 'TITILE_INFO_SUCCESS';
export const PERSONALITY_INFO = 'PERSONALITY_INFO';
export const PERSONALITY_INFO_SUCCESS = 'PERSONALITY_INFO_SUCCESS';
export const SUBMIT_ADD_PERSONALITY = 'SUBMIT_ADD_PERSONALITY';
export const SUBMIT_ADD_PERSONALITY_SUCCESS = 'SUBMIT_ADD_PERSONALITY_SUCCESS';

export const GET_SELECT_DATAITEMLIST = 'GET_SELECT_DATAITEMLIST';
export const GET_SELECT_DATAITEMLIST_SUCCESS = 'GET_SELECT_DATAITEMLIST_SUCCESS';

export const SELECT_OBJECT = 'SELECT_OBJECT';
export const SELECT_OBJECT_SUCCESS = 'SELECT_OBJECT_SUCCESS';
export const SELECTED_CONSUMER = 'SELECTED_CONSUMER';
export const CONSUMER_TREE = 'CONSUMER_TREE';
export const CONSUMER_TREE_SUCCESS = 'CONSUMER_TREE_SUCCESS';
export const IMG_INFO = 'IMG_INFO';
export const IMG_INFO_SUCCESS = 'IMG_INFO_SUCCESS';
export const FILE_INFO = 'FILE_INFO';
export const FILE_INFO_SUCCESS = 'FILE_INFO_SUCCESS';
export const SUBMIT_ADD_DIAGRAM = 'SUBMIT_ADD_DIAGRAM';
export const SUBMIT_ADD_DIAGRAM_SUCCESS = 'SUBMIT_ADD_DIAGRAM_SUCCESS';
export const DEL_DIAGRAM = 'DEL_DIAGRAM';
export const DEL_DIAGRAM_SUCCESS = 'DEL_DIAGRAM_SUCCESS';
export const SUBMIT_EDIT_DIAGRAM = 'SUBMIT_EDIT_DIAGRAM';
export const SUBMIT_EDIT_DIAGRAM_SUCCESS = 'SUBMIT_EDIT_DIAGRAM_SUCCESS';
export const TEMPLATE_OPTION = 'TEMPLATE_OPTION';
export const TEMPLATE_OPTION_SUCCESS = 'TEMPLATE_OPTION_SUCCESS';
export const GET_RELATION_TREE = 'GET_RELATION_TREE';
export const GET_RELATION_TREE_SUCCESS = 'GET_RELATION_TREE_SUCCESS';
export const GET_DATAITEM_WITH_TREE = 'GET_DATAITEM_WITH_TREE';
export const GET_DATAITEM_WITH_TREE_SUCCESS = 'GET_DATAITEM_WITH_TREE_SUCCESS';
export const EDIT_FIELD_BY_TYPE = 'EDIT_FIELD_BY_TYPE';
export const GET_DEVICE_AMMETER = 'GET_DEVICE_AMMETER';
export const GET_DEVICE_AMMETER_SUCCESS = 'GET_DEVICE_AMMETER_SUCCESS';
export const GET_LIST_BY_MONITOROBJECT = 'GET_LIST_BY_MONITOROBJECT';
export const GET_LIST_BY_MONITOROBJECT_SUCCESS = 'GET_LIST_BY_MONITOROBJECT_SUCCESS';
export const GET_LIST_BY_COMSUMERID = 'GET_LIST_BY_COMSUMERID';
export const GET_LIST_BY_COMSUMERID_SUCCESS = 'GET_LIST_BY_COMSUMERID_SUCCESS';
export const SAVE_CONSUMERNAME = 'SAVE_CONSUMERNAME';
export const GET_SJZXAMMETERS = 'GET_SJZXAMMETERS';
export const GET_SJZXAMMETERS_SUCCESS = 'GET_SJZXAMMETERS_SUCCESS';
export const SUBMIT_ADD_MULTI = 'SUBMIT_ADD_MULTI';
export const SUBMIT_ADD_MULTI_SUCCESS = 'SUBMIT_ADD_MULTI_SUCCESS';
export const DEL_MONITOR_RELATION = 'DEL_MONITOR_RELATION';
export const DEL_MONITOR_RELATION_SUCCESS = 'DEL_MONITOR_RELATION_SUCCESS';
export const SUBMIT_EDIT_PERSONALITY ='SUBMIT_EDIT_PERSONALITY';
export const SUBMIT_EDIT_PERSONALITY_SUCCESS = 'SUBMIT_EDIT_PERSONALITY_SUCCESS';
export const GET_LIST_BY_PAMMETER = 'GET_LIST_BY_PAMMETER';
export const GET_LIST_BY_PAMMETER_SUCCESS = 'GET_LIST_BY_PAMMETER_SUCCESS';
export const EDIT_RECALCULTION = 'EDIT_RECALCULTION';
export const EDIT_RECALCULTION_SUCCESS = 'EDIT_RECALCULTION_SUCCESS';
export const REMOVE_AMMETER_RELATION = 'REMOVE_AMMETER_RELATION';
export const REMOVE_AMMETER_RELATION_SUCCESS = 'REMOVE_AMMETER_RELATION_SUCCESS';
export const ADD_AMMETER_RELATIONS = 'ADD_AMMETER_RELATIONS';
export const ADD_AMMETER_RELATIONS_SUCCESS = 'ADD_AMMETER_RELATIONS_SUCCESS';
export const CHANGE_TREE_NODE = 'CHANGE_TREE_NODE';
export const CHANGE_TREE_NODE_SUCCESS = 'CHANGE_TREE_NODE_SUCCESS';
export const CHNANGE_TITLEINFO = 'CHNANGE_TITLEINFO';
export const CHNANGE_TITLEINFO_SUCCESS = 'CHNANGE_TITLEINFO_SUCCESS';
export const CHNANGE_OTHERINFO = 'CHNANGE_OTHERINFO';
export const CHNANGE_OTHERINFO_SUCCESS = 'CHNANGE_OTHERINFO_SUCCESS';
export const CHNANGE_SITEINFO = 'CHNANGE_SITEINFO';
export const CHNANGE_SITEINFO_SUCCESS = 'CHNANGE_SITEINFO_SUCCESS';
export const CHNANGE_EXTENDINFO = 'CHNANGE_EXTENDINFO';
export const CHNANGE_EXTENDINFO_SUCCESS = 'CHNANGE_EXTENDINFO_SUCCESS';
export const EDIT_DATA_AMMTER = 'EDIT_DATA_AMMTER';
export const GET_TREE_NODE_BASEINFO = 'GET_TREE_NODE_BASEINFO';

export const SHOW_MONITOR_DATA_CHART = 'SHOW_MONITOR_DATA_CHART';
/* 模板管理 */
export const CHOOSE_SITE = 'CHOOSE_SITE';
export const CHOOSE_SITE_SUCCESS = 'CHOOSE_SITE_SUCCESS';
export const CHOOSE_NODE = 'CHOOSE_NODE';
export const CHOOSE_NODE_SUCCESS = 'CHOOSE_NODE_SUCCESS';
export const ADD_TEMPLATE='ADD_TEMPLATE'
export const ADD_TEMPLATE_SUCCESS='ADD_TEMPLATE_SUCCESS'
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const REMOVE_TEMPLATE_SUCCESS = 'REMOVE_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';

//----------拍摄方案↓----------//
export const GET_SHOOTING_SCHEMES_BY_ID = 'GET_SHOOTING_SCHEMES_BY_ID';
export const SET_SHOOTING_SCHEMES_BY_ID = 'SET_SHOOTING_SCHEMES_BY_ID';
export const SET_MONITOR_ITEM_LIST = 'SET_MONITOR_ITEM_LIST';
export const GET_MONITOR_ITEM_LIST = 'GET_MONITOR_ITEM_LIST';
export const GET_SHOOTING_SCHEME = "GET_SHOOTING_SCHEME";
export const SET_SHOOTING_SCHEME = "SET_SHOOTING_SCHEME";
export const EDIT_SHOOTING_SCHEME_SUCCESS = "EDIT_SHOOTING_SCHEME_SUCCESS";
export const EDIT_SHOOTING_SCHEME_ITEM = "EDIT_SHOOTING_SCHEME_ITEM";
export const EDIT_SHOOTING_SCHEME = "EDIT_SHOOTING_SCHEME";
export const ADD_SHOOTING_SCHEME = "ADD_SHOOTING_SCHEME";
//----------拍摄方案↑----------//

//层级关系
export const REMOVE_SUB_LEVEL = 'REMOVE_SUB_LEVEL';
export const REMOVE_SUB_LEVEL_SUCCESS = 'REMOVE_SUB_LEVEL_SUCCESS';
export const UPGRADE_DEGRADE_SUB_LEVEL = 'UPGRADE_DEGRADE_SUB_LEVEL';
export const UPGRADE_DEGRAD_SUB_LEVEL_SUCCESS = 'UPGRADE_DEGRAD_SUB_LEVEL_SUCCESS';
export const ADD_SUB_LEVEL_STATION = 'ADD_SUB_LEVEL_STATION'; //新增站点
export const ADD_SUB_LEVEL_LOCATION = 'ADD_SUB_LEVEL_LOCATION'; //新增位置
export const ADD_SUB_LEVEL_DEVICE = 'ADD_SUB_LEVEL_DEVICE'; //新增设备
export const ADD_SUB_LEVEL_GROUP = 'ADD_SUB_LEVEL_GROUP'; //新增总加组
export const ADD_SUB_LEVEL_SUCCESS = 'ADD_SUB_LEVEL_SUCCESS'; //新增成功
export const GET_ADDABLE_NODE_TYPE = 'GET_ADDABLE_NODE_TYPE'; //获取可添加下级类型
export const GET_ADDABLE_NODE_TYPE_SUCCESS = 'GET_ADDABLE_NODE_TYPE_SUCCESS';


export const GET_QUERY_UALA = 'GET_QUERY_UALA';
export const SET_QUERY_UALA = 'SET_QUERY_UALA';
export const RECALC_DATA = 'RECALC_DATA';

export const GET_COM_DEVICE_LIST = 'GET_COM_DEVICE_LIST'; //获取通讯管理机
export const SET_COM_DEVICE_LIST = 'SET_COM_DEVICE_LIST'; //获取通讯管理机

export const GET_COM_DEVICE_BIND_DEVICES_LIST = 'GET_COM_DEVICE_BIND_DEVICES_LIST'; //获取通讯管理机绑定设备列表
export const SET_COM_DEVICE_BIND_DEVICES_LIST = 'SET_COM_DEVICE_BIND_DEVICES_LIST';
import React from "react";

import { Layout } from 'antd';

const { Footer } = Layout;

export default class SDNFooter extends React.Component {
  render() {
    return (
      <Footer 
        style={{ 
          color:'#FFF', 
          fontSize: 14, 
          textAlign: 'center',
          background: '#001529' 
        }}
      >
          ©2019 版权所有 不得转载
      </Footer>
    );
  }
}

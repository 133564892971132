// --------------------增删改查告警 ↓-------------------- //
export const GET_ALERT_BY_ID = 'GET_ALERT_BY_ID';
export const SET_ALERT_BY_ID = 'SET_ALERT_BY_ID';
export const EDIT_ALERT = 'EDIT_ALERT';
export const SUBMIT_ADD_ALERT_SUCCESS = 'SUBMIT_ADD_ALERT_SUCCESS';
export const SUBMIT_EDIT_ALERT_SUCCESS = 'SUBMIT_EDIT_ALERT_SUCCESS';
export const SUBMIT_EDIT_ALERT = 'SUBMIT_EDIT_ALERT';
export const REMOVE_ALERT_BY_ID = 'REMOVE_ALERT_BY_ID';
export const ALERT_REMOVED = 'ALERT_REMOVED';
export const SUBMIT_ADD_ALERT = 'SUBMIT_ADD_ALERT';
// --------------------增删改查告警 ↑-------------------- //

// --------------------添加参数 ↓-------------------- //
export const GET_DATA_ITEM = 'GET_DATA_ITEM';
export const SET_DATA_ITEM = 'SET_DATA_ITEM';
export const EDIT_ALERT_PARAMS = 'EDIT_ALERT_PARAMS';
export const ADD_ALERT_PARAMS = 'ADD_ALERT_PARAMS';
export const SUBMIT_EDIT_ALERT_PARAMS = 'SUBMIT_EDIT_ALERT_PARAMS';
export const SUBMIT_ADD_ALERT_PARAMS = 'SUBMIT_ADD_ALERT_PARAMS';
// --------------------添加参数 ↑-------------------- //
// --------------------告警配置 ↓-------------------- //
export const SET_GROUP_LIST = "SET_GROUP_LIST";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const SET_SITE_LIST_BY_ID = "SET_SITE_LIST_BY_ID";
export const GET_SITE_LIST_BY_ID = "GET_SITE_LIST_BY_ID";
export const SET_DEVICE_TREE = "SET_DEVICE_TREE";
export const GET_DEVICE_TREE = "GET_DEVICE_TREE";
export const SEL_GROUP_ITEM = "SEL_GROUP_ITEM";
export const SEL_SITE_ITEM = "SEL_SITE_ITEM";
export const REMOVE_LINK_BY_ID = "REMOVE_LINK_BY_ID";
export const LINK_REMOVED = "LINK_REMOVED";
export const GRAFANA_REMOVED = "GRAFANA_REMOVED";
export const SET_LINKAGE_CONFIG = "SET_LINKAGE_CONFIG";
export const GET_LINKAGE_CONFIG = "GET_LINKAGE_CONFIG";
export const SUBMIT_EDIT_LINKAGE_SUCCESS = "SUBMIT_EDIT_LINKAGE_SUCCESS";
export const SUBMIT_ADD_LINKAGE_SUCCESS = "SUBMIT_ADD_LINKAGE_SUCCESS";
export const EDIT_EVENT_CONFIG_FIELD_SUCCESS = "EDIT_EVENT_CONFIG_FIELD_SUCCESS";
export const SUBMIT_EDIT_LINKAGE = "SUBMIT_EDIT_LINKAGE";
export const SUBMIT_ADD_LINKAGE = "SUBMIT_ADD_LINKAGE";
export const EDIT_LINK = "EDIT_LINK";
export const SET_SET_DATA_TYPE = "SET_SET_DATA_TYPE";
export const SET_MONITOR_ITEMS = "SET_MONITOR_ITEMS";
export const SET_CAMERA_ITEM = "SET_CAMERA_ITEM";
export const GET_CAMERA_ITEM = "GET_CAMERA_ITEM";
export const SET_CONTROL_ITEM = "SET_CONTROL_ITEM";
export const GET_CONTROL_ITEM = "GET_CONTROL_ITEM";
export const SET_EVENT_CONFIG_BY_ID = "SET_EVENT_CONFIG_BY_ID";
export const GET_EVENT_CONFIG_BY_ID = "GET_EVENT_CONFIG_BY_ID";
export const GET_MONITOR_ITEMS_BY_ID = "GET_MONITOR_ITEMS_BY_ID";
export const EDIT_EVENT_CONFIG = "EDIT_EVENT_CONFIG";
export const EDIT_EVENT_CONFIG_FIELD = "EDIT_EVENT_CONFIG_FIELD";
export const SUBMIT_EDIT_EVENT_CONFIG = "SUBMIT_EDIT_EVENT_CONFIG";
export const SUBMIT_EDIT_EVENT_CONFIG_SUCCESS = "SUBMIT_EDIT_EVENT_CONFIG_SUCCESS";
export const NEW_ALERT_LINK_TYPE = "NEW_ALERT_LINK_TYPE";
export const COPY_ALERT_LINK_TYPE = "COPY_ALERT_LINK_TYPE";
export const EDIT_ALERT_LINK_TYPE = "EDIT_ALERT_LINK_TYPE";
// --------------------告警配置 ↑-------------------- //
// --------------------告警查询 ↓-------------------- //
export const SET_SITE_LIST = "SET_SITE_LIST";
export const GET_SITE_LIST = "GET_SITE_LIST";
export const SET_ALERT_COUNT = "SET_ALERT_COUNT";
export const GET_ALERT_COUNT = "GET_ALERT_COUNT";
export const CONFIRM_ALERT = "CONFIRM_ALERT";
export const CONFIRM_ALERT_SUCCESS = "CONFIRM_ALERT_SUCCESS";
// --------------------告警查询 ↑-------------------- //
// --------------------综合告警 ↓-------------------- //
export const INIT_COM_PAGE = "INIT_COM_PAGE";
export const SUBMIT_ADD_GRAFANA_ALERT = "SUBMIT_ADD_GRAFANA_ALERT";
export const SUBMIT_EDIT_GRAFANA_ALERT = "SUBMIT_EDIT_GRAFANA_ALERT";
export const SET_MONITOR_BY_SITE = "SET_MONITOR_BY_SITE";
export const GET_MONITOR_BY_SITE = "GET_MONITOR_BY_SITE";
export const SET_SECOND_BY_SITE = "SET_SECOND_BY_SITE";
export const GET_SECOND_BY_SITE = "GET_SECOND_BY_SITE";
export const REMOVE_GRAFANA_ALERT_BY_ID = "REMOVE_GRAFANA_ALERT_BY_ID";
export const SUBMIT_ADD_COM_ALARM_SUCCESS = "SUBMIT_ADD_COM_ALARM_SUCCESS";
export const SUBMIT_EDIT_COM_ALARM_SUCCESS = "SUBMIT_EDIT_COM_ALARM_SUCCESS";
export const SET_COM_VIEW_CONFIG = "SET_COM_VIEW_CONFIG";

// --------------------综合告警 ↑-------------------- //
export const SET_GRAFANA_URL = "SET_GRAFANA_URL";
export const GET_GRAFANA_URL = "GET_GRAFANA_URL";
export const EDIT_GRAFANA_URL = "EDIT_GRAFANA_URL";

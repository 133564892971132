import React from 'react'

export default class OperateButton extends React.Component {

    static defaultProps = {
        perm: '',
        aClass: '',
        awesomeClass: '',
        iClass: '',
        onClick: {},
        text: '',
    }

    render() {
        const {
            perm,
            onClick,
            aClass,
            awesomeClass,
            iClass,
            text,
        } = this.props;
        const aClassName = 'btn btn-edit btn-default btn-xs hover-tooltip ' + aClass
        let fontClassName = '';
        if (awesomeClass.length > 0) {
          fontClassName = 'fa ' + awesomeClass;
        }
        else {
          fontClassName = 'iconfont ' + iClass;
        }

        return (
                <span className="margin-0-2"
                    perm={perm}
                    style={{display: 'inline'}}>
                    <button className={aClassName}
                       onClick={onClick}>
                    <span className="tooltip-model">{text}</span>
                    <i className={fontClassName}></i>
                </button>
                </span>
        );
    }

}


export const getLayout= state => state.layout
export const getOutlook= state => state.outlook
export const getSystem = state => state.system
export const getChat= state => state.chat
export const getEvents= state => state.events
export const getTodo= state => state.todo
export const getI18n= state => state.i18n

export const getSysAdmin = state => state.sysAdmin
export const getDemandAnalysis = state => state.demandAnalysis
export const getElectricQuality = state => state.electricQuality
export const getPeakAnalysis = state => state.peakAnalysis
export const getPowerPeakAnalysis = state => state.powerPeakAnalysis
export const getBaseConfig= state => state.baseConfig
export const getEventAlert= state => state.eventAlert
export const getEquipment= state => state.equipment
export const getOperationAdmin= state => state.operationAdmin
export const getPowerAnalysis= state => state.powerAnalysis

import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNPowerBill = Loadable({
  loader: () => import("./SDNPowerBill"),
  loading: Loading
});

const SDNBalanceNet = Loadable({
  loader: () => import("./SDNBalanceNet"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNPowerAudit/SDNPowerBill",
    exact: true,
    component: SDNPowerBill,
    name: "SDNPowerBill"
  },
  {
    path: "/SDNPowerAudit/SDNBalanceNet",
    exact: true,
    component: SDNBalanceNet,
    name: "SDNBalanceNet"
  }
];
import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNGatewayCommunication = Loadable({
  loader: () => import("./SDNGatewayCommunication"),
  loading: Loading
});

const SDNMeterCommunication = Loadable({
  loader: () => import("./SDNMeterCommunication"),
  loading: Loading
});

const SDNCollectionRateStatistics = Loadable({
  loader: () => import("./SDNCollectionRateStatistics"),
  loading: Loading
});


export const routes = [
  {
    path: "/SDNCommunication/SDNGatewayCommunication",
    exact: true,
    component: SDNGatewayCommunication,
    name: "SDNGatewayCommunication"
  },
  {
    path: "/SDNCommunication/SDNMeterCommunication",
    exact: true,
    component: SDNMeterCommunication,
    name: "SDNMeterCommunication"
  },
  {
    path: "/SDNCommunication/SDNCollectionRateStatistics",
    exact: true,
    component: SDNCollectionRateStatistics,
    name: "SDNCollectionRateStatistics"
  },  
];

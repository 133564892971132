import { BaseService } from '../SDNRequest';

const service = {
	    //行业资讯	    
        NewsService :{
			
			//新增知识信息
			add : function(newsJSON, upFile) {
				return BaseService.upload('/api/service/news/add',{
						'newsJSON':newsJSON, 'upFile':upFile
					});
			},
			
			//删除行业资讯对象
			del : function(id) {
				return BaseService.post('/api/service/news/del',{
						'id':id
					});
			},
			
			//修改知识对象
			edit : function(newsJSON, upFile) {
				return BaseService.upload('/api/service/news/edit',{
						'newsJSON':newsJSON, 'upFile':upFile
					});
			},
			
			//修改行业资讯对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/service/news/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改知识信息对象不附带图片文件
			editNoFiles : function(mapParams) {
						return BaseService.post('/api/service/news/editNoFiles',mapParams);
			},
			
			//导出行业资讯对象到文件
			exportAll : function(dt, title, orderBy, fieldName) {
				return BaseService.download('/api/service/news/exportall',{
						'dt':dt, 'title':title, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出行业资讯对象到文件
			exportDataTable : function(dataTable, dt, title, fieldName) {
				return BaseService.download('/api/service/news/exportdatatable',{
						'dataTable':dataTable, 'dt':dt, 'title':title, 'fieldName':fieldName
					});
			},
			
			//根据ID获取知识对象
			get : function(id) {
				return BaseService.get('/api/service/news/get',{
						'id':id
					});
			},
			
			//查询所有行业资讯对象
			getAll : function() {
				return BaseService.get('/api/service/news/getall');
			},
			
			//DataTable查询资讯分页数据
			getDataTable : function(dataTable) {
				return BaseService.post('/api/service/news/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询运维策略的行业资讯
			getOperationNews : function() {
				return BaseService.get('/api/service/news/getOperationNews');
			},
			
			//分页显示行业资讯对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/service/news/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询行业资讯对象
			searchAll : function(dt, title, orderBy) {
				return BaseService.get('/api/service/news/searchall',{
						'dt':dt, 'title':title, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询行业资讯对象
			searchDataTable : function(dataTable, dt, title) {
				return BaseService.get('/api/service/news/searchdatatable',{
						'dataTable':dataTable, 'dt':dt, 'title':title
					});
			},
			
			//条件查询行业资讯对象
			searchPage : function(dt, title, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/service/news/searchpage',{
						'dt':dt, 'title':title, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
 
}

export default service;
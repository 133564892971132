import {archive, events, sys} from "../../service";
import {call, put, takeLatest} from 'redux-saga/effects';
import {
  DATAITEM_ROLE_REMOVED,
  GET_DEVICETYPE_ROLE_WITH_FUN,
  GET_ROLE_DETAIL,
  GET_SELECT_MENU,
  REMOVE_DATAITEM_ROLE,
  REMOVE_DEVICETYPE_ROLE,
  SET_DEVICETYPE_ROLE_WITH_FUN,
  SET_ROLE_WITH_FUN,
  SET_SELECT_MENU,
  SUBMITE_EDIT_DATAITEM_ROLE,
  SUBMITE_EDIT_DEVICETYPE_ROLE,
  SUBMITE_EDIT_ROLE_SUCCESS,
  SUBMITE_NEW_DEVICETYPE_ROLE,
  REMOVE_UNITTYPE_ROLE,
  SUBMITE_EDIT_UNITTYPE_ROLE,
  SUBMITE_NEW_UNITTYPE_ROLE,
  GET_UNITTYPE_ROLE_WITH_FUN,
  REMOVE_MONITOROBJECT_ROLE,
  GET_MONITOROBJECT_ROLE_WITH_FUN,
  SUBMITE_EDIT_MONITOROBJECT_ROLE,
  SUBMITE_NEW_MONITOROBJECT_ROLE,
  DATAITEMCONFIG_GET_MAIN_TITLE_INFO,
  DATAITEMCONFIG_GET_DATA_ARRAY,
  DATAITEMCONFIG_GET_MAIN_TITLE_INFO_BACK,
  DATAITEMCONFIG_GET_DATA_ARRAY_BACK,
  SUBMITE_EDIT_DATAITEMCONFIG_ROLE,
  SUBMITE_NEW_DATAITEMCONFIG_ROLE,
  DATAITEMCONFIG_GET_DATE_ZONE_LIST_BACK,
  DATAITEMCONFIG_GET_DATE_ZONE_LIST,
  GET_MONI_OB_EXTRA_ROLE_WITH_FUN,
  GET_MONI_OB_EXTRA_ROLE_WITH_FUN_BACK,
  SUBMITE_EDIT_MONI_OB_EXTRA_ROLE,
  SUBMITE_NEW_MONI_OB_EXTRA_ROLE,
  REMOVE_MONI_OB_EXTRA_ROLE,
  EDIT_MONI_OB_CONFIG_ROLE,
  REMOVE_DATAITEMCONFIG_ROLE,
  DATAITEMCONFIG_ROLE_REMOVED,
  SUBMITE_ADD_ROLE_SUCCESS,
  SUBMITE_ADD_MONI_OB_ITEM,
  GET_MONI_OB_ALL_GRAPH_ENTITY,
  GET_MONI_OB_ALL_GRAPH_ENTITY_BACK,
  GET_MONI_OB_SETTING_ITEM_DICTIONARY,
  GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK,
  EDIT_MONI_OB_CONFIG_GRAPHENTITY_ROLE,
  REMOVE_MONI_OB_GRAPH_ENTITY_ROLE,
  GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST_BACK,
  GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST,
  SUBMITE_ADD_GRAPH_ENTITY_OB_ITEM,
  ADD_MONI_OB_GRAPH_ATTR,
  SUBMITE_NEW_GRAPH_ENTITY_ROLE,
  SUBMITE_EDIT_GRAPH_ENTITY_ROLE,
  GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST_BACK_DO,
  DO_REFLASH_TABLE,
  GET_ALL_INFO_LIST,
  SUBMITE_NEW_UNITTYPE_ROLE_BACK,
  REMOVE_MONI_OB_CONFIG_ROLE,
  REMOVE_EDIT_GRAPH_ROLE,
  SUBMITE_NEW_GRAPH_ENTITY_ROLE_BACK,
  SUBMITE_EDIT_GRAPH_ENTITY_ROLE_BACK,
  SUBMITE_ADD_DATAITEM_ROLE, SUBMITE_EDIT_ROLE_DEFEATE
} from "./SDNBaseConfigActionType";
//import alarm from "../../service/API/alarm";

export function* getRoleDetail(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.DataItemService.get, action.payload);
    yield put({type: SET_ROLE_WITH_FUN, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* getDeviceTypeRoleDetail(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.DeviceModelService.get, action.payload);
    yield put({type: SET_DEVICETYPE_ROLE_WITH_FUN, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* getSelectMenu(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.MonitorObjectTypeService.getAll, action.payload);
    yield put({type: SET_SELECT_MENU, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* submitEditDataItemRole(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.DataItemService.edit, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* submitAddDataItemRole(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.DataItemService.add, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}


export function* removeDataItemRole(action) {
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.DataItemService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

/* 删除设备类型 */
export function* removeDeviceTypeRole(action) {
  // //;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.DeviceModelService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//修改设备类型单行
export function* submitEditDeviceTypeRole(action) {
  // //;
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.DeviceModelService.edit, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

//添加设备类型单行
export function* submitNewDeviceTypeRole(action) {
  // ;
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.DeviceModelService.add, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}


/* 获取管理单元类型单行 */
export function* getUnitTypeRoleDetail(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.UnitTypeService.get, action.payload);
    yield put({type: SET_ROLE_WITH_FUN, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

/* 删除管理单元类型 */
export function* removeUnitTypeRole(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.UnitTypeService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//修改管理单元类型单行
export function* submitEditUnitTypeRole(action) {
  // ;
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.UnitTypeService.edit, action.payload.role, "", action.payload.files);
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

//添加管理单元类型单行
export function* submitNewUnitTypeRole(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.UnitTypeService.add, action.payload.role, "", action.payload.files);
    // ;
    yield put({type: SUBMITE_NEW_UNITTYPE_ROLE_BACK, payload: ret});
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}



/* 删除监控对象类型 */
export function* removeMonitorObjectRole(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.MonitorObjectTypeService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//获取数据项配置
export function* getMainTitleInfo(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.DataItemService.get, action.payload);
  yield put({type: DATAITEMCONFIG_GET_MAIN_TITLE_INFO_BACK, payload: ret});
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getDataArray(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(events.LimitSetService.getListByDataItem, action.payload);
  yield put({type: DATAITEMCONFIG_GET_DATA_ARRAY_BACK, payload: ret});
  yield put({type: 'FETCH_SUCCESS'});
}

export function* newDataItemConfig(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(events.LimitSetService.add, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* editDataItemConfig(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(events.LimitSetService.edit, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* removeDataItemConfig(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(events.LimitSetService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getDateZoneList(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(events.TimezoneService.getAll, action.payload);
  yield put({type: DATAITEMCONFIG_GET_DATE_ZONE_LIST_BACK, payload: ret});
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getMoniObExtraRoleData(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.MonitorTypeExtendService.get, action.payload);
    yield put({type: GET_MONI_OB_EXTRA_ROLE_WITH_FUN_BACK, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* removeMoniObExtraRoleData(action) {
  // ;
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.MonitorTypeExtendService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* editMoniObExtraRoleData(action) {
  //
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.MonitorTypeExtendService.edit, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* newMoniObExtraRoleData(action) {
  //
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.MonitorTypeExtendService.add, action.payload);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* removeMoniObConfigRoleData(action) {
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.MonitorTypeDataService.removeDataItem, action.payload.monitorTypeDataId, action.payload.dataItemId);
  console.log(ret)
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* removeGraphEntityData(action) {
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.GraphEntityService.del, action.payload.id);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
    const res = yield call(archive.GraphEntityService.getAllByMonitorObjectType, action.payload.moniId);
    yield put({type: GET_MONI_OB_ALL_GRAPH_ENTITY_BACK, payload: res});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* editMoniObConfigRoleData(action) {
  //
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.MonitorTypeDataService.editField, action.payload.id, action.payload.type, action.payload.info, '');
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    } else {
      yield put({type: SUBMITE_EDIT_ROLE_DEFEATE});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* editMoniObConfigGraphEntityRoleData(action) {
  //
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.GraphEntityParamService.editField, action.payload.id, action.payload.type, action.payload.info, '');
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* addMoniObConfigItem(action) {
  //
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.MonitorTypeDataService.addDataItem, action.payload.id, action.payload.listStr);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* addGraphEntityItem(action) {
  //
  try {
    yield put({type: 'FETCH_REQUEST'});
    // ;
    const ret = yield call(archive.GraphEntityParamService.addDataItem, action.payload.id, action.payload.listStr);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* getAllGraphEntity(action) {
  try {
    //
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.GraphEntityService.getAllByMonitorObjectType, action.payload);
    yield put({type: GET_MONI_OB_ALL_GRAPH_ENTITY_BACK, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* getByCategroyCode(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(sys.DictionaryService.getByCategroyCode, action.payload);
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: action.payload, res: res}});
    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* removeGraphEntityDataItemRole(action) {
  yield put({type: 'FETCH_REQUEST'});
  const ret = yield call(archive.GraphEntityParamService.del, action.payload);
  if (ret.code === 200) {
    yield put({type: DATAITEM_ROLE_REMOVED});
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getMoniGraphEntityExtendInfo(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const res = yield call(archive.MonitorTypeExtendService.getGraphEntityExtendInfo, action.payload);
    yield put({type: GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST_BACK, payload: res});

    yield put({type: GET_ALL_INFO_LIST});
    /*
    const res2 = yield call(sys.DictionaryService.getByCategroyCode, 'controlProp');
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: 'controlProp', res: res2}});

    const res3 = yield call(sys.DictionaryService.getByCategroyCode, 'realTimeData');
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: 'realTimeData', res: res3}});
    */

    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* getAllInfoList(action) {
  //改装成获取所有参数列表的方法
  try {
    //;
    yield put({type: 'FETCH_REQUEST'});

    const res2 = yield call(sys.DictionaryService.getByCategroyCode, 'controlProp');
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: 'controlProp', res: res2}});

    const res3 = yield call(sys.DictionaryService.getByCategroyCode, 'realTimeData');
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: 'realTimeData', res: res3}});

    const res4 = yield call(sys.DictionaryService.getByCategroyCode, 'event');
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: 'event', res: res4}});

    const res5 = yield call(sys.DictionaryService.getByCategroyCode, 'dataItemAttr');
    yield put({type: GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK, payload: {key: 'dataItemAttr', res: res5}});

    yield put({type: DO_REFLASH_TABLE});

    yield put({type: 'FETCH_SUCCESS'});
  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* addMoniObGraphAttr(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    //;
    var tempOb = {
      controlType: action.payload.type,
      graphEntity: action.payload.orgId
    };
    const ret = yield call(archive.GraphEntityParamService.add, tempOb);
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* addGraphEntityRole(action) {
  try {
    var tempOb = {
      entityName: action.payload.name,
      entityUrl: action.payload.url,
      monitorObjectType: {
        monitorObjectTypeId: action.payload.id,
      }
    };
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.GraphEntityService.add, tempOb);
    //;
    if (!ret.code) {
      yield put({type: SUBMITE_NEW_GRAPH_ENTITY_ROLE_BACK, payload: ret});
      const res = yield call(archive.GraphEntityService.getAllByMonitorObjectType, action.payload.id);
      yield put({type: GET_MONI_OB_ALL_GRAPH_ENTITY_BACK, payload: res});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* editGraphEntityRole(action) {
  try {
    //;
    var tempOb = {
      entityName: action.payload.roleInfo.entityName,
      entityUrl: action.payload.roleInfo.entityUrl,
      graphEntityId: action.payload.roleInfo.graphEntityId,
      monitorObjectType: action.payload.roleInfo.monitorObjectType
    };
    yield put({type: 'FETCH_REQUEST'});
    //;
    const ret = yield call(archive.GraphEntityService.edit, tempOb);
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_GRAPH_ENTITY_ROLE_BACK, payload: ret});
      const res = yield call(archive.GraphEntityService.getAllByMonitorObjectType, action.payload.id);
      yield put({type: GET_MONI_OB_ALL_GRAPH_ENTITY_BACK, payload: res});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* submitNewMonitorObjectRole(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.MonitorObjectTypeService.add, JSON.stringify(action.payload.role),action.payload.files,'');
    //;
    if (ret.code === 200) {
      yield put({type: SUBMITE_ADD_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* submitEditMonitorObjectRole(action) {
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(archive.MonitorObjectTypeService.edit, JSON.stringify(action.payload.role),action.payload.files, action.payload.listFiles);
    //;
    if (ret.code === 200) {
      yield put({type: SUBMITE_EDIT_ROLE_SUCCESS});
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch (error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}


const baseConfigSagas = [
  takeLatest(SUBMITE_EDIT_DATAITEM_ROLE, submitEditDataItemRole),//修改单行数据
  takeLatest(SUBMITE_ADD_DATAITEM_ROLE, submitAddDataItemRole),//修改单行数据
  takeLatest(GET_ROLE_DETAIL, getRoleDetail),//获取单行数据（目前么有用，直接由datatable传入）
  takeLatest(REMOVE_DATAITEM_ROLE, removeDataItemRole),//删除单行

  takeLatest(REMOVE_DEVICETYPE_ROLE, removeDeviceTypeRole),//删除单行
  takeLatest(GET_SELECT_MENU, getSelectMenu),//删除单行
  takeLatest(GET_DEVICETYPE_ROLE_WITH_FUN, getDeviceTypeRoleDetail),//获取单行数据（目前么有用，直接由datatable传入）
  takeLatest(SUBMITE_EDIT_DEVICETYPE_ROLE, submitEditDeviceTypeRole),//修改单行数据
  takeLatest(SUBMITE_NEW_DEVICETYPE_ROLE, submitNewDeviceTypeRole),//新建单行数据

  takeLatest(REMOVE_UNITTYPE_ROLE, removeUnitTypeRole),//删除单行
  takeLatest(GET_UNITTYPE_ROLE_WITH_FUN, getUnitTypeRoleDetail),//
  takeLatest(SUBMITE_EDIT_UNITTYPE_ROLE, submitEditUnitTypeRole),//
  takeLatest(SUBMITE_NEW_UNITTYPE_ROLE, submitNewUnitTypeRole),//

  takeLatest(REMOVE_MONITOROBJECT_ROLE, removeMonitorObjectRole),//删除单行
  //takeLatest(GET_MONITOROBJECT_ROLE_WITH_FUN, getMonitorObjectRoleDetail),
  takeLatest(SUBMITE_EDIT_MONITOROBJECT_ROLE, submitEditMonitorObjectRole),
  takeLatest(SUBMITE_NEW_MONITOROBJECT_ROLE, submitNewMonitorObjectRole),

  takeLatest(DATAITEMCONFIG_GET_MAIN_TITLE_INFO, getMainTitleInfo),//
  takeLatest(DATAITEMCONFIG_GET_DATA_ARRAY, getDataArray),//
  takeLatest(SUBMITE_EDIT_DATAITEMCONFIG_ROLE, editDataItemConfig),//
  takeLatest(SUBMITE_NEW_DATAITEMCONFIG_ROLE, newDataItemConfig),//
  takeLatest(DATAITEMCONFIG_GET_DATE_ZONE_LIST, getDateZoneList),//
  takeLatest(REMOVE_DATAITEMCONFIG_ROLE, removeDataItemConfig),//

  takeLatest(GET_MONI_OB_EXTRA_ROLE_WITH_FUN, getMoniObExtraRoleData),//
  takeLatest(SUBMITE_EDIT_MONI_OB_EXTRA_ROLE, editMoniObExtraRoleData),//
  takeLatest(SUBMITE_NEW_MONI_OB_EXTRA_ROLE, newMoniObExtraRoleData),//
  takeLatest(REMOVE_MONI_OB_EXTRA_ROLE, removeMoniObExtraRoleData),//
  takeLatest(EDIT_MONI_OB_CONFIG_ROLE, editMoniObConfigRoleData),//
  takeLatest(REMOVE_MONI_OB_CONFIG_ROLE, removeMoniObConfigRoleData),//
  takeLatest(SUBMITE_ADD_MONI_OB_ITEM, addMoniObConfigItem),//
  takeLatest(SUBMITE_ADD_GRAPH_ENTITY_OB_ITEM, addGraphEntityItem),//
  takeLatest(GET_MONI_OB_ALL_GRAPH_ENTITY, getAllGraphEntity),//
  takeLatest(GET_MONI_OB_SETTING_ITEM_DICTIONARY, getByCategroyCode),//
  takeLatest(EDIT_MONI_OB_CONFIG_GRAPHENTITY_ROLE, editMoniObConfigGraphEntityRoleData),//
  takeLatest(REMOVE_MONI_OB_GRAPH_ENTITY_ROLE, removeGraphEntityDataItemRole),//
  takeLatest(GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST, getMoniGraphEntityExtendInfo),//
  takeLatest(ADD_MONI_OB_GRAPH_ATTR, addMoniObGraphAttr),//
  takeLatest(REMOVE_EDIT_GRAPH_ROLE, removeGraphEntityData),//

  takeLatest(SUBMITE_NEW_GRAPH_ENTITY_ROLE, addGraphEntityRole),//
  takeLatest(SUBMITE_EDIT_GRAPH_ENTITY_ROLE, editGraphEntityRole),//

  takeLatest(GET_ALL_INFO_LIST, getAllInfoList),//

];

export default baseConfigSagas;

import { efficiency } from "../../../service";
import { put, takeLatest, call } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';

export const POWER_RANK_ENERGER_TOP = 'POWER_RANK_ENERGER_TOP';
export const POWER_RANK_ENERGER_TOP_SUCCESS = 'POWER_RANK_ENERGER_TOP_SUCCESS';

export const POWER_RANK_ENERGY_HOUR_TOP = 'POWER_RANK_ENERGY_HOUR_TOP';
export const POWER_RANK_ENERGY_HOUR_TOP_SUCCESS = 'POWER_RANK_ENERGY_HOUR_TOP_SUCCESS';

//能耗设备排名
function* energyDeviceTop(action) {
    const {
        consumerId,
        dataType,
        beginDate,
        endDate,
        orderBy
    } = action.payload;
    const ret = yield call(
        efficiency.OverviewService.energyDeviceTop, 
        consumerId,
        dataType,
        beginDate,
        endDate,
        orderBy
    );
    if (!ret.code) {
        yield put({ type: POWER_RANK_ENERGER_TOP_SUCCESS, payload: ret });
    } else {
        yield put({ type: POWER_RANK_ENERGER_TOP_SUCCESS });
    }
}

//能耗时段排名
function* energyHourTop(action) {
    const {
        consumerId,
        dataType,
        beginDate,
        endDate,
        orderBy
    } = action.payload;
    const ret = yield call(
        efficiency.OverviewService.energyHourTop, 
        consumerId,
        dataType,
        beginDate,
        endDate,
        orderBy
    );
    if (!ret.code) {
        yield put({ type: POWER_RANK_ENERGY_HOUR_TOP_SUCCESS, payload: ret });
    } else {
        yield put({ type: POWER_RANK_ENERGY_HOUR_TOP_SUCCESS });
    }
}

export  const SDNPowerRankSagas = [
    takeLatest(POWER_RANK_ENERGER_TOP, energyDeviceTop),
    takeLatest(POWER_RANK_ENERGY_HOUR_TOP, energyHourTop)
];

const initalState = {
    energyDeviceTop: {},
    energyHourTop: {}
}

export const SDNPowerRankReducer = handleActions({

    POWER_RANK_ENERGER_TOP_SUCCESS: (state, action) => {
        return {
            ...state,
            energyDeviceTop: getEnergyDeviceTop(action.payload || {})
        };
    },

    POWER_RANK_ENERGY_HOUR_TOP_SUCCESS: (state, action) => {
        return {
            ...state,
            energyHourTop: getEnergyHourTop(action.payload || {})
        };
    },

}, initalState);

const getEnergyDeviceTop = (datas) => {
    let energyDeviceTop = {};

    let titles = [];
    let values = [];

    for (let key in datas) {
        let deviceTop = datas[key];
        titles.push(deviceTop["deviceName"] || "--");
        if (deviceTop.hasOwnProperty("value")) {
            values.push(deviceTop["value"] || "0");
        } else {
            values.push("");
        }        

        if (titles.length >= 10) {
            break;
        }
    }

    while (titles.length > 0 && titles.length < 10) {
        titles.push("--");
        values.push("");
    }

    energyDeviceTop["titles"] = titles;
    energyDeviceTop["values"] = values;
    
    return energyDeviceTop;
}

const getEnergyHourTop = (datas) => {
    let energyHourTop = [];

    let titles = [];
    let values = [];

    for (let key in datas) {
        let hourTop = datas[key];
        titles.push(hourTop["hour"] || "--");
        if (hourTop.hasOwnProperty("value")) {
            values.push(hourTop["value"] || "0");
        } else {
            values.push("");
        }

        if (titles.length >= 10) {
            break;
        }
    }

    while (titles.length > 0 && titles.length < 10) {
        titles.push("--");
        values.push("");
    }

    energyHourTop["titles"] = titles;
    energyHourTop["values"] = values;

    return energyHourTop;
}

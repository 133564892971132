import {efficiency} from "../../../service";
import {call, put, takeLatest} from 'redux-saga/effects';
import {FETCH_FAILED, FETCH_SUCCESS} from "../../../config/SDNActionType";

export const GET_STATEMENT_DATATABLE = 'GET_STATEMENT_DATATABLE';
export const GET_STATEMENT_DATATABLE_SUCCESS = 'GET_STATEMENT_DATATABLE_SUCCESS';


export function* getStatementDatatable(action) {
  //efficiency.StatementService.exportMultiReport
  try {
    yield put({type: 'FETCH_REQUEST'});
    const ret = yield call(
      efficiency.StatementService.getMonitorData,
      action.payload.orgId,
      action.payload.classifiedType,
      action.payload.fromDt,
      action.payload.toDt,
      action.payload.intervalType,
      action.payload.isAllChildren
    );
    yield put({type: GET_STATEMENT_DATATABLE_SUCCESS, payload: ret.list ? ret.list : []});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }

}

export const statementStatisticsSagas = [
  takeLatest(GET_STATEMENT_DATATABLE, getStatementDatatable),
];

//reducer
export function SDNStatementStatisticsReducer(
  state = {
    statementData: [],//报表统计
  },
  action
) {
  switch (action.type) {

    case GET_STATEMENT_DATATABLE_SUCCESS: {
      ;
      return {
        ...state,
        statementData: action.payload.length === 0 ? [] : action.payload
      };
    }

    default:
      return state;
  }
}

/**
 * version  0.1
 * date     2019/8/23 10:20
 * author   caojiaxu
 * desc
 */
import {put, select, takeLatest, call, all} from 'redux-saga/effects';
import {efficiency} from "../../../service/API";
import moment from "moment";
import {getSystem} from "../../../config";
//------------------- 峰值分析start -------------------//
export const GET_DATA_BY_NODE = 'GET_DATA_BY_NODE';
export const SET_PEAK_COLUMN = 'SET_PEAK_COLUMN';
export const SET_PEAK_BUBBLE = 'SET_PEAK_BUBBLE';
export const EDIT_PEAK_PARAMS = 'EDIT_PEAK_PARAMS';
export const PEAK_PARAMS_CHANGED = 'PEAK_PARAMS_CHANGED';
export const EXPORT_POWER_ADMIN_DATA = 'EXPORT_POWER_ADMIN_DATA';

//------------------- 峰值分析end -------------------//
const INIT_PAGE = "INIT_PAGE";
const intervalList = [
  {title: '小时', value: 201},
  {title: '天', value: 301},
  {title: '月', value: 501}
];

export function* editPeakParams(action) {

  yield put({type: PEAK_PARAMS_CHANGED, payload: action.payload});
  yield put({type: GET_DATA_BY_NODE, payload: action.payload});
}

export function* exportPeakData(action) {
  
  const params = action.payload;
  try {
    const system = yield select(getSystem);
    const {showSubDevice} = system;
    if (showSubDevice) {
      const href = yield call(
        efficiency.PeakService.exportDailyPeakData,
        params.orgId || '',
        params.dataClassifyId || '',
        params.beginDate.format("YYYY-MM-DD"),
        params.endDate.format("YYYY-MM-DD")
      );
      window.location.href = href//
    } else {

      const href = yield call(
        efficiency.PeakService.exportDailyPeakDataByAmmeterId,
        params.orgId || '',
        params.dataAmmeterId || '',
        params.beginDate.format("YYYY-MM-DD"),
        params.endDate.format("YYYY-MM-DD")
      );
      window.location.href = href//
    }
  } catch (e) {

    console.log("导出失败")
  }

}

export function* getDataByNode(action) {
  const system = yield select(getSystem);
  const {showSubDevice} = system;

  const params = action.payload;
  //峰值分析散点图
  if (showSubDevice) {
    //峰值分析散点图
    let ret = yield call(
      efficiency.PeakService.getPeakDistribution,
      params.orgId || '',
      params.dataClassifyId || '',
      params.beginDate.format("YYYY-MM-DD"),
      params.endDate.format("YYYY-MM-DD")
    );
    const bubbleData = ret.code ? [] : ret;
    yield put({type: SET_PEAK_BUBBLE, payload: bubbleData});
    ret = yield call(
      efficiency.PeakService.getPeakTimesList,
      params.orgId || '',
      params.dataClassifyId||'',
      params.beginDate.format("YYYY-MM-DD"),
      params.endDate.format("YYYY-MM-DD")
    );
    const statisticData = ret.code ? {} : ret;
    yield put({type: SET_PEAK_COLUMN, payload: statisticData});
  } else {

    let ret = yield call(
      efficiency.PeakService.getPeakDistributionByAmmeterId,
      params.dataAmmeterId || '',
      params.beginDate.format("YYYY-MM-DD"),
      params.endDate.format("YYYY-MM-DD")
    );
    const bubbleData = ret.code ? [] : ret;
    yield put({type: SET_PEAK_BUBBLE, payload: bubbleData});
    ret = yield call(
      efficiency.PeakService.getPeakTimesListByAmmeterId,
      params.dataAmmeterId || '',
      params.beginDate.format("YYYY-MM-DD"),
      params.endDate.format("YYYY-MM-DD")
    );
    const statisticData = ret.code ? {} : ret;
    yield put({type: SET_PEAK_COLUMN, payload: statisticData});
  }
}

export const peakAnalysisSagas = [
  takeLatest(GET_DATA_BY_NODE, getDataByNode),
  takeLatest(EDIT_PEAK_PARAMS, editPeakParams),
  takeLatest(EXPORT_POWER_ADMIN_DATA, exportPeakData),
];

//reducer
export function peakAnalysisReducer(
  state = {
    page: {},
    //峰值分析
    peakParams: { //峰值参数
      orgId: "",
      dataAmmeterId: "",
      dataClassifyId: "",
      beginDate: moment().startOf('month').minute(0),
      endDate: moment().minute(0),
      format: 'YYYY-MM-DD HH:mm'
    },
    peakInfo: {},
    peakColumnList: [],
    peakTopList: [],
    peakBubbleList: [],
  },
  action
) {
  switch (action.type) {
    case INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case PEAK_PARAMS_CHANGED: {
      return {
        ...state,
        peakParams: action.payload
      };
    }

    case SET_PEAK_BUBBLE: {
      return {
        ...state,
        peakInfo: action.payload,
        peakBubbleList: action.payload.list || []
      };
    }
    case SET_PEAK_COLUMN: {
      return {
        ...state,
        peakColumnList: action.payload.timeList || [],
        peakTopList: action.payload.topList || []
      };
    }
    default:
      return state;
  }
}

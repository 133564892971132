import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNMenuAdmin = Loadable({
  loader: () => import("./components/SDNMenuAdmin"),
  loading: Loading
});

const SDNCharAdmin = Loadable({
  loader: () => import("./components/SDNCharAdmin"),
  loading: Loading
});

const SDNAccountsAdmin = Loadable({
  loader: () => import("./components/SDNAccountsAdmin"),
  loading: Loading
});

const SDNDataDictionary = Loadable({
  loader: () => import("./components/SDNDataDictionary"),
  loading: Loading
});

const SDNConfigDataDic = Loadable({
  loader: () => import("./components/SDNConfigDataDic"),
  loading: Loading
});

const SDNSystemLogs = Loadable({
  loader: () => import("./components/SDNSystemLogs"),
  loading: Loading
});

const SDNMetricalDataImport = Loadable({
  loader: () => import("./components/SDNMetricalDataImport"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNSysAdmin/SDNMenuAdmin",
    exact: true,
    component: SDNMenuAdmin,
    name: "SDNMenuAdmin"
  },
  {
    path: "/SDNSysAdmin/SDNCharAdmin",
    exact: true,
    component: SDNCharAdmin,
    name: "SDNCharAdmin"
  },
  {
    path: "/SDNSysAdmin/SDNAccountsAdmin",
    exact: true,
    component: SDNAccountsAdmin,
    name: "SDNAccountsAdmin"
  },
  {
    path: "/SDNSysAdmin/SDNDataDictionary",
    exact: true,
    component: SDNDataDictionary,
    name: "SDNDataDictionary"
  },
  {
    path: "/SDNSysAdmin/SDNConfigDataDic/:params",
    exact: true,
    component: SDNConfigDataDic,
    name: "SDNConfigDataDic"
  },
  {
    path: "/SDNSysAdmin/SDNSystemLogs",
    exact: true,
    component: SDNSystemLogs,
    name: "SDNSystemLogs"
  },
  {
    path: "/SDNSysAdmin/SDNMetricalDataImport",
    exact: true,
    component: SDNMetricalDataImport,
    name: "SDNMetricalDataImport"
  }
];

import React, { Component } from 'react';
import SDNDatePicker from "../../ui/components/SDNDatePicker";
import SDNQuestionPopover from '../../ui/components/SDNQuestionPopover';

import "react-datepicker/dist/react-datepicker.css";

export default class TableTitles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null
    };
  }

  handleInput = (e, index) => {
    if (this.props.onSearch) {
      this.props.onSearch(index, e.target.value);
    }
  }

  handleStartDateChange = (title, date) => {
    if (title.onStartDateChange) {
      title.onStartDateChange(date);
    }
    this.setState({
      startDate: date
    });
  }

  handleEndDateChange = (title, date) => {
    if (title.onEndDateChange) {
      title.onEndDateChange(date);
    }
    this.setState({
      endDate: date
    });
  }

  setCtrls = (item, index) => {
    let html = null;
    switch (item.type) {
      case "select":
        html = (
          <div className="select">
            <select className="form-control" onChange={e => this.handleInput(e, item.targets)}>
              {item.options.map((option, idx) => <option key={idx} value={option.value} defaultValue="">{option.text}</option>)}
            </select><i></i>
          </div>
        );
        break;
      case "date-range":
        html = (
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-6">
              <SDNDatePicker
                selected={this.state.startDate}
                defaultDate={''}
                dateFormat="yyyy-MM-dd"
                placeholderText='From'
                maxDate={new Date()}
                onChange={date => this.handleStartDateChange(item, date)}
              />
            </div>
            <div className="col-sm-6">
              <SDNDatePicker
                className='dateselect-filter-from form-control datepicker'
                defaultDate={''}
                selected={this.state.endDate}
                dateFormat="yyyy-MM-dd"
                placeholderText='To'
                maxDate={new Date()}
                onChange={date => this.handleEndDateChange(item, date)}
              />
            </div>
          </div>
        );
        break;
      case "range":
        html = (
          <div className="input">
            <input type="text" className="form-control" placeholder="选择范围" onChange={e => this.handleInput(e, item.targets)} />
          </div>
        );
        break;
      default:
        html = (
          <div className="input">
            <input type="text" className="form-control" placeholder={'请输入' + item.label} onChange={e => this.handleInput(e, item.targets)} />
          </div>
        );
        break;
    }
    let n = parseInt(12 / this.props.controlscols), cls = 'col-sm-3';
    if (!isNaN(n)) cls = 'col-sm-' + n;
    return <div className={cls} key={index} style={{ float: 'right' }}><div style={{ padding: '5px' }}>{html}</div></div>;
  }

  renderCtrls = () => {
    const { controls, titles } = this.props;
    if (!Array.isArray(controls) || !controls[0]) return null;
    let ctrlsHtml = <div className="row smart-form">{controls.map(this.setCtrls)}</div>;
    return <tr><th colSpan={titles.length} style={{ padding: '0 9px' }}>{ctrlsHtml}</th></tr>;
  }

  typeCom = (title, index) => {
    switch (title.type) {
      case "select":
        return (
          <th className="hasinput" key={index} filter-type="select" key={index}>
            <select className="form-control" onChange={e => this.handleInput(e, index)}>
              {
                title.options.map((item, index) => {
                  return (
                    <option key={index} value={item.value} defaultValue="">{item.title}</option>
                  )
                })
              }
            </select>
          </th>
        )
      case "date-range":
        return (
          <th className="hasinput" key={index} filter-type="date-range">
            <div className="row">
              <div className="col-sm-6">
                <SDNDatePicker
                  selected={this.state.startDate}
                  defaultDate={''}
                  dateFormat="yyyy-MM-dd"
                  placeholderText='From'
                  maxDate={new Date()}
                  onChange={date => this.handleStartDateChange(title, date)}
                />
              </div>
              <div className="col-sm-6">
                <SDNDatePicker
                  className='dateselect-filter-from form-control datepicker'
                  defaultDate={''}
                  selected={this.state.endDate}
                  dateFormat="yyyy-MM-dd"
                  placeholderText='To'
                  maxDate={new Date()}
                  onChange={date => this.handleEndDateChange(title, date)}
                />
              </div>
            </div>
          </th>
        )
      case "range":
        return <input
          type="text"
          className="form-control"
          key={index}
          placeholder="选择范围"
          onChange={e => this.handleInput(e, index)}
        />
      default:
        return (
          <th className="hasinput" key={index} filter-type="text">
            <input
              type="text"
              className="form-control"
              placeholder={"请输入" + title.name}
              onChange={e => this.handleInput(e, index)}
            />
          </th>
        )

    }
  }

  renderFilter = () => {
    const {
      showFilter,
      titles
    } = this.props

    const fitler = titles.map((title, index) => {
      const show = title.show == null ? true : false;
      if (!show) {
        return <th key={index} style={{ height: 0 }} />;
      }
      return this.typeCom(title, index);
    }
    )

    return showFilter ? <tr>{fitler}</tr> : null;
  }

  renderTitles = () => {
    const { titles } = this.props
    if (Array.isArray(titles[0])) {
      return titles.map((trs, i) =>
        <tr style={{ background: 'none' }} key={i}>
          {
            trs.map((col, j) => {
              const { name = '', style = {}, ref = null, popoverContent: text = '', rowSpan = 1, colSpan = 1 } = col;
              const popover = (text && text.length > 0) ? <SDNQuestionPopover content={text} /> : null;
              return <th
                key={j}
                ref={ref}
                rowSpan={rowSpan}
                colSpan={colSpan}
                className={col.className}
                style={{ textAlign: "center", ...style }}
              > {name} {popover}</th>;
            })
          }
        </tr>
      )
    }
    return <tr>{titles.map((col, i) => {
      const { name = '', style = {}, popoverContent: text = '' } = col;
      const popover = (text && text.length > 0) ? <SDNQuestionPopover content={text} /> : null;
      return <th key={i} className={col.className} style={{ textAlign: "center", ...style }}> {name} {popover}</th>;
    })}</tr>;
  }

  render() {
    return <>
      <thead>
        {this.renderCtrls()}
        {this.renderFilter()}
        {this.renderTitles()}
      </thead>
    </>;
  }

}

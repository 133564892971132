import { BaseService } from '../SDNRequest';

const events = {
	    //告警联动控制记录	    
        LinkageControlRecordService :{
			
			//增加告警联动控制记录对象
			add : function(mapParams) {
						return BaseService.post('/api/event/linkageControlRecord/add',mapParams);
			},
			
			//删除告警联动控制记录对象
			del : function(id) {
				return BaseService.post('/api/event/linkageControlRecord/del',{
						'id':id
					});
			},
			
			//修改告警联动控制记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/linkageControlRecord/edit',mapParams);
			},
			
			//修改告警联动控制记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/linkageControlRecord/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出告警联动控制记录对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/linkageControlRecord/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取告警联动控制记录对象
			get : function(id) {
				return BaseService.get('/api/event/linkageControlRecord/get',{
						'id':id
					});
			},
			
			//查询所有告警联动控制记录对象
			getAll : function() {
				return BaseService.get('/api/event/linkageControlRecord/getall');
			},
			
			//获取联动记录dataTable
			getControlRecordDataTable : function(dataTable) {
				return BaseService.post('/api/event/linkageControlRecord/getControlRecordDataTable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查询告警联动控制记录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/linkageControlRecord/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示告警联动控制记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/linkageControlRecord/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //事件记录	    
        AlarmRecordService :{
			
			//增加事件记录对象
			add : function(mapParams) {
						return BaseService.post('/api/event/alarmRecord/add',mapParams);
			},
			
			//删除事件记录对象
			del : function(id) {
				return BaseService.post('/api/event/alarmRecord/del',{
						'id':id
					});
			},
			
			//修改事件记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/alarmRecord/edit',mapParams);
			},
			
			//批量确认
			editConfirmState : function(ids, state, procInfo) {
				return BaseService.post('/api/event/alarmRecord/editConfirmState',{
						'ids':ids, 'state':state, 'procInfo':procInfo
					});
			},
			
			//修改事件记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/alarmRecord/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取事件记录对象
			get : function(id) {
				return BaseService.get('/api/event/alarmRecord/get',{
						'id':id
					});
			},
			
			//查询站点最近N条告警 
			getAlarmRecordsBySite : function(siteId, limitNum) {
				return BaseService.get('/api/event/alarmRecord/getAlarmRecordsBySite',{
						'siteId':siteId, 'limitNum':limitNum
					});
			},
			
			//查询所有事件记录对象
			getAll : function() {
				return BaseService.get('/api/event/alarmRecord/getall');
			},
			
			//查询告警信息，根据事件记录id 
			getDataById : function(id) {
				return BaseService.get('/api/event/alarmRecord/getDataById',{
						'id':id
					});
			},
			
			//DataTable查询事件记录对象
			getDatatable : function(dataTable, alarmRecordId) {
				return BaseService.post('/api/event/alarmRecord/getDatatable',{
						'dataTable':dataTable, 'alarmRecordId':alarmRecordId
					});
			},
			
			//DataTable查询监控对象的告警事件记录
			getDatatableByMonitorObject : function(dataTable, monitorObjectId) {
				return BaseService.post('/api/event/alarmRecord/getDatatableByMonitorObject',{
						'dataTable':dataTable, 'monitorObjectId':monitorObjectId
					});
			},
			
			//分页显示事件记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/alarmRecord/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取最近三天的告警信息
			getRecentAlarmMsg : function(state) {
				return BaseService.get('/api/event/alarmRecord/getRecentAlarmMsg',{
						'state':state
					});
			},
			
			//获取最近三天的告警信息的量()
			getRecentAlarmMsgCount : function() {
				return BaseService.get('/api/event/alarmRecord/getRecentAlarmMsgCount');
			},
		},
	    //事件告警	    
        AlarmService :{
			
			//增加事件告警对象
			add : function(mapParams) {
						return BaseService.post('/api/event/alarm/add',mapParams);
			},
			
			//根据告警类型获取告警数据
			alarmsByType : function(alarmType) {
				return BaseService.get('/api/event/alarm/alarmsByType',{
						'alarmType':alarmType
					});
			},
			
			//删除事件告警对象
			del : function(id) {
				return BaseService.post('/api/event/alarm/del',{
						'id':id
					});
			},
			
			//修改事件告警对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/alarm/edit',mapParams);
			},
			
			//修改事件告警对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/alarm/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出事件告警对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/alarm/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取事件告警对象
			get : function(id) {
				return BaseService.get('/api/event/alarm/get',{
						'id':id
					});
			},
			
			//查询告警事件列表
			getAlarmDataTable : function(dataTable) {
				return BaseService.get('/api/event/alarm/getAlarmDataTable',{
						'dataTable':dataTable
					});
			},
			
			//查询所有事件告警对象
			getAll : function() {
				return BaseService.get('/api/event/alarm/getall');
			},
			
			//获取所有的告警事件信息(只返回四个字段： alarmlId, alarmName, alarmType, alarmSubType)
			getAllSimpleAlarms : function() {
				return BaseService.get('/api/event/alarm/getAllSimpleAlarms');
			},
			
			//DataTable查询事件告警对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/alarm/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示事件告警对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/alarm/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取采集正常情况下的告警数据
			normalAlarmsByType : function(alarmType) {
				return BaseService.get('/api/event/alarm/normalAlarmsByType',{
						'alarmType':alarmType
					});
			},
			
			//获取综合告警事件的ID 及 名称
			objAlarmIdNames : function() {
				return BaseService.get('/api/event/alarm/objAlarmIdNames');
			},
		},
	    //告警联动触发	    
        AlarmTriggerService :{
			
			//增加告警联动触发对象
			add : function(mapParams) {
						return BaseService.post('/api/event/alarmTrigger/add',mapParams);
			},
			
			//删除告警联动触发对象
			del : function(id) {
				return BaseService.post('/api/event/alarmTrigger/del',{
						'id':id
					});
			},
			
			//修改告警联动触发对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/alarmTrigger/edit',mapParams);
			},
			
			//修改告警联动触发对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/alarmTrigger/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出告警联动触发对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/alarmTrigger/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取告警联动触发对象
			get : function(id) {
				return BaseService.get('/api/event/alarmTrigger/get',{
						'id':id
					});
			},
			
			//查询所有告警联动触发对象
			getAll : function() {
				return BaseService.get('/api/event/alarmTrigger/getall');
			},
			
			//DataTable查询告警联动触发对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/alarmTrigger/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示告警联动触发对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/alarmTrigger/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控对象告警配置	    
        ObjectAlarmService :{
			
			//增加监控对象告警配置对象
			add : function(mapParams) {
						return BaseService.post('/api/event/objectAlarm/add',mapParams);
			},
			
			//新增综合告警 
			addObjAlarm : function(objectAlarmJsonStr, alarmsJsonStr) {
				return BaseService.post('/api/event/objectAlarm/addObjAlarm',{
						'objectAlarmJsonStr':objectAlarmJsonStr, 'alarmsJsonStr':alarmsJsonStr
					});
			},
			
			//删除监控对象告警配置对象
			del : function(id) {
				return BaseService.post('/api/event/objectAlarm/del',{
						'id':id
					});
			},
			
			//删除监控对象告警配置
			delObjAlarm : function(objAlarmId) {
				return BaseService.post('/api/event/objectAlarm/delObjAlarm',{
						'objAlarmId':objAlarmId
					});
			},
			
			//修改监控对象告警配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/objectAlarm/edit',mapParams);
			},
			
			//修改监控对象告警配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/objectAlarm/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出监控对象告警配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/objectAlarm/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取监控对象告警配置对象
			get : function(id) {
				return BaseService.get('/api/event/objectAlarm/get',{
						'id':id
					});
			},
			
			//查询所有监控对象告警配置对象
			getAll : function() {
				return BaseService.get('/api/event/objectAlarm/getall');
			},
			
			//DataTable查询监控对象告警配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/objectAlarm/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//综合告警分页数据DataTable
			getObjAlarmDataTable : function(dataTable) {
				return BaseService.get('/api/event/objectAlarm/getObjAlarmDataTable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示监控对象告警配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/objectAlarm/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//更新当前综合告警的参数 
			updateObjectAlarmParams : function(objectAlarmId) {
				return BaseService.post('/api/event/objectAlarm/updateObjectAlarmParams',{
						'objectAlarmId':objectAlarmId
					});
			},
			
			//设置  短信、邮件、数据状态 
			updateSetState : function(objectAlarmId, value, setType) {
				return BaseService.post('/api/event/objectAlarm/updateSetState',{
						'objectAlarmId':objectAlarmId, 'value':value, 'setType':setType
					});
			},
		},
	    //监控点限值	    
        ItemLimitService :{
			
			//增加监控点限值对象
			add : function(mapParams) {
						return BaseService.post('/api/event/itemLimit/add',mapParams);
			},
			
			//检测监控点配置是否完整
			checkMonitorItemConfig : function() {
				return BaseService.post('/api/event/itemLimit/checkMonitorItemConfig');
			},
			
			//删除监控点限值对象
			del : function(id) {
				return BaseService.post('/api/event/itemLimit/del',{
						'id':id
					});
			},
			
			//修改监控点限值对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/itemLimit/edit',mapParams);
			},
			
			//修改监控点限值对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/itemLimit/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出监控点限值对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/itemLimit/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取监控点限值对象
			get : function(id) {
				return BaseService.get('/api/event/itemLimit/get',{
						'id':id
					});
			},
			
			//获取告警配置列表页面的dataTable数据
			getAlarmConfigDataTable : function(dataTable, dataType) {
				return BaseService.post('/api/event/itemLimit/getAlarmConfigDataTable',{
						'dataTable':dataTable, 'dataType':dataType
					});
			},
			
			//查询所有监控点限值对象
			getAll : function() {
				return BaseService.get('/api/event/itemLimit/getall');
			},
			
			//DataTable查询监控点限值对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/itemLimit/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示监控点限值对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/itemLimit/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//修改限值类型
			updateLimitSet : function(monitorItemId, analogLimitSetId) {
				return BaseService.post('/api/event/itemLimit/updateLimitSet',{
						'monitorItemId':monitorItemId, 'analogLimitSetId':analogLimitSetId
					});
			},
			
			//设置是否发送短信或邮件
			updateSetSendState : function(monitorItemId, value, setType) {
				return BaseService.post('/api/event/itemLimit/updateSetSendState',{
						'monitorItemId':monitorItemId, 'value':value, 'setType':setType
					});
			},
		},
	    //限值类型	    
        LimitSetService :{
			
			//增加限值类型对象
			add : function(mapParams) {
						return BaseService.post('/api/event/limitSet/add',mapParams);
			},
			
			//删除限值类型对象
			del : function(id) {
				return BaseService.post('/api/event/limitSet/del',{
						'id':id
					});
			},
			
			//修改限值类型对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/limitSet/edit',mapParams);
			},
			
			//修改限值类型对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/limitSet/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出限值类型对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/limitSet/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取限值类型对象
			get : function(id) {
				return BaseService.get('/api/event/limitSet/get',{
						'id':id
					});
			},
			
			//查询所有限值类型对象
			getAll : function() {
				return BaseService.get('/api/event/limitSet/getall');
			},
			
			//DataTable查询限值类型对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/limitSet/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据数据项查询所有限值类型对象
			getListByDataItem : function(dataItemId) {
				return BaseService.get('/api/event/limitSet/getListByDataItem',{
						'dataItemId':dataItemId
					});
			},
			
			//分页显示限值类型对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/limitSet/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据数据项目的ID  获取方案的列表
			getProgramsByDataItem : function(dataItemId) {
				return BaseService.get('/api/event/limitSet/getProgramsByDataItem',{
						'dataItemId':dataItemId
					});
			},
			
			//获取限值相应范围类型的数据
			getScopeData : function(dataItemId, scope) {
				return BaseService.get('/api/event/limitSet/getScopeData',{
						'dataItemId':dataItemId, 'scope':scope
					});
			},
			
			//修改方案，只有专用方案
			updateLimitSetMsg : function(limitSetJsonStr, oldSpeLimitSetId) {
				return BaseService.post('/api/event/limitSet/updateLimitSetMsg',{
						'limitSetJsonStr':limitSetJsonStr, 'oldSpeLimitSetId':oldSpeLimitSetId
					});
			},
		},
	    //事件告警参数配置	    
        ObjectAlarmParamService :{
			
			//增加事件告警参数配置对象
			add : function(mapParams) {
						return BaseService.post('/api/event/objectAlarmParam/add',mapParams);
			},
			
			//获取监控对象下的    监控点的ID ：　站点 + 监控点   列表  
			configItems : function(objId) {
				return BaseService.get('/api/event/objectAlarmParam/configItems',{
						'objId':objId
					});
			},
			
			//删除事件告警参数配置对象
			del : function(id) {
				return BaseService.post('/api/event/objectAlarmParam/del',{
						'id':id
					});
			},
			
			//修改事件告警参数配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/objectAlarmParam/edit',mapParams);
			},
			
			//修改事件告警参数配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/objectAlarmParam/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出事件告警参数配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/objectAlarmParam/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取事件告警参数配置对象
			get : function(id) {
				return BaseService.get('/api/event/objectAlarmParam/get',{
						'id':id
					});
			},
			
			//查询所有事件告警参数配置对象
			getAll : function() {
				return BaseService.get('/api/event/objectAlarmParam/getall');
			},
			
			//DataTable查询事件告警参数配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/objectAlarmParam/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示事件告警参数配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/objectAlarmParam/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取参数配置列表
			paramConfigList : function(paramType, objAlarmId) {
				return BaseService.get('/api/event/objectAlarmParam/paramConfigList',{
						'paramType':paramType, 'objAlarmId':objAlarmId
					});
			},
			
			//选择监控点
			updateMonitorItem : function(objAlarmParamId, monitorItemId) {
				return BaseService.post('/api/event/objectAlarmParam/updateMonitorItem',{
						'objAlarmParamId':objAlarmParamId, 'monitorItemId':monitorItemId
					});
			},
			
			//设置监控对象告警参数的值
			updateParamValue : function(objAlarmParamId, paramValue) {
				return BaseService.post('/api/event/objectAlarmParam/updateParamValue',{
						'objAlarmParamId':objAlarmParamId, 'paramValue':paramValue
					});
			},
		},
	    //时区	    
        TimezoneService :{
			
			//增加时区对象
			add : function(mapParams) {
						return BaseService.post('/api/event/timezone/add',mapParams);
			},
			
			//删除时区对象
			del : function(id) {
				return BaseService.post('/api/event/timezone/del',{
						'id':id
					});
			},
			
			//修改时区对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/timezone/edit',mapParams);
			},
			
			//修改时区对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/timezone/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出时区对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/timezone/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取时区对象
			get : function(id) {
				return BaseService.get('/api/event/timezone/get',{
						'id':id
					});
			},
			
			//查询所有时区对象
			getAll : function() {
				return BaseService.get('/api/event/timezone/getall');
			},
			
			//DataTable查询时区对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/timezone/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示时区对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/timezone/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //告警联动控制	    
        AlarmLinkageControlService :{
			
			//增加告警联动控制对象
			add : function(mapParams) {
						return BaseService.post('/api/event/alarmLinkageControl/add',mapParams);
			},
			
			//添加告警联动信息
			addControl : function(alarmJsonStr, alarmLinkageControlJsonStr, alarmTriggerJsonStr) {
				return BaseService.post('/api/event/alarmLinkageControl/addControl',{
						'alarmJsonStr':alarmJsonStr, 'alarmLinkageControlJsonStr':alarmLinkageControlJsonStr, 'alarmTriggerJsonStr':alarmTriggerJsonStr
					});
			},
			
			//添加综合告警联动信息
			addObjControl : function(alarmJsonStr, alarmLinkageControlJsonStr, alarmTriggerJsonStr) {
				return BaseService.post('/api/event/alarmLinkageControl/addObjControl',{
						'alarmJsonStr':alarmJsonStr, 'alarmLinkageControlJsonStr':alarmLinkageControlJsonStr, 'alarmTriggerJsonStr':alarmTriggerJsonStr
					});
			},
			
			//删除告警联动控制对象
			del : function(id) {
				return BaseService.post('/api/event/alarmLinkageControl/del',{
						'id':id
					});
			},
			
			//删除告警联动控制对象
			delLinkage : function(id) {
				return BaseService.post('/api/event/alarmLinkageControl/delLinkage',{
						'id':id
					});
			},
			
			//修改告警联动控制对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/alarmLinkageControl/edit',mapParams);
			},
			
			//修改告警联动控制对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/alarmLinkageControl/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出告警联动控制对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/alarmLinkageControl/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取告警联动控制对象
			get : function(id) {
				return BaseService.get('/api/event/alarmLinkageControl/get',{
						'id':id
					});
			},
			
			//查询所有告警联动控制对象
			getAll : function() {
				return BaseService.get('/api/event/alarmLinkageControl/getall');
			},
			
			//DataTable查询告警联动控制对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/alarmLinkageControl/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据监控点的ID 获取告警联动dataTable数据
			getDataTableByMonitorItemId : function(dataTable, monitorItemId) {
				return BaseService.post('/api/event/alarmLinkageControl/getDataTableByMonitorItemId',{
						'dataTable':dataTable, 'monitorItemId':monitorItemId
					});
			},
			
			//根据监控对象的ID 获取告警联动dataTable数据
			getDataTableByMonitorObjectId : function(dataTable, monitorObjectId) {
				return BaseService.post('/api/event/alarmLinkageControl/getDataTableByMonitorObjectId',{
						'dataTable':dataTable, 'monitorObjectId':monitorObjectId
					});
			},
			
			//分页显示告警联动控制对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/alarmLinkageControl/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//DataTable 根据告警事件记录获取控制联动信息
			linkageControlDetail : function(dataTable, alarmRecordId) {
				return BaseService.get('/api/event/alarmLinkageControl/linkageControlDetail',{
						'dataTable':dataTable, 'alarmRecordId':alarmRecordId
					});
			},
			
			//修改告警联动信息
			updateControl : function(alarmJsonStr, alarmLinkageControlJsonStr, alarmTriggerJsonStr) {
				return BaseService.post('/api/event/alarmLinkageControl/updateControl',{
						'alarmJsonStr':alarmJsonStr, 'alarmLinkageControlJsonStr':alarmLinkageControlJsonStr, 'alarmTriggerJsonStr':alarmTriggerJsonStr
					});
			},
			
			//修改综合告警联动信息
			updateObjControl : function(alarmJsonStr, alarmLinkageControlJsonStr, alarmTriggerJsonStr) {
				return BaseService.post('/api/event/alarmLinkageControl/updateObjControl',{
						'alarmJsonStr':alarmJsonStr, 'alarmLinkageControlJsonStr':alarmLinkageControlJsonStr, 'alarmTriggerJsonStr':alarmTriggerJsonStr
					});
			},
		},
	    //事件告警参数	    
        AlarmParamService :{
			
			//增加事件告警参数对象
			add : function(mapParams) {
						return BaseService.post('/api/event/alarmParam/add',mapParams);
			},
			
			//删除事件告警参数对象
			del : function(id) {
				return BaseService.post('/api/event/alarmParam/del',{
						'id':id
					});
			},
			
			//修改事件告警参数对象
			edit : function(mapParams) {
						return BaseService.post('/api/event/alarmParam/edit',mapParams);
			},
			
			//修改事件告警参数对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/event/alarmParam/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出事件告警参数对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/event/alarmParam/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取事件告警参数对象
			get : function(id) {
				return BaseService.get('/api/event/alarmParam/get',{
						'id':id
					});
			},
			
			//查询所有事件告警参数对象
			getAll : function() {
				return BaseService.get('/api/event/alarmParam/getall');
			},
			
			//DataTable查询事件告警参数对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/event/alarmParam/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示事件告警参数对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/event/alarmParam/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据告警事件的ID 获取告警事件参数
			getParamsByAlarmlId : function(alarmlId) {
				return BaseService.get('/api/event/alarmParam/getParamsByAlarmlId',{
						'alarmlId':alarmlId
					});
			},
		},
 
}

export default events;
import React from 'react';
import { Modal, Button, Spin } from 'antd';

export default class ModalView extends React.Component {

  static defaultProps = {
    visible: true,
    title:'',
    cancelTitle:'取消',
    confirmTitle: '确定',
    showFooter:true,
    expandClass:"",
    draggable: true,
    spinning: false,
    width: 600
	}

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.visible != nextProps.visible) {
      this.setState({ show: nextProps.visible });
    }
  }

  show = () => {
    this.setState({
      show: true
    });
  }

  hide = () => {
    this.setState({
      show: false
    })
  }

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
    this.hide();
  }

  render () {
    if (!this.state.show || !this.props.visible) {
      return null;
    }
    let footer = [];
    if (this.props.showDetail) {
      footer.push(
        <Button 
          key="detail" 
          onClick={() => {
            if (this.props.onShowDetail) {
              this.props.onShowDetail();
            }
          }}
        >
          {this.props.showDetailTitle}
        </Button>
      )
    }

    footer.push(
      <Button 
          key="cancel" 
          onClick={() => {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            this.hide();
          }}
        >
          { this.props.cancelTitle }
        </Button>
    );

    footer.push(
      <Button 
          key="confirm" 
          type='primary'
          onClick={() => {
            if (this.props.onValidateConfirm) {
              if (this.props.onValidateConfirm()) {
                // this.hide();
              }
            } else {
              if (this.props.onConfirm) {
                if (this.props.onConfirm()) {
                  // this.hide();
                }
              } else {
                this.hide();
              }
            }
          }}
        >
          {this.props.confirmTitle}
        </Button>
    );
    return (
      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.state.show}
        footer={footer}
        // onOk={() => this.confirm}
        onCancel={() => this.handleCancel() }
        width={this.props.width}
      >
        <Spin spinning={this.props.spinning}>
          { this.props.children }
        </Spin>
      </Modal>
    )
  }
}

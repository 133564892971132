import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";


const SDNPowerOverviewStatistic = Loadable({
  loader: () => import("./SDNPowerOverviewStatistic"),
  loading: Loading
});

const SDNPowerEnterpriseStatistic = Loadable({
  loader: () => import("./SDNPowerEnterpriseStatistic"),
  loading: Loading
});

const SDNPowerProcedureStatistic = Loadable({
  loader: () => import("./SDNPowerProcedureStatistic"),
  loading: Loading
});

const SDNPowerClassifyStatistic = Loadable({
  loader: () => import("./SDNPowerClassifyStatistic"),
  loading: Loading
});

const SDNPowerTeamStatistic = Loadable({
  loader: () => import("./SDNPowerTeamStatistic"),
  loading: Loading
});

const SDNPowerProductionStatistic = Loadable({
  loader: () => import("./SDNPowerProductionStatistic"),
  loading: Loading
});

const SDNPowerReportStatistic = Loadable({
  loader: () => import("./SDNPowerReportStatistic"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNPowerStatistic/SDNPowerOverviewStatistic",
    exact: true,
    component: SDNPowerOverviewStatistic,
    name: "SDNPowerOverviewStatistic"
  },
  {
    path: "/SDNPowerStatistic/SDNPowerEnterpriseStatistic",
    exact: true,
    component: SDNPowerEnterpriseStatistic,
    name: "SDNPowerEnterpriseStatistic"
  },
  {
    path: "/SDNPowerStatistic/SDNPowerProcedureStatistic",
    exact: true,
    component: SDNPowerProcedureStatistic,
    name: "SDNPowerProcedureStatistic"
  },
  {
    path: "/SDNPowerStatistic/SDNPowerClassifyStatistic",
    exact: true,
    component: SDNPowerClassifyStatistic,
    name: "SDNPowerClassifyStatistic"
  },
  {
    path: "/SDNPowerStatistic/SDNPowerTeamStatistic",
    exact: true,
    component: SDNPowerTeamStatistic,
    name: "SDNPowerTeamStatistic"
  },
  {
    path: "/SDNPowerStatistic/SDNPowerProductionStatistic",
    exact: true,
    component: SDNPowerProductionStatistic,
    name: "SDNPowerProductionStatistic"
  },
  {
    path: "/SDNPowerStatistic/SDNPowerReportStatistic",
    exact: true,
    component: SDNPowerReportStatistic,
    name: "SDNPowerReportStatistic"
  },
];

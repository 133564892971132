/**
 * Created by wangli on 2019/5/30 0030.
 */

export const hgStorage = (function () {
    if (localStorage) {
        return {
            set: function (name, value) {
                return localStorage.setItem(name, value);
            },
            get: function (name) {
                return localStorage.getItem(name);
            },
            del: function (name) {
                return localStorage.removeItem(name);
            }
        };
    } else {
        return {
            set: function (name, value) {
                return window.Cookie.write(name, value);
            },
            get: function (name) {
                return window.Cookie.read(name);
            },
            del: function (name) {
                return window.Cookie.del(name);
            }
        };
    }
})();
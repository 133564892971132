/** ======================================== 运营管理  =====================================*/

export const OPERATION_INIT_PAGE = 'OPERATION_INIT_PAGE';

/** ======================================== 集团管理 =====================================*/

export const OPERATION_GROUP_GET_GROUPINFO = 'OPERATION_GROUP_GET_GROUPINFO';
export const OPERATION_GROUP_GET_GROUPINFO_SUCCESS = 'OPERATION_GROUP_GET_GROUPINFO_SUCCESS';

export const OPERATION_GROUP_ADD = 'OPERATION_GROUP_ADD';
export const OPERATION_GROUP_ADD_SUCCESS = 'OPERATION_GROUP_ADD_SUCCESS';

export const OPERATION_GROUP_EDIT = 'OPERATION_GROUP_EDIT';
export const OPERATION_GROUP_EDIT_SUCCESS = 'OPERATION_GROUP_EDIT_SUCCESS';

export const OPERATION_GROUP_DELETE = 'OPERATION_GROUP_DELETE';
export const OPERATION_GROUP_DELETE_SUCCESS = 'OPERATION_GROUP_DELETE_SUCCESS';

/** ======================================== 企业管理 =====================================*/

export const OPERATION_ENTERPRISE_GET_ENTERPRISEINFO = 'OPERATION_ENTERPRISE_GET_ENTERPRISEINFO';
export const OPERATION_ENTERPRISE_GET_ENTERPRISEINFO_SUCCESS = 'OPERATION_ENTERPRISE_GET_ENTERPRISEINFO_SUCCESS';

export const OPERATION_ENTERPRISE_GET_INDUSTRYS = 'OPERATION_ENTERPRISE_GET_INDUSTRYS';
export const OPERATION_ENTERPRISE_GET_INDUSTRYS_SUCCESS = 'OPERATION_ENTERPRISE_GET_INDUSTRYS_SUCCESS';

export const OPERATION_ENTERPRISE_GET_ADMIN_AREAS = 'OPERATION_ENTERPRISE_GET_ADMIN_AREAS';
export const OPERATION_ENTERPRISE_GET_ADMIN_AREAS_SUCCESS = 'OPERATION_ENTERPRISE_GET_ADMIN_AREAS_SUCCESS';

export const OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES = 'OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES';
export const OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES_SUCCESS = 'OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES_SUCCESS';

export const OPERATION_ENTERPRISE_GET_GROUPS = 'OPERATION_ENTERPRISE_GET_GROUPS';
export const OPERATION_ENTERPRISE_GET_GROUPS_SUCCESS = 'OPERATION_ENTERPRISE_GET_GROUPS_SUCCESS';

export const OPERATION_ENTERPRISE_ADD = 'OPERATION_ENTERPRISE_ADD';
export const OPERATION_ENTERPRISE_ADD_SUCCESS = 'OPERATION_ENTERPRISE_ADD_SUCCESS';

export const OPERATION_ENTERPRISE_EDIT = 'OPERATION_ENTERPRISE_EDIT';
export const OPERATION_ENTERPRISE_EDIT_SUCCESS = 'OPERATION_ENTERPRISE_EDIT_SUCCESS';

export const OPERATION_ENTERPRISE_DELETE = 'OPERATION_ENTERPRISE_DELETE';
export const OPERATION_ENTERPRISE_DELETE_SUCCESS = 'OPERATION_ENTERPRISE_DELETE_SUCCESS';

/** ======================================== 用户管理 =====================================*/

export const OPERATION_USER_GET_USERINFO = 'OPERATION_USER_GET_USERINFO';
export const OPERATION_USER_GET_USERINFO_SUCCESS = 'OPERATION_USER_GET_USERINFO_SUCCESS';

export const OPERATION_USER_GET_EMP_CLASSIFYID = 'OPERATION_USER_GET_EMP_CLASSIFYID';
export const OPERATION_USER_GET_EMP_CLASSIFYID_SUCCESS = 'OPERATION_USER_GET_EMP_CLASSIFYID_SUCCESS';

export const OPERATION_USER_GET_ASSOCIATE_ENTERPRISES = 'OPERATION_USER_GET_ASSOCIATE_ENTERPRISES';
export const OPERATION_USER_GET_ASSOCIATE_ENTERPRISES_SUCCESS = 'OPERATION_USER_GET_ASSOCIATE_ENTERPRISES_SUCCESS';

export const OPERATION_USER_GET_ROLES = 'OPERATION_USER_GET_ROLES';
export const OPERATION_USER_GET_ROLES_SUCCESS = 'OPERATION_USER_GET_ROLES_SUCCESS';

export const OPERATION_USER_ADD = 'OPERATION_USER_ADD';
export const OPERATION_USER_ADD_SUCCESS = 'OPERATION_USER_ADD_SUCCESS';

export const OPERATION_USER_EDIT = 'OPERATION_USER_EDIT';
export const OPERATION_USER_EDIT_SUCCESS = 'OPERATION_USER_EDIT_SUCCESS';

export const OPERATION_USER_UPDATE_PASSWORD = 'OPERATION_USER_UPDATE_PASSWORD';
export const OPERATION_USER_UPDATE_PASSWORD_SUCCESS = 'OPERATION_USER_UPDATE_PASSWORD_SUCCESS';

export const OPERATION_USER_DELETE = 'OPERATION_USER_DELETE';
export const OPERATION_USER_DELETE_SUCCESS = 'OPERATION_USER_DELETE_SUCCESS';

export const OPERATION_SHOW_EDIT_USER_ADMIN = 'OPERATION_SHOW_EDIT_USER_ADMIN';
export const OPERATION_USER_SHOW_UPDATE_PASSWORD = 'OPERATION_USER_SHOW_UPDATE_PASSWORD';

/** ======================================== 行业资讯 =====================================*/

export const OPERATION_NEWS_GET_NEWS_TYPES = 'OPERATION_NEWS_GET_NEWS_TYPES';
export const OPERATION_NEWS_GET_NEWS_TYPES_SUCCESS = 'OPERATION_NEWS_GET_NEWS_TYPES_SUCCESS';

export const OPERATION_NEWS_GET_NEWSINFO = 'OPERATION_NEWS_GET_NEWSINFO';
export const OPERATION_NEWS_GET_NEWSINFO_SUCCESS = 'OPERATION_NEWS_GET_NEWSINFO_SUCCESS';

export const OPERATION_NEWS_GET_FILERECORD = 'OPERATION_NEWS_GET_FILERECORD';
export const OPERATION_NEWS_GET_FILERECORD_SUCCESS = 'OPERATION_NEWS_GET_FILERECORD_SUCCESS';

export const OPERATION_NEWS_ADD = 'OPERATION_NEWS_ADD';
export const OPERATION_NEWS_ADD_SUCCESS = 'OPERATION_NEWS_ADD_SUCCESS';

export const OPERATION_NEWS_EDIT = 'OPERATION_NEWS_EDIT';
export const OPERATION_NEWS_EDIT_NOFILE = 'OPERATION_NEWS_EDIT_NOFILE';
export const OPERATION_NEWS_EDIT_SUCCESS = 'OPERATION_NEWS_EDIT_SUCCESS';

export const OPERATION_NEWS_DELETE = 'OPERATION_NEWS_DELETE';
export const OPERATION_NEWS_DELETE_SUCCESS = 'OPERATION_NEWS_DELETE_SUCCESS';
import React  from 'react';
import { Layout } from 'antd';
import Navigation from './Navigation';
import LoginInfo from './LoginInfo';
import { connect } from 'react-redux';

const { Sider } = Layout;

class SiderBar extends React.Component {
    constructor (props) {
        super (props);
        this.state = { collapsed: false };
    }
    
    render () {
        return (
            <Sider 
                width={250} 
                collapsed={!this.props.showSiderBar}
                style={{ overflow: 'auto', height: '100%' }}
            >
                <LoginInfo />
                <Navigation />
            </Sider>
        );
    }
}

const mapStateToProps = state => {
    return {
        showSiderBar: state.layout.showSiderBar
    }
}

export default connect(mapStateToProps, null)(SiderBar);
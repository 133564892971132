import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNGroupAdmin = Loadable({
  loader: () => import("./components/SDNGroupAdmin"),
  loading: Loading
});

const SDNUserAdmin = Loadable({
  loader: () => import("./components/SDNUserAdmin"),
  loading: Loading
});

const SDNCustomerAdmin = Loadable({
  loader: () => import("./components/SDNCustomerAdmin"),
  loading: Loading
});

const SDNNews = Loadable({
  loader: () => import("./components/SDNNews"),
  loading: Loading
});

const SDNEditNews = Loadable({
  loader: () => import("./components/SDNEditNews"),
  loading: Loading
});

export const routes = [  
  {
    path: "/SDNOperationAdmin/SDNGroupAdmin",
    exact: true,
    component: SDNGroupAdmin,
    name: "SDNGroupAdmin"
  },

  {
    path: "/SDNOperationAdmin/SDNUserAdmin",
    exact: true,
    component: SDNUserAdmin,
    name: "SDNUserAdmin"
  },

  {
    path: "/SDNOperationAdmin/SDNCustomerAdmin",
    exact: true,
    component: SDNCustomerAdmin,
    name: "SDNCustomerAdmin"
  },

  {
    path: "/SDNOperationAdmin/SDNNews",
    exact: true,
    component: SDNNews,
    name: "SDNNews"
  },

  {
    path: "/SDNOperationAdmin/SDNEditNews",
    exact: true,
    component: SDNEditNews,
    name: "SDNEditNews"
  },
];


import { routes as auth } from "../views/auth";

import { routes as SDNSysAdmin } from '../views/SDNSysAdmin';
import { routes as SDNPowerAdmin } from '../views/SDNPowerAdmin';
import { routes as SDNPowerAudit } from '../views/SDNPowerAudit';
import { routes as SDNPowerStatistic } from '../views/SDNPowerStatistic';
import { routes as SDNEnergyOverview } from '../views/SDNEnergyOverview';
import { routes as SDNEventAlert } from '../views/SDNEventAlert';
import { routes as SDNBaseConfig } from '../views/SDNBaseConfig';
import { routes as SDNOperationAdmin } from '../views/SDNOperationAdmin';
import { routes as SDNEquipment } from '../views/SDNEquipment';
import { routes as SDNUserAdmin } from '../views/SDNUserAdmin';
import { routes as SDNMonitoring } from '../views/SDNMonitoring';
import { routes as SDNDataUpload } from '../views/SDNDataUpload';
import { routes as HunanDataUpload } from '../views/HunanDataUpload';
import { routes as SDNStatisticsCfg } from '../views/SDNStatisticsCfg';
import { routes as SDNCommunication } from '../views/SDNCommunication';

export const routes = [
  ...SDNSysAdmin,
  ...SDNPowerAdmin,
  ...SDNPowerAudit,
  ...SDNPowerStatistic,
  ...SDNEnergyOverview,  
  ...SDNEventAlert,
  ...SDNBaseConfig,
  ...SDNOperationAdmin,
  ...SDNEquipment,
  ...SDNUserAdmin,
  ...SDNMonitoring,
  ...SDNDataUpload,
  ...HunanDataUpload,
  ...SDNStatisticsCfg,
  ...SDNCommunication,
];

export const authRoutes = [
  ...auth
];

import { BaseService } from '../SDNRequest';

const sys = {
	    //信息贴	    
        PostService :{
			
			//增加信息贴对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/post/add',mapParams);
			},
			
			//删除信息贴对象
			del : function(id) {
				return BaseService.post('/api/sys/post/del',{
						'id':id
					});
			},
			
			//修改信息贴对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/post/edit',mapParams);
			},
			
			//修改信息贴对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/post/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出信息贴对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/post/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取信息贴对象
			get : function(id) {
				return BaseService.get('/api/sys/post/get',{
						'id':id
					});
			},
			
			//查询所有信息贴对象
			getAll : function() {
				return BaseService.get('/api/sys/post/getall');
			},
			
			//DataTable查询信息贴对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/post/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示信息贴对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/post/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//DataTable查询信息贴对象
			getPostDataTable : function(dataTable) {
				return BaseService.get('/api/sys/post/getPostDataTable',{
						'dataTable':dataTable
					});
			},
		},
	    //系统管理对象	    
        ObjService :{
			
			//增加系统管理对象对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/obj/add',mapParams);
			},
			
			//删除系统管理对象对象
			del : function(id) {
				return BaseService.post('/api/sys/obj/del',{
						'id':id
					});
			},
			
			//修改系统管理对象对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/obj/edit',mapParams);
			},
			
			//修改系统管理对象对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/obj/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出系统管理对象对象到文件
			exportAll : function(objName, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/obj/exportall',{
						'objName':objName, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出系统管理对象对象到文件
			exportDataTable : function(dataTable, objName, state, fieldName) {
				return BaseService.download('/api/sys/obj/exportdatatable',{
						'dataTable':dataTable, 'objName':objName, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取系统管理对象对象
			get : function(id) {
				return BaseService.get('/api/sys/obj/get',{
						'id':id
					});
			},
			
			//查询所有系统管理对象对象
			getAll : function() {
				return BaseService.get('/api/sys/obj/getall');
			},
			
			//DataTable查询系统管理对象对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/obj/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示系统管理对象对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/obj/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询系统管理对象对象
			searchAll : function(objName, state, orderBy) {
				return BaseService.get('/api/sys/obj/searchall',{
						'objName':objName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询系统管理对象对象
			searchDataTable : function(dataTable, objName, state) {
				return BaseService.get('/api/sys/obj/searchdatatable',{
						'dataTable':dataTable, 'objName':objName, 'state':state
					});
			},
			
			//条件查询系统管理对象对象
			searchPage : function(objName, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/obj/searchpage',{
						'objName':objName, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //信息贴回复记录	    
        PostReplyService :{
			
			//增加信息贴回复记录对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/postReply/add',mapParams);
			},
			
			//删除信息贴回复记录对象
			del : function(id) {
				return BaseService.post('/api/sys/postReply/del',{
						'id':id
					});
			},
			
			//修改信息贴回复记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/postReply/edit',mapParams);
			},
			
			//修改信息贴回复记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/postReply/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//新增或修改信息贴回复记录对象
			editOrAddPostReply : function(mapParams) {
						return BaseService.post('/api/sys/postReply/editOrAddPostReply',mapParams);
			},
			
			//通过DataTable导出信息贴回复记录对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/postReply/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取信息贴回复记录对象
			get : function(id) {
				return BaseService.get('/api/sys/postReply/get',{
						'id':id
					});
			},
			
			//查询所有信息贴回复记录对象
			getAll : function() {
				return BaseService.get('/api/sys/postReply/getall');
			},
			
			//DataTable查询信息贴回复记录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/postReply/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示信息贴回复记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/postReply/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流节点	    
        WorkflowNodeService :{
			
			//增加工作流节点对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowNode/add',mapParams);
			},
			
			//删除工作流节点对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowNode/del',{
						'id':id
					});
			},
			
			//修改工作流节点对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowNode/edit',mapParams);
			},
			
			//修改工作流节点对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowNode/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流节点对象到文件
			exportAll : function(workflow, paramName, nodeType, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflowNode/exportall',{
						'workflow':workflow, 'paramName':paramName, 'nodeType':nodeType, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流节点对象到文件
			exportDataTable : function(dataTable, workflow, paramName, nodeType, fieldName) {
				return BaseService.download('/api/sys/workflowNode/exportdatatable',{
						'dataTable':dataTable, 'workflow':workflow, 'paramName':paramName, 'nodeType':nodeType, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流节点对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowNode/get',{
						'id':id
					});
			},
			
			//查询所有工作流节点对象
			getAll : function(workflowId, orderBy) {
				return BaseService.get('/api/sys/workflowNode/getall',{
						'workflowId':workflowId, 'orderBy':orderBy
					});
			},
			
			//DataTable查询工作流节点对象
			getDataTable : function(dataTable, workflowId) {
				return BaseService.get('/api/sys/workflowNode/getdatatable',{
						'dataTable':dataTable, 'workflowId':workflowId
					});
			},
			
			//分页显示工作流节点对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowNode/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询工作流节点对象
			searchAll : function(workflow, paramName, nodeType, orderBy) {
				return BaseService.get('/api/sys/workflowNode/searchall',{
						'workflow':workflow, 'paramName':paramName, 'nodeType':nodeType, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流节点对象
			searchDataTable : function(dataTable, workflow, paramName, nodeType) {
				return BaseService.get('/api/sys/workflowNode/searchdatatable',{
						'dataTable':dataTable, 'workflow':workflow, 'paramName':paramName, 'nodeType':nodeType
					});
			},
			
			//条件查询工作流节点对象
			searchPage : function(workflow, paramName, nodeType, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowNode/searchpage',{
						'workflow':workflow, 'paramName':paramName, 'nodeType':nodeType, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //功能权限定义	    
        FunService :{
			
			//增加功能权限定义对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/fun/add',mapParams);
			},
			
			//删除功能权限定义对象
			del : function(id) {
				return BaseService.post('/api/sys/fun/del',{
						'id':id
					});
			},
			
			//修改功能权限定义对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/fun/edit',mapParams);
			},
			
			//修改功能权限定义对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/fun/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出功能权限定义对象到文件
			exportAll : function(app, pFunId, funType, funCode, funName, operType, url, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/fun/exportall',{
						'app':app, 'pFunId':pFunId, 'funType':funType, 'funCode':funCode, 'funName':funName, 'operType':operType, 'url':url, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出功能权限定义对象到文件
			exportDataTable : function(dataTable, app, pFunId, funType, funCode, funName, operType, url, state, fieldName) {
				return BaseService.download('/api/sys/fun/exportdatatable',{
						'dataTable':dataTable, 'app':app, 'pFunId':pFunId, 'funType':funType, 'funCode':funCode, 'funName':funName, 'operType':operType, 'url':url, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取功能权限定义对象
			get : function(id) {
				return BaseService.get('/api/sys/fun/get',{
						'id':id
					});
			},
			
			//查询所有功能权限定义对象
			getAll : function() {
				return BaseService.get('/api/sys/fun/getall');
			},
			
			//查询所有功能权限定义对象
			getAllTreeForm : function() {
				return BaseService.get('/api/sys/fun/getAllTreeForm');
			},
			
			//获取子菜单或功能
			getCFunDataTable : function(dataTable, pFunId) {
				return BaseService.get('/api/sys/fun/getCFunDataTable',{
						'dataTable':dataTable, 'pFunId':pFunId
					});
			},
			
			//DataTable查询功能权限定义对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/fun/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示功能权限定义对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/fun/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询功能权限定义对象
			searchAll : function(app, pFunId, funType, funCode, funName, operType, url, state, orderBy) {
				return BaseService.get('/api/sys/fun/searchall',{
						'app':app, 'pFunId':pFunId, 'funType':funType, 'funCode':funCode, 'funName':funName, 'operType':operType, 'url':url, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询功能权限定义对象
			searchDataTable : function(dataTable, app, pFunId, funType, funCode, funName, operType, url, state) {
				return BaseService.get('/api/sys/fun/searchdatatable',{
						'dataTable':dataTable, 'app':app, 'pFunId':pFunId, 'funType':funType, 'funCode':funCode, 'funName':funName, 'operType':operType, 'url':url, 'state':state
					});
			},
			
			//条件查询功能权限定义对象
			searchPage : function(app, pFunId, funType, funCode, funName, operType, url, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/fun/searchpage',{
						'app':app, 'pFunId':pFunId, 'funType':funType, 'funCode':funCode, 'funName':funName, 'operType':operType, 'url':url, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //天气服务数据表	    
        WeatherSamplingService :{
		},
	    //工作流节点字段权限（预留）	    
        WorkflowNodeFieldService :{
			
			//增加工作流节点字段权限（预留）对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowNodeField/add',mapParams);
			},
			
			//删除工作流节点字段权限（预留）对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowNodeField/del',{
						'id':id
					});
			},
			
			//修改工作流节点字段权限（预留）对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowNodeField/edit',mapParams);
			},
			
			//修改工作流节点字段权限（预留）对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowNodeField/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出工作流节点字段权限（预留）对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/workflowNodeField/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流节点字段权限（预留）对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowNodeField/get',{
						'id':id
					});
			},
			
			//查询所有工作流节点字段权限（预留）对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowNodeField/getall');
			},
			
			//DataTable查询工作流节点字段权限（预留）对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowNodeField/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示工作流节点字段权限（预留）对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowNodeField/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //数据字典	    
        DictionaryService :{
			
			//增加数据字典对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/dictionary/add',mapParams);
			},
			
			//删除数据字典对象
			del : function(id) {
				return BaseService.post('/api/sys/dictionary/del',{
						'id':id
					});
			},
			
			//修改数据字典对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/dictionary/edit',mapParams);
			},
			
			//修改数据字典对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/dictionary/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出数据字典对象到文件
			exportAll : function(dictionaryCategory, code, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/dictionary/exportall',{
						'dictionaryCategory':dictionaryCategory, 'code':code, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出数据字典对象到文件
			exportDataTable : function(dataTable, dictionaryCategory, code, state, fieldName) {
				return BaseService.download('/api/sys/dictionary/exportdatatable',{
						'dataTable':dataTable, 'dictionaryCategory':dictionaryCategory, 'code':code, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取数据字典对象
			get : function(id) {
				return BaseService.get('/api/sys/dictionary/get',{
						'id':id
					});
			},
			
			//查询所有数据字典对象
			getAll : function() {
				return BaseService.get('/api/sys/dictionary/getall');
			},
			
			//根据数据字典的分类编码查询数据字典对应的信息
			getByCategroyCode : function(categroyCode, orderBy) {
				return BaseService.get('/api/sys/dictionary/getByCategroyCode',{
						'categroyCode':categroyCode, 'orderBy':orderBy
					});
			},
			
			//根据字典大类和字典类编码确定信息
			getByCode : function(dictCateCode, dictCode) {
				return BaseService.get('/api/sys/dictionary/getByCode',{
						'dictCateCode':dictCateCode, 'dictCode':dictCode
					});
			},
			
			//DataTable查询数据字典对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/dictionary/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示数据字典对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/dictionary/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询数据字典对象
			searchAll : function(dictionaryCategory, code, state, orderBy) {
				return BaseService.get('/api/sys/dictionary/searchall',{
						'dictionaryCategory':dictionaryCategory, 'code':code, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询数据字典对象
			searchDataTable : function(dataTable, dictionaryCategory, code, state) {
				return BaseService.get('/api/sys/dictionary/searchdatatable',{
						'dataTable':dataTable, 'dictionaryCategory':dictionaryCategory, 'code':code, 'state':state
					});
			},
			
			//DataTable条件查询数据字典对象
			searchDataTableByProperties : function(dataTable, dictCateId) {
				return BaseService.get('/api/sys/dictionary/searchdatatableByProperties',{
						'dataTable':dataTable, 'dictCateId':dictCateId
					});
			},
			
			//条件查询数据字典对象
			searchPage : function(dictionaryCategory, code, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/dictionary/searchpage',{
						'dictionaryCategory':dictionaryCategory, 'code':code, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流	    
        WorkflowService :{
			
			//增加工作流对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflow/add',mapParams);
			},
			
			//流程申请
			apply : function(workflowObjId, workflowId, workflowType) {
				return BaseService.post('/api/sys/workflow/apply',{
						'workflowObjId':workflowObjId, 'workflowId':workflowId, 'workflowType':workflowType
					});
			},
			
			//流程审批通过
			applyAgree : function(procInfoId, workflowObjId, remark) {
				return BaseService.post('/api/sys/workflow/applyAgree',{
						'procInfoId':procInfoId, 'workflowObjId':workflowObjId, 'remark':remark
					});
			},
			
			//流程审批通过
			applyAgreeParams : function(procInfoId, workflowObjId, params, remark) {
				return BaseService.post('/api/sys/workflow/applyAgreeParams',{
						'procInfoId':procInfoId, 'workflowObjId':workflowObjId, 'params':params, 'remark':remark
					});
			},
			
			//流程审批不通过
			applyDisagree : function(procInfoId, workflowObjId, remark) {
				return BaseService.post('/api/sys/workflow/applyDisagree',{
						'procInfoId':procInfoId, 'workflowObjId':workflowObjId, 'remark':remark
					});
			},
			
			//流程撤销
			callback : function(workflowObjId, remark) {
				return BaseService.post('/api/sys/workflow/callback',{
						'workflowObjId':workflowObjId, 'remark':remark
					});
			},
			
			//删除工作流对象
			del : function(id) {
				return BaseService.post('/api/sys/workflow/del',{
						'id':id
					});
			},
			
			//修改工作流对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflow/edit',mapParams);
			},
			
			//修改工作流对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflow/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流对象到文件
			exportAll : function(workflowType, workflowName, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflow/exportall',{
						'workflowType':workflowType, 'workflowName':workflowName, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流对象到文件
			exportDataTable : function(dataTable, workflowType, workflowName, state, fieldName) {
				return BaseService.download('/api/sys/workflow/exportdatatable',{
						'dataTable':dataTable, 'workflowType':workflowType, 'workflowName':workflowName, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流对象
			get : function(id) {
				return BaseService.get('/api/sys/workflow/get',{
						'id':id
					});
			},
			
			//查询所有工作流对象
			getAll : function() {
				return BaseService.get('/api/sys/workflow/getall');
			},
			
			//app分页查询
			getAppWorkflow : function(signal, beginTime, endTime, workflowTypes, states, pageNo, pageSize, workflowReqName) {
				return BaseService.get('/api/sys/workflow/getAppWorkflow',{
						'signal':signal, 'beginTime':beginTime, 'endTime':endTime, 'workflowTypes':workflowTypes, 'states':states, 'pageNo':pageNo, 'pageSize':pageSize, 'workflowReqName':workflowReqName
					});
			},
			
			//当前流程节点审批信息
			getCurrNodeInfo : function(workflowObjId, type) {
				return BaseService.get('/api/sys/workflow/getCurrNodeInfo',{
						'workflowObjId':workflowObjId, 'type':type
					});
			},
			
			//流程当前信息
			getCurrRequestInfo : function(workflowObjId) {
				return BaseService.get('/api/sys/workflow/getCurrRequestInfo',{
						'workflowObjId':workflowObjId
					});
			},
			
			//DataTable查询工作流对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflow/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查看流程列表
			getDataTableWorkflow : function(dataTable, signal, beginTime, endTime, workflowTypes) {
				return BaseService.get('/api/sys/workflow/getDataTableWorkflow',{
						'dataTable':dataTable, 'signal':signal, 'beginTime':beginTime, 'endTime':endTime, 'workflowTypes':workflowTypes
					});
			},
			
			//DataTable查看未完成流程列表
			getDataTableWorkflowUnfinish : function(dataTable, beginTime, endTime, workflowTypes) {
				return BaseService.get('/api/sys/workflow/getDataTableWorkflowUnfinish',{
						'dataTable':dataTable, 'beginTime':beginTime, 'endTime':endTime, 'workflowTypes':workflowTypes
					});
			},
			
			//获取当前节点信息
			getNodeInfo : function(workflowObjId) {
				return BaseService.get('/api/sys/workflow/getNodeInfo',{
						'workflowObjId':workflowObjId
					});
			},
			
			//分页显示工作流对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflow/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//流程进度信息
			getRequestInfo : function(workflowObjId) {
				return BaseService.get('/api/sys/workflow/getRequestInfo',{
						'workflowObjId':workflowObjId
					});
			},
			
			//当前审批的信息
			getRequestMsg : function(workflowObjId) {
				return BaseService.get('/api/sys/workflow/getRequestMsg',{
						'workflowObjId':workflowObjId
					});
			},
			
			//流程统计数
			getTotalInfo : function(beginTime, endTime, workflowTypes, states) {
				return BaseService.get('/api/sys/workflow/getTotalInfo',{
						'beginTime':beginTime, 'endTime':endTime, 'workflowTypes':workflowTypes, 'states':states
					});
			},
			
			//流程信息
			getWorkflowInfo : function(workflowObjId) {
				return BaseService.get('/api/sys/workflow/getWorkflowInfo',{
						'workflowObjId':workflowObjId
					});
			},
			
			//流程对象信息
			getWorkflowObjectInfo : function(workflowObjId) {
				return BaseService.get('/api/sys/workflow/getWorkflowObjectInfo',{
						'workflowObjId':workflowObjId
					});
			},
			
			//上传文件
			importBackupFile : function(upFile, fileName) {
				return BaseService.upload('/api/sys/workflow/importBackupFile',{
						'upFile':upFile, 'fileName':fileName
					});
			},
			
			//流程重申请
			reApply : function(workflowObjId, requestId, workflowType) {
				return BaseService.post('/api/sys/workflow/reApply',{
						'workflowObjId':workflowObjId, 'requestId':requestId, 'workflowType':workflowType
					});
			},
			
			//流程浏览
			readState : function(procInfoId) {
				return BaseService.post('/api/sys/workflow/readState',{
						'procInfoId':procInfoId
					});
			},
			
			//流程数据保存
			saveParam : function(workflowObjId, params, remark) {
				return BaseService.post('/api/sys/workflow/saveParam',{
						'workflowObjId':workflowObjId, 'params':params, 'remark':remark
					});
			},
			
			//条件查询工作流对象
			searchAll : function(workflowType, workflowName, state, orderBy) {
				return BaseService.get('/api/sys/workflow/searchall',{
						'workflowType':workflowType, 'workflowName':workflowName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流对象
			searchDataTable : function(dataTable, workflowType, workflowName, state) {
				return BaseService.get('/api/sys/workflow/searchdatatable',{
						'dataTable':dataTable, 'workflowType':workflowType, 'workflowName':workflowName, 'state':state
					});
			},
			
			//条件查询工作流对象
			searchPage : function(workflowType, workflowName, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflow/searchpage',{
						'workflowType':workflowType, 'workflowName':workflowName, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//流程中止
			stop : function(workflowObjId, remark) {
				return BaseService.post('/api/sys/workflow/stop',{
						'workflowObjId':workflowObjId, 'remark':remark
					});
			},
			
			//转办人员
			turnProcOrg : function(workflowProcInfoId, workflowObjId, procOrgId, turnOperLevel, remark) {
				return BaseService.post('/api/sys/workflow/turnProcOrg',{
						'workflowProcInfoId':workflowProcInfoId, 'workflowObjId':workflowObjId, 'procOrgId':procOrgId, 'turnOperLevel':turnOperLevel, 'remark':remark
					});
			},
		},
	    //首页信息	    
        HomeService :{
			
			//增加反馈消息 messageType 0 留言 1 预约演示
			addMessage : function(messageStr) {
				return BaseService.post('/api/sys/home/addMessage',{
						'messageStr':messageStr
					});
			},
			
			//下载帮助手册
			downloadHelp : function() {
				return BaseService.download('/api/sys/home/downloadHelp');
			},
			
			//根据文档类型获取所有文档（0公告,1资讯）
			getDocuments : function(docTypeId) {
				return BaseService.get('/api/sys/home/getDocuments',{
						'docTypeId':docTypeId
					});
			},
			
			//根据ID获取文档
			getDocumentsInfo : function(documentId) {
				return BaseService.get('/api/sys/home/getDocumentsInfo',{
						'documentId':documentId
					});
			},
			
			//获取常见问题，按发布顺序
			getUsualQuestion : function() {
				return BaseService.get('/api/sys/home/getUsualQuestion');
			},
		},
	    //组织单元关系	    
        OrgRelationService :{
			
			//增加组织单元关系对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgRelation/add',mapParams);
			},
			
			//增加组织单元关系对象
			addRelation : function(pId, cId, type) {
				return BaseService.post('/api/sys/orgRelation/addRelation',{
						'pId':pId, 'cId':cId, 'type':type
					});
			},
			
			//删除组织单元关系对象
			del : function(id) {
				return BaseService.post('/api/sys/orgRelation/del',{
						'id':id
					});
			},
			
			//修改组织单元关系对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgRelation/edit',mapParams);
			},
			
			//修改组织单元关系对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgRelation/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出组织单元关系对象到文件
			exportDataTable : function(dataTable, pOrgId, relationType, fieldName) {
				return BaseService.download('/api/sys/orgRelation/exportdatatable',{
						'dataTable':dataTable, 'pOrgId':pOrgId, 'relationType':relationType, 'fieldName':fieldName
					});
			},
			
			//根据ID获取组织单元关系对象
			get : function(id) {
				return BaseService.get('/api/sys/orgRelation/get',{
						'id':id
					});
			},
			
			//查询所有组织单元关系对象
			getAll : function() {
				return BaseService.get('/api/sys/orgRelation/getall');
			},
			
			//获取运维用户绑定的站点
			getBindedSite : function(cOrgId) {
				return BaseService.get('/api/sys/orgRelation/getBindedSite',{
						'cOrgId':cOrgId
					});
			},
			
			//根据ID获取用户树
			getConsumerList : function(orgId, orgTypes) {
				return BaseService.get('/api/sys/orgRelation/getConsumerList',{
						'orgId':orgId, 'orgTypes':orgTypes
					});
			},
			
			//DataTable查询组织单元关系对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgRelation/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//获取组织单元通过父id和类型
			getOrgUnitBypOrgId : function(pOrgId, relationType, orderBy, likeValue) {
				return BaseService.get('/api/sys/orgRelation/getOrgUnitBypOrgId',{
						'pOrgId':pOrgId, 'relationType':relationType, 'orderBy':orderBy, 'likeValue':likeValue
					});
			},
			
			//分页显示组织单元关系对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgRelation/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据ID获取用户下站列表
			getStationList : function(userId) {
				return BaseService.get('/api/sys/orgRelation/getStationList',{
						'userId':userId
					});
			},
			
			//DataTable条件查询组织单元关系对象
			searchDataTable : function(dataTable, pOrgId, relationType) {
				return BaseService.get('/api/sys/orgRelation/searchdatatable',{
						'dataTable':dataTable, 'pOrgId':pOrgId, 'relationType':relationType
					});
			},
			
			//条件查询组织单元关系对象
			searchPage : function(pOrgId, relationType, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/orgRelation/searchpage',{
						'pOrgId':pOrgId, 'relationType':relationType, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流程控制	    
        WorkflowControlService :{
			
			//增加工作流程控制对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowControl/add',mapParams);
			},
			
			//删除工作流程控制对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowControl/del',{
						'id':id
					});
			},
			
			//修改工作流程控制对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowControl/edit',mapParams);
			},
			
			//修改工作流程控制对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowControl/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流程控制对象到文件
			exportAll : function(ix, workflowNode, judgeMethod, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflowControl/exportall',{
						'ix':ix, 'workflowNode':workflowNode, 'judgeMethod':judgeMethod, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流程控制对象到文件
			exportDataTable : function(dataTable, ix, workflowNode, judgeMethod, fieldName) {
				return BaseService.download('/api/sys/workflowControl/exportdatatable',{
						'dataTable':dataTable, 'ix':ix, 'workflowNode':workflowNode, 'judgeMethod':judgeMethod, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流程控制对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowControl/get',{
						'id':id
					});
			},
			
			//查询所有工作流程控制对象
			getAll : function(workflowId, orderBy) {
				return BaseService.get('/api/sys/workflowControl/getall',{
						'workflowId':workflowId, 'orderBy':orderBy
					});
			},
			
			//DataTable查询工作流程控制对象
			getDataTable : function(dataTable, workflowId) {
				return BaseService.get('/api/sys/workflowControl/getdatatable',{
						'dataTable':dataTable, 'workflowId':workflowId
					});
			},
			
			//分页显示工作流程控制对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowControl/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询工作流程控制对象
			searchAll : function(ix, workflowNode, judgeMethod, orderBy) {
				return BaseService.get('/api/sys/workflowControl/searchall',{
						'ix':ix, 'workflowNode':workflowNode, 'judgeMethod':judgeMethod, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流程控制对象
			searchDataTable : function(dataTable, ix, workflowNode, judgeMethod) {
				return BaseService.get('/api/sys/workflowControl/searchdatatable',{
						'dataTable':dataTable, 'ix':ix, 'workflowNode':workflowNode, 'judgeMethod':judgeMethod
					});
			},
			
			//条件查询工作流程控制对象
			searchPage : function(ix, workflowNode, judgeMethod, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowControl/searchpage',{
						'ix':ix, 'workflowNode':workflowNode, 'judgeMethod':judgeMethod, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流申请	    
        WorkflowRequestService :{
			
			//增加工作流申请对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowRequest/add',mapParams);
			},
			
			//删除工作流申请对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowRequest/del',{
						'id':id
					});
			},
			
			//修改工作流申请对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowRequest/edit',mapParams);
			},
			
			//修改工作流申请对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowRequest/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流申请对象到文件
			exportAll : function(workflow, workflowObjId, orgId, beginTime, endTime, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflowRequest/exportall',{
						'workflow':workflow, 'workflowObjId':workflowObjId, 'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流申请对象到文件
			exportDataTable : function(dataTable, workflow, workflowObjId, orgId, beginTime, endTime, state, fieldName) {
				return BaseService.download('/api/sys/workflowRequest/exportdatatable',{
						'dataTable':dataTable, 'workflow':workflow, 'workflowObjId':workflowObjId, 'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流申请对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowRequest/get',{
						'id':id
					});
			},
			
			//查询所有工作流申请对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowRequest/getall');
			},
			
			//根据申请对象ID获取工作流申请对象
			getByObjId : function(workflowObjId) {
				return BaseService.get('/api/sys/workflowRequest/getByObjId',{
						'workflowObjId':workflowObjId
					});
			},
			
			//DataTable查询工作流申请对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowRequest/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示工作流申请对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowRequest/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询工作流申请对象
			searchAll : function(workflow, workflowObjId, orgId, beginTime, endTime, state, orderBy) {
				return BaseService.get('/api/sys/workflowRequest/searchall',{
						'workflow':workflow, 'workflowObjId':workflowObjId, 'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流申请对象
			searchDataTable : function(dataTable, workflow, workflowObjId, orgId, beginTime, endTime, state) {
				return BaseService.get('/api/sys/workflowRequest/searchdatatable',{
						'dataTable':dataTable, 'workflow':workflow, 'workflowObjId':workflowObjId, 'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'state':state
					});
			},
			
			//条件查询工作流申请对象
			searchPage : function(workflow, workflowObjId, orgId, beginTime, endTime, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowRequest/searchpage',{
						'workflow':workflow, 'workflowObjId':workflowObjId, 'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //日历事件	    
        HolidayEventService :{
			
			//增加日历事件对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/holidayEvent/add',mapParams);
			},
			
			//单个日历增加事件
			addEventByDt : function(orgId, date, eventId, way) {
				return BaseService.post('/api/sys/holidayEvent/addEventByDt',{
						'orgId':orgId, 'date':date, 'eventId':eventId, 'way':way
					});
			},
			
			//拖动事件,删除起点日期事件，落点日期新增事件
			addEventFromTo : function(orgId, fromHolidayId, fromDate, toDate, eventId, way) {
				return BaseService.post('/api/sys/holidayEvent/addEventFromTo',{
						'orgId':orgId, 'fromHolidayId':fromHolidayId, 'fromDate':fromDate, 'toDate':toDate, 'eventId':eventId, 'way':way
					});
			},
			
			//删除日历事件对象
			del : function(id) {
				return BaseService.post('/api/sys/holidayEvent/del',{
						'id':id
					});
			},
			
			// 单个日历删除事件
			delEventByDt : function(holidayId, id) {
				return BaseService.post('/api/sys/holidayEvent/delEventByDt',{
						'holidayId':holidayId, 'id':id
					});
			},
			
			//修改日历事件对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/holidayEvent/edit',mapParams);
			},
			
			//修改日历事件对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/holidayEvent/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出日历事件对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/holidayEvent/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取日历事件对象
			get : function(id) {
				return BaseService.get('/api/sys/holidayEvent/get',{
						'id':id
					});
			},
			
			//查询所有日历事件对象
			getAll : function() {
				return BaseService.get('/api/sys/holidayEvent/getall');
			},
			
			//DataTable查询日历事件对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/holidayEvent/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询用户本月的所有事件
			getEventByDt : function(orgId, dt, orderBy) {
				return BaseService.get('/api/sys/holidayEvent/getEventByDt',{
						'orgId':orgId, 'dt':dt, 'orderBy':orderBy
					});
			},
			
			//分页显示日历事件对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/holidayEvent/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //参数配置	    
        OrgConfigService :{
			
			//增加参数配置对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgConfig/add',mapParams);
			},
			
			//删除参数配置对象
			del : function(id) {
				return BaseService.post('/api/sys/orgConfig/del',{
						'id':id
					});
			},
			
			//修改参数配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgConfig/edit',mapParams);
			},
			
			//修改参数配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgConfig/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出参数配置对象到文件
			exportAll : function(orgUnit, classfy, code, name, orderBy, fieldName) {
				return BaseService.download('/api/sys/orgConfig/exportall',{
						'orgUnit':orgUnit, 'classfy':classfy, 'code':code, 'name':name, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出参数配置对象到文件
			exportDataTable : function(dataTable, orgUnit, classfy, code, name, fieldName) {
				return BaseService.download('/api/sys/orgConfig/exportdatatable',{
						'dataTable':dataTable, 'orgUnit':orgUnit, 'classfy':classfy, 'code':code, 'name':name, 'fieldName':fieldName
					});
			},
			
			//根据ID获取参数配置对象
			get : function(id) {
				return BaseService.get('/api/sys/orgConfig/get',{
						'id':id
					});
			},
			
			//查询所有参数配置对象
			getAll : function() {
				return BaseService.get('/api/sys/orgConfig/getall');
			},
			
			//DataTable查询参数配置对象
			getConfigsDatatable : function(dataTable) {
				return BaseService.get('/api/sys/orgConfig/getConfigsDatatable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查询参数配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgConfig/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示参数配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgConfig/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询参数配置对象
			searchAll : function(orgUnit, classfy, code, name, orderBy) {
				return BaseService.get('/api/sys/orgConfig/searchall',{
						'orgUnit':orgUnit, 'classfy':classfy, 'code':code, 'name':name, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询参数配置对象
			searchDataTable : function(dataTable, orgUnit, classfy, code, name) {
				return BaseService.get('/api/sys/orgConfig/searchdatatable',{
						'dataTable':dataTable, 'orgUnit':orgUnit, 'classfy':classfy, 'code':code, 'name':name
					});
			},
			
			//条件查询参数配置对象
			searchPage : function(orgUnit, classfy, code, name, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/orgConfig/searchpage',{
						'orgUnit':orgUnit, 'classfy':classfy, 'code':code, 'name':name, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //组织限制访问信息	    
        OrgRestrictService :{
			
			//增加组织限制访问信息对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgRestrict/add',mapParams);
			},
			
			//增加所有选择的组织限制访问信息对象
			addAll : function(orgLimitList, orgId) {
				return BaseService.post('/api/sys/orgRestrict/addAll',{
						'orgLimitList':orgLimitList, 'orgId':orgId
					});
			},
			
			//删除组织限制访问信息对象
			del : function(id) {
				return BaseService.post('/api/sys/orgRestrict/del',{
						'id':id
					});
			},
			
			//修改组织限制访问信息对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgRestrict/edit',mapParams);
			},
			
			//修改组织限制访问信息对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgRestrict/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出组织限制访问信息对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/orgRestrict/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取组织限制访问信息对象
			get : function(id) {
				return BaseService.get('/api/sys/orgRestrict/get',{
						'id':id
					});
			},
			
			//查询所有组织限制访问信息对象
			getAll : function() {
				return BaseService.get('/api/sys/orgRestrict/getall');
			},
			
			//DataTable查询组织限制访问信息对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgRestrict/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示组织限制访问信息对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgRestrict/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流参数数据	    
        WorkflowParamDataService :{
			
			//增加工作流参数数据对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowParamData/add',mapParams);
			},
			
			//删除工作流参数数据对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowParamData/del',{
						'id':id
					});
			},
			
			//修改工作流参数数据对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowParamData/edit',mapParams);
			},
			
			//修改工作流参数数据对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowParamData/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流参数数据对象到文件
			exportAll : function(workflowRequest, procOrgId, paramName, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflowParamData/exportall',{
						'workflowRequest':workflowRequest, 'procOrgId':procOrgId, 'paramName':paramName, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流参数数据对象到文件
			exportDataTable : function(dataTable, workflowRequest, procOrgId, paramName, fieldName) {
				return BaseService.download('/api/sys/workflowParamData/exportdatatable',{
						'dataTable':dataTable, 'workflowRequest':workflowRequest, 'procOrgId':procOrgId, 'paramName':paramName, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流参数数据对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowParamData/get',{
						'id':id
					});
			},
			
			//查询所有工作流参数数据对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowParamData/getall');
			},
			
			//DataTable查询工作流参数数据对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowParamData/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示工作流参数数据对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowParamData/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询工作流参数数据对象
			searchAll : function(workflowRequest, procOrgId, paramName, orderBy) {
				return BaseService.get('/api/sys/workflowParamData/searchall',{
						'workflowRequest':workflowRequest, 'procOrgId':procOrgId, 'paramName':paramName, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流参数数据对象
			searchDataTable : function(dataTable, workflowRequest, procOrgId, paramName) {
				return BaseService.get('/api/sys/workflowParamData/searchdatatable',{
						'dataTable':dataTable, 'workflowRequest':workflowRequest, 'procOrgId':procOrgId, 'paramName':paramName
					});
			},
			
			//条件查询工作流参数数据对象
			searchPage : function(workflowRequest, procOrgId, paramName, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowParamData/searchpage',{
						'workflowRequest':workflowRequest, 'procOrgId':procOrgId, 'paramName':paramName, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //节点操作员	    
        WorkflowNodeOrgService :{
			
			//增加节点操作员对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowNodeOrg/add',mapParams);
			},
			
			//增加节点操作员对象
			addNodeOrgs : function(workflowNodeId, nodeOrgsJson) {
				return BaseService.post('/api/sys/workflowNodeOrg/addNodeOrgs',{
						'workflowNodeId':workflowNodeId, 'nodeOrgsJson':nodeOrgsJson
					});
			},
			
			//删除节点操作员对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowNodeOrg/del',{
						'id':id
					});
			},
			
			//修改节点操作员对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowNodeOrg/edit',mapParams);
			},
			
			//修改节点操作员对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowNodeOrg/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出节点操作员对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/workflowNodeOrg/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取节点操作员对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowNodeOrg/get',{
						'id':id
					});
			},
			
			//查询所有节点操作员对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowNodeOrg/getall');
			},
			
			//查询节点操作员
			getAllByNodeId : function(workflowNodeId) {
				return BaseService.get('/api/sys/workflowNodeOrg/getAllByNodeId',{
						'workflowNodeId':workflowNodeId
					});
			},
			
			//DataTable查询节点操作员对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowNodeOrg/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示节点操作员对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowNodeOrg/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //操作记录	    
        OperLogService :{
			
			//增加操作记录对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/operLog/add',mapParams);
			},
			
			//删除操作记录对象
			del : function(id) {
				return BaseService.post('/api/sys/operLog/del',{
						'id':id
					});
			},
			
			//修改操作记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/operLog/edit',mapParams);
			},
			
			//修改操作记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/operLog/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出操作记录对象到文件
			exportAll : function(orgId, dt, objTypeId, objId, operType, orderBy, fieldName) {
				return BaseService.download('/api/sys/operLog/exportall',{
						'orgId':orgId, 'dt':dt, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出操作记录对象到文件
			exportDataTable : function(dataTable, orgId, dt, objTypeId, objId, operType, fieldName) {
				return BaseService.download('/api/sys/operLog/exportdatatable',{
						'dataTable':dataTable, 'orgId':orgId, 'dt':dt, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType, 'fieldName':fieldName
					});
			},
			
			//根据ID获取操作记录对象
			get : function(id) {
				return BaseService.get('/api/sys/operLog/get',{
						'id':id
					});
			},
			
			//查询所有操作记录对象
			getAll : function() {
				return BaseService.get('/api/sys/operLog/getall');
			},
			
			//获取客户的操作记录
			getCustomerOperLogs : function(customerId) {
				return BaseService.get('/api/sys/operLog/getCustomerOperLogs',{
						'customerId':customerId
					});
			},
			
			//DataTable查询操作记录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/operLog/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示操作记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/operLog/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询操作记录对象
			searchAll : function(orgId, dt, objTypeId, objId, operType, orderBy) {
				return BaseService.get('/api/sys/operLog/searchall',{
						'orgId':orgId, 'dt':dt, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType, 'orderBy':orderBy
					});
			},
			
			//条件查询员工操作记录
			searchByDataTable : function(dataTable, orgId, beginTime, endTime, objTypeId, objId, operType) {
				return BaseService.get('/api/sys/operLog/searchByDataTable',{
						'dataTable':dataTable, 'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType
					});
			},
			
			//DataTable条件查询操作记录对象
			searchDataTable : function(dataTable, orgId, dt, objTypeId, objId, operType) {
				return BaseService.get('/api/sys/operLog/searchdatatable',{
						'dataTable':dataTable, 'orgId':orgId, 'dt':dt, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType
					});
			},
			
			//条件查询员工操作记录
			searchEmpPage : function(orgId, beginTime, endTime, objTypeId, objId, operType, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/operLog/searchEmpPage',{
						'orgId':orgId, 'beginTime':beginTime, 'endTime':endTime, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询操作记录对象
			searchPage : function(orgId, dt, objTypeId, objId, operType, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/operLog/searchpage',{
						'orgId':orgId, 'dt':dt, 'objTypeId':objTypeId, 'objId':objId, 'operType':operType, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //访问记录	    
        AccessRecordService :{
			
			//增加访问记录对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/accessRecord/add',mapParams);
			},
			
			//删除访问记录对象
			del : function(id) {
				return BaseService.post('/api/sys/accessRecord/del',{
						'id':id
					});
			},
			
			//修改访问记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/accessRecord/edit',mapParams);
			},
			
			//修改访问记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/accessRecord/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出访问记录对象到文件
			exportAll : function(orgEmployee, dt, orderBy, fieldName) {
				return BaseService.download('/api/sys/accessRecord/exportall',{
						'orgEmployee':orgEmployee, 'dt':dt, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出访问记录对象到文件
			exportDataTable : function(dataTable, orgEmployee, dt, fieldName) {
				return BaseService.download('/api/sys/accessRecord/exportdatatable',{
						'dataTable':dataTable, 'orgEmployee':orgEmployee, 'dt':dt, 'fieldName':fieldName
					});
			},
			
			//根据ID获取访问记录对象
			get : function(id) {
				return BaseService.get('/api/sys/accessRecord/get',{
						'id':id
					});
			},
			
			//DataTable查询访问记录对象
			getAccessRecordChartData : function(startDt, endDt, appCode, productTypeId) {
				return BaseService.get('/api/sys/accessRecord/getAccessRecordChartData',{
						'startDt':startDt, 'endDt':endDt, 'appCode':appCode, 'productTypeId':productTypeId
					});
			},
			
			//DataTable查询访问记录对象
			getAccessRecordDataTable : function(dataTable, startDt, endDt, appCode, type, productTypeId) {
				return BaseService.get('/api/sys/accessRecord/getAccessRecordDataTable',{
						'dataTable':dataTable, 'startDt':startDt, 'endDt':endDt, 'appCode':appCode, 'type':type, 'productTypeId':productTypeId
					});
			},
			
			//查询所有访问记录对象
			getAll : function() {
				return BaseService.get('/api/sys/accessRecord/getall');
			},
			
			//DataTable查询访问记录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/accessRecord/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示访问记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/accessRecord/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询访问记录对象
			searchAll : function(orgEmployee, dt, orderBy) {
				return BaseService.get('/api/sys/accessRecord/searchall',{
						'orgEmployee':orgEmployee, 'dt':dt, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询访问记录对象
			searchDataTable : function(dataTable, orgEmployee, dt) {
				return BaseService.get('/api/sys/accessRecord/searchdatatable',{
						'dataTable':dataTable, 'orgEmployee':orgEmployee, 'dt':dt
					});
			},
			
			//条件查询访问记录对象
			searchPage : function(orgEmployee, dt, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/accessRecord/searchpage',{
						'orgEmployee':orgEmployee, 'dt':dt, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //员工	    
        OrgEmployeeService :{
			
			//增加员工对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgEmployee/add',mapParams);
			},
			
			//增加用户或者二级员工对象
			addEmpAndRel : function(mapParams) {
						return BaseService.post('/api/sys/orgEmployee/addEmpAndRel',mapParams);
			},
			
			//增加员工对象和角色信息
			addEmpWithRole : function(orgEmployeeStr, roleIdList) {
				return BaseService.post('/api/sys/orgEmployee/addEmpWithRole',{
						'orgEmployeeStr':orgEmployeeStr, 'roleIdList':roleIdList
					});
			},
			
			//新增OpeWorker员工对象
			addOpeWorker : function(orgEmployee, consumerId, listMonitorSiteOrgId) {
				return BaseService.post('/api/sys/orgEmployee/addOpeWorker',{
						'orgEmployee':orgEmployee, 'consumerId':consumerId, 'listMonitorSiteOrgId':listMonitorSiteOrgId
					});
			},
			
			//删除员工对象
			del : function(id) {
				return BaseService.post('/api/sys/orgEmployee/del',{
						'id':id
					});
			},
			
			//删除员工对象及其所有关系
			delEmpAndAllRelation : function(id) {
				return BaseService.post('/api/sys/orgEmployee/delEmpAndAllRelation',{
						'id':id
					});
			},
			
			//删除OpeWorker员工对象
			delOpeWorker : function(id) {
				return BaseService.post('/api/sys/orgEmployee/delOpeWorker',{
						'id':id
					});
			},
			
			//删除员工与父级关系
			delRel : function(id) {
				return BaseService.post('/api/sys/orgEmployee/delRel',{
						'id':id
					});
			},
			
			//运维助手-用户管理-用户列表。下载导入模板
			downloadOpeWorkerTemplate : function() {
				return BaseService.download('/api/sys/orgEmployee/downloadOpeWorkerTemplate');
			},
			
			//修改员工对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgEmployee/edit',mapParams);
			},
			
			//修改员工对象和角色信息
			editEmpWithRole : function(orgEmployeeStr, roleIdList) {
				return BaseService.post('/api/sys/orgEmployee/editEmpWithRole',{
						'orgEmployeeStr':orgEmployeeStr, 'roleIdList':roleIdList
					});
			},
			
			//修改员工对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgEmployee/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//app修改员工对象的字段
			editFieldApp : function(fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgEmployee/editFieldApp',{
						'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改OpeWorker员工对象
			editOpeWorker : function(orgEmployee, consumerId, listMonitorSiteOrgId) {
				return BaseService.post('/api/sys/orgEmployee/editOpeWorker',{
						'orgEmployee':orgEmployee, 'consumerId':consumerId, 'listMonitorSiteOrgId':listMonitorSiteOrgId
					});
			},
			
			//解锁
			editUnlock : function(id) {
				return BaseService.post('/api/sys/orgEmployee/editUnlock',{
						'id':id
					});
			},
			
			//导出员工对象到文件
			exportAll : function(orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, orderBy, fieldName) {
				return BaseService.download('/api/sys/orgEmployee/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出员工对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, fieldName) {
				return BaseService.download('/api/sys/orgEmployee/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'fieldName':fieldName
					});
			},
			
			//运维助手-用户管理-用户列表。导出
			exportOpeWorker : function(orgName, telephone, operationSite, orgCode) {
				return BaseService.download('/api/sys/orgEmployee/exportOpeWorker',{
						'orgName':orgName, 'telephone':telephone, 'operationSite':operationSite, 'orgCode':orgCode
					});
			},
			
			//根据ID获取员工对象
			get : function(id) {
				return BaseService.get('/api/sys/orgEmployee/get',{
						'id':id
					});
			},
			
			//查询所有员工对象
			getAll : function() {
				return BaseService.get('/api/sys/orgEmployee/getall');
			},
			
			//获得指定客户指定月份指定类型联系人以及申报电量情况
			getAllByConsumer : function(dt, orgId, classCode) {
				return BaseService.get('/api/sys/orgEmployee/getAllByConsumer',{
						'dt':dt, 'orgId':orgId, 'classCode':classCode
					});
			},
			
			//全部联系人列表DataTable
			getConsumersAndEmpDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgEmployee/getConsumersAndEmpDataTable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查询员工对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgEmployee/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据ID获取员工个人信息
			getEmp : function(id) {
				return BaseService.get('/api/sys/orgEmployee/getEmp',{
						'id':id
					});
			},
			
			//根据账号获取员工信息
			getEmpByCode : function(orgCode) {
				return BaseService.get('/api/sys/orgEmployee/getEmpByCode',{
						'orgCode':orgCode
					});
			},
			
			//获取员工头像
			getEmpIcon : function() {
				return BaseService.get('/api/sys/orgEmployee/getEmpIcon');
			},
			
			//获取员工信息
			getEmpInfoApp : function() {
				return BaseService.get('/api/sys/orgEmployee/getEmpInfoApp');
			},
			
			//获取员工信息和头像
			getEmpMap : function(id) {
				return BaseService.get('/api/sys/orgEmployee/getEmpMap',{
						'id':id
					});
			},
			
			//获取员工接收短信和邮件状态信息
			getEmpRecvStateApp : function() {
				return BaseService.get('/api/sys/orgEmployee/getEmpRecvStateApp');
			},
			
			//查询用户下的所有联系人
			getEmployeeByConsumer : function(dataTable, orgId) {
				return BaseService.get('/api/sys/orgEmployee/getEmployeeByConsumer',{
						'dataTable':dataTable, 'orgId':orgId
					});
			},
			
			//通过联系方式查询联系人
			getEmployeeByTel : function(telephone) {
				return BaseService.get('/api/sys/orgEmployee/getEmployeeByTel',{
						'telephone':telephone
					});
			},
			
			//通过电话号码查询Employee
			getEmployeeInfoByTel : function(telephone) {
				return BaseService.get('/api/sys/orgEmployee/getEmployeeInfoByTel',{
						'telephone':telephone
					});
			},
			
			//根据ID获取员工对象
			getEmployeeWithRole : function(id) {
				return BaseService.get('/api/sys/orgEmployee/getEmployeeWithRole',{
						'id':id
					});
			},
			
			//根据用户ID查询联系人列表
			getEmployeesByCustomer : function(orgId) {
				return BaseService.get('/api/sys/orgEmployee/getEmployeesByCustomer',{
						'orgId':orgId
					});
			},
			
			//获取账号信息列表DataTable
			getEmployeesDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgEmployee/getEmployeesDataTable',{
						'dataTable':dataTable
					});
			},
			
			//查询系统人员 
			getListWithRoleName : function() {
				return BaseService.get('/api/sys/orgEmployee/getListWithRoleName');
			},
			
			//运维助手-用户管理-用户列表DataTable
			getOpeWorkerDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgEmployee/getOpeWorkerDataTable',{
						'dataTable':dataTable
					});
			},
			
			//取到运维员工
			getOperationEmployee : function() {
				return BaseService.get('/api/sys/orgEmployee/getOperationEmployee');
			},
			
			//查询运维人员 
			getOperationList : function() {
				return BaseService.get('/api/sys/orgEmployee/getOperationList');
			},
			
			//分页显示员工对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgEmployee/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//运维助手-用户管理-用户列表。导入用户
			importOpeWorker : function(upFile) {
				return BaseService.upload('/api/sys/orgEmployee/importOpeWorker',{
						'upFile':upFile
					});
			},
			
			//条件查询员工对象
			searchAll : function(orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, orderBy) {
				return BaseService.get('/api/sys/orgEmployee/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询员工对象
			searchDataTable : function(dataTable, orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime) {
				return BaseService.get('/api/sys/orgEmployee/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime
					});
			},
			
			//条件查询员工对象
			searchPage : function(orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/orgEmployee/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//修改密码
			updateEmpPassword : function(aId, eId, adminPsw, newPsw) {
				return BaseService.post('/api/sys/orgEmployee/updateEmpPassword',{
						'aId':aId, 'eId':eId, 'adminPsw':adminPsw, 'newPsw':newPsw
					});
			},
			
			//修改密码
			updatePassword : function(id, oldPsw, newPsw) {
				return BaseService.get('/api/sys/orgEmployee/updatePassword',{
						'id':id, 'oldPsw':oldPsw, 'newPsw':newPsw
					});
			},
			
			//修改密码
			updatePasswordEncrypt : function(id, oldPsw, newPsw) {
				return BaseService.get('/api/sys/orgEmployee/updatePasswordEncrypt',{
						'id':id, 'oldPsw':oldPsw, 'newPsw':newPsw
					});
			},
			
			//上传员工头像
			uploadEmpIcon : function(upFile) {
				return BaseService.upload('/api/sys/orgEmployee/uploadEmpIcon',{
						'upFile':upFile
					});
			},
		},
	    //应用系统	    
        AppService :{
			
			//增加应用系统对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/app/add',mapParams);
			},
			
			//删除应用系统对象
			del : function(id) {
				return BaseService.post('/api/sys/app/del',{
						'id':id
					});
			},
			
			//修改应用系统对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/app/edit',mapParams);
			},
			
			//修改应用系统对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/app/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出应用系统对象到文件
			exportAll : function(appCode, appName, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/app/exportall',{
						'appCode':appCode, 'appName':appName, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出应用系统对象到文件
			exportDataTable : function(dataTable, appCode, appName, state, fieldName) {
				return BaseService.download('/api/sys/app/exportdatatable',{
						'dataTable':dataTable, 'appCode':appCode, 'appName':appName, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取应用系统对象
			get : function(id) {
				return BaseService.get('/api/sys/app/get',{
						'id':id
					});
			},
			
			//查询所有应用系统对象
			getAll : function() {
				return BaseService.get('/api/sys/app/getall');
			},
			
			//条件查询应用系统对象
			getAllByPropertiesMap : function(propMap, orderBy) {
				return BaseService.get('/api/sys/app/getAllByPropertiesMap',{
						'propMap':propMap, 'orderBy':orderBy
					});
			},
			
			//DataTable查询应用系统对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/app/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示应用系统对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/app/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询应用系统对象
			searchAll : function(appCode, appName, state, orderBy) {
				return BaseService.get('/api/sys/app/searchall',{
						'appCode':appCode, 'appName':appName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询应用系统对象
			searchDataTable : function(dataTable, appCode, appName, state) {
				return BaseService.get('/api/sys/app/searchdatatable',{
						'dataTable':dataTable, 'appCode':appCode, 'appName':appName, 'state':state
					});
			},
			
			//条件查询应用系统对象
			searchPage : function(appCode, appName, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/app/searchpage',{
						'appCode':appCode, 'appName':appName, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //联想	    
        AssociateService :{
			
			//售电公司员工匹配
			equalMatchEmp : function(inputName, limit) {
				return BaseService.get('/api/sys/associate/equalMatchEmp',{
						'inputName':inputName, 'limit':limit
					});
			},
			
			//流程节点权限
			equalMatchNodeOrg : function(inputName, limit) {
				return BaseService.get('/api/sys/associate/equalMatchNodeOrg',{
						'inputName':inputName, 'limit':limit
					});
			},
			
			//用户模糊匹配
			fuzzyMatchConsumer : function(inputName, limit) {
				return BaseService.get('/api/sys/associate/fuzzyMatchConsumer',{
						'inputName':inputName, 'limit':limit
					});
			},
			
			//售电公司员工匹配
			fuzzyMatchEmp : function(inputName, limit, roleName) {
				return BaseService.get('/api/sys/associate/fuzzyMatchEmp',{
						'inputName':inputName, 'limit':limit, 'roleName':roleName
					});
			},
			
			//流程节点权限
			fuzzyMatchNodeOrg : function(inputName, limit) {
				return BaseService.get('/api/sys/associate/fuzzyMatchNodeOrg',{
						'inputName':inputName, 'limit':limit
					});
			},
		},
	    //节假日安排	    
        HolidayService :{
			
			//增加节假日安排对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/holiday/add',mapParams);
			},
			
			//删除节假日安排对象
			del : function(id) {
				return BaseService.post('/api/sys/holiday/del',{
						'id':id
					});
			},
			
			//修改节假日安排对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/holiday/edit',mapParams);
			},
			
			//修改节假日安排对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/holiday/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出节假日安排对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/holiday/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取节假日安排对象
			get : function(id) {
				return BaseService.get('/api/sys/holiday/get',{
						'id':id
					});
			},
			
			//查询所有节假日安排对象
			getAll : function() {
				return BaseService.get('/api/sys/holiday/getall');
			},
			
			//根据选择年月及客户ID反回日历数据
			getConsumerHolidayByDt : function(id, dt) {
				return BaseService.post('/api/sys/holiday/getConsumerHolidayByDt',{
						'id':id, 'dt':dt
					});
			},
			
			//DataTable查询节假日安排对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/holiday/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示节假日安排对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/holiday/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流输入参数	    
        WorkflowParamService :{
			
			//增加工作流输入参数对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowParam/add',mapParams);
			},
			
			//增加工作流输入参数对象
			addByJson : function(workflowId, workflowParamJson) {
				return BaseService.post('/api/sys/workflowParam/addByJson',{
						'workflowId':workflowId, 'workflowParamJson':workflowParamJson
					});
			},
			
			//删除工作流输入参数对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowParam/del',{
						'id':id
					});
			},
			
			//修改工作流输入参数对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowParam/edit',mapParams);
			},
			
			//修改工作流输入参数对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowParam/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流输入参数对象到文件
			exportAll : function(workflow, paramName, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflowParam/exportall',{
						'workflow':workflow, 'paramName':paramName, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流输入参数对象到文件
			exportDataTable : function(dataTable, workflow, paramName, fieldName) {
				return BaseService.download('/api/sys/workflowParam/exportdatatable',{
						'dataTable':dataTable, 'workflow':workflow, 'paramName':paramName, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流输入参数对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowParam/get',{
						'id':id
					});
			},
			
			//查询所有工作流输入参数对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowParam/getall');
			},
			
			//查询所有工作流输入参数对象
			getAllForWorkflow : function(workflowId, orderBy) {
				return BaseService.get('/api/sys/workflowParam/getAllForWorkflow',{
						'workflowId':workflowId, 'orderBy':orderBy
					});
			},
			
			//DataTable查询工作流输入参数对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowParam/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示工作流输入参数对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowParam/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询工作流输入参数对象
			searchAll : function(workflow, paramName, orderBy) {
				return BaseService.get('/api/sys/workflowParam/searchall',{
						'workflow':workflow, 'paramName':paramName, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流输入参数对象
			searchDataTable : function(dataTable, workflow, paramName) {
				return BaseService.get('/api/sys/workflowParam/searchdatatable',{
						'dataTable':dataTable, 'workflow':workflow, 'paramName':paramName
					});
			},
			
			//条件查询工作流输入参数对象
			searchPage : function(workflow, paramName, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowParam/searchpage',{
						'workflow':workflow, 'paramName':paramName, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //字典分类	    
        DictionaryCategoryService :{
			
			//增加字典分类对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/dictionaryCategory/add',mapParams);
			},
			
			//删除字典分类对象
			del : function(id) {
				return BaseService.post('/api/sys/dictionaryCategory/del',{
						'id':id
					});
			},
			
			//修改字典分类对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/dictionaryCategory/edit',mapParams);
			},
			
			//修改字典分类对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/dictionaryCategory/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出字典分类对象到文件
			exportAll : function(code, orderBy, fieldName) {
				return BaseService.download('/api/sys/dictionaryCategory/exportall',{
						'code':code, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出字典分类对象到文件
			exportDataTable : function(dataTable, code, fieldName) {
				return BaseService.download('/api/sys/dictionaryCategory/exportdatatable',{
						'dataTable':dataTable, 'code':code, 'fieldName':fieldName
					});
			},
			
			//根据ID获取字典分类对象
			get : function(id) {
				return BaseService.get('/api/sys/dictionaryCategory/get',{
						'id':id
					});
			},
			
			//查询所有字典分类对象
			getAll : function() {
				return BaseService.get('/api/sys/dictionaryCategory/getall');
			},
			
			//DataTable查询字典分类对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/dictionaryCategory/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示字典分类对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/dictionaryCategory/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询字典分类对象
			searchAll : function(code, orderBy) {
				return BaseService.get('/api/sys/dictionaryCategory/searchall',{
						'code':code, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询字典分类对象
			searchDataTable : function(dataTable, code) {
				return BaseService.get('/api/sys/dictionaryCategory/searchdatatable',{
						'dataTable':dataTable, 'code':code
					});
			},
			
			//条件查询字典分类对象
			searchPage : function(code, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/dictionaryCategory/searchpage',{
						'code':code, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流节点功能权限（预留）	    
        WorkflowNodeFunService :{
			
			//增加工作流节点功能权限（预留）对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowNodeFun/add',mapParams);
			},
			
			//删除工作流节点功能权限（预留）对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowNodeFun/del',{
						'id':id
					});
			},
			
			//修改工作流节点功能权限（预留）对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowNodeFun/edit',mapParams);
			},
			
			//修改工作流节点功能权限（预留）对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowNodeFun/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出工作流节点功能权限（预留）对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/workflowNodeFun/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流节点功能权限（预留）对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowNodeFun/get',{
						'id':id
					});
			},
			
			//查询所有工作流节点功能权限（预留）对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowNodeFun/getall');
			},
			
			//DataTable查询工作流节点功能权限（预留）对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowNodeFun/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示工作流节点功能权限（预留）对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowNodeFun/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //对象属性	    
        ObjAttrService :{
			
			//增加对象属性对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/objAttr/add',mapParams);
			},
			
			//删除对象属性对象
			del : function(id) {
				return BaseService.post('/api/sys/objAttr/del',{
						'id':id
					});
			},
			
			//修改对象属性对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/objAttr/edit',mapParams);
			},
			
			//修改对象属性对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/objAttr/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出对象属性对象到文件
			exportAll : function(obj, objAttrName, attrName, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/objAttr/exportall',{
						'obj':obj, 'objAttrName':objAttrName, 'attrName':attrName, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出对象属性对象到文件
			exportDataTable : function(dataTable, obj, objAttrName, attrName, state, fieldName) {
				return BaseService.download('/api/sys/objAttr/exportdatatable',{
						'dataTable':dataTable, 'obj':obj, 'objAttrName':objAttrName, 'attrName':attrName, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取对象属性对象
			get : function(id) {
				return BaseService.get('/api/sys/objAttr/get',{
						'id':id
					});
			},
			
			//查询所有对象属性对象
			getAll : function() {
				return BaseService.get('/api/sys/objAttr/getall');
			},
			
			//DataTable查询对象属性对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/objAttr/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示对象属性对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/objAttr/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询对象属性对象
			searchAll : function(obj, objAttrName, attrName, state, orderBy) {
				return BaseService.get('/api/sys/objAttr/searchall',{
						'obj':obj, 'objAttrName':objAttrName, 'attrName':attrName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询对象属性对象
			searchDataTable : function(dataTable, obj, objAttrName, attrName, state) {
				return BaseService.get('/api/sys/objAttr/searchdatatable',{
						'dataTable':dataTable, 'obj':obj, 'objAttrName':objAttrName, 'attrName':attrName, 'state':state
					});
			},
			
			//条件查询对象属性对象
			searchPage : function(obj, objAttrName, attrName, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/objAttr/searchpage',{
						'obj':obj, 'objAttrName':objAttrName, 'attrName':attrName, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //阿里微消息队列mqtt控制器	    
        MqttService :{
			
			//获取连接mqtt所需AccessKey
			getAccessKey : function() {
				return BaseService.post('/api/sys/mqtt/getAccessKey');
			},
			
			//获得mqtt配置信息
			getConfig : function() {
				return BaseService.post('/api/sys/mqtt/getConfig');
			},
			
			//发送多条历史数据至mqtt
			getDataList : function(deviceCodes, monitorItemIds, dataTypes, begin, end, interval) {
				return BaseService.post('/api/sys/mqtt/sendDataList',{
						'deviceCodes':deviceCodes, 'monitorItemIds':monitorItemIds, 'dataTypes':dataTypes, 'begin':begin, 'end':end, 'interval':interval
					});
			},
			
			//申请mqtt Token
			getToken : function(topics) {
				return BaseService.post('/api/sys/mqtt/getToken',{
						'topics':topics
					});
			},
			
			//删除mqtt Token
			revokeToken : function(token) {
				return BaseService.post('/api/sys/mqtt/revokeToken',{
						'token':token
					});
			},
			
			//发送数据至mqtt初始化
			sendInitial : function(json, interval) {
				return BaseService.post('/api/sys/mqtt/sendInitial',{
						'json':json, 'interval':interval
					});
			},
			
			//发送实时数据至mqtt
			sendRealData : function(deviceCodes, monitorItemIds, dataTypes) {
				return BaseService.post('/api/sys/mqtt/sendRealData',{
						'deviceCodes':deviceCodes, 'monitorItemIds':monitorItemIds, 'dataTypes':dataTypes
					});
			},
			
			//发送数据中心单条历史数据至mqtt
			sendSJZXData : function(deviceCodes, dt, interval) {
				return BaseService.post('/api/sys/mqtt/sendSJZXData',{
						'deviceCodes':deviceCodes, 'dt':dt, 'interval':interval
					});
			},
			
			//发送多条历史数据至mqtt
			sendSJZXDataList : function(deviceCodes, begin, end, interval) {
				return BaseService.post('/api/sys/mqtt/sendSJZXDataList',{
						'deviceCodes':deviceCodes, 'begin':begin, 'end':end, 'interval':interval
					});
			},
			
			//发送数据中心实时数据至mqtt
			sendSJZXRealData : function(deviceCodes) {
				return BaseService.post('/api/sys/mqtt/sendSJZXRealData',{
						'deviceCodes':deviceCodes
					});
			},
		},
	    //行政区划	    
        AdminAreaService :{
			
			//增加行政区划对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/adminArea/add',mapParams);
			},
			
			//删除行政区划对象
			del : function(id) {
				return BaseService.post('/api/sys/adminArea/del',{
						'id':id
					});
			},
			
			//修改行政区划对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/adminArea/edit',mapParams);
			},
			
			//修改行政区划对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/adminArea/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出行政区划对象到文件
			exportAll : function(pAreaCode, areaName, areaFullName, orderBy, fieldName) {
				return BaseService.download('/api/sys/adminArea/exportall',{
						'pAreaCode':pAreaCode, 'areaName':areaName, 'areaFullName':areaFullName, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出行政区划对象到文件
			exportDataTable : function(dataTable, pAreaCode, areaName, areaFullName, fieldName) {
				return BaseService.download('/api/sys/adminArea/exportdatatable',{
						'dataTable':dataTable, 'pAreaCode':pAreaCode, 'areaName':areaName, 'areaFullName':areaFullName, 'fieldName':fieldName
					});
			},
			
			//根据ID获取行政区划对象
			get : function(id) {
				return BaseService.get('/api/sys/adminArea/get',{
						'id':id
					});
			},
			
			//查询所有行政区划对象
			getAll : function() {
				return BaseService.get('/api/sys/adminArea/getall');
			},
			
			//查询所有行政区划对象
			getAllData : function() {
				return BaseService.get('/api/sys/adminArea/getAllDatas');
			},
			
			//查询所有行政区划对象集合信息
			getAllDataLists : function() {
				return BaseService.get('/api/sys/adminArea/getAllDataLists');
			},
			
			//根据省份Id获取省和下面所有地市数据
			getAreaByProvince : function(areaCode) {
				return BaseService.get('/api/sys/adminArea/getAreaByProvince',{
						'areaCode':areaCode
					});
			},
			
			//根据ID获取售电公司天气code
			getComWeatherCodeByAreaCode : function(areaCode) {
				return BaseService.get('/api/sys/adminArea/getComWeatherCodeByAreaCode',{
						'areaCode':areaCode
					});
			},
			
			//DataTable查询行政区划对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/adminArea/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示行政区划对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/adminArea/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据ID数组获取行政区划对象
			gets : function(idList) {
				return BaseService.get('/api/sys/adminArea/gets',{
						'idList':idList
					});
			},
			
			//条件查询行政区划对象
			searchAll : function(pAreaCode, areaName, areaFullName, state, orderBy) {
				return BaseService.get('/api/sys/adminArea/searchall',{
						'pAreaCode':pAreaCode, 'areaName':areaName, 'areaFullName':areaFullName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询行政区划对象
			searchDataTable : function(dataTable, pAreaCode, areaName, areaFullName) {
				return BaseService.get('/api/sys/adminArea/searchdatatable',{
						'dataTable':dataTable, 'pAreaCode':pAreaCode, 'areaName':areaName, 'areaFullName':areaFullName
					});
			},
			
			//条件查询行政区划对象
			searchPage : function(pAreaCode, areaName, areaFullName, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/adminArea/searchpage',{
						'pAreaCode':pAreaCode, 'areaName':areaName, 'areaFullName':areaFullName, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //系统登录	    
        LoginService :{
			
			//添加菜单功能
			addMenuFun : function(funCode, funName, url, operType) {
				return BaseService.post('/api/sys/addMenuFun',{
						'funCode':funCode, 'funName':funName, 'url':url, 'operType':operType
					});
			},
			
			//验证短信验证码
			checkSms : function(orgId, phone, code) {
				return BaseService.post('/api/sys/checkSms',{
						'orgId':orgId, 'phone':phone, 'code':code
					});
			},
			
			//获取所有菜单及功能
			getAllMenus : function() {
				return BaseService.get('/api/sys/getAllMenus');
			},
			
			//查询shiro权限列表
			getShiroMenus : function() {
				return BaseService.get('/api/sys/getShiroMenus');
			},
			
			//获取版本号
			getSystemVersion : function() {
				return BaseService.get('/api/sys/getSystemVersion');
			},
			
			//根据账号和密码登录
			login : function(userName, password, appCode) {
				return BaseService.post('/api/sys/login',{
						'userName':userName, 'password':password, 'appCode':appCode
					});
			},
			
			//根据账号和加密密码登录
			loginAppEncode : function(userName, password, appCode) {
				return BaseService.post('/api/sys/loginAppEncode',{
						'userName':userName, 'password':password, 'appCode':appCode
					});
			},
			
			//发送短信验证码
			sendSMS : function(orgId, phone, type) {
				return BaseService.post('/api/sys/sendSMS',{
						'orgId':orgId, 'phone':phone, 'type':type
					});
			},
			
			//获取流水号编码
			serialNumber : function(serialNumberCode, threadNum) {
				return BaseService.post('/api/sys/serialNumber',{
						'serialNumberCode':serialNumberCode, 'threadNum':threadNum
					});
			},
			
			//设置是否扫描
			setScan : function(isScan) {
				return BaseService.get('/api/sys/setScan',{
						'isScan':isScan
					});
			},
		},
	    //移动设备授权	    
        DeviceAuthzService :{
			
			//增加移动设备授权对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/deviceAuthz/add',mapParams);
			},
			
			//删除移动设备授权对象
			del : function(id) {
				return BaseService.post('/api/sys/deviceAuthz/del',{
						'id':id
					});
			},
			
			//修改移动设备授权对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/deviceAuthz/edit',mapParams);
			},
			
			//修改移动设备授权对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/deviceAuthz/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出移动设备授权对象到文件
			exportAll : function(state, orderBy, fieldName) {
				return BaseService.download('/api/sys/deviceAuthz/exportall',{
						'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出移动设备授权对象到文件
			exportDataTable : function(dataTable, state, fieldName) {
				return BaseService.download('/api/sys/deviceAuthz/exportdatatable',{
						'dataTable':dataTable, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取移动设备授权对象
			get : function(id) {
				return BaseService.get('/api/sys/deviceAuthz/get',{
						'id':id
					});
			},
			
			//查询所有移动设备授权对象
			getAll : function() {
				return BaseService.get('/api/sys/deviceAuthz/getall');
			},
			
			//DataTable查询移动设备授权对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/deviceAuthz/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示移动设备授权对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/deviceAuthz/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询移动设备授权对象
			searchAll : function(state, orderBy) {
				return BaseService.get('/api/sys/deviceAuthz/searchall',{
						'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询移动设备授权对象
			searchDataTable : function(dataTable, state) {
				return BaseService.get('/api/sys/deviceAuthz/searchdatatable',{
						'dataTable':dataTable, 'state':state
					});
			},
			
			//条件查询移动设备授权对象
			searchPage : function(state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/deviceAuthz/searchpage',{
						'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //工作流处理信息	    
        WorkflowProcInfoService :{
			
			//增加工作流处理信息对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/workflowProcInfo/add',mapParams);
			},
			
			//删除工作流处理信息对象
			del : function(id) {
				return BaseService.post('/api/sys/workflowProcInfo/del',{
						'id':id
					});
			},
			
			//修改工作流处理信息对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/workflowProcInfo/edit',mapParams);
			},
			
			//修改工作流处理信息对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/workflowProcInfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出工作流处理信息对象到文件
			exportAll : function(workflowRequest, workflowNode, beginTime, endTime, orgUnit, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/workflowProcInfo/exportall',{
						'workflowRequest':workflowRequest, 'workflowNode':workflowNode, 'beginTime':beginTime, 'endTime':endTime, 'orgUnit':orgUnit, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出工作流处理信息对象到文件
			exportDataTable : function(dataTable, workflowRequest, workflowNode, beginTime, endTime, orgUnit, state, fieldName) {
				return BaseService.download('/api/sys/workflowProcInfo/exportdatatable',{
						'dataTable':dataTable, 'workflowRequest':workflowRequest, 'workflowNode':workflowNode, 'beginTime':beginTime, 'endTime':endTime, 'orgUnit':orgUnit, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取工作流处理信息对象
			get : function(id) {
				return BaseService.get('/api/sys/workflowProcInfo/get',{
						'id':id
					});
			},
			
			//查询所有工作流处理信息对象
			getAll : function() {
				return BaseService.get('/api/sys/workflowProcInfo/getall');
			},
			
			//查询所有工作流处理信息对象
			getAllByObjId : function(workflowObjId, operLevels) {
				return BaseService.get('/api/sys/workflowProcInfo/getAllByObjId',{
						'workflowObjId':workflowObjId, 'operLevels':operLevels
					});
			},
			
			//DataTable查询工作流处理信息对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/workflowProcInfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示工作流处理信息对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowProcInfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询工作流处理信息对象
			searchAll : function(workflowRequest, workflowNode, beginTime, endTime, orgUnit, state, orderBy) {
				return BaseService.get('/api/sys/workflowProcInfo/searchall',{
						'workflowRequest':workflowRequest, 'workflowNode':workflowNode, 'beginTime':beginTime, 'endTime':endTime, 'orgUnit':orgUnit, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询工作流处理信息对象
			searchDataTable : function(dataTable, workflowRequest, workflowNode, beginTime, endTime, orgUnit, state) {
				return BaseService.get('/api/sys/workflowProcInfo/searchdatatable',{
						'dataTable':dataTable, 'workflowRequest':workflowRequest, 'workflowNode':workflowNode, 'beginTime':beginTime, 'endTime':endTime, 'orgUnit':orgUnit, 'state':state
					});
			},
			
			//条件查询工作流处理信息对象
			searchPage : function(workflowRequest, workflowNode, beginTime, endTime, orgUnit, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/workflowProcInfo/searchpage',{
						'workflowRequest':workflowRequest, 'workflowNode':workflowNode, 'beginTime':beginTime, 'endTime':endTime, 'orgUnit':orgUnit, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //文件记录表	    
        FileRecordService :{
			
			//增加文件记录表对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/fileRecord/add',mapParams);
			},
			
			//删除文件记录表对象
			del : function(id) {
				return BaseService.post('/api/sys/fileRecord/del',{
						'id':id
					});
			},
			
			//导出文件记录表对象到文件
			downloadFile : function(id, fileName, filePath, type) {
				return BaseService.download('/api/sys/fileRecord/downloadFile',{
						'id':id, 'fileName':fileName, 'filePath':filePath, 'type':type
					});
			},
			
			//下载网络文件
			downloadUrlFile : function(fileName, url) {
				return BaseService.download('/api/sys/fileRecord/downloadUrlFile',{
						'fileName':fileName, 'url':url
					});
			},
			
			//修改文件记录表对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/fileRecord/edit',mapParams);
			},
			
			//修改文件记录表对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/fileRecord/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出文件记录表对象到文件
			exportAll : function(orgUnit, filePropId, fileClassId, fileNo, fileName, uploader, uploadTime, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/fileRecord/exportall',{
						'orgUnit':orgUnit, 'filePropId':filePropId, 'fileClassId':fileClassId, 'fileNo':fileNo, 'fileName':fileName, 'uploader':uploader, 'uploadTime':uploadTime, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出文件记录表对象到文件
			exportDataTable : function(dataTable, orgUnit, filePropId, fileClassId, fileNo, fileName, uploader, uploadTime, state, fieldName) {
				return BaseService.download('/api/sys/fileRecord/exportdatatable',{
						'dataTable':dataTable, 'orgUnit':orgUnit, 'filePropId':filePropId, 'fileClassId':fileClassId, 'fileNo':fileNo, 'fileName':fileName, 'uploader':uploader, 'uploadTime':uploadTime, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取文件记录表对象
			get : function(id) {
				return BaseService.get('/api/sys/fileRecord/get',{
						'id':id
					});
			},
			
			//查询所有文件记录表对象
			getAll : function() {
				return BaseService.get('/api/sys/fileRecord/getall');
			},
			
			//DataTable查询文件记录表对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/fileRecord/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示文件记录表对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/fileRecord/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			// 文本编辑器图片上传
			importEditorImage : function(rootUrl, upload) {
				return BaseService.download('/api/sys/fileRecord/importEditorImage',{
						'rootUrl':rootUrl, 'upload':upload
					});
			},
			
			//上传缩略图
			importThumbnail : function(upFile, fileName) {
				return BaseService.upload('/api/sys/fileRecord/importThumbnail',{
						'upFile':upFile, 'fileName':fileName
					});
			},
			
			//条件查询文件记录表对象
			searchAll : function(orgUnit, filePropId, fileClassId, fileNo, fileName, uploader, uploadTime, state, orderBy) {
				return BaseService.get('/api/sys/fileRecord/searchall',{
						'orgUnit':orgUnit, 'filePropId':filePropId, 'fileClassId':fileClassId, 'fileNo':fileNo, 'fileName':fileName, 'uploader':uploader, 'uploadTime':uploadTime, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询文件记录表对象
			searchDataTable : function(dataTable, orgUnit, filePropId, fileClassId, fileNo, fileName, uploader, uploadTime, state) {
				return BaseService.get('/api/sys/fileRecord/searchdatatable',{
						'dataTable':dataTable, 'orgUnit':orgUnit, 'filePropId':filePropId, 'fileClassId':fileClassId, 'fileNo':fileNo, 'fileName':fileName, 'uploader':uploader, 'uploadTime':uploadTime, 'state':state
					});
			},
			
			//条件查询文件记录表对象
			searchPage : function(orgUnit, filePropId, fileClassId, fileNo, fileName, uploader, uploadTime, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/fileRecord/searchpage',{
						'orgUnit':orgUnit, 'filePropId':filePropId, 'fileClassId':fileClassId, 'fileNo':fileNo, 'fileName':fileName, 'uploader':uploader, 'uploadTime':uploadTime, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //行业定义	    
        IndustryService :{
			
			//增加行业定义对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/industry/add',mapParams);
			},
			
			//删除行业定义对象
			del : function(id) {
				return BaseService.post('/api/sys/industry/del',{
						'id':id
					});
			},
			
			//修改行业定义对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/industry/edit',mapParams);
			},
			
			//修改行业定义对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/industry/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出行业定义对象到文件
			exportAll : function(industryName, orderBy, fieldName) {
				return BaseService.download('/api/sys/industry/exportall',{
						'industryName':industryName, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出行业定义对象到文件
			exportDataTable : function(dataTable, industryName, fieldName) {
				return BaseService.download('/api/sys/industry/exportdatatable',{
						'dataTable':dataTable, 'industryName':industryName, 'fieldName':fieldName
					});
			},
			
			//根据ID获取行业定义对象
			get : function(id) {
				return BaseService.get('/api/sys/industry/get',{
						'id':id
					});
			},
			
			//查询所有行业定义对象
			getAll : function() {
				return BaseService.get('/api/sys/industry/getall');
			},
			
			//查询所有行业定义对象
			getAllData : function() {
				return BaseService.get('/api/sys/industry/getAllData');
			},
			
			//查询所有行业，返回一个list集合
			getAllDataList : function() {
				return BaseService.get('/api/sys/industry/getAllDataList');
			},
			
			//DataTable查询行业定义对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/industry/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询有用户的行业
			getIndustryListByConsumer : function() {
				return BaseService.get('/api/sys/industry/getIndustryListByConsumer');
			},
			
			//分页显示行业定义对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/industry/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询行业定义对象
			searchAll : function(industryName, orderBy) {
				return BaseService.get('/api/sys/industry/searchall',{
						'industryName':industryName, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询行业定义对象
			searchDataTable : function(dataTable, industryName) {
				return BaseService.get('/api/sys/industry/searchdatatable',{
						'dataTable':dataTable, 'industryName':industryName
					});
			},
			
			//条件查询行业定义对象
			searchPage : function(industryName, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/industry/searchpage',{
						'industryName':industryName, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //员工	    
        PersonInfoService :{
			
			//增加员工对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/personInfo/add',mapParams);
			},
			
			//增加员工对象和角色信息
			addEmpWithRole : function(orgEmployeeStr, roleIdList) {
				return BaseService.post('/api/sys/personInfo/addEmpWithRole',{
						'orgEmployeeStr':orgEmployeeStr, 'roleIdList':roleIdList
					});
			},
			
			//删除员工对象
			del : function(id) {
				return BaseService.post('/api/sys/personInfo/del',{
						'id':id
					});
			},
			
			//修改员工对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/personInfo/edit',mapParams);
			},
			
			//修改员工对象和角色信息
			editEmpWithRole : function(orgEmployeeStr, roleIdList) {
				return BaseService.post('/api/sys/personInfo/editEmpWithRole',{
						'orgEmployeeStr':orgEmployeeStr, 'roleIdList':roleIdList
					});
			},
			
			//修改员工对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/personInfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出员工对象到文件
			exportAll : function(orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, orderBy, fieldName) {
				return BaseService.download('/api/sys/personInfo/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出员工对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, fieldName) {
				return BaseService.download('/api/sys/personInfo/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'fieldName':fieldName
					});
			},
			
			//根据ID获取员工对象
			get : function(id) {
				return BaseService.get('/api/sys/personInfo/get',{
						'id':id
					});
			},
			
			//查询所有员工对象
			getAll : function() {
				return BaseService.get('/api/sys/personInfo/getall');
			},
			
			//根据ID获取员工个人信息
			getEmp : function(id) {
				return BaseService.get('/api/sys/personInfo/getEmp',{
						'id':id
					});
			},
			
			//分页显示员工对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/personInfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询员工对象
			searchAll : function(orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, orderBy) {
				return BaseService.get('/api/sys/personInfo/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询员工对象
			searchDataTable : function(dataTable, orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime) {
				return BaseService.get('/api/sys/personInfo/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime
					});
			},
			
			//条件查询员工对象
			searchPage : function(orgCode, orgName, state, idNumber, telephone, empClassifyId, expiredTime, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/personInfo/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'idNumber':idNumber, 'telephone':telephone, 'empClassifyId':empClassifyId, 'expiredTime':expiredTime, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//修改密码
			updateEmpPassword : function(aId, eId, adminPsw, newPsw) {
				return BaseService.get('/api/sys/personInfo/updateEmpPassword',{
						'aId':aId, 'eId':eId, 'adminPsw':adminPsw, 'newPsw':newPsw
					});
			},
			
			//修改密码
			updatePassword : function(id, oldPsw, newPsw) {
				return BaseService.get('/api/sys/personInfo/updatePassword',{
						'id':id, 'oldPsw':oldPsw, 'newPsw':newPsw
					});
			},
		},
	    //角色	    
        OrgRoleService :{
			
			//增加角色对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgRole/add',mapParams);
			},
			
			//增加角色对象和权限
			addWithFun : function(roleStr, orgFunList, orgLimitList) {
				return BaseService.post('/api/sys/orgRole/addWithFun',{
						'roleStr':roleStr, 'orgFunList':orgFunList, 'orgLimitList':orgLimitList
					});
			},
			
			//删除角色对象
			del : function(id) {
				return BaseService.post('/api/sys/orgRole/del',{
						'id':id
					});
			},
			
			//修改角色对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgRole/edit',mapParams);
			},
			
			//修改角色对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgRole/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改角色对象和权限
			editWithFun : function(roleStr, orgFunList, orgLimitList) {
				return BaseService.post('/api/sys/orgRole/editWithFun',{
						'roleStr':roleStr, 'orgFunList':orgFunList, 'orgLimitList':orgLimitList
					});
			},
			
			//导出角色对象到文件
			exportAll : function(orgCode, orgName, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/orgRole/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出角色对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, state, fieldName) {
				return BaseService.download('/api/sys/orgRole/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取角色对象
			get : function(id) {
				return BaseService.get('/api/sys/orgRole/get',{
						'id':id
					});
			},
			
			//查询所有角色对象
			getAll : function() {
				return BaseService.get('/api/sys/orgRole/getall');
			},
			
			//DataTable查询角色对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgRole/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示角色对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgRole/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据ID获取角色对象
			getRoleWithFun : function(orgId) {
				return BaseService.get('/api/sys/orgRole/getRoleWithFun',{
						'orgId':orgId
					});
			},
			
			//条件查询角色对象
			searchAll : function(orgCode, orgName, state, orderBy) {
				return BaseService.get('/api/sys/orgRole/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询角色对象
			searchDataTable : function(dataTable, orgCode, orgName, state) {
				return BaseService.get('/api/sys/orgRole/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state
					});
			},
			
			//条件查询角色对象
			searchPage : function(orgCode, orgName, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/orgRole/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //第三方账户	    
        ThirdAccountService :{
			
			//增加第三方账户对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/thirdAccount/add',mapParams);
			},
			
			//删除第三方账户对象
			del : function(id) {
				return BaseService.post('/api/sys/thirdAccount/del',{
						'id':id
					});
			},
			
			//修改第三方账户对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/thirdAccount/edit',mapParams);
			},
			
			//修改第三方账户对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/thirdAccount/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出第三方账户对象到文件
			exportAll : function(thirdAccountType, orgEmployee, nickname, loginCode, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/thirdAccount/exportall',{
						'thirdAccountType':thirdAccountType, 'orgEmployee':orgEmployee, 'nickname':nickname, 'loginCode':loginCode, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出第三方账户对象到文件
			exportDataTable : function(dataTable, thirdAccountType, orgEmployee, nickname, loginCode, state, fieldName) {
				return BaseService.download('/api/sys/thirdAccount/exportdatatable',{
						'dataTable':dataTable, 'thirdAccountType':thirdAccountType, 'orgEmployee':orgEmployee, 'nickname':nickname, 'loginCode':loginCode, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取第三方账户对象
			get : function(id) {
				return BaseService.get('/api/sys/thirdAccount/get',{
						'id':id
					});
			},
			
			//查询所有第三方账户对象
			getAll : function() {
				return BaseService.get('/api/sys/thirdAccount/getall');
			},
			
			//DataTable查询第三方账户对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/thirdAccount/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示第三方账户对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/thirdAccount/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询第三方账户对象
			searchAll : function(thirdAccountType, orgEmployee, nickname, loginCode, state, orderBy) {
				return BaseService.get('/api/sys/thirdAccount/searchall',{
						'thirdAccountType':thirdAccountType, 'orgEmployee':orgEmployee, 'nickname':nickname, 'loginCode':loginCode, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询第三方账户对象
			searchDataTable : function(dataTable, thirdAccountType, orgEmployee, nickname, loginCode, state) {
				return BaseService.get('/api/sys/thirdAccount/searchdatatable',{
						'dataTable':dataTable, 'thirdAccountType':thirdAccountType, 'orgEmployee':orgEmployee, 'nickname':nickname, 'loginCode':loginCode, 'state':state
					});
			},
			
			//条件查询第三方账户对象
			searchPage : function(thirdAccountType, orgEmployee, nickname, loginCode, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/thirdAccount/searchpage',{
						'thirdAccountType':thirdAccountType, 'orgEmployee':orgEmployee, 'nickname':nickname, 'loginCode':loginCode, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //组织单元	    
        OrgUnitService :{
			
			//增加组织单元对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgUnit/add',mapParams);
			},
			
			//获取所有的客户
			allCustomer : function() {
				return BaseService.get('/api/sys/orgUnit/allCustomer');
			},
			
			//获取所有的报备人
			allReporters : function(orgType) {
				return BaseService.get('/api/sys/orgUnit/allReporters',{
						'orgType':orgType
					});
			},
			
			//删除组织单元对象
			del : function(id) {
				return BaseService.post('/api/sys/orgUnit/del',{
						'id':id
					});
			},
			
			//修改组织单元对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgUnit/edit',mapParams);
			},
			
			//修改组织单元对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgUnit/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出组织单元对象到文件
			exportAll : function(orgCode, orgName, orgType, orderBy, fieldName) {
				return BaseService.download('/api/sys/orgUnit/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'orgType':orgType, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出组织单元对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, orgType, fieldName) {
				return BaseService.download('/api/sys/orgUnit/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'orgType':orgType, 'fieldName':fieldName
					});
			},
			
			//用户模糊匹配
			fuzzyMatchConsumer : function(inputName) {
				return BaseService.get('/api/sys/orgUnit/fuzzyMatchConsumer',{
						'inputName':inputName
					});
			},
			
			//客户模糊匹配
			fuzzyMatchCustomer : function(inputName) {
				return BaseService.get('/api/sys/orgUnit/fuzzyMatchCustomer',{
						'inputName':inputName
					});
			},
			
			//报备人名称模糊匹配
			fuzzyMatchReporter : function(inputName, orgType) {
				return BaseService.get('/api/sys/orgUnit/fuzzyMatchReporter',{
						'inputName':inputName, 'orgType':orgType
					});
			},
			
			//根据ID获取组织单元对象
			get : function(id) {
				return BaseService.get('/api/sys/orgUnit/get',{
						'id':id
					});
			},
			
			//查询所有组织单元对象
			getAll : function() {
				return BaseService.get('/api/sys/orgUnit/getall');
			},
			
			//DataTable查询组织单元对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgUnit/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示组织单元对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgUnit/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询组织单元对象
			searchAll : function(orgCode, orgName, orgType, orderBy) {
				return BaseService.get('/api/sys/orgUnit/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'orgType':orgType, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询组织单元对象
			searchDataTable : function(dataTable, orgCode, orgName, orgType) {
				return BaseService.get('/api/sys/orgUnit/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'orgType':orgType
					});
			},
			
			//条件查询组织单元对象
			searchPage : function(orgCode, orgName, orgType, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/orgUnit/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'orgType':orgType, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //法定节假日安排	    
        PublicHolidayService :{
			
			//增加法定节假日安排对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/publicHoliday/add',mapParams);
			},
			
			//删除法定节假日安排对象
			del : function(id) {
				return BaseService.post('/api/sys/publicHoliday/del',{
						'id':id
					});
			},
			
			//修改法定节假日安排对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/publicHoliday/edit',mapParams);
			},
			
			//修改法定节假日安排对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/publicHoliday/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出法定节假日安排对象到文件
			exportAll : function(dt, holidayTypeId, orderBy, fieldName) {
				return BaseService.download('/api/sys/publicHoliday/exportall',{
						'dt':dt, 'holidayTypeId':holidayTypeId, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出法定节假日安排对象到文件
			exportDataTable : function(dataTable, dt, holidayTypeId, fieldName) {
				return BaseService.download('/api/sys/publicHoliday/exportdatatable',{
						'dataTable':dataTable, 'dt':dt, 'holidayTypeId':holidayTypeId, 'fieldName':fieldName
					});
			},
			
			//根据ID获取法定节假日安排对象
			get : function(id) {
				return BaseService.get('/api/sys/publicHoliday/get',{
						'id':id
					});
			},
			
			//查询所有法定节假日安排对象
			getAll : function() {
				return BaseService.get('/api/sys/publicHoliday/getall');
			},
			
			//DataTable查询法定节假日安排对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/publicHoliday/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示法定节假日安排对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/publicHoliday/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询法定节假日安排对象
			searchAll : function(dt, holidayTypeId, orderBy) {
				return BaseService.get('/api/sys/publicHoliday/searchall',{
						'dt':dt, 'holidayTypeId':holidayTypeId, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询法定节假日安排对象
			searchDataTable : function(dataTable, dt, holidayTypeId) {
				return BaseService.get('/api/sys/publicHoliday/searchdatatable',{
						'dataTable':dataTable, 'dt':dt, 'holidayTypeId':holidayTypeId
					});
			},
			
			//条件查询法定节假日安排对象
			searchPage : function(dt, holidayTypeId, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/publicHoliday/searchpage',{
						'dt':dt, 'holidayTypeId':holidayTypeId, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //组织功能权限	    
        OrgFunService :{
			
			//增加组织功能权限对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgFun/add',mapParams);
			},
			
			//增加所有选择的组织功能权限对象
			addAll : function(orgFunArr, orgId) {
				return BaseService.post('/api/sys/orgFun/addAll',{
						'orgFunArr':orgFunArr, 'orgId':orgId
					});
			},
			
			//删除组织功能权限对象
			del : function(id) {
				return BaseService.post('/api/sys/orgFun/del',{
						'id':id
					});
			},
			
			//修改组织功能权限对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgFun/edit',mapParams);
			},
			
			//修改组织功能权限对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgFun/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出组织功能权限对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/sys/orgFun/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取组织功能权限对象
			get : function(id) {
				return BaseService.get('/api/sys/orgFun/get',{
						'id':id
					});
			},
			
			//查询所有组织功能权限对象
			getAll : function() {
				return BaseService.get('/api/sys/orgFun/getall');
			},
			
			//DataTable查询组织功能权限对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgFun/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示组织功能权限对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgFun/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //系统日志记录	    
        LogService :{
			
			//增加系统日志记录对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/log/add',mapParams);
			},
			
			//删除系统日志记录对象
			del : function(id) {
				return BaseService.post('/api/sys/log/del',{
						'id':id
					});
			},
			
			//修改系统日志记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/log/edit',mapParams);
			},
			
			//修改系统日志记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/log/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出系统日志记录对象到文件
			exportAll : function(appCode, orgId, logType, ip, className, method, isVerifyRight, state, openDate, orderBy, fieldName) {
				return BaseService.download('/api/sys/log/exportall',{
						'appCode':appCode, 'orgId':orgId, 'logType':logType, 'ip':ip, 'className':className, 'method':method, 'isVerifyRight':isVerifyRight, 'state':state, 'openDate':openDate, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出系统日志记录对象到文件
			exportDataTable : function(dataTable, appCode, orgId, logType, ip, className, method, isVerifyRight, state, openDate, fieldName) {
				return BaseService.download('/api/sys/log/exportdatatable',{
						'dataTable':dataTable, 'appCode':appCode, 'orgId':orgId, 'logType':logType, 'ip':ip, 'className':className, 'method':method, 'isVerifyRight':isVerifyRight, 'state':state, 'openDate':openDate, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出系统日志记录对象到文件
			exportDataTableNamye : function(dataTable, appCode, orgId, logType, ip, className, method, isVerifyRight, state, openDate, fieldName) {
				return BaseService.download('/api/sys/log/exportdatatableName',{
						'dataTable':dataTable, 'appCode':appCode, 'orgId':orgId, 'logType':logType, 'ip':ip, 'className':className, 'method':method, 'isVerifyRight':isVerifyRight, 'state':state, 'openDate':openDate, 'fieldName':fieldName
					});
			},
			
			//导出
			exportTable : function(dataTable, beginTime, endTime) {
				return BaseService.download('/api/sys/log/exportTable',{
						'dataTable':dataTable, 'beginTime':beginTime, 'endTime':endTime
					});
			},
			
			//根据ID获取系统日志记录对象
			get : function(id) {
				return BaseService.get('/api/sys/log/get',{
						'id':id
					});
			},
			
			//查询所有系统日志记录对象
			getAll : function() {
				return BaseService.get('/api/sys/log/getall');
			},
			
			//DataTable查询系统日志记录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/log/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查询系统日志记录对象
			getDataTableByDate : function(dataTable, beginTime, endTime) {
				return BaseService.get('/api/sys/log/getDataTableByDate',{
						'dataTable':dataTable, 'beginTime':beginTime, 'endTime':endTime
					});
			},
			
			//分页显示系统日志记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/log/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询系统日志记录对象
			searchAll : function(appCode, orgId, logType, ip, className, method, isVerifyRight, state, openDate, orderBy) {
				return BaseService.get('/api/sys/log/searchall',{
						'appCode':appCode, 'orgId':orgId, 'logType':logType, 'ip':ip, 'className':className, 'method':method, 'isVerifyRight':isVerifyRight, 'state':state, 'openDate':openDate, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询系统日志记录对象
			searchDataTable : function(dataTable, appCode, orgId, logType, ip, className, method, isVerifyRight, state, openDate) {
				return BaseService.get('/api/sys/log/searchdatatable',{
						'dataTable':dataTable, 'appCode':appCode, 'orgId':orgId, 'logType':logType, 'ip':ip, 'className':className, 'method':method, 'isVerifyRight':isVerifyRight, 'state':state, 'openDate':openDate
					});
			},
			
			//条件查询系统日志记录对象
			searchPage : function(appCode, orgId, logType, ip, className, method, isVerifyRight, state, openDate, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/log/searchpage',{
						'appCode':appCode, 'orgId':orgId, 'logType':logType, 'ip':ip, 'className':className, 'method':method, 'isVerifyRight':isVerifyRight, 'state':state, 'openDate':openDate, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //部门(岗位)	    
        OrgDeptService :{
			
			//增加部门(岗位)对象
			add : function(mapParams) {
						return BaseService.post('/api/sys/orgDept/add',mapParams);
			},
			
			//删除部门(岗位)对象
			del : function(id) {
				return BaseService.post('/api/sys/orgDept/del',{
						'id':id
					});
			},
			
			//修改部门(岗位)对象
			edit : function(mapParams) {
						return BaseService.post('/api/sys/orgDept/edit',mapParams);
			},
			
			//修改部门(岗位)对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/sys/orgDept/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出部门(岗位)对象到文件
			exportAll : function(orgCode, orgName, state, orderBy, fieldName) {
				return BaseService.download('/api/sys/orgDept/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出部门(岗位)对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, state, fieldName) {
				return BaseService.download('/api/sys/orgDept/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取部门(岗位)对象
			get : function(id) {
				return BaseService.get('/api/sys/orgDept/get',{
						'id':id
					});
			},
			
			//查询所有部门(岗位)对象
			getAll : function() {
				return BaseService.get('/api/sys/orgDept/getall');
			},
			
			//DataTable查询部门(岗位)对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/sys/orgDept/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示部门(岗位)对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/sys/orgDept/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询部门(岗位)对象
			searchAll : function(orgCode, orgName, state, orderBy) {
				return BaseService.get('/api/sys/orgDept/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询部门(岗位)对象
			searchDataTable : function(dataTable, orgCode, orgName, state) {
				return BaseService.get('/api/sys/orgDept/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'state':state
					});
			},
			
			//条件查询部门(岗位)对象
			searchPage : function(orgCode, orgName, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/sys/orgDept/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
 
}

export default sys;
import { efficiency } from "../../../service";
import {put, takeLatest, call, all} from 'redux-saga/effects';
import moment from 'moment';
import { Utils } from "../../../common/utils";

export const LOAD_ANALYSIS_SET_DATAAMMETERID = 'LOAD_ANALYSIS_SET_DATAAMMETERID';
export const GET_DAY_LOAD_DATA = 'GET_DAY_LOAD_DATA';
export const GET_DAY_LOAD_DATA_SUCCESS = 'GET_DAY_LOAD_DATA_SUCCESS';
export const GET_MONTH_LOAD_DATA = 'GET_MONTH_LOAD_DATA';
export const GET_MONTH_LOAD_DATA_SUCCESS = 'GET_MONTH_LOAD_DATA_SUCCESS';
export const GET_DAY_LOAD_RATE_DATA = 'GET_DAY_LOAD_RATE_DATA';
export const GET_DAY_LOAD_RATE_DATA_SUCCESS = 'GET_DAY_LOAD_RATE_DATA_SUCCESS';
export const GET_MONTH_LOAD_RATE_DATA = 'GET_MONTH_LOAD_RATE_DATA';
export const GET_MONTH_LOAD_RATE_DATA_SUCCESS = 'GET_MONTH_LOAD_RATE_DATA_SUCCESS';
export const GET_DAY_LOAD_PIE_RATE_DATA = 'GET_DAY_LOAD_PIE_RATE_DATA';
export const GET_DAY_LOAD_PIE_RATE_DATA_SUCCESS = 'GET_DAY_LOAD_PIE_RATE_DATA_SUCCESS';

export const SET_LOAD_DAY_RATE_SELECTED_DATE = 'SET_LOAD_DAY_RATE_SELECTED_DATE';

const LOAD_DAY_DATA_BEGIN = 'LOAD_DAY_DATA_BEGIN';
const LOAD_DAY_DATA_END= 'LOAD_DAY_DATA_END';
const LOAD_MONTH_DATA_BEGIN = 'LOAD_MONTH_DATA_BEGIN';
const LOAD_MONTH_DATA_END= 'LOAD_MONTH_DATA_END';

const LOAD_DAY_RATE_DATA_BEGIN = 'LOAD_DAY_RATE_DATA_BEGIN';
const LOAD_DAY_RATE_DATA_END= 'LOAD_DAY_RATE_DATA_END';
const LOAD_MONTH_RATE_DATA_BEGIN = 'LOAD_MONTH_RATE_DATA_BEGIN';
const LOAD_MONTH_RATE_DATA_END= 'LOAD_MONTH_RATE_DATA_END';

//日用电负荷趋势分析
export function* getDayLoadData (action) {
    const {
        dataAmmeterId,
        date
    } = action.payload;
    yield put({ type: LOAD_DAY_DATA_BEGIN });
    const [dayLoadData, dayLoadTableData] = yield all([
        call(
            efficiency.ElectricityLoadAnalysisService.getDailyElecTrendDate,
            dataAmmeterId,
            date,
            1
        ),
        call(
            efficiency.ElectricityLoadAnalysisService.getElectricityLoadStatistics,
            dataAmmeterId,
            date,
            1
        )
    ]);
    yield put({ type: LOAD_DAY_DATA_END });

    if (!dayLoadData.code) {
        for (const key in dayLoadData.PZ) {
            const element = dayLoadData.PZ[key];
            for (const item of element) {
                const dt = moment(item[0]);
                const dtObject = { hour: dt.hour(), minute: dt.minute() };
                item[0] = moment(dtObject).valueOf();
            }
        }
    }
    if (!dayLoadTableData.code) {
        for (const item of dayLoadTableData) {
            if (item.dtType === 1) {
                item.dtType = '当日'
            }
            else if (item.dtType === 2) {
                item.dtType = '前一日'
            }
            else if (item.dtType === 3) {
                item.dtType = '上周同日'
            }
            else if (item.dtType === 4) {
                item.dtType = '上月同日'
            }
        }
    }

    yield put({
        type: GET_DAY_LOAD_DATA_SUCCESS,
        payload: {
            dayLoadData: dayLoadData.code ? null: dayLoadData.PZ,
            dayLoadTableData: dayLoadTableData.code ? null : dayLoadTableData
        }
    });
}

//月用电负荷趋势分析
export function* getMonthLoadData (action) {
    const {
        dataAmmeterId,
        date
    } = action.payload;
    yield put({ type: LOAD_MONTH_DATA_BEGIN });
    const [ maxDataRet, minDataRet, loadRateDataRet, PVDiffDataRet ] = yield all([
        call(
            efficiency.ElectricityLoadAnalysisService.getMonthElecTrendDate,
            dataAmmeterId,
            date,
            1
        ),
        call(
            efficiency.ElectricityLoadAnalysisService.getMonthElecTrendDate,
            dataAmmeterId,
            date,
            2
        ),
        call(
            efficiency.ElectricityLoadAnalysisService.getMonthElecTrendDate,
            dataAmmeterId,
            date,
            3
        ),
        call(
            efficiency.ElectricityLoadAnalysisService.getMonthElecTrendDate,
            dataAmmeterId,
            date,
            4
        )
    ]);
    yield put({ type: LOAD_MONTH_DATA_END });
    const payload = {
        maxData: maxDataRet.code ? null : maxDataRet.pZMax,
        minData: minDataRet.code ? null : minDataRet.pZMin,
        loadRateData: loadRateDataRet.code ? null : loadRateDataRet.pZRate,
        PVDiffData: PVDiffDataRet.code ? null : PVDiffDataRet.ppvdv
    }
    yield put({type: GET_MONTH_LOAD_DATA_SUCCESS, payload: payload});
}

//日负载率分析
export function* getDayLoadRateData (action) {
    const {
        dataAmmeterId,
        date
    } = action.payload;
    yield put({ type: LOAD_DAY_DATA_BEGIN });
    const [dayLoadRateData, dayLoadRateTableData] = yield all([
        call(
            efficiency.ElectricityLoadAnalysisService.getDailyElecTrendDate,
            dataAmmeterId,
            date,
            2
        ),
        call(
            efficiency.ElectricityLoadAnalysisService.getElectricityLoadStatistics,
            dataAmmeterId,
            date,
            2
        )
    ]);
    yield put({ type: LOAD_DAY_DATA_END });

    if (!dayLoadRateData.code) {
        for (const key in dayLoadRateData.LR) {
            const element = dayLoadRateData.LR[key];
            for (const item of element) {
                const dt = moment(item[0]);
                const dtObject = { hour: dt.hour(), minute: dt.minute() };
                item[0] = moment(dtObject).valueOf();
            }
        }
    }

    if (!dayLoadRateTableData.code) {
        for (const item of dayLoadRateTableData) {
            if (item.dtType === 1) {
                item.dtType = '当日'
            }
            else if (item.dtType === 2) {
                item.dtType = '前一日'
            }
            else if (item.dtType === 3) {
                item.dtType = '上周同日'
            }
            else if (item.dtType === 4) {
                item.dtType = '上月同日'
            }
        }
    }

    yield put({
        type: GET_DAY_LOAD_RATE_DATA_SUCCESS,
        payload: {
            dayLoadRateData: dayLoadRateData.code ? null: dayLoadRateData.LR,
            dayLoadRateTableData: dayLoadRateTableData.code ? null : dayLoadRateTableData
        }
    });
}

//月负载率分析
export function* getMonthLoadRateData (action) {
    const {
        dataAmmeterId,
        date
    } = action.payload;
    yield put({ type: LOAD_MONTH_RATE_DATA_BEGIN });

    const [ monthRateLineDataRet, dayRatePieDataRet ] = yield all([
        call(
            efficiency.ElectricityLoadAnalysisService.getMonthElecLoadDate,
            dataAmmeterId,
            date.format('YYYY-MM-DD HH:mm:ss')
        ),
        call(
            efficiency.ElectricityLoadAnalysisService.getDailyElecLoadChart,
            dataAmmeterId,
            date.startOf('month').format('YYYY-MM-DD HH:mm:ss')
        )
    ]);
    yield put({ type: LOAD_MONTH_RATE_DATA_END });
    yield put({
        type: GET_MONTH_LOAD_RATE_DATA_SUCCESS,
        payload: {
            monthRateLineData: monthRateLineDataRet.code ? null : monthRateLineDataRet.dataMap,
            dayRatePieData: dayRatePieDataRet.code ? null : dayRatePieDataRet.dataMap
        }
    });
}

export function* getDayRatePieLoadRateData (action) {
    const {
        dataAmmeterId,
        date
    } = action.payload;

    yield put({ type: LOAD_DAY_RATE_DATA_BEGIN });
    const dayRatePieDataRet = yield call(
        efficiency.ElectricityLoadAnalysisService.getDailyElecLoadChart,
        dataAmmeterId,
        date.format('YYYY-MM-DD')
    );
    yield put({ type: LOAD_DAY_RATE_DATA_END });
    yield put({
        type: GET_DAY_LOAD_PIE_RATE_DATA_SUCCESS,
        payload: dayRatePieDataRet.code ? null : dayRatePieDataRet.dataMap
    });
}

export const loadAnalysisSagas = [
    takeLatest(GET_DAY_LOAD_DATA, getDayLoadData),
    takeLatest(GET_MONTH_LOAD_DATA, getMonthLoadData),
    takeLatest(GET_DAY_LOAD_RATE_DATA, getDayLoadRateData),
    takeLatest(GET_MONTH_LOAD_RATE_DATA, getMonthLoadRateData),
    takeLatest(GET_DAY_LOAD_PIE_RATE_DATA, getDayRatePieLoadRateData)
];

export function SDNLoadAnalysisReducer (
    state = {
        dayLoading: false,
        monthLoading: false,
        dayRateLoading: false,
        monthRateLoading: false,
        nodeId: '',
        dataAmmeterId: '',
        dayLoadData: null,
        dayLoadTableData: null,
        monthLoadData: {
            maxData: null,
            minData: null,
            loadRateData: null,
            PVDiffData: null
        },
        dayLoadRateData: null,
        dayLoadRateTableData: null,
        dayRatePieDate: moment().startOf('month'),
        monthRateData: {
            monthRateLineData: null,
            dayRatePieData: null
        }
    },
    action
) {
    switch (action.type) {

        case LOAD_ANALYSIS_SET_DATAAMMETERID: {
            
            return { ...state, nodeId: action.payload.nodeId, dataAmmeterId: action.payload.dataAmmeterId }
        }

        case GET_DAY_LOAD_DATA_SUCCESS: {
            //
            return { ...state, dayLoadData: action.payload.dayLoadData, dayLoadTableData: action.payload.dayLoadTableData }
        }

        case GET_MONTH_LOAD_DATA_SUCCESS: {
            return {
                ...state, monthLoadData: action.payload
            }
        }

        case GET_DAY_LOAD_RATE_DATA_SUCCESS: {
            return { ...state, dayLoadRateData: action.payload.dayLoadRateData, dayLoadRateTableData: action.payload.dayLoadRateTableData }
        }

        case GET_MONTH_LOAD_RATE_DATA_SUCCESS: {
            return { ...state, monthRateData: action.payload }
        }

        case GET_DAY_LOAD_PIE_RATE_DATA_SUCCESS: {
            const monthRateData = { ...state.monthRateData, dayRatePieData: action.payload };
            return { ...state, monthRateData: monthRateData }
        }

        case LOAD_DAY_DATA_BEGIN: {
            return { ...state, dayLoading: true };
        }

        case LOAD_DAY_DATA_END: {

            return { ...state, dayLoading: false };
        }

        case LOAD_MONTH_DATA_BEGIN: {
            return { ...state, monthLoading: true };
        }

        case LOAD_MONTH_DATA_END: {
            return { ...state, monthLoading: false };
        }

        case LOAD_DAY_RATE_DATA_BEGIN: {
            return { ...state, dayRateLoading: true };
        }

        case LOAD_DAY_RATE_DATA_END: {
            return { ...state, dayRateLoading: false };
        }

        case LOAD_MONTH_RATE_DATA_BEGIN: {
            return { ...state, monthRateLoading: true };
        }

        case LOAD_MONTH_RATE_DATA_END: {
            return { ...state, monthRateLoading: false };
        }

        case SET_LOAD_DAY_RATE_SELECTED_DATE: {
            return { ...state, dayRatePieDate: action.payload }
        }

        default:
            return state;
    }
}

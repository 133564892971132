import { sys } from "../../service";
import { put, select, takeLatest, call } from 'redux-saga/effects';
import { getSystem } from '../../config';

import * as actionType from './SDNSysAdminActionTypes';

export function* submitAddMenu (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.FunService.add, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.ADD_MENU_SUCCESS });
    yield put({ type: actionType.GET_ALL_MENU });
    yield put({ type: actionType.SHOW_EDIT_MENU, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* submitEditMenu (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.FunService.edit, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.EDIT_MENU_SUCCESS });
    yield put({ type: actionType.GET_ALL_MENU });
    yield put({ type: actionType.SHOW_EDIT_MENU, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getAllMenu (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.FunService.getAllTreeForm);
  if (ret && !ret.code) {
    yield put({type: actionType.GET_ALL_MENU_SUCCESS, payload:ret });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getMenu (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.FunService.get, action.payload);
  if (ret && !ret.code) {
    yield put({type: actionType.GET_MENU_SUCCESS, payload:ret });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* removeMenu (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.FunService.del, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.REMOVE_MENU_SUCCESS });
    yield put({ type: actionType.GET_ALL_MENU });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getRoleWithFun (action) {
  try {
    yield put({type: 'FETCH_REQUEST' });
    const res = yield call(sys.OrgRoleService.getRoleWithFun, action.payload);
    yield put({type: actionType.SET_ROLE_WITH_FUN, payload: res});
    yield put({type: 'FETCH_SUCCESS'});
  }
  catch(error){
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* submitAddRole(action) {
  const {
    role,
    funIdList
  } = action.payload;
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.OrgRoleService.addWithFun, JSON.stringify(role), funIdList, '');
  if (ret.code === 200) {
    yield put({ type: actionType.SUBMIT_ADD_ROLE_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_ROLE, payload:false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* submitEditRole(action) {
  try {
    const {
      role,
      funIdList
    } = action.payload;
    yield put({type: 'FETCH_REQUEST' });
    const ret = yield call(sys.OrgRoleService.editWithFun, JSON.stringify(role), funIdList, '');
    if (ret.code === 200) {
      yield put({ type: actionType.SUBMIT_EDIT_ROLE_SUCCESS });
      yield put({ type: actionType.SHOW_EDIT_ROLE, payload:false });
    }
    yield put({type: 'FETCH_SUCCESS'});

  } catch(error) {
    yield put({type: 'FETCH_FAILED', error: error.message});
  }
}

export function* removeRole(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.OrgRoleService.del, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.ROLE_REMOVED });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

const getRoleFunctionListString = funIdList => {
  if (!funIdList) {
    return '';
  }
  let orgFunList = '';
  for (let i = 0; i < funIdList.length; i++) {

    if (i < funIdList.length - 1) {
      orgFunList += funIdList[i] + ',';
    }
    else {
       orgFunList += funIdList[i];
    }
  }

  return orgFunList;
}

export function* submitAddDataDictionary(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.DictionaryCategoryService.add, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.ADD_DATA_DICTIONARY_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_DATA_DICTIONARY, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* submitEditDataDictionary(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.DictionaryCategoryService.edit, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.EDIT_DATA_DICTIONARY_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_DATA_DICTIONARY, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* removeDataDictionary(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.DictionaryCategoryService.del, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.REMOVE_DATA_DICTIONARY_SUCCESS });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* submitAddDataDicConfig(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.DictionaryService.add, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.ADD_DATA_DIC_CONFIG_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_DATA_DIC_CONFIG, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* submitEditDataDicConfig(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.DictionaryService.edit, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.EDIT_DATA_DIC_CONFIG_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_DATA_DIC_CONFIG, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* removeDataDicConfig(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.DictionaryService.del, action.payload);
  if (ret.code === 200) {
    yield put({ type: actionType.REMOVE_DATA_DIC_CONFIG_SUCCESS });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//获取账号
export function* getAccount(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.OrgEmployeeService.getEmployeeWithRole, action.payload);
  if (!ret.code && ret.length > 0) {
    yield put({ type: actionType.GET_ACCOUNT_SUCCESS, payload: ret[0] });
  }

  yield put({type: 'FETCH_SUCCESS'});
}

//新建账号
export function* addAccount(action) {
  yield put({type: 'FETCH_REQUEST' });
  const orgEmployeeStr = JSON.stringify(action.payload);
  const ret = yield call(sys.OrgEmployeeService.addEmpWithRole, orgEmployeeStr, action.payload.roleIdList || '', '');
  if (ret.code == 200) {
    yield put({ type: actionType.ADD_ACCOUNT_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_ACCOUNT, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//修改账号
export function* editAccount(action) {  
  yield put({type: 'FETCH_REQUEST' });
  const orgEmployeeStr = JSON.stringify(action.payload);
  const ret = yield call(sys.OrgEmployeeService.editEmpWithRole, orgEmployeeStr, action.payload.roleIdList || '', '');
  if (ret.code == 200) {
    yield put({ type: actionType.EDIT_ACCOUNT_SUCCESS });
    yield put({ type: actionType.SHOW_EDIT_ACCOUNT, payload: false });
    if (action.showResetAccountPwd) {
      yield put({ type: actionType.SHOW_RESET_ACCOUNT_PWD, payload: true });
    }
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//删除账号
export function* removeAccount(action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(sys.OrgEmployeeService.del, action.payload);
  if (ret.code == 200) {
    yield put({ type: actionType.REMOVE_ACCOUNT_SUCCESS });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

//修改账号密码
export function* resetAccountPwd(action) {
  yield put({type: 'FETCH_REQUEST' });
  const {
    employeeId,
    oldPwd,
    newPwd
  } = action.payload;
  const system = yield select(getSystem);
  const { user } = system;
  const ret = yield call(sys.OrgEmployeeService.updateEmpPassword, user.orgId, employeeId, oldPwd, newPwd);
  if (ret.code == 200) {
    yield put({ type: actionType.RESET_ACCOUNT_PWD_SUCCESS });
    yield put({ type: actionType.SHOW_RESET_ACCOUNT_PWD, payload: false });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

const sysAdminSagas = [
  //菜单管理
  takeLatest(actionType.SUBMIT_ADD_MENU, submitAddMenu),
  takeLatest(actionType.SUBMIT_EDIT_MENU, submitEditMenu),
  takeLatest(actionType.GET_ALL_MENU, getAllMenu),
  takeLatest(actionType.GET_MENU, getMenu),
  takeLatest(actionType.REMOVE_MENU, removeMenu),
  //角色管理
  takeLatest(actionType.SUBMIT_ADD_ROLE, submitAddRole),
  takeLatest(actionType.SUBMIT_EDIT_ROLE, submitEditRole),
  takeLatest(actionType.GET_ROLE_WITH_FUN, getRoleWithFun),
  takeLatest(actionType.REMOVE_ROLE, removeRole),
  //数据字典
  takeLatest(actionType.SUBMIT_ADD_DATA_DICTIONARY, submitAddDataDictionary),
  takeLatest(actionType.SUBMIT_EDIT_DATA_DICTIONARY, submitEditDataDictionary),
  takeLatest(actionType.REMOVE_DATA_DICTIONARY, removeDataDictionary),
  //数据字典配置
  takeLatest(actionType.SUBMIT_ADD_DATA_DIC_CONFIG, submitAddDataDicConfig),
  takeLatest(actionType.SUBMIT_EDIT_DATA_DIC_CONFIG, submitEditDataDicConfig),
  takeLatest(actionType.REMOVE_DATA_DIC_CONFIG, removeDataDicConfig),
  //账号管理
  takeLatest(actionType.GET_ACCOUNT, getAccount),
  takeLatest(actionType.ADD_ACCOUNT, addAccount),
  takeLatest(actionType.EDIT_ACCOUNT, editAccount),
  takeLatest(actionType.REMOVE_ACCOUNT, removeAccount),
  takeLatest(actionType.RESET_ACCOUNT_PWD, resetAccountPwd),
];

export default sysAdminSagas;

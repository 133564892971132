import axios from 'axios';
import {pageUrl} from "./SDNResource";
import {redirectTo} from './SDNRedirect.js';
import {empty} from '../common/utils/functions/dataProcess';
import {
  errorBox,
  infoBox
} from "../common/utils/functions";

import {serverUrl, requestConfigTemp} from '../config';
import $ from "jquery";

export {serverUrl};
let tempErrorMsg = '';
const axiosRequest = async (url, params, type = 'get') => {
  const getUrl = serverUrl + url;
  axios.defaults.withCredentials = true;

  let axiosParams = {
    method: type,
    url: getUrl,
    responseType: 'json',
    headers: {Accept: "application/json, text/plain, */*", ws_auth_token: "123"}
  }

  if (type == 'post') {
    axiosParams.data = params;
    axiosParams.headers["Content-Type"] = 'application/x-www-form-urlencoded';
    axiosParams.transformRequest = [data => {
      return formatParams(data);
    }]
  } else {
    axiosParams.params = params;
  }

  const result = await axios(axiosParams).then((response) => {
    if (!response.data) {
      return null;
    }
    if (response.data.code === 901) {
      redirectTo(pageUrl.login);
      return {code: response.data.code, error: response.data.message, message: response.data.message};
    } 
    else if (response.data.code !== 200) {
      //TODO 暂不统一提示错误，单个页面独自处理
      if (tempErrorMsg != response.data.message) {
        tempErrorMsg = response.data.message;
        infoBox(response.data.message);
      }
      setTimeout (function(){tempErrorMsg=''},1000);
      return {code: response.data.code, error: response.data.message, message: response.data.message};
    }
    if (!response.data.data) {
      return response.data;
    }
    return response.data.data;
  })
    .catch((error) => {
      errorBox(error.message);
      return {
        code: error.response && error.response.status ? error.response.status : -1,
        error: error.message,
        message: error.message,
      };
    });

  return result;
}

const axiosPostRequest = async (url, params, files) => {
  const getUrl = serverUrl + url;
  axios.defaults.withCredentials = true;
  let body = new FormData();
  if (files) {
    files.map((item) => {
      for (let key in item) {
        let file = item[key];
        body.append(key, file);
      }
    })
  }
  if (params) {
    Object.keys(params).forEach((key) => {
      // if (params[key] instanceof Date) {
      //   body.append(key, value.toISOString());
      // } else {
      body.append(key, String(params[key]));
      //}
    });
  }

  let config = {
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: "application/json, text/plain, */*",
      ws_auth_token: "123"
    }
  };

  const result = await axios.post(getUrl, body, config).then((response) => {
    if (response.data.code === 901) {
      redirectTo(pageUrl.login);
      return null;
    } else if (response.data.code !== 200) {
      errorBox(response.data.message);
      return {code: response.data.code, error: response.data.message, message: response.data.message};
    }
    if (!response.data.data) {
      return response.data;
    }
    return response.data.data;
  })
    .catch((error) => {
      errorBox(error.message);
      return {code: error.response.status, error: error.message, message: error.message};
    });

  return result;
}

export const getRequestAxios = (url, params) => {
  return axiosRequest(url, params);
}

export const imgPostRequestAxios = (url, data, handle) => {
  let formData = new FormData();

  if (data != null && data != undefined && typeof (data) == "object") {
    for (let x in data) {
      if (Array.isArray(data[x]) && data[x].length > 0) {
        for (let j = 0; j < data[x].length; j++) {
          formData.append(x, data[x][j]);
        }
      } else {
        if (empty(data[x])) formData.append(x, "");
        else formData.append(x, data[x]);
      }
    }
  }

  let instance = axios.create({
    baseURL: serverUrl,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', //该项建议设置 如果未 POST请求时 数据未做处理时会出现错误，最理想的解决方法就是 直接设置该项
      ws_auth_token: "123"
    },
    withCredentials: true//是否允许发送Cookie 如果为true 则服务器的 Access-control-Allow-Credentials 必须为 true 来源为 XMLHttpRequest的withCredentials配置项
  });
  instance.post(instance.defaults.baseURL + url, formData).then(res => {
    handle(res.data);
  }).catch(error => {
    return error
  })
}

export const formatParams = (params) => {
  var query = '',
    name, value, fullSubName, subName, subValue, innerObj, i;
  for (name in params) {
    value = params[name];
    if (value instanceof Array) {
      query += encodeURIComponent(name) + '=';
      for (i = 0; i < value.length; ++i) {
        subValue = value[i];
        //最后一个不显示豆号。
        if (i === value.length - 1) {
          query += encodeURIComponent(subValue);
        } else {
          query += encodeURIComponent(subValue) + ',';
        }
      }
      query += '&';
    } else if (value instanceof Object) {
      for (subName in value) {
        subValue = value[subName];
        //fullSubName = name + '[' + subName + ']';
        fullSubName = name + '.' + subName;
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += formatParams(innerObj) + '&';
      }
    } else if (value !== undefined && value !== null) {
      query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
    } else {
      query += encodeURIComponent(name) + '=&';
    }

  }
  var ret = query.length ? query.substr(0, query.length - 1) : query;
  return ret;
}

//删除value为null及undefined的参数，易小林，2019.07.04
const removeEmptyKeys = (params) => {
  let deleteKeys = [];
  for (let key in params) {
    let value = params[key];
    if (value === null || typeof value === 'undefined') {
      deleteKeys.push(key);
    }
  }

  for (let index in deleteKeys) {
    let key = deleteKeys[index];
    delete params[key];
  }

  return params;
}

export const BaseService = {

  get: (url, params) => {
    return axiosRequest(url, removeEmptyKeys(params));
  },

  post: (url, params) => {
    return axiosRequest(url, removeEmptyKeys(params), 'post');
  },

  upload: (url, params) => { //logoFile, upFile
    let files = [];
    let deleteKeys = [];

    for (let key in params) {
      let value = params[key];
      if (key && value && value instanceof File) {
        let temp = {};
        temp[key] = value;
        files.push(temp);
        deleteKeys.push(key);
      }
    }

    for (let index in deleteKeys) {
      let key = deleteKeys[index];
      delete params[key];
    }

    return axiosPostRequest(url, removeEmptyKeys(params), files);
  },

  download: (url, params) => {
    if (params === null) {
      return serverUrl + url;
    } else {
      return serverUrl + url + "?" + formatParams(params);
    }
  }
}

/***************************************************************** Old Request Service *******************************************************************/

const getConfig = function (config, exConfig) {
  var reConfig = {}, key;
  for (key in requestConfigTemp) {
    if (config[key] !== undefined) {
      reConfig[key] = config[key];
    } else {
      if (exConfig !== undefined && exConfig[key] !== undefined) {
        reConfig[key] = exConfig[key];
      } else {
        reConfig[key] = requestConfigTemp[key];
      }
    }
  }
  reConfig.url = serverUrl + reConfig.url;
  return reConfig;
}

const requestAct = function (config, exConfig) {
  var reConfig = getConfig(config, exConfig);
  $.ajax(reConfig);
}

// 通用请求
export const sdnRequest = function (url, config) {
  requestAct({"url": url}, config);
}
// GET请求
export const getRequest = function (url, config) {
  requestAct({"url": url, "type": "GET"}, config);
}
// 异步GET请求
export const getRequestAsync = function (url, config) {
  requestAct({"url": url, "type": "GET", "async": true}, config);
}
// 带参数的GET请求
export const getRequestData = function (url, data, config) {
  requestAct({"url": url, "type": "GET", "data": data}, config);
}
// 带参数的异步GET请求
export const getRequestAsyncData = function (url, data, config) {
  requestAct({"url": url, "type": "GET", "async": true, "data": data}, config);
}
// 有回调的GET请求
export const getRequestCall = function (url, success, config) {
  requestAct({"url": url, "type": "GET", "success": success}, config);
}
// 有回调的异步GET请求
export const getRequestAsyncCall = function (url, success, config) {
  requestAct({"url": url, "type": "GET", "async": true, "success": success}, config);
}
// 带参数有回调的GET请求
export const getRequestDataCall = function (url, data, success, config) {
  requestAct({"url": url, "type": "GET", "data": data, "success": success}, config);
}
// 带参数有回调的异步GET请求
export const getRequestAsyncDataCall = function (url, data, success, config) {
  requestAct({"url": url, "type": "GET", "async": true, "data": data, "success": success}, config);
}
// POST请求
export const postRequest = function (url, config) {
  requestAct({"url": url, "type": "POST"}, config);
}
// 异步POST请求
export const postRequestAsync = function (url, config) {
  requestAct({"url": url, "type": "POST", "async": true}, config);
}
// 带参数的POST请求
export const postRequestData = function (url, data, config) {
  requestAct({"url": url, "type": "POST", "data": data}, config);
}
// 带参数的异步POST请求
export const postRequestAsyncData = function (url, data, config) {
  requestAct({"url": url, "type": "POST", "async": true, "data": data}, config);
}
// 有回调的POST请求
export const postRequestCall = function (url, success, config) {
  requestAct({"url": url, "type": "POST", "success": success}, config);
}
// 有回调的异步POST请求
export const postRequestAsyncCall = function (url, success, config) {
  requestAct({"url": url, "type": "POST", "async": true, "success": success}, config);
}
// 带参数有回调的POST请求
export const postRequestDataCall = function (url, data, success, config) {
  requestAct({"url": url, "type": "POST", "data": data, "success": success}, config);
}
// 带参数有回调的异步POST请求
export const postRequestAsyncDataCall = function (url, data, success, config) {
  requestAct({"url": url, "type": "POST", "async": true, "data": data, "success": success}, config);
}

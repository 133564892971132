import { BaseService } from '../SDNRequest';

const hunan = {
	    //hunan_unit_configure	    
        HunanUnitConfigureService :{
			
			//增加yunshui_unit_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/hunan/unitConfigure/add',mapParams);
			},
			
			//删除yunshui_unit_configure对象
			del : function(id) {
				return BaseService.post('/api/hunan/unitConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_unit_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/hunan/unitConfigure/edit',mapParams);
			},
			
			//修改yunshui_unit_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hunan/unitConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_unit_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/hunan/unitConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_unit_configure对象
			get : function(id) {
				return BaseService.get('/api/hunan/unitConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_unit_configure对象
			getAll : function() {
				return BaseService.get('/api/hunan/unitConfigure/getall');
			},
			
			//DataTable查询yunshui_unit_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/hunan/unitConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_unit_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/hunan/unitConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/hunan/unitConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //hunan_upload_service	    
        HunanUploadServiceService :{
			
			//基础数据上传
			basicInfoUpload : function(orgId) {
				return BaseService.get('/api/hunan/uploadservice/basicinfoupload',{
						'orgId':orgId
					});
			},
			
			//采集数据上传
			collectDataUpload : function(orgId, dateStr, type) {
				return BaseService.get('/api/hunan/uploadservice/collectdataupload',{
						'orgId':orgId, 'dateStr':dateStr, 'type':type
					});
			},
			
			//采集数据上传All
			collectDataUploadAll : function(orgId, dateStr) {
				return BaseService.get('/api/hunan/uploadservice/collectDataUploadAll',{
						'orgId':orgId, 'dateStr':dateStr
					});
			},
			
			//端设备注册
			deviceRegist : function(orgId) {
				return BaseService.get('/api/hunan/uploadservice/regist',{
						'orgId':orgId
					});
			},
			
			//基础数据下载
			downloadBaseData : function(orgId) {
				return BaseService.get('/api/hunan/uploadservice/downloadbasedata',{
						'orgId':orgId
					});
			},
			
			//获取业务数据(bizData)
			getBizData : function(ammeterId, dataType, dtStart, dtEnd) {
				return BaseService.get('/api/hunan/uploadservice/getBizData',{
						'ammeterId':ammeterId, 'dataType':dataType, 'dtStart':dtStart, 'dtEnd':dtEnd
					});
			},
			
			//获取采集数据上传
			getCollectDataAll : function(orgId, dateStr) {
				return BaseService.get('/api/hunan/uploadservice/getCollectDataAll',{
						'orgId':orgId, 'dateStr':dateStr
					});
			},
			
			//获取采集数据上传
			getCollectDataUpload : function(orgId, dateStr, type) {
				return BaseService.get('/api/hunan/uploadservice/getCollectDataUpload',{
						'orgId':orgId, 'dateStr':dateStr, 'type':type
					});
			},
			
			// 采集数据上传数据-不检查orgId-云南项目用
			getCollectDataUploadNoCheck : function(orgId, dateStr, type) {
				return BaseService.get('/api/hunan/uploadservice/getCollectDataUploadNoCheck',{
						'orgId':orgId, 'dateStr':dateStr, 'type':type
					});
			},
			
			//获取某天未上传的数据列表
			getOneDayUnUploadList : function(orgId, dateStr, succCode, type) {
				return BaseService.get('/api/hunan/uploadservice/getOneDayUnUploadList',{
						'orgId':orgId, 'dateStr':dateStr, 'succCode':succCode, 'type':type
					});
			},
			
			//获取配置参数
			getParams : function() {
				return BaseService.get('/api/hunan/uploadservice/getParams');
			},
			
			// 获取时段未上传的数据列表
			getRangeUnUploadList : function(orgId, dateBegin, dateEnd, succCode, type) {
				return BaseService.get('/api/hunan/uploadservice/getRangeUnUploadList',{
						'orgId':orgId, 'dateBegin':dateBegin, 'dateEnd':dateEnd, 'succCode':succCode, 'type':type
					});
			},
			
			//批量补传时段内未上传的数据
			reBatchUploadData : function(orgId, dateBegin, dateEnd, type) {
				return BaseService.get('/api/hunan/uploadservice/reBatchUploadData',{
						'orgId':orgId, 'dateBegin':dateBegin, 'dateEnd':dateEnd, 'type':type
					});
			},
			
			//人工补传某天某个DataCode的数据
			reUploadData : function(orgId, dateStr, dataCode, type) {
				return BaseService.get('/api/hunan/uploadservice/reUploadData',{
						'orgId':orgId, 'dateStr':dateStr, 'dataCode':dataCode, 'type':type
					});
			},
			
			//人工手动补某天未上传成功的数据
			reUploadOneDay : function(orgId, dateStr, type) {
				return BaseService.get('/api/hunan/uploadservice/reUploadOneDay',{
						'orgId':orgId, 'dateStr':dateStr, 'type':type
					});
			},
			
			//平台版本校验
			versionCheck : function(orgId) {
				return BaseService.get('/api/hunan/uploadservice/versioncheck',{
						'orgId':orgId
					});
			},
		},
	    //hunan_process_configure	    
        HunanProcessConfigureService :{
			
			//增加yunshui_process_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/hunan/processConfigure/add',mapParams);
			},
			
			//删除yunshui_process_configure对象
			del : function(id) {
				return BaseService.post('/api/hunan/processConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_process_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/hunan/processConfigure/edit',mapParams);
			},
			
			//修改yunshui_process_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hunan/processConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_process_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/hunan/processConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_process_configure对象
			get : function(id) {
				return BaseService.get('/api/hunan/processConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_process_configure对象
			getAll : function() {
				return BaseService.get('/api/hunan/processConfigure/getall');
			},
			
			//DataTable查询yunshui_process_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/hunan/processConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_process_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/hunan/processConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/hunan/processConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //hunan_data_collect_configure	    
        HunanDataCollectConfigureService :{
			
			//增加yunshui_data_collect_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/hunan/dataCollectConfigure/add',mapParams);
			},
			
			//删除yunshui_data_collect_configure对象
			del : function(id) {
				return BaseService.post('/api/hunan/dataCollectConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_data_collect_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/hunan/dataCollectConfigure/edit',mapParams);
			},
			
			//修改yunshui_data_collect_configure对象的字段
			editConvertRation : function(id, value) {
				return BaseService.post('/api/hunan/dataCollectConfigure/editConvertRation',{
						'id':id, 'value':value
					});
			},
			
			//修改yunshui_data_collect_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hunan/dataCollectConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_data_collect_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/hunan/dataCollectConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_data_collect_configure对象
			get : function(id) {
				return BaseService.get('/api/hunan/dataCollectConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_data_collect_configure对象
			getAll : function() {
				return BaseService.get('/api/hunan/dataCollectConfigure/getall');
			},
			
			//获取上传数据项信息
			getDataCollectList : function(orgId, statType) {
				return BaseService.get('/api/hunan/dataCollectConfigure/getDataCollectList',{
						'orgId':orgId, 'statType':statType
					});
			},
			
			//DataTable查询yunshui_data_collect_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/hunan/dataCollectConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_data_collect_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/hunan/dataCollectConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/hunan/dataCollectConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //hunan_enterprise_info	    
        HunanEnterpriseInfoService :{
			
			//增加yunshui_enterprise_info对象
			add : function(mapParams) {
						return BaseService.upload('/api/hunan/enterpriseInfo/add',mapParams);
			},
			
			//删除yunshui_enterprise_info对象
			del : function(id) {
				return BaseService.post('/api/hunan/enterpriseInfo/del',{
						'id':id
					});
			},
			
			//修改yunshui_enterprise_info对象
			edit : function(mapParams) {
						return BaseService.upload('/api/hunan/enterpriseInfo/edit',mapParams);
			},
			
			//修改yunshui_enterprise_info对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hunan/enterpriseInfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取yunshui_enterprise_info对象
			get : function(id) {
				return BaseService.get('/api/hunan/enterpriseInfo/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_enterprise_info对象
			getAll : function() {
				return BaseService.get('/api/hunan/enterpriseInfo/getall');
			},
			
			//DataTable查询yunshui_enterprise_info对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/hunan/enterpriseInfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_enterprise_info对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/hunan/enterpriseInfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //hunan_platforminfo_controller	    
        HunanPlatformInfoService :{
			
			//增加湖南对接平台信息表对象
			add : function(mapParams) {
						return BaseService.post('/api/hunan/platformInfo/add',mapParams);
			},
			
			//删除湖南对接平台信息表对象
			del : function(id) {
				return BaseService.post('/api/hunan/platformInfo/del',{
						'id':id
					});
			},
			
			//修改湖南对接平台信息表对象
			edit : function(mapParams) {
						return BaseService.post('/api/hunan/platformInfo/edit',mapParams);
			},
			
			//修改湖南对接平台信息表对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/hunan/platformInfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改企业上传时间
			editUploadTime : function(orgId, uploadTime) {
				return BaseService.get('/api/hunan/platformInfo/editUploadTime',{
						'orgId':orgId, 'uploadTime':uploadTime
					});
			},
			
			//启用禁用上传
			enableUpload : function(orgId, enable) {
				return BaseService.get('/api/hunan/platformInfo/enableUpload',{
						'orgId':orgId, 'enable':enable
					});
			},
			
			//启用禁用上传全部
			enableUploadAll : function(enable) {
				return BaseService.get('/api/hunan/platformInfo/enableUploadAll',{
						'enable':enable
					});
			},
			
			//根据ID获取湖南对接平台信息表对象
			get : function(id) {
				return BaseService.get('/api/hunan/platformInfo/get',{
						'id':id
					});
			},
			
			//查询所有湖南对接平台信息表对象
			getAll : function() {
				return BaseService.get('/api/hunan/platformInfo/getall');
			},
			
			//根据orgId获取湖南对接平台信息表对象
			getByOrgId : function(orgId) {
				return BaseService.get('/api/hunan/platformInfo/getbyorgid',{
						'orgId':orgId
					});
			},
			
			//根据orgId获取湖南对接平台信息表DB对象
			getByOrgIdDb : function(orgId) {
				return BaseService.get('/api/hunan/platformInfo/getByOrgIdDb',{
						'orgId':orgId
					});
			},
			
			//DataTable查询湖南对接平台信息表对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/hunan/platformInfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示湖南对接平台信息表对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/hunan/platformInfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
 
}

export default hunan;
import React from "react";
import $ from "jquery";

require("datatables.net-bs");
require("datatables.net-buttons-bs");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-bs");
require("datatables.net-responsive-bs");
require("datatables.net-select-bs");

export default class Datatable extends React.Component {
  componentDidMount() {
    this.datatable();
  }

  search = (index, value) => {
    if (this.dataTable) {
      this.dataTable.column(index).search(value).draw();
    }
  }

  clear = () => {
    if (this.dataTable) {
      this.dataTable.clear();
    }
  }


  addRows = rows => {
    if (this.dataTable) {
      this.dataTable.rows.add(rows).draw();
    }
  }

  destroyButtons = () => {
    if (this.dataTable) {
      this.dataTable.buttons().destroy();
    }
  }

  addButton = (index, button) => {
    if (this.dataTable) {
      this.dataTable.button().add(index, button);
    }
  }

  reload = () => {
    if (this.dataTable) {
      this.dataTable.draw(false);
    }
  }

  ajaxReload = () => {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable.clear();
      this.datatable();
    }
  }

  params = ''

  datatable() {

    let options = this.props.options || {};

    let toolbarHeader = '', filter = '<"pull-left"f>', buttons = '';
    if (options.searching === false || this.props.isShowSearching === false) {
      filter = '';
    }
    if (Array.isArray(options.buttons) && options.buttons[0]) buttons = '<"pull-right"B>';
    if (filter || buttons) toolbarHeader = '<"dt-toolbar"' + filter + buttons + ">";
    let toolbarFooter = '<"dt-toolbar-footer"<"pull-left"l><"pull-left"i><"pull-right"p>>';
    if (options.paging === false) toolbarFooter = '';

    if (typeof options.ajax === "string") options.ajax = { url: options.ajax };

    options = {
      dom: toolbarHeader + 'tr' + toolbarFooter,
      renderer: { pageButton: 'bootstrap' },
      select: this.props.select,
      info: true,
      paging: true,
      pageLength: 10,
      pagingType: 'full_numbers',
      lengthChange: true,
      lengthMenu: [[10, 15, 25, 50, 100], [10, 15, 25, 50, 100]],
      oLanguage: {
        sProcessing: "处理中...",
        sLengthMenu: "_MENU_",//"每页 _MENU_ 项",
        sZeroRecords: "",//"没有匹配结果",
        sInfo: "当前显示第 _START_ 至 _END_ 项，共 _TOTAL_ 项",
        sInfoEmpty: "",//"当前显示第 0 至 0 项，共 0 项",
        sInfoFiltered: "",//"(由 _MAX_ 项结果过滤)",
        sInfoPostFix: "",
        sSearch: '<span class="input-group-addon"><i class="glyphicon glyphicon-search"></i></span>',
        sUrl: "",
        sEmptyTable: "<i class='iconfont icon-empty'></i><br>暂无数据",
        sLoadingRecords: "载入中...",
        sInfoThousands: ",",
        oPaginate: {
          sFirst: "首页",
          sPrevious: "上一页",
          sNext: "下一页",
          sLast: "末页",
          sJump: "跳转"
        },
        oAria: {
          sSortAscending: ": 以升序排列此列",
          sSortDescending: ": 以降序排列此列"
        },
        select: {
          rows: {
            _: " 已选中%d项 ",
            0: ""
          }
        }
      },
      autoWidth: false,
      retrieve: true,
      responsive: true,

      ...options
    };

    const element = $(this.refs.table);
    const _dataTable = element.DataTable(options);

    this.dataTable = _dataTable;
    this.params = _dataTable.ajax && _dataTable.ajax.params instanceof Function ? _dataTable.ajax.params() : '';

    if (this.props.filter) {
      element.on("keyup change", "thead th input[type=text]", function () {
        _dataTable
          .column($(this).parent().index() + ":visible")
          .search(this.value)
          .draw();
      });
    }

    if (this.props.onSelected) {
      let selected = this.props.onSelected;
      let multiSlect = this.props.multiselect;
      element.on('click', 'tr', function () {
        const tr = $(this).closest("tr");
        if (multiSlect) {
          $(this).toggleClass('DTTT_selected');
          $(this).find('td').addClass('sorting_1');
        } else {
          tr.toggleClass('DTTT_selected');
          tr.siblings().removeClass('DTTT_selected');
          $(this).find('td').addClass('sorting_1');
        }
        const row = _dataTable.row(tr);
        let data = row.data();
        selected(data);
      });
    }

    if (this.props.detailsFormat) {
      const format = this.props.detailsFormat;
      element.on("click", "td.details-control", function () {
        const tr = $(this).closest("tr");
        const row = _dataTable.row(tr);
        if (row.child.isShown()) {
          row.child.hide();
          tr.removeClass("shown");
        }
        else {
          row.child(format(row.data())).show();
          tr.addClass("shown");
        }
      });
    }

    if (options.data) { //易小林，2020.08.12 加载本地数据筛选排序后更新序号；className为index列
      _dataTable.on('order.dt search.dt', function () {
        if (_dataTable.column) {
          const nodes = _dataTable.column('.index', {
            search: 'applied',
            order: 'applied'
          }).nodes();
          if (nodes) {
            nodes.each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
          }
        }
      })
    }
  }

  render() {
    let {
      table,
      children,
      options,
      detailsFormat,
      paginationLength,
      isShowSearching,
      ...props
    } = this.props;

    return <table {...props} ref="table">{children}</table>;
  }
}

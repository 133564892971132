import { put, takeLatest, call, } from 'redux-saga/effects';
import { errorBox, successBox } from "../../common";

import { getService } from "./SDNDatauploadConstant";
import {yunshui} from "../../service";

export const DATAUPLOAD_initPage = 'DATAUPLOAD_initPage';

export const DATAUPLOAD_setShowModel = 'DATAUPLOAD_setShowModel';
export const DATAUPLOAD_setShowEdit = 'DATAUPLOAD_setShowEdit';

export const DATAUPLOAD_importFile = 'DATAUPLOAD_importFile';
export const DATAUPLOAD_importFile_success = 'DATAUPLOAD_importFile_success';

export const DATAUPLOAD_delData = 'DATAUPLOAD_delData';
export const DATAUPLOAD_delData_success = 'DATAUPLOAD_delData_success';

export const DATAUPLOAD_getData = 'DATAUPLOAD_getData';
export const DATAUPLOAD_getData_success = 'DATAUPLOAD_getData_success';

export const DATAUPLOAD_editData = 'DATAUPLOAD_editData';
export const DATAUPLOAD_editData_success = 'DATAUPLOAD_editData_success';

export const DATAUPLOAD_addData = 'DATAUPLOAD_addData';
export const DATAUPLOAD_addData_success = 'DATAUPLOAD_addData_success';

export const DATAUPLOAD_editField = 'DATAUPLOAD_editField';
export const DATAUPLOAD_editField_success = 'DATAUPLOAD_editField_success';

export const GET_DATA_COLLECT_LIST = 'GET_DATA_COLLECT_LIST';
export const SET_DATA_COLLECT_LIST = 'SET_DATA_COLLECT_LIST';
export const GET_CONVERT_RATION = 'GET_CONVERT_RATION';
export const SET_CONVERT_RATION = 'SET_CONVERT_RATION';
export const POST_DATA_UPDATE = 'POST_DATA_UPDATE';
export const SET_DATA_UPDATE = 'SET_DATA_UPDATE';
export const DATA_UPDATE_PROCESS = 'DATA_UPDATE_PROCESS';
export const SET_DATA_UPDATE_PROCESS = 'SET_DATA_UPDATE_PROCESS';
export const DATA_UPDATE_STOP = 'DATA_UPDATE_STOP';
export const SET_DATA_UPDATE_STOP = 'SET_DATA_UPDATE_STOP';

export const dataUploadSagas = [    
    takeLatest(DATAUPLOAD_importFile, importFile),
    takeLatest(DATAUPLOAD_delData, delData),    
    takeLatest(DATAUPLOAD_getData, getData),
    takeLatest(DATAUPLOAD_editField, editField),
    takeLatest(DATAUPLOAD_editData, editData),
    takeLatest(DATAUPLOAD_addData, addData),

    takeLatest(GET_DATA_COLLECT_LIST, getDataCollectList),
    takeLatest(GET_CONVERT_RATION, getConvertRation),
    takeLatest(POST_DATA_UPDATE, postDataUpdate),
    takeLatest(DATA_UPDATE_PROCESS, dataUpdateProcess),
    takeLatest(DATA_UPDATE_STOP, dataUpdateStop),
];

export function SDNDataUploadReducer(
    state = {
        page: null,
        isShowModel: false,
        isImporting: false,
        isShowEdit: false,
        itemDetailData: {},
        coefficient:'',
        searchKey:'',
        process:''
    },
    action) {
    switch (action.type) {

        case DATAUPLOAD_initPage: {
            return {
                ...state,
                page:action.page,
            }
        }

        case DATAUPLOAD_delData_success: {
            if (action.res && action.res.code == 200) {
                successBox("删除成功");
                if (state.page) {
                    state.page.reloadData();
                }                
            }
            return {
                ...state,
            }
        }

        case DATAUPLOAD_getData_success: {
            if (action.res) {
                return {
                    ...state,
                    itemDetailData: action.res,
                }
                               
            }
            return {
                ...state,
            }
        }  

        case DATAUPLOAD_editData_success: {
            if (action.res && action.res.code == 200) {
                successBox("修改成功");
                if (state.page) {
                    state.page.reloadData();
                }  
                return {
                    ...state,
                    isShowEdit: false,
                }       
            }
            return {
                ...state,
            }
        }

        case DATAUPLOAD_addData_success: {
            if (action.res && action.res.code == 200) {
                successBox("新建成功");
                if (state.page) {
                    state.page.reloadData();
                }  
                return {
                    ...state,
                    isShowEdit: false,
                }       
            }
            return {
                ...state,
            }
        }      
        
        case DATAUPLOAD_editField_success: {
            if (action.res && action.res.code == 200) {
                successBox("修改成功");
                if (state.page) {
                    state.page.reloadData();
                }  
                return {
                    ...state,
                }       
            }
            return {
                ...state,
            }
        }      
        
        case 'DATAUPLOAD_setShowModel': {
            return {
                ...state,
                isShowModel:action.isShowModel,
            }
        }

        case 'DATAUPLOAD_setShowEdit': {
            return {
                ...state,
                isShowEdit:action.isShowEdit,
            }
        }        

        case 'DATAUPLOAD_importFile_success': {
            if (action.res && action.res.code == 200) {
                successBox("导入成功");
                if (state.page) {
                    state.page.reloadData();
                }

                return {
                    ...state,
                    isImporting:false,
                    isShowModel:false,
                }
            }

            return {
                ...state,
                isImporting:false,
            }
        }

        case 'SET_DATA_COLLECT_LIST': {
            return {
                ...state,
                itemDetailData:action.res,
            }
        }

        case 'SET_CONVERT_RATION': {
            if(action.res && action.res.value!==undefined){
                return {
                    ...state,
                    coefficient:action.res.value,
                }
            }
            return{
                ...state,
                coefficient:'',
            }
        }        

        case 'SET_DATA_UPDATE': {
            return {
                ...state,
                searchKey:action.res.key,
                process:action.res.process,
            }
        }

        case 'SET_DATA_UPDATE_PROCESS': {
            if (action.res) {
                if(action.res.process===1){
                    successBox("上传成功");
                    if (state.page) {
                        state.page.reloadData();
                    }
                }
                return {
                    ...state,
                    searchKey:action.res.key,
                    process:action.res.process,
                }
            }
            return {
                ...state,
            }
        }        

        case 'SET_DATA_UPDATE_STOP': {
            if (action.res && action.res.code == 200) {
                successBox("取消上传");
                if (state.page) {
                    state.page.reloadData();
                }

                return {
                    ...state,
                }
            }

            return {
                ...state,
            }
        }

        default:
            return state;
    }
}

export function* delData(action) {
    try {
        const service = getService(action.index);
        const id = action.id;

        if (service && service.del) {
            const res = yield call(service.del, id);          
            yield put({ type: DATAUPLOAD_delData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* getData(action) {
    try {
        const service = getService(action.index);
        const id = action.id;

        if (service && service.edit) {
            const res = yield call(service.get, id);          
            yield put({ type: DATAUPLOAD_getData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* editData(action) {
    try {
        const service = getService(action.index);
        const mapParams = action.mapParams;

        if (service && service.edit) {
            const res = yield call(service.edit, mapParams);          
            yield put({ type: DATAUPLOAD_editData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* addData(action) {
    try {
        const service = getService(action.index);
        const mapParams = action.mapParams;

        if (service && service.add) {
            const res = yield call(service.add, mapParams);          
            yield put({ type: DATAUPLOAD_addData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* editField(action) {
    try {
        const service = getService(action.index);
        let {id, fieldName, fieldValue, remarks} = action;
        if (remarks == undefined) {
            remarks = '';
        }

        if (service && service.editField) {
            const res = yield call(service.editField, id, fieldName, fieldValue, remarks);          
            yield put({ type: DATAUPLOAD_editField_success, res: res });
        } 
    } catch (e) {
        
    }
}

export function* importFile(action) {
    try {
        const service = getService(action.index);
        const {upFile, orgId} = action;

        if (service && service.importFile) {
            if(action.index < 19){
                const res = yield call(service.importFile, upFile, orgId);          
                yield put({ type: DATAUPLOAD_importFile_success, res: res });
            }else{
                let mapParams={};
                mapParams.upFile=action.upFile;
                mapParams.orgId=action.orgId;
                mapParams.type=action.fileType;
                const res = yield call(service.add, mapParams);          
                yield put({ type: DATAUPLOAD_importFile_success, res: res });
            }
        } else {
            yield put({ type: DATAUPLOAD_importFile_success, res: null });
        }  
    } catch (e) {

    }
}

export function* getDataCollectList(action) {
    try {
        const service = yunshui.DataCollectConfigureService;
        const orgId = action.orgId;
        const statType = action.statType;

        if (service && service.getDataCollectList) {
            const res = yield call(service.getDataCollectList, orgId, statType);          
            yield put({ type: SET_DATA_COLLECT_LIST, res: res });
        } 
    } catch (e) {

    }
}

export function* getConvertRation(action) {
    try {
        const service = yunshui.UploadRecordService;
        const orgId = action.orgId;
        const date = action.date;

        if (service && service.getConvertRation) {
            const res = yield call(service.getConvertRation, orgId, date);          
            yield put({ type: SET_CONVERT_RATION, res: res });
        } 
    } catch (e) {

    }
}

export function* postDataUpdate(action) {
    try {
        const service = yunshui.UploadRecordService;
        let {orgId, dataCollectConfigureIds, year, month} = action.mapParams;
        if (service && service.dataUpdate) {
            const res = yield call(service.dataUpdate, orgId, dataCollectConfigureIds, year, month);          
            yield put({ type: SET_DATA_UPDATE, res: res });
        } 
    } catch (e) {
        
    }
}

export function* dataUpdateProcess(action) {
    try {
        const service = yunshui.UploadRecordService;
        const key = action.searchKey;

        const res = yield call(service.dataUpdateProcess, key);          
        yield put({ type: SET_DATA_UPDATE_PROCESS, res: res });
    } catch (e) {

    }
}

export function* dataUpdateStop(action) {
    try {
        const service = yunshui.UploadRecordService;;
        const key = action.searchKey;

        if (service && service.dataUpdateStop) {
            const res = yield call(service.dataUpdateStop, key);          
            yield put({ type: SET_DATA_UPDATE_STOP, res: res });
        } 
    } catch (e) {

    }
}
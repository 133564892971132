/**
 * version  0.1
 * date     2019/6/19 10:06
 * author   caojiaxu
 * desc
 */
import alarm from "../../service/API/alarm";


import {put, select, takeLatest, call} from 'redux-saga/effects';
import {getEventAlert} from "../../config/SDNSelector";
import archive from "../../service/API/archive";
import {FETCH_FAILED, FETCH_REQUEST, FETCH_SUCCESS} from "../../config/SDNActionType";
import {
  ALERT_REMOVED,
  CONFIRM_ALERT,
  CONFIRM_ALERT_SUCCESS,
  EDIT_EVENT_CONFIG_FIELD,
  EDIT_EVENT_CONFIG_FIELD_SUCCESS, EDIT_GRAFANA_URL,
  GET_ALERT_BY_ID,
  GET_ALERT_COUNT,
  GET_CAMERA_ITEM,
  GET_CONTROL_ITEM,
  GET_DATA_ITEM,
  GET_DEVICE_TREE,
  GET_EVENT_CONFIG_BY_ID, GET_GRAFANA_URL,
  GET_GROUP_LIST,
  GET_LINKAGE_CONFIG, GET_MONITOR_BY_SITE,
  GET_MONITOR_ITEMS_BY_ID, GET_SECOND_BY_SITE,
  GET_SITE_LIST,
  GET_SITE_LIST_BY_ID, GRAFANA_REMOVED,
  LINK_REMOVED,
  REMOVE_ALERT_BY_ID, REMOVE_GRAFANA_ALERT_BY_ID,
  REMOVE_LINK_BY_ID,
  SEL_GROUP_ITEM, SEL_SITE_ITEM,
  SET_ALERT_BY_ID,
  SET_ALERT_COUNT,
  SET_CAMERA_ITEM,
  SET_CONTROL_ITEM,
  SET_DATA_ITEM,
  SET_DEVICE_TREE,
  SET_EVENT_CONFIG_BY_ID, SET_GRAFANA_URL,
  SET_GROUP_LIST,
  SET_LINKAGE_CONFIG, SET_MONITOR_BY_SITE,
  SET_MONITOR_ITEMS, SET_SECOND_BY_SITE,
  SET_SITE_LIST,
  SET_SITE_LIST_BY_ID,
  SUBMIT_ADD_ALERT,
  SUBMIT_ADD_ALERT_PARAMS,
  SUBMIT_ADD_ALERT_SUCCESS, SUBMIT_ADD_COM_ALARM_SUCCESS, SUBMIT_ADD_GRAFANA_ALERT,
  SUBMIT_ADD_LINKAGE,
  SUBMIT_ADD_LINKAGE_SUCCESS,
  SUBMIT_EDIT_ALERT,
  SUBMIT_EDIT_ALERT_PARAMS,
  SUBMIT_EDIT_ALERT_SUCCESS, SUBMIT_EDIT_COM_ALARM_SUCCESS,
  SUBMIT_EDIT_EVENT_CONFIG,
  SUBMIT_EDIT_EVENT_CONFIG_SUCCESS, SUBMIT_EDIT_GRAFANA_ALERT,
  SUBMIT_EDIT_LINKAGE,
  SUBMIT_EDIT_LINKAGE_SUCCESS
} from "./SDNEventAlertAcitonType";
import {successBox} from "../../common/utils/functions";

// --------------------告警查询 ↓-------------------- //
// 获取单个告警查询站点
export function* getSiteList(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(archive.MonitorSiteService.getSiteListByLoginInfo);
    yield put({type: SET_SITE_LIST, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 获取单个告警查询数量
export function* getAlertCount(action) {
  try {
    yield put({type: FETCH_REQUEST});

    let payload = action.payload;
    const res = yield call(alarm.RecordService.getStatistics, payload.type, payload.enterpriseId, payload.siteId);
    yield put({type: SET_ALERT_COUNT, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 确认告警
export function* confirmAlert(action) {
  try {
    yield put({type: FETCH_REQUEST});

    let payload = action.payload;
    const res = yield call(alarm.RecordService.confirm, payload.alarmRecordId, payload.confirmInfo);
    yield put({type: CONFIRM_ALERT_SUCCESS, payload: res});
    successBox('确认成功')
    yield put({type: GET_ALERT_COUNT, payload: {type: payload.type, enterpriseId: payload.enterpriseId, siteId: payload.siteId}});

    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// --------------------告警查询 ↑-------------------- //

export function* getGroupList() {
  try {
    yield put({type: FETCH_REQUEST});
    let res = yield call(archive.ConsumerService.getGroupList);
    if (res != null) {

      if (res.length > 0) {
        for (let i in res) {
          let item = res[i];
          item.key = item.id
          item.label = item.text
          item.value = item.text
          if (item.children != null) {

            for (let j in item.children) {
              let child = item.children[j];
              child.key = child.id;
              child.label = child.text;
              child.value = child.text;
            }
          }
        }

        yield put({type: SEL_GROUP_ITEM, payload: res[0]});
        //
        yield put({type: GET_SITE_LIST_BY_ID, payload: res[0].id});

      }

    }
    yield put({type: SET_GROUP_LIST, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* getAlertSiteListById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    // console.log("getAlertSiteListById", action.payload);
    //
    const res = yield call(archive.MonitorSiteService.getSiteListByEnterpriseId, action.payload);
    if (res != null) {
      // console.log(res)
      for (let i in res) {
        let item = res[i];
        item.key = item.siteId;
        item.value = item.siteId;
        item.label = item.siteName;
      }
    }
    if (res != null && res.length > 0) {
      //
      yield put({type: SEL_SITE_ITEM, payload: res[0]});
      yield put({type: GET_DEVICE_TREE, payload: res[0].siteId});
    } else {
      yield put({type: SEL_SITE_ITEM, payload: {}});
      yield put({type: GET_DEVICE_TREE, payload: ""});
    }
    yield put({type: SET_SITE_LIST_BY_ID, payload: res});

    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* getDeviceTree(action) {
  try {//
    yield put({type: FETCH_REQUEST});
    const res = yield call(archive.MonitorObjectService.getSecondaryDeviceTree, action.payload);
    let red = [{}];
    const getStr = function (list) {
      list.forEach(function (row) {
        if (row.children) {
          row.key = row.id;
          row.title = row.name;
          getStr(row.children)
        } else {
          row.key = row.id;
          row.title = row.name;
        }
      })
      return
    }
    if (res != null) {
      red[0].key = res.id;
      red[0].title = res.name;
      getStr(res.children);
      red[0].children = res.children
    }
    yield put({type: SET_DEVICE_TREE, payload: red});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: SET_DEVICE_TREE, payload: []});
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

const processMenuTree = menu => {
  const list = menu.children.map((item) => {
    let subMenu = {
      key: item.id,
      title: item.name,
      classifiedType: item.classifiedType,
      content: "<span><i class=\"fa fa-lg fa-calendar\"></i>" + item.name + "</span>",
      expanded: true
    };
    if (item.children && item.children.length > 0) {
      subMenu.children = processMenuTree(item);
    }
    // console.log("tree==========", subMenu)
    return subMenu
  });
  return list;
}

// --------------------告警联动 ↓-------------------- //
// 获取单个告警事件配置
export function* getEventConfigById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(alarm.EventConfigService.get, action.payload);
    yield put({type: SET_EVENT_CONFIG_BY_ID, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 编辑单个告警事件配置Field
export function* editEventConfigField(action) {
  const eventAlert = yield select(getEventAlert);
  const {eventConfig} = eventAlert;
  try {

    yield put({type: FETCH_REQUEST});
    let payload = action.payload;
    const ret = yield call(alarm.EventConfigService.editField, payload.id, payload.fieldName, payload.fieldValue, "");
    yield put({type: EDIT_EVENT_CONFIG_FIELD_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 编辑单个告警事件配置
export function* submitEventConfig(action) {
  const eventAlert = yield select(getEventAlert);
  const {eventConfig} = eventAlert;
  try {

    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.EventConfigService.edit, eventConfig);
    yield put({type: SUBMIT_EDIT_EVENT_CONFIG_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 获取监控点List
export function* getMonitorItemsById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(archive.DeviceService.getItemsOfDevice, action.payload);
    yield put({type: SET_MONITOR_ITEMS, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 获取摄像头监控点
export function* getCameraItemById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(archive.MonitorItemService.getCameraItem, action.payload);
    yield put({type: SET_CAMERA_ITEM, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 获取监控点
export function* getControlItemById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(archive.MonitorItemService.getControlItem, action);
    yield put({type: SET_CONTROL_ITEM, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// 获取联动配置
export function* getLinkageConfig(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(alarm.LinkageConfigService.get, action.payload);
    yield put({type: SET_LINKAGE_CONFIG, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitEditLinkage() {
  const eventAlert = yield select(getEventAlert);
  const {linkConfig} = eventAlert;
  try {

    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.LinkageConfigService.edit, {
      eventType: linkConfig.eventType,
      eventId: linkConfig.eventId,
      linkageId: linkConfig.linkageId,
      linkageType: linkConfig.linkageType,
      delay: linkConfig.delay,
      phones: linkConfig.phones,
      emails: linkConfig.emails,
      cameraItemId: linkConfig.cameraItemId,
      duration: linkConfig.duration,
      leadTime: linkConfig.leadTime,
      controlItemId: linkConfig.controlItemId,
      controlValue: linkConfig.controlValue
    });
    yield put({type: SUBMIT_EDIT_LINKAGE_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitAddLinkage() {
  const eventAlert = yield select(getEventAlert);
  const {linkConfig} = eventAlert;
  try {
    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.LinkageConfigService.add, {
      eventType: linkConfig.eventType,
      eventId: linkConfig.eventId,
      linkageType: linkConfig.linkageType,
      delay: linkConfig.delay,
      phones: linkConfig.phones,
      emails: linkConfig.emails,
      cameraItemId: linkConfig.cameraItemId,
      duration: linkConfig.duration,
      leadTime: linkConfig.leadTime,
      controlItemId: linkConfig.controlItemId,
      controlValue: linkConfig.controlValue
    });
    yield put({type: SUBMIT_ADD_LINKAGE_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* removeLinkById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(alarm.LinkageConfigService.del, action.payload);
    yield put({type: LINK_REMOVED});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// --------------------告警联动 ↑-------------------- //

// --------------------告警配置-综合告警 ↓-------------------- //

export function* removeGrafanaAlertById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(alarm.EventConfigGrafanaService.del, action.payload);
    yield put({type: GRAFANA_REMOVED});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitEditGrafanaAlert(action) {
  try {
    let params = action.payload;
    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.EventConfigGrafanaService.edit, {
      eventId: params.eventId,
      eventName: params.eventName,
      eventIdentifier: params.eventIdentifier,
      primaryDeviceId: params.primaryDeviceId,
      primaryDeviceName: params.primaryDeviceName,
      secondaryDeviceId: params.secondaryDeviceId,
      secondaryDeviceName: params.secondaryDeviceName,
      alarmLevel: params.alarmLevel,
      enable: params.enable,
      siteId: params.siteId,
      siteName: params.siteName,
      rem: params.rem,
    });
    if (ret.code == 200) {
      yield put({type: SUBMIT_EDIT_COM_ALARM_SUCCESS});
    }

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitAddGrafanaAlert(action) {
  try {
    let params = action.payload;
    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.EventConfigGrafanaService.add, {
      eventName: params.eventName,
      eventIdentifier: params.eventIdentifier,
      primaryDeviceId: params.primaryDeviceId.join(','),
      primaryDeviceName: params.primaryDeviceName,
      secondaryDeviceId: params.secondaryDeviceId.join(','),
      secondaryDeviceName: params.secondaryDeviceName,
      alarmLevel: params.alarmLevel,
      enable: params.enable,
      siteId: params.siteId,
      siteName: params.siteName,
      rem: params.rem,
    });
    if (ret.code==200) {
      // successBox(ret.message||'新建成功')
      yield put({type: SUBMIT_ADD_COM_ALARM_SUCCESS});
    }

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

/**
 * 获取监控点列表
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMonitorBySiteId(action) {
  try {
    yield put({type: FETCH_REQUEST});
    let res = yield call(archive.MonitorObjectService.getPrimaryBySite, action.payload);
    if (res && res.length > 0) {
      res.forEach(function (row) {
        row.key = row.id;
        row.value = row.id;
        row.label = row.name;
      });
      yield put({type: SET_MONITOR_BY_SITE, payload: res});
    } else {
      yield put({type: SET_MONITOR_BY_SITE, payload: []});
    }
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

/**
 * 获取二级设备列表
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getSecondBySiteId(action) {
  try {
    yield put({type: FETCH_REQUEST});
    let res = yield call(archive.MonitorObjectService.getSecondBySite, action.payload);
    console.log("res start===", res);
    if (res && res.length > 0) {
      res.forEach(function (row) {
        row.key = row.id;
        row.value = row.id;
        row.label = row.name;
      });
      yield put({type: SET_SECOND_BY_SITE, payload: res});
    } else {

      yield put({type: SET_SECOND_BY_SITE, payload: []});
    }
    console.log("res end===", res);
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// --------------------告警配置-综合告警 ↑-------------------- //


// --------------------组合告警 ↓-------------------- //
export function* getAlertById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(alarm.AlarmService.get, action.payload);
    yield put({type: SET_ALERT_BY_ID, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* removeAlertById(action) {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(alarm.AlarmService.del, action.payload);
    yield put({type: ALERT_REMOVED});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}


export function* submitEditAlert() {
  const eventAlert = yield select(getEventAlert);
  const {editAlert} = eventAlert;
  try {

    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.AlarmService.edit, {
      alarmlId: editAlert.alarmlId,
      alarmName: editAlert.alarmName,
      alarmType: editAlert.alarmType,
      alarmLevel: editAlert.alarmLevel,
      triggerMethod: editAlert.triggerMethod,
      triggerFieldName: editAlert.triggerFieldName,
      triggerInterval: editAlert.triggerInterval,
      algoUrl: editAlert.algoUrl,
      rem: editAlert.rem,
    });
    yield put({type: SUBMIT_EDIT_ALERT_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitAddAlert() {
  const eventAlert = yield select(getEventAlert);
  const {editAlert} = eventAlert;
  try {

    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.AlarmService.add, {
      eventName: editAlert.eventName,
      alarmType: editAlert.alarmType,
      alarmLevel: editAlert.alarmLevel,
      triggerMethod: editAlert.triggerMethod,
      triggerFieldName: editAlert.triggerFieldName,
      triggerInterval: editAlert.triggerInterval,
      algoUrl: editAlert.algoUrl,
      rem: editAlert.rem,
    });
    yield put({type: SUBMIT_ADD_ALERT_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}


// 获取数据项
export function* getDataItem() {
  try {
    yield put({type: FETCH_REQUEST});
    const res = yield call(archive.DataItemService.getAll);
    yield put({type: SET_DATA_ITEM, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitEditParam() {
  const alertParam = yield select(getEventAlert);
  try {

    yield put({type: FETCH_REQUEST});
    const ret = yield call(alarm.AlarmParamService.edit, {
      alarm: alertParam.alarm,
      ix: alertParam.ix,
      paramType: alertParam.paramType,
      dataItem: alertParam.dataItem,
      paramCode: alertParam.paramCode,
      paramName: alertParam.paramName,
      paramValue: alertParam.paramValue,
    });
    yield put({type: SUBMIT_EDIT_ALERT_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitAddParam() {
  const param = yield select(getEventAlert);
  const {alertParam} = param
  // console.log("alertParam", param)
  try {

    yield put({type: FETCH_REQUEST});
    let args = {
      'alarm.alarmlId': alertParam.alarm.alarmlId,
      ix: alertParam.ix,
      paramType: alertParam.paramType,
      'dataItem.dataItemId': alertParam.dataItem.dataItemId,
      paramCode: alertParam.paramCode,
      paramName: alertParam.paramName,
      paramValue: alertParam.paramValue,
    };
    // console.log("args", args)
    const ret = yield call(alarm.AlarmParamService.add, args);
    yield put({type: SUBMIT_EDIT_ALERT_SUCCESS});
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// --------------------组合告警 ↑-------------------- //
// --------------------实时告警 ↑-------------------- //
// 获取实时告警url
export function* getGrafanaUrl(action) {
  try {
    yield put({type: FETCH_REQUEST});
    let payload = action.payload;
    const res = yield call(archive.GrafanaAccountService.getGrafanaToken, payload.enterpriseId, payload.rebuild);
    yield put({type: SET_GRAFANA_URL, payload: res});
    yield put({type: FETCH_SUCCESS});
  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export function* submitEditGrafanaUrl(action) {
  try {

    yield put({type: FETCH_REQUEST});
    let payload = action.payload;
    const ret = yield call(archive.GrafanaAccountService.editUrl, payload.enterpriseId, payload.url);
    if (!ret.code) {
      successBox("编辑成功")
    }
    yield put({type: FETCH_SUCCESS});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

// --------------------实时告警 ↑-------------------- //
const eventAlertSagas = [
  takeLatest(SUBMIT_EDIT_ALERT, submitEditAlert),
  takeLatest(SUBMIT_ADD_ALERT, submitAddAlert),
  takeLatest(GET_ALERT_BY_ID, getAlertById),
  takeLatest(REMOVE_ALERT_BY_ID, removeAlertById),
  takeLatest(GET_DATA_ITEM, getDataItem),
  takeLatest(SUBMIT_EDIT_ALERT_PARAMS, submitEditParam),
  takeLatest(SUBMIT_ADD_ALERT_PARAMS, submitAddParam),
  takeLatest(GET_GROUP_LIST, getGroupList),
  takeLatest(GET_SITE_LIST_BY_ID, getAlertSiteListById),
  takeLatest(GET_DEVICE_TREE, getDeviceTree),
  takeLatest(REMOVE_LINK_BY_ID, removeLinkById),
  takeLatest(GET_LINKAGE_CONFIG, getLinkageConfig),
  takeLatest(GET_CAMERA_ITEM, getCameraItemById),
  takeLatest(GET_CONTROL_ITEM, getControlItemById),
  takeLatest(SUBMIT_ADD_LINKAGE, submitAddLinkage),
  takeLatest(SUBMIT_EDIT_LINKAGE, submitEditLinkage),
  takeLatest(GET_EVENT_CONFIG_BY_ID, getEventConfigById),
  takeLatest(GET_MONITOR_ITEMS_BY_ID, getMonitorItemsById),
  takeLatest(EDIT_EVENT_CONFIG_FIELD, editEventConfigField),
  takeLatest(SUBMIT_EDIT_EVENT_CONFIG, submitEventConfig),
  takeLatest(GET_SITE_LIST, getSiteList),
  takeLatest(GET_ALERT_COUNT, getAlertCount),
  takeLatest(CONFIRM_ALERT, confirmAlert),
  takeLatest(SUBMIT_EDIT_GRAFANA_ALERT, submitEditGrafanaAlert),
  takeLatest(SUBMIT_ADD_GRAFANA_ALERT, submitAddGrafanaAlert),
  takeLatest(GET_MONITOR_BY_SITE, getMonitorBySiteId),
  takeLatest(GET_SECOND_BY_SITE, getSecondBySiteId),
  takeLatest(REMOVE_GRAFANA_ALERT_BY_ID, removeGrafanaAlertById),
  takeLatest(GET_GRAFANA_URL, getGrafanaUrl),
  takeLatest(EDIT_GRAFANA_URL, submitEditGrafanaUrl),
];

export default eventAlertSagas;

import * as actionType from "./SDNSysAdminActionTypes";
import { handleActions } from 'redux-actions';
import store from "../../config/configureStore";
import { getRoute } from '../../service/SDNResource';

export default function SDNSysAdminReducer (
  state = {
    page: {},
    allMenu: [],
    menuTree: [],
    showEditMenu: false,
    showResetAccountPwd: false,
    showEditCharAdmin: false,
    showEditAccount: false,
    showEditDataDicConfig: false,
    showEditDataDictionary: false
  },
  action
) {
  switch (action.type) {
    case actionType.INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }

    case actionType.SHOW_EDIT_MENU: {
      return { ...state, showEditMenu: action.payload };
    }

    case actionType.SHOW_RESET_ACCOUNT_PWD: {
      return { ...state, showResetAccountPwd: action.payload };
    }

    case actionType.SHOW_EDIT_ROLE: {
      return { ...state, showEditCharAdmin: action.payload };
    }

    case actionType.SHOW_EDIT_ACCOUNT: {
      return { ...state, showEditAccount: action.payload };
    }

    case actionType.SHOW_EDIT_DATA_DIC_CONFIG: {
      return { ...state, showEditDataDicConfig: action.payload };
    }

    case actionType.SHOW_EDIT_DATA_DICTIONARY: {
      return { ...state, showEditDataDictionary: action.payload };
    }

    case actionType.GET_ALL_MENU_SUCCESS: {
      const tree = processMenuTree(action.payload);
      if (state.page.setDefaultTreeId) {
        state.page.setDefaultTreeId(tree && tree.length > 0 ? tree[0].key : '');
      }
      return {
        ...state,
        menuTree: tree//processMenuTree(require("../../config/navigation.json").items)
      };
    }

    case actionType.ADD_MENU_SUCCESS:
    case actionType.SUBMIT_ADD_ROLE_SUCCESS:
    case actionType.ADD_DATA_DICTIONARY_SUCCESS:
    case actionType.ADD_DATA_DIC_CONFIG_SUCCESS:
    case actionType.ADD_ACCOUNT_SUCCESS:
    {
      state.page.completion('添加成功');
      return state
    }

    case actionType.EDIT_MENU_SUCCESS:
    case actionType.SUBMIT_EDIT_ROLE_SUCCESS:
    case actionType.EDIT_DATA_DICTIONARY_SUCCESS:
    case actionType.EDIT_DATA_DIC_CONFIG_SUCCESS:
    case actionType.EDIT_ACCOUNT_SUCCESS:
    {
      state.page.completion('修改成功');
      return state
    }
    case actionType.REMOVE_MENU_SUCCESS:
    case actionType.REMOVE_DATA_DICTIONARY_SUCCESS:
    case actionType.REMOVE_DATA_DIC_CONFIG_SUCCESS:
    case actionType.ROLE_REMOVED:
    case actionType.REMOVE_ACCOUNT_SUCCESS:
    {
      state.page.completion('删除成功');
      return state;
    }

    case actionType.SET_ROLE_WITH_FUN: {
      state.page.setFunIdList(action.payload.funIdList);
      return state;
    }

    case actionType.GET_ACCOUNT_SUCCESS: {
      state.page.setAccount(action.payload);
      return state;
    }

    case actionType.RESET_ACCOUNT_PWD_SUCCESS: {
      state.page.completion('修改密码成功');
      return state;
    }

    case actionType.GET_MENU_SUCCESS: {
      if (state.page.setMenu) {
        state.page.setMenu(action.payload);
      }
      return state;
    }

    default:
      return state;
  }
}

const processMenuTree = menu => {
  const children = menu.map((item, index) => {
    let subMenu = { key: item.id, title: item.data.funName, checked: false }
    if (item.children) {
      subMenu.children = processMenuTree(item.children);
    }
    // console.log(subMenu);
    return subMenu;
  });

  return children;
}

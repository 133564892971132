import { yunshui } from "../../service";

export const items = [
    '企业注册信息',
    '用能单位联系人信息',
    '用能单位能源管理体系信息',
    '用能单位能源管理人员信息',
    '用能单位能耗监测项目负责人信息',
    '用能单位能源计量人员配置信息',
    '用能单位企业认证信息',
    '用能单位产品结构信息',
    '用能单位产值、增加值、销售收入信息',
    '用能单位节能项目情况信息',
    '用能单位水、电、燃气户号信息',
    '用能单位非能源产品信息',
    '用能单位生产工序信息',
    '用能单位工序单元信息',
    '用能单位重点耗能设备信息',
    '用能单位数据采集配置项信息',
    '用能单位计量器具信息',
    '用能单位计量器具更换记录',
    '用能单位计量器具检定校准记录',

    '单位基本情况和组织机构设置框图',
    '能源计量工作自查报告',
    '能源计量管理制度',
    '相关审查报告',
    '能源计量人员一览表',
    '主要用能设备一览表',
    '能源计量器具一览表',
    '进出用能单位能源计量器具一览表分表',
    '进出主要次级用能单位能源计量器具一览表分表',
    '主要用能设备能源计量器具一览表分表',
    '其他能源计量器具一览表分表',
    '年度能源购进、消费与库存情况表',
    '能源流向图',
    '能源计量器具配备及计量采集点网络图',
    '能源计量器具自行检定装置量值传递/溯源框图',
    '能源计量器具量值传递/溯源框图',
    '获取审查计划',
    '获取审查结果',
];

const itemIds = [
    'contacterId',
    'contacterId',
    'energySysId',
    'energyManagerId',
    'energyMonitorId',
    'calculaterId',
    'energyCertificationId',
    'productStructureId',
    'outputValueId',
    'energyConservationId',
    'energyAccountId',
    'materielProductId',
    'processConfigureId',
    'unitConfigureId',
    'deviceConfigureId',
    'dataCollectConfigureId',
    'gaugeConfigureId',
    'gaugeReplaceConfigureId',
    'gaugeVerifyConfigureId',
    'checkFileId',
    'checkFileId',
    'checkFileId',
    'checkFileId',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'checkFileId',
    'checkFileId',
    'checkFileId',
    'checkFileId',
];

export const getItemIdKey = (index) => {
    if (index > 0 && index < itemIds.length) {
        return itemIds[index];
    }
    return '';
}

export const getTreeDataChildren = (start, end) => {
    let children = [];
    for (let index = start; index <= end; index++) {
        if (index < items.length) {
            children.push({ key: index, title: items[index] });
        }
    }
    return children;
}

export const treeDatas = [
    {
        key: -1,
        title: '基础数据上传信息',
        children: getTreeDataChildren(0, 11)
    },
    {
        key: -2,
        title: '采集配置数据上传信息',
        children: getTreeDataChildren(12, 18)
    }
];

export const checkTreeDatas = [
    {
        key: -11,
        title: '组织单位信息',
        children: getTreeDataChildren(19, 22)
    },
    {
        key: -12,
        title: '计量相关信息',
        children: getTreeDataChildren(23, 30)
    },
    {
        key: -13,
        title: '能源图信息',
        children: getTreeDataChildren(31, 34)
    },
    {
        key: -14,
        title: '审查',
        children: getTreeDataChildren(35, 36)
    }
];

export const getTemplateName = (index) => {
    if (index >= 0 && index < items.length) {
        return items[index];
    }
    return '';
}

export const getdatatable = (index) => {
    switch (index) {
        case "0": //企业注册信息
            return yunshui.EnterpriseInfoService;
        case "1": //用能单位联系人信息
            return '/api/yunshui/contacter/getdatatable';
        case "2": //用能单位能源管理体系信息
            return '/api/yunshui/energySys/getdatatable';
        case "3": //用能单位能源管理人员信息
            return '/api/yunshui/energyManager/getdatatable';
        case "4": //用能单位能耗监测项目负责人信息
            return '/api/yunshui/energyMonitor/getdatatable';
        case "5": //用能单位能源计量人员配置信息
            return '/api/yunshui/calculater/getdatatable';
        case "6": //用能单位企业认证信息
            return '/api/yunshui/energyCertification/getdatatable';
        case "7": //用能单位产品结构信息
            return '/api/yunshui/productStructure/getdatatable';
        case "8": //用能单位产值、增加值、销售收入信息
            return '/api/yunshui/outputValue/getdatatable';
        case "9": //用能单位节能项目情况信息
            return '/api/yunshui/energyConservation/getdatatable';
        case "10": //用能单位水、电、燃气户号信息
            return '/api/yunshui/energyAccount/getdatatable';
        case "11": //用能单位非能源产品信息
            return '/api/yunshui/materielProduct/getdatatable';
        case "12": //用能单位生产工序信息
            return '/api/yunshui/processConfigure/getdatatable';
        case "13": //用能单位工序单元信息
            return '/api/yunshui/unitConfigure/getdatatable';
        case "14": //用能单位重点耗能设备信息
            return '/api/yunshui/deviceConfigure/getdatatable';
        case "15": //用能单位数据采集配置项信息
            return '/api/yunshui/dataCollectConfigure/getdatatable';
        case "16": //用能单位计量器具信息
            return '/api/yunshui/gaugeConfigure/getdatatable';
        case "17": //用能单位计量器具更换记录
            return '/api/yunshui/gaugeReplaceConfigure/getdatatable';
        case "18": //用能单位计量器具检定校准记录
            return '/api/yunshui/gaugeVerifyConfigure/getdatatable';
        case "19": //单位基本情况和组织机构设置框图
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "20": //能源计量工作自查报告
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "21": //能源计量管理制度
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "22": //相关审查报告
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "23": //能源计量人员一览表
            return '/api/yunshui/companyCalculater/getdatatable';
        case "24": //主要用能设备一览表
            return '/api/yunshui/companyDev/getdatatable';
        case "25": //能源计量器具一览表
            return '/api/yunshui/companyGauges/getdatatable';
        case "26": //进出用能单位能源计量器具一览表分表
            return '/api/yunshui/companyGauges/getdatatable';
        case "27": //进出主要次级用能单位能源计量器具一览表分表
            return '/api/yunshui/companyGauges/getdatatable';
        case "28": //主要用能设备能源计量器具一览表分表
            return '/api/yunshui/companyGauges/getdatatable';
        case "29": //其他能源计量器具一览表分表
            return '/api/yunshui/companyGauges/getdatatable';
        case "30": //年度能源购进、消费与库存情况表
            return '/api/yunshui/energyAccount/getdatatable';
        case "31": //能源流向图
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "32": //能源计量器具配备及计量采集点网络图
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "33": //能源计量器具自行检定装置量值传递/溯源框图
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "34": //能源计量器具量值传递/溯源框图
            return '/api/yunshui/checkplanCheckfile/getdatatable';
        case "35": //获取审查计划
            return '/api/yunshui/contacter/getdatatable';
        case "36": //获取审查结果
            return '/api/yunshui/contacter/getdatatable';
    }
    return null;
}

export const getService = (index) => {
    switch (index) {
        case "0": //企业注册信息
            return yunshui.EnterpriseInfoService;
        case "1": //用能单位联系人信息
            return yunshui.ContacterService;
        case "2": //用能单位能源管理体系信息
            return yunshui.EnergySysService;
        case "3": //用能单位能源管理人员信息
            return yunshui.EnergyManagerService;
        case "4": //用能单位能耗监测项目负责人信息
            return yunshui.EnergyMonitorService;
        case "5": //用能单位能源计量人员配置信息
            return yunshui.CalculaterService;
        case "6": //用能单位企业认证信息
            return yunshui.EnergyCertificationService;
        case "7": //用能单位产品结构信息
            return yunshui.ProductStructureService;
        case "8": //用能单位产值、增加值、销售收入信息
            return yunshui.OutputValueService;
        case "9": //用能单位节能项目情况信息
            return yunshui.EnergyConservationService;
        case "10": //用能单位水、电、燃气户号信息
            return yunshui.EnergyAccountService;
        case "11": //用能单位非能源产品信息
            return yunshui.MaterielProductService;
        case "12": //用能单位生产工序信息
            return yunshui.ProcessConfigureService;
        case "13": //用能单位工序单元信息
            return yunshui.UnitConfigureService;
        case "14": //用能单位重点耗能设备信息
            return yunshui.DeviceConfigureService;
        case "15": //用能单位数据采集配置项信息
            return yunshui.DataCollectConfigureService;
        case "16": //用能单位计量器具信息
            return yunshui.GaugeConfigureService;
        case "17": //用能单位计量器具更换记录
            return yunshui.GaugeReplaceConfigureService;
        case "18": //用能单位计量器具检定校准记录
            return yunshui.GaugeVerifyConfigureService;
        case "19": //单位基本情况和组织机构设置框图
            return yunshui.CheckplanCheckfileService;
        case "20": //能源计量工作自查报告
            return yunshui.CheckplanCheckfileService;
        case "21": //能源计量管理制度
            return yunshui.CheckplanCheckfileService;
        case "22": //相关审查报告
            return yunshui.CheckplanCheckfileService;
        case "23": //能源计量人员一览表
            return yunshui.CheckplanCheckfileService;
        case "24": //主要用能设备一览表
            return yunshui.CheckplanCheckfileService;
        case "25": //能源计量器具一览表
            return yunshui.CheckplanCheckfileService;
        case "26": //进出用能单位能源计量器具一览表分表
            return yunshui.CheckplanCheckfileService;
        case "27": //进出主要次级用能单位能源计量器具一览表分表
            return yunshui.CheckplanCheckfileService;
        case "28": //主要用能设备能源计量器具一览表分表
            return yunshui.CheckplanCheckfileService;
        case "29": //其他能源计量器具一览表分表
            return yunshui.CheckplanCheckfileService;
        case "30": //年度能源购进、消费与库存情况表
            return yunshui.CheckplanCheckfileService;
        case "31": //能源流向图
            return yunshui.CheckplanCheckfileService;
        case "32": //能源计量器具配备及计量采集点网络图
            return yunshui.CheckplanCheckfileService;
        case "33": //能源计量器具自行检定装置量值传递/溯源框图
            return yunshui.CheckplanCheckfileService;
        case "34": //能源计量器具量值传递/溯源框图
            return yunshui.CheckplanCheckfileService;
    }
    return null;
}

export const getTableColumns = (getDataUploadType) => [
    [//9.5.1.1 用能单位联系人信息
        { title: '姓名', data: 'contacterName' },
        { title: '电话', data: 'phone', },
        { title: '所在部门', data: 'dept', },
        { title: '职位、或者岗位', data: 'job', },
        { title: '传真', data: 'fax', },
        { title: '邮件', data: 'email', },
        { title: '备注', data: 'remark' },
    ],
    [//9.5.1.2 用能单位能源管理体系信息
        { title: '能源管理机构', data: 'energyOffice', },
        { title: '主管节能领导姓名', data: 'energyOfficial', },
        { title: '主管节能领导职位', data: 'energyOfficialPosition', },
        { title: '电话', data: 'phone', },
        { title: '传真', data: 'fax', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.3 用能单位能源管理人员信息
        { title: '姓名 ', data: 'manager', },
        { title: '电话', data: 'phone', },
        { title: '部门名称', data: 'dept', },
        { title: '职位、或者岗位', data: 'job', },
        { title: '培训或者取证情况描述', data: 'trainInfo', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.4 用能单位能耗监测项目负责人信息
        { title: '能耗监测项目负责人姓名 ', data: 'monitor', },
        { title: '能耗监测项目负责人电话', data: 'phone', },
        { title: '能耗监测项目负责人所属部门', data: 'dept', },
        { title: '能耗监测项目负责人职位', data: 'job', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.5 用能单位能源计量人员配置信息
        { title: '姓名 ', data: 'calculaterName', },
        { title: '电话', data: 'phone', },
        { title: '工作部门', data: 'dept', },
        { title: '职位、或者岗位', data: 'job', },
        { title: '专业技术职称', data: 'technicalPost', },
        { title: '专业技术证书', data: 'certificate', },//图片
        { title: '名称', data: 'certificateName', },
        { title: '编号', data: 'certificateNo', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.6 用能单位企业认证信息
        {
            title: '是否通过能源管理体系认证 ', data: 'energyPass', render: (record, type, full) => {
                if (record !== undefined) {
                    if (record == 1) {
                        return '通过';
                    } else if (record == 0) {
                        return '暂未通过';
                    }
                    return record;
                }
                
                return '';
            }
        },
        { title: '企业认证负责人姓名 ', data: 'director', },
        { title: '企业认证负责人电话', data: 'phone', },
        { title: '认证日期', data: 'passDate', searchable: false },
        { title: '认证机构', data: 'passOrg', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.7 用能单位产品结构信息
        { title: '产品名称 ', data: 'productName', },
        { title: '产品编号', data: 'productCode', },
        { title: '产品类型', data: 'productType', },
        { title: '描述', data: 'remark', },
        { title: '计量单位', data: 'productUnit', },
    ],
    [//9.5.1.8 用能单位产值、增加值、销售收入信息
        { title: '计入年度 ', data: 'countYear', },
        { title: '年度增加值(万)', data: 'outputValue', },
        { title: '年度产值(万)', data: 'addedValue', },
        { title: '年度销售收入(万)', data: 'salesIncome', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.9 用能单位节能项目情况信息
        { title: '项目类型', data: 'projectType', },
        { title: '项目名称', data: 'projectName', },
        { title: '改造措施', data: 'improveMeasure', },
        { title: '投资金额(万)', data: 'investmentAmount', },
        { title: '时间安排', data: 'projectTimeline', searchable: false },
        { title: '预期节能量(tce/年)', data: 'energySavingAmount', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.10 用能单位水、电、燃气户号信息
        {
            title: '账户类型', data: 'accountType', render: (record, type, full) => {
                if (record !== undefined) {
                    if (record == 1) {
                        return '水';
                    } else if (record == 2) {
                        return '电';
                    } else if (record == 3) {
                        return '燃';
                    }
                    return record;
                }
                
                return '';
            }
        },
        { title: '账号', data: 'accountNo', },
        { title: '开户名称', data: 'accountName', },
        { title: '供能机构名称', data: 'provider', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.1.11 用能单位非能源产品信息
        { title: '产品名称', data: 'produceName', },
        { title: '产品编码', data: 'produceNo', },
        {
            title: '产品分类', data: 'produceType', render: (record, type, full) => {
                if (record !== undefined) {
                    if (record == 'P') {
                        return '主产品';
                    } else if (record == 'M') {
                        return '中间产品';
                    } else if (record == 'S') {
                        return '原料/物料';
                    }
                    return record;
                }
                
                return '';
            }
        },
        { title: '计量单位', data: 'produceUnit', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.2.1 用能单位生产工序信息
        { title: '生产工序编码', data: 'processCode', },
        { title: '生产工序名称', data: 'processName', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.2.2 用能单位工序单元信息
        { title: '工序单元编码', data: 'unitCode', },
        { title: '工序单元名称', data: 'unitName', },
        { title: '工序编码', data: 'processCode', },
        { title: '投产日期', data: 'commDate', searchable: false },
        { title: '生产能力', data: 'designedCapacity', },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.2.3 用能单位重点耗能设备信息
        { title: '设备名称', data: 'deviceName', },
        { title: '设备编号', data: 'deviceNo', },
        { title: '规格型号', data: 'model', },
        { title: '安装地点', data: 'location', },
        { title: '所属部门', data: 'dept', },
        {
            title: '设备分类', data: 'deviceType', render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见设备分类编码表  
                    return getDataUploadType(ItemIndex.DEVICE_TYPE_CODES.key, record);
                }
                
                return '';
            }
        },
        { title: '启用日期', data: 'usingDate', searchable: false },
        { title: '运行情况', data: 'currentState', },
        {
            title: '能源种类编号', data: 'energyCode', visible: false, render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见能源分类编码表,多种能源用’,’分割,如 “3300,3310”
                    const datas = record.split(',');
                    let dataArray = [];
                    datas.map((item) => {
                        const dataUploadType = getDataUploadType(ItemIndex.ENERGY_TYPE_UNIT_COLLECT_CODES.key, item);
                        if (dataUploadType) {
                            dataArray.push(dataUploadType);
                        }              
                    });
                    return dataArray.join(',');
                }
                
                return '';
            }
        },
        { title: '能源消耗量或功率', data: 'energyValue', visible: false, },
        { title: '工序编码+工序单元编码', data: 'processUnitCode', visible: false, },
        { title: '备注', data: 'remark', visible: false, },
    ],
    [//9.5.2.4 用能单位数据采集配置项信息
        { title: '采集数据项指标名称', data: 'collectItemName', },
        { title: '采集数据项编码', data: 'dataCode', },
        { title: '生产工序', data: 'processCode', },
        { title: '工序单元', data: 'processUnitCode', },
        {
            title: '重点耗能设备类型', data: 'equipmentCode', render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见设备分类编码表
                    return getDataUploadType(ItemIndex.DEVICE_TYPE_CODES.key, record);
                }
                
                return '';
            }
        },
        { title: '重点耗能设备编号', data: 'equipmentUnitCode' },
        {
            title: '采集对象类型', data: 'energyClassCode', render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见采集数据类型编码表
                    return getDataUploadType(ItemIndex.DATA_COLLECTION_TYPE_CODES.key, record);
                }
                
                return '';
            }
        },
        {
            title: '能源分类 + 分项', data: 'energyTypeCode', render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见平台基础数据表
                    const datas = record.split(',');
                    let dataArray = [];
                    datas.map((item) => {
                        const dataUploadType = getDataUploadType(ItemIndex.ENERGY_TYPE_UNIT_COLLECT_CODES.key, item);
                        if (dataUploadType) {
                            dataArray.push(dataUploadType);
                        }              
                    });
                    return dataArray.join(',');
                }
                
                return '';
            }
        },
        {
            title: '用途编码', data: 'dataUsageCode', visible: false, render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见能源用途编码表
                    return getDataUploadType(ItemIndex.ENERGY_USE_CODES.key, record);
                }
                
                return '';
            }
        },
        {
            title: '数据采集来源', data: 'inputType', visible: false, render: (record, type, full) => {
                if (record !== undefined) {
                    return getDataUploadType(ItemIndex.DATA_COLLECTION_ORIGIN_CODES.key, record);
                }
                
                return '';
            }
        },
        { title: '数据最大值', data: 'dataValueMax', visible: false, },
        { title: '数据最小值', data: 'dataValueMin', visible: false, },
        {
            title: '采集频率', data: 'statType', visible: false, render: (record, type, full) => {
                if (record !== undefined) {
                    if (record == 0) {
                        return '实时';
                    } else if (record == 1) {
                        return '日';
                    } else if (record == 2) {
                        return '月';
                    } else if (record == 3) {
                        return '年';
                    }
                    return record;
                }
                
                return '';
            }
        },
        { title: '采集系统名称', data: 'collectSystemName', visible: false, },
        { title: '折标系数', data: 'convertRation',  }, 
        {
            title: '范围', data: 'scope', visible: false, render: (record, type, full) => {
                if (record !== undefined) {
                    if (record == 1) {
                        return '全厂';
                    } else if (record == 2) {
                        return '生产工序';
                    } else if (record == 3) {
                        return '生产工序单元';
                    } else if (record == 4) {
                        return '重点耗能设备';
                    }
                    return record;
                }
                
                return '';
            }
        },
        { title: '备注', data: 'remark', visible: false, },
    ],
    [//9.5.2.5 用能单位计量器具信息
        { title: '计量器具名称', data: 'meterName', },
        { title: '计量器具代号', data: 'gaugeCode', },
        { title: '计量器具类型', data: 'meterType', },
        { title: '器具等级', data: 'meterLevel', render: (record, type, full) => getMeterLevel(record)},
        { title: '计量相关参数', data: 'params', visible: false},
        { title: '所属上报数据组合编码', data: 'dataCode', },
        { title: '与上报数据组合编码算术关系', data: 'reportArithmetic', visible: false,  render: record => getReportArithmetic(record)},
        { title: '与上报数据组合编码算术系数', data: 'reportRatio', visible: false },
        { title: '生产厂家', data: 'manufacturer', },
        { title: '规格型号', data: 'model', visible: false },
        { title: '精确度等级', data: 'precisionLevel', },
        { title: '测量范围', data: 'ranges', visible: false },
        { title: '管理编号', data: 'manageNo', },
        { title: '检定校准状态', data: 'alignState', visible: false, render: record => getAlignState(record)},
        { title: '检定校准周期(月)', data: 'alignCycle', visible: false, },
        { title: '最近一次检定校准时间', data: 'lastAlignDate', visible: false, searchable: false },
        { title: '下次一次检定校准时间', data: 'nextAlignDate', visible: false, searchable: false },
        { title: '检测机构', data: 'alignOrg', visible: false, },
        { title: '未检定/校准原因', data: 'unAlignReason', visible: false },
        { title: '安装地点', data: 'location', },
        { title: '安装方', data: 'installer', visible: false, render: record => getInstaller(record)},
        { title: '安装时间', data: 'installTime', visible: false, searchable: false },
        { title: '接入系统', data: 'linkSys', visible: false, render: record => getLinkSys(record)},
        { title: '当前状态', data: 'currentState', visible: false,  render: record => getCurrentState(record)},
        { title: '状态发生时间', data: 'stateChangeTime', visible: false, searchable: false },
        { title: '设备出厂序列号', data: 'serNo', visible: false },
        { title: '计量器具二维码', data: 'barCode', visible: false, },
        { title: '计量种类', data: 'paramsType', render: record => getParamsType(record)},
        { title: '备注', data: 'remark', visible: false},
    ],
    [//9.5.2.6 用能单位计量器具更换记录
        { title: '被替换计量器出产序号', data: 'replacedSerno', },
        { title: '被替换计量器管理编号', data: 'replacedManageNo', },
        { title: '被替换计量器二维码', data: 'replacedBarCode', },
        { title: '新装计量器出厂序号', data: 'installSerNo', },
        { title: '新装计量器管理编号', data: 'installManageNo', },
        { title: '新装计量器二维码', data: 'installBarCode', },
        { title: '新装计量器时间', data: 'installTime', searchable: false },
        { title: '替换说明', data: 'remark', },
    ],
    [//9.5.2.7 用能单位计量器具检定校准记录
        { title: '计量器出产序号', data: 'serNo', },
        { title: '计量器管理编号', data: 'manageNo', },
        { title: '计量器二维码', data: 'barCode', },
        { title: '校验机构', data: 'verifyOrg', },
        { title: '校验时间', data: 'verifyTime', searchable: false },
        { title: '备注', data: 'remark', },
    ],
    [//9.5.3.1 单位基本情况和组织机构设置框图
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.3.2 能源计量工作自查报告
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.3.3 能源计量管理制度
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.3.4 相关审查报告
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.4.1 能源计量人员一览表
        { title: '姓名', data: 'calculaterName' },
        { title: '所在部门', data: 'dept', },
        { title: '职责', data: 'job', },
        { title: '专业技术职务', data: 'technicalPost', },
        { title: '是否参加岗位培训考试', data: 'certificateNo',render: record => record?"是":"否"},
        { title: '岗位资格证号', data: 'certificateNo', },
        { title: '任职证明文件', data: 'certificate', },
        { title: '备注', data: 'remark' },
    ],
    [//9.5.4.2 主要用能设备一览表
        { title: '设备名称', data: 'deviceName', },
        { title: '设备编号', data: 'deviceNo', },
        { title: '规格型号', data: 'model', },
        { title: '安装地点', data: 'location', },
        { title: '所属部门', data: 'dept', },
        {
            title: '设备分类', data: 'deviceType', render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见设备分类编码表  
                    return getDataUploadType(ItemIndex.DEVICE_TYPE_CODES.key, record);
                }
                
                return '';
            }
        },
        { title: '启用日期', data: 'usingDate', searchable: false },
        { title: '运行情况', data: 'currentState', },
        {
            title: '能源种类编号', data: 'energyCode', visible: false, render: (record, type, full) => {
                if (record !== undefined) {
                    //取值参见能源分类编码表,多种能源用’,’分割,如 “3300,3310”
                    const datas = record.split(',');
                    let dataArray = [];
                    datas.map((item) => {
                        const dataUploadType = getDataUploadType(ItemIndex.ENERGY_TYPE_UNIT_COLLECT_CODES.key, item);
                        if (dataUploadType) {
                            dataArray.push(dataUploadType);
                        }              
                    });
                    return dataArray.join(',');
                }
                
                return '';
            }
        },
        { title: '能源消耗量或功率', data: 'energyValue', visible: false, },
        { title: '工序编码+工序单元编码', data: 'processUnitCode', visible: false, },
        { title: '备注', data: 'remark', visible: false, },
    ],
    [//9.5.4.3 能源计量器具一览表
        { title: '计量器具名称', data: 'meterName', },
        { title: '计量器具代号', data: 'gaugeCode', },
        { title: '计量器具类型', data: 'meterType', },
        { title: '器具等级', data: 'meterLevel', render: (record, type, full) => getMeterLevel(record)},
        { title: '计量相关参数', data: 'params', visible: false},
        { title: '所属上报数据组合编码', data: 'dataCode', },
        { title: '与上报数据组合编码算术关系', data: 'reportArithmetic', visible: false,  render: record => getReportArithmetic(record)},
        { title: '与上报数据组合编码算术系数', data: 'reportRatio', visible: false },
        { title: '生产厂家', data: 'manufacturer', },
        { title: '规格型号', data: 'model', visible: false },
        { title: '精确度等级', data: 'precisionLevel', },
        { title: '测量范围', data: 'ranges', visible: false },
        { title: '管理编号', data: 'manageNo', },
        { title: '检定校准状态', data: 'alignState', visible: false, render: record => getAlignState(record)},
        { title: '检定校准周期(月)', data: 'alignCycle', visible: false, },
        { title: '最近一次检定校准时间', data: 'lastAlignDate', visible: false, searchable: false },
        { title: '下次一次检定校准时间', data: 'nextAlignDate', visible: false, searchable: false },
        { title: '检测机构', data: 'alignOrg', visible: false, },
        { title: '未检定/校准原因', data: 'unAlignReason', visible: false },
        { title: '安装地点', data: 'location', },
        { title: '安装方', data: 'installer', visible: false, render: record => getInstaller(record)},
        { title: '安装时间', data: 'installTime', visible: false, searchable: false },
        { title: '接入系统', data: 'linkSys', visible: false, render: record => getLinkSys(record)},
        { title: '当前状态', data: 'currentState', visible: false,  render: record => getCurrentState(record)},
        { title: '状态发生时间', data: 'stateChangeTime', visible: false, searchable: false },
        { title: '设备出厂序列号', data: 'serNo', visible: false },
        { title: '计量器具二维码', data: 'barCode', visible: false, },
        { title: '计量种类', data: 'paramsType', render: record => getParamsType(record)},
        { title: '备注', data: 'remark', visible: false},
    ],
    [//9.5.4.4 进出用能单位能源计量器具一览表分表
        { title: '计量器具名称', data: 'meterName', },
        { title: '计量器具代号', data: 'gaugeCode', },
        { title: '计量器具类型', data: 'meterType', },
        { title: '器具等级', data: 'meterLevel', render: (record, type, full) => getMeterLevel(record)},
        { title: '计量相关参数', data: 'params', visible: false},
        { title: '所属上报数据组合编码', data: 'dataCode', },
        { title: '与上报数据组合编码算术关系', data: 'reportArithmetic', visible: false,  render: record => getReportArithmetic(record)},
        { title: '与上报数据组合编码算术系数', data: 'reportRatio', visible: false },
        { title: '生产厂家', data: 'manufacturer', },
        { title: '规格型号', data: 'model', visible: false },
        { title: '精确度等级', data: 'precisionLevel', },
        { title: '测量范围', data: 'ranges', visible: false },
        { title: '管理编号', data: 'manageNo', },
        { title: '检定校准状态', data: 'alignState', visible: false, render: record => getAlignState(record)},
        { title: '检定校准周期(月)', data: 'alignCycle', visible: false, },
        { title: '最近一次检定校准时间', data: 'lastAlignDate', visible: false, searchable: false },
        { title: '下次一次检定校准时间', data: 'nextAlignDate', visible: false, searchable: false },
        { title: '检测机构', data: 'alignOrg', visible: false, },
        { title: '未检定/校准原因', data: 'unAlignReason', visible: false },
        { title: '安装地点', data: 'location', },
        { title: '安装方', data: 'installer', visible: false, render: record => getInstaller(record)},
        { title: '安装时间', data: 'installTime', visible: false, searchable: false },
        { title: '接入系统', data: 'linkSys', visible: false, render: record => getLinkSys(record)},
        { title: '当前状态', data: 'currentState', visible: false,  render: record => getCurrentState(record)},
        { title: '状态发生时间', data: 'stateChangeTime', visible: false, searchable: false },
        { title: '设备出厂序列号', data: 'serNo', visible: false },
        { title: '计量器具二维码', data: 'barCode', visible: false, },
        { title: '计量种类', data: 'paramsType', render: record => getParamsType(record)},
        { title: '备注', data: 'remark', visible: false},
    ],
    [//9.5.4.5 进出主要次级用能单位能源计量器具一览表分表
        { title: '计量器具名称', data: 'meterName', },
        { title: '计量器具代号', data: 'gaugeCode', },
        { title: '计量器具类型', data: 'meterType', },
        { title: '器具等级', data: 'meterLevel', render: (record, type, full) => getMeterLevel(record)},
        { title: '计量相关参数', data: 'params', visible: false},
        { title: '所属上报数据组合编码', data: 'dataCode', },
        { title: '与上报数据组合编码算术关系', data: 'reportArithmetic', visible: false,  render: record => getReportArithmetic(record)},
        { title: '与上报数据组合编码算术系数', data: 'reportRatio', visible: false },
        { title: '生产厂家', data: 'manufacturer', },
        { title: '规格型号', data: 'model', visible: false },
        { title: '精确度等级', data: 'precisionLevel', },
        { title: '测量范围', data: 'ranges', visible: false },
        { title: '管理编号', data: 'manageNo', },
        { title: '检定校准状态', data: 'alignState', visible: false, render: record => getAlignState(record)},
        { title: '检定校准周期(月)', data: 'alignCycle', visible: false, },
        { title: '最近一次检定校准时间', data: 'lastAlignDate', visible: false, searchable: false },
        { title: '下次一次检定校准时间', data: 'nextAlignDate', visible: false, searchable: false },
        { title: '检测机构', data: 'alignOrg', visible: false, },
        { title: '未检定/校准原因', data: 'unAlignReason', visible: false },
        { title: '安装地点', data: 'location', },
        { title: '安装方', data: 'installer', visible: false, render: record => getInstaller(record)},
        { title: '安装时间', data: 'installTime', visible: false, searchable: false },
        { title: '接入系统', data: 'linkSys', visible: false, render: record => getLinkSys(record)},
        { title: '当前状态', data: 'currentState', visible: false,  render: record => getCurrentState(record)},
        { title: '状态发生时间', data: 'stateChangeTime', visible: false, searchable: false },
        { title: '设备出厂序列号', data: 'serNo', visible: false },
        { title: '计量器具二维码', data: 'barCode', visible: false, },
        { title: '计量种类', data: 'paramsType', render: record => getParamsType(record)},
        { title: '备注', data: 'remark', visible: false},
    ],
    [//9.5.4.6 主要用能设备能源计量器具一览表分表
        { title: '计量器具名称', data: 'meterName', },
        { title: '计量器具代号', data: 'gaugeCode', },
        { title: '计量器具类型', data: 'meterType', },
        { title: '器具等级', data: 'meterLevel', render: (record, type, full) => getMeterLevel(record)},
        { title: '计量相关参数', data: 'params', visible: false},
        { title: '所属上报数据组合编码', data: 'dataCode', },
        { title: '与上报数据组合编码算术关系', data: 'reportArithmetic', visible: false,  render: record => getReportArithmetic(record)},
        { title: '与上报数据组合编码算术系数', data: 'reportRatio', visible: false },
        { title: '生产厂家', data: 'manufacturer', },
        { title: '规格型号', data: 'model', visible: false },
        { title: '精确度等级', data: 'precisionLevel', },
        { title: '测量范围', data: 'ranges', visible: false },
        { title: '管理编号', data: 'manageNo', },
        { title: '检定校准状态', data: 'alignState', visible: false, render: record => getAlignState(record)},
        { title: '检定校准周期(月)', data: 'alignCycle', visible: false, },
        { title: '最近一次检定校准时间', data: 'lastAlignDate', visible: false, searchable: false },
        { title: '下次一次检定校准时间', data: 'nextAlignDate', visible: false, searchable: false },
        { title: '检测机构', data: 'alignOrg', visible: false, },
        { title: '未检定/校准原因', data: 'unAlignReason', visible: false },
        { title: '安装地点', data: 'location', },
        { title: '安装方', data: 'installer', visible: false, render: record => getInstaller(record)},
        { title: '安装时间', data: 'installTime', visible: false, searchable: false },
        { title: '接入系统', data: 'linkSys', visible: false, render: record => getLinkSys(record)},
        { title: '当前状态', data: 'currentState', visible: false,  render: record => getCurrentState(record)},
        { title: '状态发生时间', data: 'stateChangeTime', visible: false, searchable: false },
        { title: '设备出厂序列号', data: 'serNo', visible: false },
        { title: '计量器具二维码', data: 'barCode', visible: false, },
        { title: '计量种类', data: 'paramsType', render: record => getParamsType(record)},
        { title: '备注', data: 'remark', visible: false},
    ],
    [//9.5.4.7 其他能源计量器具一览表分表
        { title: '计量器具名称', data: 'meterName', },
        { title: '计量器具代号', data: 'gaugeCode', },
        { title: '计量器具类型', data: 'meterType', },
        { title: '器具等级', data: 'meterLevel', render: (record, type, full) => getMeterLevel(record)},
        { title: '计量相关参数', data: 'params', visible: false},
        { title: '所属上报数据组合编码', data: 'dataCode', },
        { title: '与上报数据组合编码算术关系', data: 'reportArithmetic', visible: false,  render: record => getReportArithmetic(record)},
        { title: '与上报数据组合编码算术系数', data: 'reportRatio', visible: false },
        { title: '生产厂家', data: 'manufacturer', },
        { title: '规格型号', data: 'model', visible: false },
        { title: '精确度等级', data: 'precisionLevel', },
        { title: '测量范围', data: 'ranges', visible: false },
        { title: '管理编号', data: 'manageNo', },
        { title: '检定校准状态', data: 'alignState', visible: false, render: record => getAlignState(record)},
        { title: '检定校准周期(月)', data: 'alignCycle', visible: false, },
        { title: '最近一次检定校准时间', data: 'lastAlignDate', visible: false, searchable: false },
        { title: '下次一次检定校准时间', data: 'nextAlignDate', visible: false, searchable: false },
        { title: '检测机构', data: 'alignOrg', visible: false, },
        { title: '未检定/校准原因', data: 'unAlignReason', visible: false },
        { title: '安装地点', data: 'location', },
        { title: '安装方', data: 'installer', visible: false, render: record => getInstaller(record)},
        { title: '安装时间', data: 'installTime', visible: false, searchable: false },
        { title: '接入系统', data: 'linkSys', visible: false, render: record => getLinkSys(record)},
        { title: '当前状态', data: 'currentState', visible: false,  render: record => getCurrentState(record)},
        { title: '状态发生时间', data: 'stateChangeTime', visible: false, searchable: false },
        { title: '设备出厂序列号', data: 'serNo', visible: false },
        { title: '计量器具二维码', data: 'barCode', visible: false, },
        { title: '计量种类', data: 'paramsType', render: record => getParamsType(record)},
        { title: '备注', data: 'remark', visible: false},
    ],
    [//9.5.4.8 年度能源购进、消费与库存情况表
        { title: '能源名称', data: 'contacterName' },
        { title: '计量单位', data: 'phone', },
        { title: '代码', data: 'phone', },
        { title: '年初库存量', data: 'dept', },
        { title: '购进量实物量', data: 'job', },
        { title: '购进金额（千元）', data: 'fax', },
        { title: '消费量合计', data: 'email', },
        { title: '消费量产品消费', data: 'remark' },
        { title: '消费量用于原材料', data: 'phone', },
        { title: '消费量非生产消费', data: 'dept', },
        { title: '消费量合计运输工具消费', data: 'job', },
        { title: '期末库存量', data: 'fax', },
        { title: '采用折标系数', data: 'email', },
        { title: '折标准煤参考系数', data: 'remark' },
    ],
    [//9.5.5.1 能源流向图
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.5.2 能源计量器具配备及计量采集点网络图
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.5.3 能源计量器具自行检定装置量值传递/溯源框图
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.5.4 能源计量器具量值传递/溯源框图
        { title: '文件', data: 'fileName' },
    ],
    [//9.5.6.1 获取审查计划
        { title: '审查计划ID', data: 'phone', },
        { title: '审查计划名称', data: 'job', },
    ],
    [//9.5.6.2 获取审查结果
        { title: '审查类型', data: 'contacterName' },
        { title: '经办人', data: 'contacterName' },
        { title: '审核计划', data: 'phone', },
        { title: '审批结论', data: 'dept', },
        { title: '审批时间', data: 'dept', },
        { title: '审批单位名称', data: 'job', },
        { title: '审批人', data: 'contacterName' },
        { title: '企业编码', data: 'fax', },
        { title: '审查状态', data: 'email', },
        { title: '审查阶段', data: 'remark' },
    ],
];

/*基础数据表编码*/
export const ItemIndex = {    
    //regionCodes
    REGION_CODES: {code:"1", key:'regionCodes'}, //"行政区划代码",
    //industryCodes
    INDUSTRY_CODES:{code:"2", key:'industryCodes'}, //"行业编码",
    //productionProcessCodes
    PRODUCTION_PROCESS_CODES:{code:"3", key:'productionProcessCodes'}, //"生产工序编码",
    //processUnitCodes
    PROCESS_UNIT_CODES:{code:"4", key:'processUnitCodes'}, //"工序单元编码",
    //energyDeviceCodes
    ENERGY_DEVICE_CODES:{code:"5", key:'energyDeviceCodes'}, //"用能设备编码",
    //dataCollectOriginCodes
    DATA_COLLECTION_ORIGIN_CODES:{code:"6", key:'dataCollectOriginCodes'}, //"数据采集来源类型编码",
    //collectDataTypeCodes
    DATA_COLLECTION_TYPE_CODES:{code:"7", key:'collectDataTypeCodes'}, //"采集数据类型编码",
    //energyTypeUnitCollectCodes
    ENERGY_TYPE_UNIT_COLLECT_CODES:{code:"8", key:'energyTypeUnitCollectCodes'}, //"能源品种编码计量单位及精度",
    //energySubstanceCodes
    ENERGY_SUBSTANCE_CODES:{code:"9", key:'energySubstanceCodes'}, //"耗能工质编码计量单位及精度",
    //unenergyProductCodes
    UN_ENERGY_PRODUCT_CODES:{code:"10", key:'unenergyProductCodes'}, //"非能源类产品及计量单位(包含平台预定义编码及企业自定义编码)",
    //energyEfficiencyCodes
    ENERGY_EFFICIENCY_CODES:{code:"11", key:'energyEfficiencyCodes'}, //"能效指标编码及计量单位",
    //businessTargetCodes
    BUSINESS_TARGET_CODES:{code:"12", key:'businessTargetCodes'}, //"经营指标编码及计量单位",
    //otherDataCodes
    OTHER_DATA_CODES:{code:"13", key:'otherDataCodes'}, //"其它数据编码",
    //energyUseCodes
    ENERGY_USE_CODES:{code:"14", key:'energyUseCodes'}, //"能源用途编码",
    //gaugeTypeCodes
    GAUGE_TYPE_CODES:{code:"15", key:'gaugeTypeCodes'}, //"计量器具编码",
    //enterpriseTypeCodes
    ENTERPRISE_TYPE_CODES:{code:"16", key:'enterpriseTypeCodes'}, //"企业类型编码",
    //deviceTypeCodes
    DEVICE_TYPE_CODES:{code:"17", key:'deviceTypeCodes'}, //"重点用能设备分类编码"
}

/*用能单位计量器具信息表通用类别名称解析*/
const getParamsType=(type)=>{
    if (type !== undefined) {
        if (type == "01") {
            return '一次能源';
        } else if (type == "02") {
            return '二次能源';
        } else if (type == "03") {
            return '载能工质';
        } else if (type == "04") {
            return '非能源产品';
        } else if (type == "21") {
            return '企业自定义物料';
        } 
        return type;    
    }
    return '';
}

const getCurrentState=(type)=>{
    if (type !== undefined) {
        if (type == 1) {
            return '正常';
        } else if (type == 2) {
            return '故障';
        } else if (type == 3) {
            return '停用';
        }
        return type;
    }
    return '';
}

const getLinkSys=(type)=>{
    if (type !== undefined) {
        if (type == '01') {
            return '用能单位自身管理系统';
        } else if (type == '02') {
            return '能源供应公司系统';
        }
        return type;
    }
    return '';
}

const getInstaller=(type)=>{
    if (type !== undefined) {
        if (type == 1) {
            return '用能单位';
        } else if (type == 2) {
            return '能源供应公司';
        } else if (type == 3) {
            return '第三方公司';
        }
        return type;
    }
    return '';
}

const getAlignState=(type)=>{
    if (type !== undefined) {
        if (type == 0) {
            return '不合格';
        } else if (type == 1) {
            return '合格';
        }
        return type;
    }
    return '';
}

const getReportArithmetic=(type)=>{
    if (type !== undefined) {
        if (type == 1) {
            return '加';
        } else if (type == 2) {
            return '减';
        } else if (type == 3) {
            return '乘';
        } else if (type == 4) {
            return '除';
        }
        return type;
    }
    return '';
}

const getMeterLevel=(type)=>{
    if (type !== undefined) {
        if (type == 1) {
            return '进出用能计量器具';
        } else if (type == 2) {
            return '主要次级用能计量器具';
        } else if (type == 3) {
            return '主要用能设备计量器具';
        }
        return type;
    }
    return '';
}
import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNStandardAnalysis = Loadable({
  loader: () => import("./SDNStandardAnalysis"),
  loading: Loading
});
const SDNReportManage = Loadable({
  loader: () => import("./SDNReportManage"),
  loading: Loading
});
const SDNPowerAnalysis = Loadable({
  loader: () => import("./SDNPowerAnalysis/SDNPowerAnalysisPage"),
  loading: Loading
});

const SDNPowerRank = Loadable({
  loader: () => import("./SDNPowerRank/SDNPowerRankPage"),
  loading: Loading
});
const SDNPowerFlow = Loadable({
  loader: () => import("./SDNPowerFlow/SDNPowerFlowPage"),
  loading: Loading
});
//能效管理-峰值分析
const SDNPowerPeak = Loadable({
    loader: () => import("./SDNPowerPeakAnalysis/SDNPowerPeakPage"),
    loading:Loading
});
//峰值分析
const SDNPeakPage = Loadable({
    loader: () => import("./SDNPeakAnalysis/SDNPeakPage"),
    loading:Loading
});

const SDNUnitConfig = Loadable({
        loader: () => import("./SDNUnitConfig/SDNUnitConfigPage"),
    loading:Loading
});

const SDNDataInput = Loadable({
  loader: () => import("./SDNDataInput/components/SDNDataInputPage"),
  loading:Loading
});

const SDNCompareAnalysis = Loadable({
        loader: () => import("./SDNCompareAnalysis/SDNCompareAnalysisPage"),
    loading:Loading
});

const SDNPowerReport = Loadable({
  loader: () => import("./SDNPowerReport/SDNPowerReport"),
loading:Loading
});

//需量分析
const SDNDemandAnalysis = Loadable({
  loader: () => import("./SDNDemandAnalysis"),
  loading: Loading
});
const SDNStatementStatistics = Loadable({
  loader: () => import("./SDNStatementStatistics/SDNStatementStatisticsPage"),
  loading: Loading
});
//电能质量分析
const SDNElectricQualityAnalysis = Loadable({
  loader: () => import("./SDNElectricQualityAnalysis"),
  loading: Loading
});
//用电负荷分析
const SDNLoadAnalysis = Loadable({
  loader: () => import("./SDNLoadAnalysis/SDNLoadAnalysisPage"),
  loading: Loading
});
//电量电费分析
const SDNCoulometryChargeAnalysis = Loadable({
  loader: () => import("./SDNCoulometryChargeAnalysis/SDNCoulometryChargePage"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNPowerAdmin/SDNPowerAnalysis",
    exact: true,
    component: SDNPowerAnalysis,
    name: "SDNPowerAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNDemandAnalysis",
    exact: true,
    component: SDNDemandAnalysis,
    name: "SDNDemandAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNStatementStatistics",
    exact: true,
    component: SDNStatementStatistics,
    name: "SDNStatementStatistics"
  },
  {
    path: "/SDNPowerAdmin/SDNPowerRankPage",
    exact: true,
    component: SDNPowerRank,
    name: "SDNPowerRankPage"
  },
  {
    path: "/SDNPowerAdmin/SDNPowerFlowPage",
    exact: true,
    component: SDNPowerFlow,
    name: "SDNPowerFlowPage"
  },
  {
    path: "/SDNPowerAdmin/SDNPowerPeak",
    exact: true,
    component: SDNPowerPeak,
    name: "SDNPowerPeak"
  },
  {
    path: "/SDNPowerAdmin/SDNPeakPage",
    exact: true,
    component: SDNPeakPage,
    name: "SDNPeakPage"
  },
  {
    path: "/SDNPowerAdmin/SDNUnitConfig",
    exact: true,
    component: SDNUnitConfig,
    name: "SDNUnitConfig"
  },
  {
    path: "/SDNPowerAdmin/SDNStandardAnalysis",
    exact: true,
    component: SDNStandardAnalysis,
    name: "SDNStandardAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNReportManage",
    exact: true,
    component: SDNReportManage,
    name: "SDNReportManage"
  },
  {
    path: "/SDNPowerAdmin/SDNCompareAnalysis",
    exact: true,
    component: SDNCompareAnalysis,
    name: "SDNCompareAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNDemand",
    exact: true,
    component: SDNLoadAnalysis,
    name: "SDNLoadAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNElectricQualityAnalysis",
    exact: true,
    component: SDNElectricQualityAnalysis,
    name: "SDNElectricQualityAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNLoadAnalysis",
    exact: true,
    component: SDNLoadAnalysis,
    name: "SDNLoadAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNCoulometryChargeAnalysis",
    exact: true,
    component: SDNCoulometryChargeAnalysis,
    name: "SDNCoulometryChargeAnalysis"
  },
  {
    path: "/SDNPowerAdmin/SDNPowerReport",
    exact: true,
    component: SDNPowerReport,
    name: "SDNPowerReport"
  },
  {
    path: "/SDNPowerAdmin/SDNDataInput",
    exact: true,
    component: SDNDataInput,
    name: "SDNDataInput"
  },
];

import * as actionType from "./ActivitiesActionTypes";
import { handleActions } from 'redux-actions';
import * as layout from "../../layout/LayoutActions";

export default function ActivitiesReducer (
  state = {
    page: {},
    alarmTopList: [],
    alarmCount:0
  },
  action
) {
  switch (action.type) {
    case actionType.INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }


    case actionType.SET_ALARM_COUNT:
      return {
        ...state,
        alarmCount: action.payload
      };
    case actionType.SET_ALARM_TOP_LIST:
      return {
        ...state,
        alarmTopList: action.payload
      };

    default:
      return state;
  }
}


/** ======================================== 能效总览  =====================================*/

export const OPERATION_Power_Overview_statistics = 'OPERATION_Power_Overview_statistics';
export const OPERATION_Power_Overview_statistics_SUCCESS = 'OPERATION_Power_Overview_statistics_SUCCESS';

export const OPERATION_Power_Overview_energyDeviceTop = 'OPERATION_Power_Overview_energyDeviceTop';
export const OPERATION_Power_Overview_energyDeviceTop_SUCCESS = 'OPERATION_Power_Overview_energyDeviceTop_SUCCESS';

export const OPERATION_Power_Overview_energyHourTop = 'OPERATION_Power_Overview_energyHourTop';
export const OPERATION_Power_Overview_energyHourTop_SUCCESS = 'OPERATION_Power_Overview_energyHourTop_SUCCESS';

export const OPERATION_Power_Overview_energyTree = 'OPERATION_Power_Overview_energyTree';
export const OPERATION_Power_Overview_energyTree_SUCCESS = 'OPERATION_Power_Overview_energyTree_SUCCESS';

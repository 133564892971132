import { handleActions } from 'redux-actions';

const initalState = {
    statistics: {},
    energyDeviceTop: {},
    energyHourTop: {},
    energyTree: {},
}

const SDNPowerOverviewReducer = handleActions({
    OPERATION_Power_Overview_statistics_SUCCESS: (state, action) => {
        return {
            ...state,
            statistics: getStatistics(action.payload || {})
        };
    },

    OPERATION_Power_Overview_energyDeviceTop_SUCCESS: (state, action) => {
        return {
            ...state,
            energyDeviceTop: getEnergyDeviceTop(action.payload || {})
        };
    },

    OPERATION_Power_Overview_energyHourTop_SUCCESS: (state, action) => {
        return {
            ...state,
            energyHourTop: getEnergyHourTop(action.payload || {})
        };
    },

    OPERATION_Power_Overview_energyTree_SUCCESS: (state, action) => {
        return {
            ...state,
            energyTree: getEnergyTree(action.payload || {})
        };
    },
}, initalState);

const getStatistics = (datas) => {
    for (let key in datas) {
        let value = datas[key];
        if (value.hasOwnProperty("uQR") || value.hasOwnProperty("fZQR") || value.hasOwnProperty("uBFqr") || value.hasOwnProperty("pZRate")) {
            value["pZRate"] *= 100;
        }
    }
    return datas;
}

const getEnergyDeviceTop = (datas) => {
    let energyDeviceTop = {};

    let titles = [];
    let values = [];

    for (let key in datas) {
        let deviceTop = datas[key];
        titles.push(deviceTop["deviceName"] || "--");
        if (deviceTop.hasOwnProperty("pP")) {
            values.push(deviceTop["pP"] || "0");
        } else {
            values.push("");
        }        

        if (titles.length >= 10) {
            break;
        }
    }

    while (titles.length > 0 && titles.length < 10) {
        titles.push("--");
        values.push("");
    }

    energyDeviceTop["titles"] = titles;
    energyDeviceTop["values"] = values;
    
    return energyDeviceTop;
}

const getEnergyHourTop = (datas) => {
    let energyHourTop = [];

    let titles = [];
    let values = [];

    for (let key in datas) {
        let hourTop = datas[key];
        titles.push(hourTop["hour"] || "--");
        if (hourTop.hasOwnProperty("pP")) {
            values.push(hourTop["pP"] || "0");
        } else {
            values.push("");
        }

        if (titles.length >= 10) {
            break;
        }
    }

    while (titles.length > 0 && titles.length < 10) {
        titles.push("--");
        values.push("");
    }

    energyHourTop["titles"] = titles;
    energyHourTop["values"] = values;

    return energyHourTop;
}

const getEnergyTree = (data) => {
    nodes = [];
    links = [];
    orgNames = [];

    //data = {"children":[{"children":[{"children":[{"dataAmmeterId":"073a0d07-6f8f-41b6-b793-f503697f966c","orgName":"冷加工机床","pP":814.0},{"dataAmmeterId":"04bdcc92-20eb-4666-877c-ee32f2809507","orgName":"1-1#电焊机","pP":802.0},{"dataAmmeterId":"9d1920fa-4757-4047-a7d0-666955838af1","orgName":"照明","pP":0.32}],"dataAmmeterId":"735bff45-2660-4e4c-8714-5c6e7665ba97","orgName":"一车间","pP":2821336.0},{"children":[{"dataAmmeterId":"70a71d2c-cbfb-4b34-be6e-0bb6ae523ec0","orgName":"电加热设备","pP":1320.0},{"dataAmmeterId":"fb09132e-86aa-4e4e-af22-aca5d5689254","orgName":"2-1#电焊机","pP":920.0},{"dataAmmeterId":"3ddf967f-cc3c-4c5f-92bf-83ebbe7e07ba","orgName":"照明","pP":381.0}],"dataAmmeterId":"97df21be-c443-4854-be6c-266b4345edfa","orgName":"二车间","pP":10840112},{"children":[{"dataAmmeterId":"4bc7c278-5e07-4baf-8388-3de82a8b4f17","orgName":"起重机","pP":1064.0},{"dataAmmeterId":"a6c1d091-ca77-40bf-8ae9-5f82848c9ef5","orgName":"照明","pP":3622.4}],"dataAmmeterId":"179a828d-ab66-47a8-897f-01aac13473e4","orgName":"三车间","pP":5116513.0},{"children":[{"dataAmmeterId":"49f8eaa7-4478-4516-9b48-77bf1a6d874a","orgName":"照明","pP":7.17},{"dataAmmeterId":"c08ced41-dabb-45c0-a3f3-66d6243a7053","orgName":"电梯空调","pP":3.24}],"dataAmmeterId":"2fc233b8-1367-4513-801d-fd85ef2b2c72","orgName":"办公楼","pP":9704028.0},{"children":[{"dataAmmeterId":"5d4ac286-0859-4fb3-9248-277efc604ccc","orgName":"食堂#总","pP":624.0}],"dataAmmeterId":"041dab89-0e99-4654-bf79-0faa0c8b59ed","orgName":"食堂","pP":4110291.2},{"dataAmmeterId":"46549546-4f80-4a23-9c83-94658d8a2a80","orgName":"水泵房","pP":84.0}],"dataAmmeterId":"404f1ac4-809f-4961-9263-f0cf3b59866a","orgName":"演示用户用能总加组","pP":87684191.4}],"dataAmmeterId":"404f1ac4-809f-4961-9263-f0cf3b59866a","orgName":"比亚迪汽车工业","pP":87684191.4};
    getEnergyTreeData(data, null, 1);
    
    let sourceNodesCount = 0;
    let endNodesCount = 0;

    for (let key in orgNames) {
        let orgName = orgNames[key];
        for (let key2 in links) {
            let link = links[key2];
            let source = link.source;
            if (orgName == source) {
                sourceNodesCount++;
                break;
            }
        }
    }    

    endNodesCount = orgNames.length-sourceNodesCount;

    return { "nodes": nodes, "links": links, "endNodesCount": endNodesCount};
}

let nodes = [];
let links = [];
let orgNames = [];

const getEnergyTreeData = (dict, parentOrgName, level, index) => {
    let orgName = dict["orgName"] || "未知名称";
    let pP = dict["pP"] || "00";

    orgName = reviseOrgName(orgName);

    let itemColor = null;
    let itemColor1 = '#7564CC';
    let itemColor2 = ['#3AA0FF', '#2FC25B', '#FACC14', '#435088', '#975FE4'];
    let itemColor3 = ['#B381E6', '#5CA3E6', '#13C2C2', '#3436C7', '#5C8EE6'];

    if (level == 1) {
        itemColor = itemColor1;
    } else if (level == 2) {
        itemColor = itemColor2[index % itemColor2.length];
    } else if (level >= 3) {
        itemColor = itemColor3[index % itemColor3.length];
    }


    nodes.push({
        "name": orgName, itemStyle: {
            color: itemColor
        }
    });

    if (parentOrgName) {
        links.push({ "source": parentOrgName, "target": orgName, "value": pP });
    }

    let children = dict["children"];
    if (children && children.length > 0 && orgName != "--") {
        const childrenLevel = level + 1;
        let index = 0;
        for (let key in children) {
            let dict = children[key];
            getEnergyTreeData(dict, orgName, childrenLevel, index);
            index++;
        }
    }
}

const reviseOrgName = (newOrgName) => {
    if (hasContaisOrgName(newOrgName)) {
        for (let i = 0; i < 1000; i++) {
            if (!hasContaisOrgName(newOrgName + '#' + i)) {
                orgNames.push(newOrgName + '#' + i);
                return newOrgName + '#' + i;
            }
        }
    }

    orgNames.push(newOrgName);
    return newOrgName;
}

const hasContaisOrgName = (newOrgName) => {
    for (let key in orgNames) {
        let orgName = orgNames[key];
        if (orgName == newOrgName) {
            return true;
        }
    }

    return false;
}

export default SDNPowerOverviewReducer;

import {archive, efficiency} from "../../../service";
import {put, select, takeLatest, call, all} from 'redux-saga/effects';
import {getPowerAnalysis} from "../../../config/SDNSelector";
import moment from "moment";
import { infoBox, Utils } from '../../../common';
import { powerClassList } from "../../../config/SDNConstant";

export const SET_UNIT = 'POWER_ANALYSIS_SET_UNIT';
export const EDIT_POWER_ANALYSIS_PARAMS = 'EDIT_POWER_ANALYSIS_PARAMS';
export const POWER_ANALYSIS_PARAMS_CHANGE = 'POWER_ANALYSIS_PARAMS_CHANGE';
export const GET_POWER_ANALYSIS_CHARTS_DATA = 'GET_POWER_ANALYSIS_CHARTS_DATA';
export const GET_POWER_ANALYSIS_CHARTS_DATA_SUCCESS = 'GET_POWER_ANALYSIS_CHARTS_DATA_SUCCESS';
export const EDIT_POWER_ANALYSIS_PIE_SUCCESS = 'EDIT_POWER_ANALYSIS_PIE_SUCCESS';
export const GET_POWER_ANALYSIS_DATA = 'GET_POWER_ANALYSIS_DATA';
export const EDIT_POWER_ANALYSIS_TABLE = 'EDIT_POWER_ANALYSIS_TABLE';
export const EDIT_POWER_ANALYSIS_TABLE_SUCCESS = 'EDIT_POWER_ANALYSIS_TABLE_SUCCESS';
export const GET_POWER_ANALYSIS_CLASSIFIED_TYPE = 'GET_POWER_ANALYSIS_CLASSIFIED_TYPE';
export const SET_POWER_ANALYSIS_CLASSIFIED_TYPE = 'SET_POWER_ANALYSIS_CLASSIFIED_TYPE';

const intervalList = [
    {title: '小时', value: 201},
    {title: '天', value: 301},
    {title: '月', value: 501}
];
export function* editPowerAnalysisParams(action) {
    yield put({type: POWER_ANALYSIS_PARAMS_CHANGE, payload: action.payload});
    yield put({type: GET_POWER_ANALYSIS_DATA});
}
const getProcessedParams = params => {
    const {
        orgId,
        beginDate,
        endDate,
        dataClassifyId,
        interval,
        format
        } = params;
    const begin = beginDate.format(format);
    const end = endDate.format(format);
    let ret = {
        orgId: orgId || '',
        beginDate: begin,
        endDate: end,
        dataClassifyId: dataClassifyId || '',
        interval: (interval || interval === 0) ? intervalList[interval].value : intervalList[0].value
    };
    return ret;
}

export function* getData(action) {
    const powerAnalysis = yield select(getPowerAnalysis);
    const params = getProcessedParams(powerAnalysis.powerParmas);

    const [allCharts, tableCharts, pieCharts] = yield all([
        call(
            efficiency.EnergyAnalysisService.charDatas,
            params.orgId,
            params.beginDate,
            params.endDate,
            params.dataClassifyId,
            params.interval
        ),
        call(
            efficiency.EnergyAnalysisService.tableDatas,
            10,
            1,
            '',
            params.orgId,
            params.beginDate,
            params.endDate,
            params.dataClassifyId,
            params.interval
        ),
        call(
            efficiency.EnergyAnalysisService.pieDatas,
            params.orgId,
            params.beginDate,
            params.endDate,
            params.dataClassifyId,
            params.interval
        )
    ]);

    if (!allCharts.code) {
        yield put({ type: GET_POWER_ANALYSIS_CHARTS_DATA_SUCCESS, payload: allCharts });
    }
    
    yield put({
        type: EDIT_POWER_ANALYSIS_TABLE_SUCCESS,
        payload: tableCharts.code ? [] : tableCharts
    });
    yield put({
        type: EDIT_POWER_ANALYSIS_PIE_SUCCESS,
        payload: pieCharts.code ? [] : pieCharts
    });
    yield put({ type: SET_UNIT, payload: powerAnalysis.powerParmas.unit });
}

export function* getTableDatas(action) {
        const powerAnalysis = yield select(getPowerAnalysis);
        const params = getProcessedParams(powerAnalysis.powerParmas);
        if (Utils.empty(params.orgId)) {
            return;
        }
        const tableCharts = yield call(
            efficiency.EnergyAnalysisService.tableDatas,
            action.payload.pageSize || 10,
            action.payload.current || 1,
            action.payload.orgName || '',
            params.orgId,
            params.beginDate,
            params.endDate,
            params.dataClassifyId,
            params.interval
        );
        yield put({
           type: EDIT_POWER_ANALYSIS_TABLE_SUCCESS,
           payload: tableCharts.code ? [] : tableCharts
        });
    }
    

export function* getClassifiedTypes(action) {
    const response = yield call(archive.DataClassifyService.getListByDataType, action.payload.typeId);
    if (!response.code) {
        const array = [];
        for (const item of response) {
            array.push({ title: item.dataSubTypeName, value: item.dataClassifyId });
        }
        yield put({ type: SET_POWER_ANALYSIS_CLASSIFIED_TYPE, payload: array });
        yield put({ type: EDIT_POWER_ANALYSIS_PARAMS, payload: {unit: action.payload.typeUnit, dataClassifyId: array.length > 0 ? array[0].value : ''}});
    }
}
export const powerAnalysisSagas = [
    takeLatest(GET_POWER_ANALYSIS_DATA, getData),
    takeLatest(EDIT_POWER_ANALYSIS_PARAMS, editPowerAnalysisParams),
    takeLatest(GET_POWER_ANALYSIS_CLASSIFIED_TYPE, getClassifiedTypes),
    takeLatest(EDIT_POWER_ANALYSIS_TABLE, getTableDatas)
];
const trim = value => {
    var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
    var str = value == null ? "" : ( value + "" ).replace(rtrim, "");
    return str;
};
//reducer
export function SDNPowerAnalysisReducer(state = {
    unit: powerClassList[0].unit,
    classifiedTypes: [],
    graphData: [],//总能耗分析图表
    chartDataKey: 'PP',
    barChartList: [],//数据统计图表
    extremeLineList: [],//极值分析图表
    pieData: [], //能效分析饼图
    tableData: [],//表格true
    pagination :null,
    minTableOne: [],
    minTableTwo: [],
    powerParmas: {
        orgId: '',
        beginDate: moment().subtract(1, 'days'),
        endDate: moment(),
        dataClassifyId: '',
        interval: 0,
        format: 'YYYY-MM-DD HH:mm',
        unit: powerClassList[0].unit
    }
}, action) {
    switch (action.type) {
        case SET_UNIT: {
            return { ...state, unit: action.payload }
        }

        case SET_POWER_ANALYSIS_CLASSIFIED_TYPE: {
            const params = { ...state.powerParmas }
            if (action.payload.length > 0) {
                params.dataClassifyId = action.payload[0].value;
            }
            return { ...state, classifiedTypes: action.payload, powerParmas: params };
        }

        case GET_POWER_ANALYSIS_CHARTS_DATA_SUCCESS:
        {
            const chartData = action.payload;
            let {barChartList,minTableOne,minTableTwo} = state;
            if (!trim(chartData.monthOnMonth) && !trim(chartData.yearOnYear) && !trim(chartData.totalEnergy)) {
                barChartList = [];
            } else {
                barChartList = [trim(chartData.monthOnMonth), trim(chartData.yearOnYear), trim(chartData.totalEnergy)];
            }
            if (!trim(chartData.yearOnYearInc) && !trim(chartData.monthOnMonthInc)) {
                minTableOne = [];
            } else {
                minTableOne = [{
                    yearOnYearInc: chartData.yearOnYearInc,
                    monthOnMonthInc: chartData.monthOnMonthInc
                }];
            }
            ;
            if (!trim(chartData.max) && !trim(chartData.min) && !trim(chartData.avg)) {
                minTableTwo = [];
            } else {
                minTableTwo = [{
                    max: trim(chartData.max),
                    min: trim(chartData.min),
                    avg: trim(chartData.avg)
                }];
            }

            for (const item of chartData.graphsData) {
                if (item.interval === 201) {
                    item.dt = item.dt.substr(0, 16);
                }
                else if (item.interval === 301) {
                    item.dt = item.dt.substr(0, 10);
                }
                else {
                    item.dt = item.dt.substr(0, 7);
                }
            }
            return {
                ...state,
                extremeLineList: chartData.pZGraphsDataMax || [],
                graphData: chartData.graphsData || [],
                chartDataKey: chartData.valueKey,
                barChartList: barChartList || [],
                minTableOne: minTableOne || [],
                minTableTwo: minTableTwo || []
            }
        }
        case EDIT_POWER_ANALYSIS_PIE_SUCCESS :
        {
            return {
                ...state,
                pieData: action.payload || []
            }
        }
        case EDIT_POWER_ANALYSIS_TABLE_SUCCESS :
        {
            // console.log(action.payload)
            if (Utils.empty(action.payload)) {
                return state;
            }
            const { list,pagination } = action.payload;
            for (let i = 0; i < list.length; ++i) {
                let item = list[i];
                item.index = (pagination.current - 1) * pagination.pageSize + i + 1;
            }
            return {
                ...state,
                tableData: list || [],
                pagination : pagination || null
            }
        }
        case POWER_ANALYSIS_PARAMS_CHANGE:
        {
            const params = action.payload;
            let {powerParmas} = state;
            Object.keys(params).forEach((key) => {
                powerParmas[key] = params[key];
            });
            return {
                ...state,
                powerParmas: powerParmas
            };
        }
        default:
            return state;
    }
}
import { efficiency } from "../../../service";
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionType from './SDNPowerOverviewActionTypes';

//能耗时段排名
function* statistics(action) {
    const ret = yield call(efficiency.OverviewService.statistics, action.dataAmmeterId || "");
    if (!ret.code) {
        yield put({ type: actionType.OPERATION_Power_Overview_statistics_SUCCESS, payload: ret });
    } else {
        yield put({ type: actionType.OPERATION_Power_Overview_statistics_SUCCESS });
    }
}

//能耗设备排名
function* energyDeviceTop(action) {
    const ret = yield call(efficiency.OverviewService.energyDeviceTop, action.consumerId);
    if (!ret.code) {
        yield put({ type: actionType.OPERATION_Power_Overview_energyDeviceTop_SUCCESS, payload: ret });
    } else {
        yield put({ type: actionType.OPERATION_Power_Overview_energyDeviceTop_SUCCESS });
    }
}

//能耗时段排名
function* energyHourTop(action) {
    const ret = yield call(efficiency.OverviewService.energyHourTop, action.consumerId);
    if (!ret.code) {
        yield put({ type: actionType.OPERATION_Power_Overview_energyHourTop_SUCCESS, payload: ret });
    } else {
        yield put({ type: actionType.OPERATION_Power_Overview_energyHourTop_SUCCESS });
    }
}

//能耗时段排名
function* energyTree(action) {
    const ret = yield call(efficiency.OverviewService.energyTree, action.consumerId);
    if (!ret.code) {
        yield put({ type: actionType.OPERATION_Power_Overview_energyTree_SUCCESS, payload: ret });
    } else {
        yield put({ type: actionType.OPERATION_Power_Overview_energyTree_SUCCESS });
    }
}

const SDNPowerOverviewSagas = [
    takeLatest(actionType.OPERATION_Power_Overview_statistics, statistics),
    takeLatest(actionType.OPERATION_Power_Overview_energyDeviceTop, energyDeviceTop),
    takeLatest(actionType.OPERATION_Power_Overview_energyHourTop, energyHourTop),
    takeLatest(actionType.OPERATION_Power_Overview_energyTree, energyTree),
];

export default SDNPowerOverviewSagas;

import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap";

export default class SDNQuestionPopover extends React.Component {

  static defaultProps = {
    content: ''
  }

  constructor (props) {
    super(props);
  }

  render () {
    const {
      content
    } = this.props;
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover id="popover-activated-on-hover-popover">
            { content }
          </Popover>
        }
      >
        <a href="javascript:void(0)" rel="popover-hover" data-placement="top" data-content="">
          <i className="fa fa-question-circle text-gray"></i>
        </a>
      </OverlayTrigger>
    )
  }
}

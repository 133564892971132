import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import { INIT_PAGE } from "../../../config/SDNActionType";
import { efficiency } from "../../../service";
import antdNotice from "../../../common/Notification/antdNotice";
import { FORMAT_DAY } from "../SDNElectricQualityAnalysis/model";
import moment from "moment";
import { getDemandAnalysis } from "../../../config/SDNSelector";
export const GET_LIVE_QUANTITY = 'GET_LIVE_QUANTITY';
export const SET_LIVE_QUANTITY = 'SET_LIVE_QUANTITY';
export const GET_QUANTITY_DETAIL = 'GET_QUANTITY_DETAIL';
export const SET_QUANTITY_DETAIL = 'SET_QUANTITY_DETAIL';
export const GET_MAX_DEMAND_DATA = 'GET_MAX_DEMAND_DATA';
export const GET_MAX_AND_SECOND_LINE = 'GET_MAX_AND_SECOND_LINE';
export const SET_MAX_AND_SECOND_LINE = 'SET_MAX_AND_SECOND_LINE';
export const GET_MAX_AND_SECOND_POINT = 'GET_MAX_AND_SECOND_POINT';
export const SET_MAX_AND_SECOND_POINT = 'SET_MAX_AND_SECOND_POINT';
export const GET_MAX_AND_SECOND_TIME = 'GET_MAX_AND_SECOND_TIME';
export const SET_MAX_AND_SECOND_TIME = 'SET_MAX_AND_SECOND_TIME';
export const GET_MAX_STANDARD_DIFF = 'GET_MAX_STANDARD_DIFF';
export const SET_MAX_STANDARD_DIFF = 'SET_MAX_STANDARD_DIFF';
export const GET_MAX_SECOND_DIFF = 'GET_MAX_SECOND_DIFF';
export const SET_MAX_SECOND_DIFF = 'SET_MAX_SECOND_DIFF';
export const GET_MAX_OVER_COUNT = 'GET_MAX_OVER_COUNT';
export const SET_MAX_OVER_COUNT = 'SET_MAX_OVER_COUNT';
export const GET_MAX_OVER_TIME = 'GET_MAX_OVER_TIME';
export const SET_MAX_OVER_TIME = 'SET_MAX_OVER_TIME';
export const SET_STANDARD = 'SET_STANDARD';
export const SET_STANDARD_SUCCESS = 'SET_STANDARD_SUCCESS';
export const SHOW_SET_STANDARD = 'SHOW_SET_STANDARD';
export const SET_DEMAND_INIT_STANDARD = 'SET_DEMAND_INIT_STANDARD';
export const EDIT_DEMAND_PARAMS = 'EDIT_DEMAND_PARAMS';
export const GET_TODAY_OVER_TOTAL = 'GET_TODAY_OVER_TOTAL';

/**
 * @desc 实时需量
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getLiveQuantity(action) {
    const res = yield call(efficiency.QuantityService.liveQuantity, action.payload);
    let payload = { today: [], yesterday: [], liveStandard: [] }
    const date = moment().format('YYYY-MM-DD')
    if (!res.code) {
        payload.standard = res.standard
        payload.today = res.today.map(item => {
            return [moment(date + ' ' + item.time).valueOf(), item.value || '-']
        })

        payload.yesterday = res.yesterday.map(item => {
            return [moment(date + ' ' + item.time).valueOf(), item.value || '-']
        })

        for (let index = 0; index <= 24; index++) {
            payload.liveStandard.push([moment(date + ' ' + index.toString().padStart(2, '0') + ':00').valueOf(), res.standard])
        }
    }
    yield put({ type: SET_LIVE_QUANTITY, payload: payload });

}

/**
 * @desc 实时需量详细数据
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getQuantityDetail(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.quantityDetail, params.dataAmmeterId, params.current, params.pageSize);
    if (res.code) {
        yield put({
            type: SET_QUANTITY_DETAIL,
            payload: { list: [], pagination: { todayOverTotal: 0, current: 1, pageSize: 10, totla: 0 } }
        });
    }
    else {
        yield put({ type: SET_QUANTITY_DETAIL, payload: res });
    }


}

/**
 * @desc 设置参考值
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* setDemandStandard(action) {
    try {
        let params = action.payload;
        const res = yield call(efficiency.QuantityService.setStandard, params.dataAmmeterId, params.standard);
        if (res.code == 200) {
            antdNotice.info("设置参考值成功");
            yield put({ type: SHOW_SET_STANDARD, payload: false });
            yield put({ type: GET_LIVE_QUANTITY, payload: params.dataAmmeterId });
            const { demandPagination } = yield select(getDemandAnalysis);
            yield put({ type: GET_QUANTITY_DETAIL, payload: { dataAmmeterId: params.dataAmmeterId, current: 1, pageSize: demandPagination.pageSize } });
        }

    } catch (e) {
    }
}

/**
 * @desc 最大需量分析
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxDemandData(action) {
    let payload = action.payload;
    yield put({ type: GET_MAX_AND_SECOND_LINE, payload });
    yield put({ type: GET_MAX_AND_SECOND_POINT, payload });
    yield put({ type: GET_MAX_AND_SECOND_TIME, payload });
    yield put({ type: GET_MAX_STANDARD_DIFF, payload });
    yield put({ type: GET_MAX_SECOND_DIFF, payload });
    yield put({ type: GET_MAX_OVER_COUNT, payload });
    yield put({ type: GET_MAX_OVER_TIME, payload });
}
/**
 * @desc 最大需量与次大需量趋势
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxAndSecondTrends(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxAndSecondLine, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_AND_SECOND_LINE, payload: res.code ? { max: [], second: [] } : res });
}
/**
 * @desc 最大需量与次大需量散点图
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxAndSecondBubbles(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxAndSecondPoint, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_AND_SECOND_POINT, payload: res.code ? { max: [], second: [] } : res });
}
/**
 * @desc 最大需量与次大需量时间分布
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxAndSecondTimes(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxAndSecondTime, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_AND_SECOND_TIME, payload: res.code ? { max: [], second: [] } : res });
}
/**
 * @desc 最大需量与参考值差值趋势
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxStandardDiff(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxStandardDiff, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_STANDARD_DIFF, payload: res.code ? [] : res });
}
/**
 * @desc 最大需量与次大需量差值趋势
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxSecondDiff(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxSecondDiff, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_SECOND_DIFF, payload: res.code ? [] : res });
}
/**
 * @desc 超参考值次数
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxOverCount(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxOverCount, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_OVER_COUNT, payload: res.code ? [] : res });
}
/**
 * @desc 超参考值时间段分布
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMaxOverTimes(action) {
    let params = action.payload;
    const res = yield call(efficiency.QuantityService.maxOverTime, params.dataAmmeterId, params.dateStart, params.dateEnd);
    yield put({ type: SET_MAX_OVER_TIME, payload: res.code ? [] : res });
}


export const demandAnalysisSagas = [
    takeLatest(GET_LIVE_QUANTITY, getLiveQuantity),
    takeLatest(GET_QUANTITY_DETAIL, getQuantityDetail),
    takeLatest(SET_STANDARD, setDemandStandard),
    takeLatest(GET_MAX_DEMAND_DATA, getMaxDemandData),
    takeLatest(GET_MAX_AND_SECOND_LINE, getMaxAndSecondTrends),
    takeLatest(GET_MAX_AND_SECOND_POINT, getMaxAndSecondBubbles),
    takeLatest(GET_MAX_AND_SECOND_TIME, getMaxAndSecondTimes),
    takeLatest(GET_MAX_STANDARD_DIFF, getMaxStandardDiff),
    takeLatest(GET_MAX_SECOND_DIFF, getMaxSecondDiff),
    takeLatest(GET_MAX_OVER_COUNT, getMaxOverCount),
    takeLatest(GET_MAX_OVER_TIME, getMaxOverTimes),
];

//reducer
export function SDNDemandAnalysisReducer(
    state = {
        page: {},
        loading: true,
        showStandard: false,
        maxTodayDemand: [], //
        maxYesterdayDemand: [], //
        liveStandard: [], //
        liveTodayDemand: [], //
        liveYesterdayDemand: [], //
        total: 0, //超参考值总次数
        todayOverTotal: 0, //今日超参考值次数
        demandDetailList: [], //
        chartData: {}, //能效分析统计和图表数据
        maxSecondTrends: { max: [], second: [] }, //最大需量与次大需量趋势
        maxSecondBubbles: { max: [], second: [] }, //最大需量与次大需量分布散点图
        maxSecondTimes: { max: [], second: [] }, //最大需量与次大需量时间分布
        maxStandardDiff: [], //最大需量与参考值差值趋势
        maxSecondDemandDiff: [], //最大需量与次大需量差值趋势
        maxOverCounts: [], //超参考值次数
        maxOverTimes: [], //超参考值时间段分布
        initStandard: null,
        demandParams: {
            nodeId: '',
            dataAmmeterId: '',
            dateStart: moment().startOf('month'),
            dateEnd: moment(),
        },
        demandPagination: {
            pageSize: 10,
            current: 1,
            total: 0,
            showSizeChanger: true,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100']
        }
    },
    action
) {
    switch (action.type) {
        case INIT_PAGE: {
            return {
                ...state,
                page: action.payload
            };
        }
        case EDIT_DEMAND_PARAMS: {
            return {
                ...state,
                demandParams: action.payload
            };
        }
        case SET_LIVE_QUANTITY: {

            return {
                ...state,
                liveTodayDemand: action.payload.today || [],
                liveYesterdayDemand: action.payload.yesterday || [],
                liveStandard: action.payload.liveStandard,
                initStandard: action.payload.standard,
                loading: false
            }
        }

        case GET_TODAY_OVER_TOTAL: {
            const { demandDetailList, todayOverTotal } = state
            let i = 0;
            const array = []
            
            while (demandDetailList.length > 0 && array.length < todayOverTotal && array.length < demandDetailList.length) {
                const item = demandDetailList[i]
                if (item.todayOver === true) {
                    array.push(item)
                }
                ++i
            }

            const pagination = {
                ...state.demandPagination,
                pageSize: array.length,
                current: 1,
                total: array.length
            }
            return { ...state, demandDetailList: array, demandPagination: pagination }
        }
        case SET_QUANTITY_DETAIL: {

            const pagination = {
                ...state.demandPagination, 
                current: action.payload.pagination.current, 
                pageSize: action.payload.pagination.pageSize, 
                total: action.payload.pagination.total
            }
            return {
                ...state,
                demandDetailList: action.payload.list,
                demandPagination: pagination,
                total: action.payload.pagination.total,
                todayOverTotal: action.payload.pagination.todayOverTotal,
                loading: false
            }
        }
        case SET_MAX_AND_SECOND_LINE: {
            return {
                ...state,
                maxSecondTrends: action.payload,
                loading: false
            }
        }
        case SET_MAX_AND_SECOND_POINT: {
            return {
                ...state,
                maxSecondBubbles: action.payload,
                loading: false
            }
        }
        case SET_MAX_AND_SECOND_TIME: {
            return {
                ...state,
                maxSecondTimes: action.payload,
                loading: false
            }
        }
        case SET_MAX_STANDARD_DIFF: {
            return {
                ...state,
                maxStandardDiff: action.payload,
                loading: false
            }
        }
        case SET_MAX_SECOND_DIFF: {
            return {
                ...state,
                maxSecondDemandDiff: action.payload,
                loading: false
            }
        }
        case SET_MAX_OVER_COUNT: {
            return {
                ...state,
                maxOverCounts: action.payload,
                loading: false
            }
        }
        case SET_MAX_OVER_TIME: {
            return {
                ...state,
                maxOverTimes: action.payload,
                loading: false
            }
        }
        case SET_STANDARD_SUCCESS: {
            return {
                ...state,
                showStandard: action.payload
            }
        }
        case SHOW_SET_STANDARD: {
            return {
                ...state,
                showStandard: action.payload
            }
        }
        case SET_DEMAND_INIT_STANDARD: {

            return {
                ...state,
                initStandard: action.payload
            }
        }

        default:
            return state;
    }
}

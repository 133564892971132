import { archive, sys } from "../../../service";
import {put, select, takeLatest, call, all} from 'redux-saga/effects';
import {getPowerAnalysis} from "../../../config/SDNSelector";
import { antdNotice } from "../../../common/Notification";

export const SHOW_UNIT_LIT = 'SHOW_UNIT_LIT';
export const GET_UNIT_LIST = 'GET_UNIT_LIST';
const SET_UNIT_LIST = 'SET_UNIT_LIST';
const LOADING_GET_UNIT_LIST = 'LOADING_GET_UNIT_LIST';
const REORDERING_UNIT = 'REORDERING_UNIT';
export const REORDER_UNIT = 'REORDER_UNIT';
export const GET_STATIONS_TREE = 'GET_STATIONS_TREE';
const SET_STATIONS_TREE = 'SET_STATIONS_TREE';

export function* getUnitList(action) {
    yield put({ type: LOADING_GET_UNIT_LIST, payload: true});
    const response = yield call(
        archive.ConsumerUnitService.getListBypOrgId,
        action.payload
    );
    yield put({ type: LOADING_GET_UNIT_LIST, payload: false});
    yield put({ type: SET_UNIT_LIST, payload: { orgId: action.payload, data: response.code ? [] : response }});
}

export function* reorderList(action) {
    yield put({ type: REORDERING_UNIT, payload: true});
    const response = yield call(
        archive.ConsumerUnitService.editIx,
        action.payload.pOrgId,
        action.payload.unitId,
        action.payload.type //1增，-1 减
    );
    yield put({ type: REORDERING_UNIT, payload: false});
    if (response.code === 200) {
        antdNotice.success('修改成功');
        yield put({ type: GET_STATIONS_TREE, payload: action.payload.consumerId });
        yield put({ type: GET_UNIT_LIST, payload: action.payload.orgId });
        
    }
    // else {
    //     antdNotice.info('修改失败');
    // }
}

export function* getStationsTree(action) {
    const response = yield call(
        sys.OrgRelationService.getConsumerList,
        action.payload,
        41
    );
    if (!response.code) {
        yield put({ type: SET_STATIONS_TREE, payload: response });
        
    }
    // else {
    //     antdNotice.info('修改失败');
    // }
}

export const SDNUnitConfigSagas = [
    takeLatest(GET_UNIT_LIST, getUnitList),
    takeLatest(REORDER_UNIT, reorderList),
    takeLatest(GET_STATIONS_TREE, getStationsTree)
];

//reducer
export function SDNUnitConfigReducer(state = {
    showUnitList: false,
    unitList: {},
    loadingUnitList: false,
    reorderingUnit: false,
    treeData: []
}, action) {
    switch (action.type) {

        case SHOW_UNIT_LIT: {
            return { ...state, showUnitList: action.payload };
        }

        case LOADING_GET_UNIT_LIST: {
            return { ...state, loadingUnitList: action.payload };
        }

        case SET_UNIT_LIST: {
            return { ...state, unitList: action.payload };
        }

        case REORDERING_UNIT: {
            return { ...state, reorderingUnit: action.payload };
        }

        case SET_STATIONS_TREE: {
            const treeData = {
                key: action.payload.orgId,
                title: action.payload.orgName,
                orgCode: action.payload.orgCode,
                orgType: action.payload.orgType,
                isHeader:true
            };
            if (action.payload.data) {
                const dataLoop = data => {
                    let result = [], item, list, i;
                    for (i = 0; i < data.length; i++) {
                        item = data[i];
                        list = { 
                            key:item.orgId, 
                            title:item.orgName,
                            orgCode:item.orgCode,
                            orgType:item.orgType 
                        };

                        if(item.data) list.children = dataLoop(item.data);
                        result.push(list);
                    }

                    return result;
                }
                
                treeData.children = dataLoop(action.payload.data);
            }
            return { ...state, treeData: treeData }
        }

        default:
            return state;
    }
}
import {put, select, takeLatest, call, all} from 'redux-saga/effects';
import {INIT_PAGE} from "../../../config/SDNActionType";
import {archive} from "../../../service";
import antdNotice from "../../../common/Notification/antdNotice";
import {successBox, warningBox} from "../../../common/utils/functions";

export const GET_POWER_YEAR_DATA = 'GET_POWER_YEAR_DATA';
export const SET_POWER_YEAR_DATA = 'SET_POWER_YEAR_DATA';
export const GET_POWER_MONTH_DATA = 'GET_POWER_MONTH_DATA';
export const SET_POWER_MONTH_DATA = 'SET_POWER_MONTH_DATA';
export const EXPORT_MONTH_DATA = 'EXPORT_MONTH_DATA';
export const EXPORT_YEAR_DATA = 'EXPORT_YEAR_DATA';
export const GET_COEFFICIENT_DATA = 'GET_COEFFICIENT_DATA';
export const SET_COEFFICIENT_DATA = 'SET_COEFFICIENT_DATA';
export const EDIT_COEFFICIENT_DATA = 'EDIT_COEFFICIENT_DATA';
export const EDIT_COEFFICIENT_VISIBLE = 'EDIT_COEFFICIENT_VISIBLE';
export const EDIT_ORG_ID = 'EDIT_ORG_ID';

/**
 * @desc 全年能源账单/全年折标煤查询
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPowerYearData(action) {
  
  const {orgId, startDt, endDt} = action.payload;
  if (orgId) {
    let res = yield call(archive.CoefficientService.getYearData, orgId, startDt, endDt);
    yield put({type: SET_POWER_YEAR_DATA, payload: res.code ? [] : res});
  }
}

/**
 * @desc 全年能源账单/全年折标煤查询
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPowerMonthData(action) {
  const {orgId, dt} = action.payload;
  if (orgId) {
    let res = yield call(archive.CoefficientService.getMonthData, orgId, dt);
    yield put({type: SET_POWER_MONTH_DATA, payload: res.code ? [] : res});
  }
}

/**
 * @desc 全年能源账单/全年折标煤查询
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getCoefficientData(action) {
  let res = yield call(archive.CoefficientService.getAllByOrgId,action.payload);
  yield put({type: SET_COEFFICIENT_DATA, payload: res.code ? [] : res});
}

/**
 * @desc 全年能源账单/全年折标煤查询
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* editCoefficientData(action) {
  const {json,orgId,startDt,endDt}=action.payload
  let res = yield call(archive.CoefficientService.editAll, json);
  
  if (res&&res.code==200) {

    successBox(res.message)
    yield put({type:GET_POWER_YEAR_DATA,payload:action.payload});
    yield put({type:GET_POWER_MONTH_DATA,payload:action.payload});
    yield put({type:EDIT_COEFFICIENT_VISIBLE,payload:false})
  }else {
    warningBox(res.message)
  }
}

/**
 * @desc 导出逐月能源账单/全年折标煤查询
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* exportMonthData(action) {
  const {orgId, dt, type} = action.payload;
  const href = yield call(archive.CoefficientService.exportMonthData, orgId, dt, type);
  window.location.href = href//
}


/**
 * @desc 导出逐月能源账单/全年折标煤查询
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* exportYearData(action) {
  const {orgId, startDt, endDt, type} = action.payload;
  const href = yield call(archive.CoefficientService.exportYearData, orgId, startDt, endDt, type);
  window.location.href = href//
}


export const powerBillSagas = [
  takeLatest(GET_POWER_YEAR_DATA, getPowerYearData),
  takeLatest(GET_POWER_MONTH_DATA, getPowerMonthData),
  takeLatest(GET_COEFFICIENT_DATA, getCoefficientData),
  takeLatest(EDIT_COEFFICIENT_DATA, editCoefficientData),
  takeLatest(EXPORT_MONTH_DATA, exportMonthData),
  takeLatest(EXPORT_YEAR_DATA, exportYearData),
];

//reducer
export function SDNPowerBillReducer(
  state = {
    page: {},
    orgId: "",
    loading: true,
    coefVisible: false,
    yearData: [], //
    monthData: [], //
    coefficient: [],
  },
  action
) {
  switch (action.type) {
    case INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case SET_POWER_YEAR_DATA: {
      return {
        ...state,
        yearData: action.payload
      };
    }
    case SET_POWER_MONTH_DATA: {
      return {
        ...state,
        monthData: action.payload
      };
    }
    case SET_COEFFICIENT_DATA: {
      return {
        ...state,
        coefficient: action.payload
      };
    }
    case EDIT_COEFFICIENT_VISIBLE:
      return {
        ...state,
        coefVisible: action.payload
      }
    case EDIT_ORG_ID:
      return {
        ...state,
        orgId: action.payload
      }
    default:
      return state;
  }
}

import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNEventAlert = Loadable({
  loader: () => import("./components/SDNEventAlert"),
  loading: Loading
});
const SDNAlertConfig = Loadable({
  loader: () => import("./components/SDNAlertConfig"),
  loading: Loading
});
const SDNRTAlertMonitor = Loadable({
  loader: () => import("./components/SDNRTAlertMonitor"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNEventAlert/SDNEventAlert",
    exact: true,
    component: SDNEventAlert,
    name: "SDNEventAlert"
  },
  {
    path: "/SDNAlertConfig/SDNAlertConfig",
    exact: true,
    component: SDNAlertConfig,
    name: "SDNAlertConfig"
  },
  {
    path: "/SDNAlertConfig/SDNRTAlertMonitor",
    exact: true,
    component: SDNRTAlertMonitor,
    name: "SDNRTAlertMonitor"
  }
];

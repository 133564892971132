import { put, select, takeLatest, call } from 'redux-saga/effects';
import {
  GET_ALARM_COUNT,
  GET_ALARM_TOP_LIST,
  SET_ALARM_COUNT,
  SET_ALARM_TOP_LIST
} from "./ActivitiesActionTypes";
import alarm from "../../service/API/alarm";

export function* getAlarmCount (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(alarm.RecordService.getCount,action.payload);
  if (!ret.code) {
    yield put({ type: SET_ALARM_COUNT, payload: ret });
  }
  yield put({type: 'FETCH_SUCCESS'});
}
export function* getAlarmTopList (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(alarm.RecordService.getTop,action.payload);
  if (!ret.code) {
    yield put({ type: SET_ALARM_TOP_LIST, payload: ret });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

const activitiesSagas = [
  takeLatest(GET_ALARM_COUNT, getAlarmCount),
  takeLatest(GET_ALARM_TOP_LIST, getAlarmTopList)
];

export default activitiesSagas;

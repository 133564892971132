import {handleActions} from 'redux-actions';
import {SUBMITE_NEW_UNITTYPE_ROLE_BACK} from "./SDNBaseConfigActionType";

const initalState = {
  page: {},
  role: {},
  selectMenu: [],
  editRole: {},
  menu: [],

  limitTypeArray:[],
  titleInfo:{},
  dateZoneList:[],
  graphEntityList:[],

  graphEntityExtraList: [],
  controlPropList: [],
  dataItemAttrList: [],
  eventList: [],
  realTimeDataList: [],
};

const SDNBaseConfigReducer = handleActions({
  INIT_PAGE: (state, action) => {
    return {
      ...state,
      page: action.payload
    };
  },

  //获取下拉选择框内容
  SET_SELECT_MENU: (state, action) => {
    return {
      ...state,
      selectMenu: action.payload
    }
  },

  SET_ROLE_WITH_FUN: (state, action) => {
    return {
      ...state,
      editRole: action.payload,
    };
  },

  GET_MONI_OB_EXTRA_ROLE_WITH_FUN_BACK: (state, action) => {
    return {
      ...state,
      role: action.payload,
    };
  },

  SET_DEVICETYPE_ROLE_WITH_FUN: (state, action) => {
    // ;
    return {
      ...state,
      role: action.payload,
    };
  },

  CONFIG_SELECTED_FUN: (state, action) => {
    return {
      ...state,
      editRole: configSelectedFunction(state.editRole, action.payload)
    }
  },

  CLEAR_ROLE: (state, action) => {
    return {
      ...state,
      role: {},
    }
  },

  CLEAR_DEVICETYPE_ROLE: (state, action) => {
    return {
      ...state,
      role: {},
    }
  },

  EDIT_ROLE: (state, action) => {
    //
    return {
      ...state,
      editRole: action.payload
    }
  },

  EDIT_DEVICETYPE_ROLE: (state, action) => {
    // ;
    return {
      ...state,
      role: action.payload
    }
  },

  SUBMITE_ADD_ROLE_SUCCESS: (state, action) => {
    // ;
    state.page.addSuccess();
    return state
  },

  SUBMITE_NEW_UNITTYPE_ROLE_BACK: (state, action) => {
    // ;
    if (action.payload.code == 200) {
      state.page.addSuccess();
    }
    return state
  },

  SUBMITE_NEW_GRAPH_ENTITY_ROLE_BACK: (state, action) => {
    // ;
    state.page.addGESuccess();
    return state
  },

  SUBMITE_EDIT_GRAPH_ENTITY_ROLE_BACK: (state, action) => {
    // ;
    state.page.editGESuccess();
    return state
  },

  SUBMITE_EDIT_ROLE_SUCCESS: (state, action) => {
    state.page.editSuccess();
    return {
      ...state,
      editRole: {}
    }
  },

  SUBMITE_EDIT_ROLE_DEFEATE: (state, action) => {
    state.page.editDefeate();
    return {
      ...state,
      editRole: {}
    }
  },

  DATAITEM_ROLE_REMOVED: (state, action) => {
    state.page.removeSuccess();
    return state;
  },

  DATAITEMCONFIG_GET_DATA_ARRAY_BACK: (state, action) => {
    // ;
    return {
      ...state,
      limitTypeArray: action.payload,
    };
  },

  DATAITEMCONFIG_GET_MAIN_TITLE_INFO_BACK: (state, action) => {
    // ;
    return {
      ...state,
      titleInfo: action.payload,
    };
  },

  DATAITEMCONFIG_GET_DATE_ZONE_LIST_BACK: (state, action) => {
    // ;
    return {
      ...state,
      dateZoneList: action.payload,
    };
  },

  GET_MONI_OB_ALL_GRAPH_ENTITY_BACK: (state, action) => {
    //
    return {
      ...state,
      graphEntityList: action.payload,
    };
  },

  GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK: (state, action) => {

    // 加工数组成xedit能解析的item
    var tempList = [];
    action.payload.res.map(
      item => {
        tempList.push({value: item.code, text: item.name})
      }
    );
    // ;
    switch (action.payload.key) {
      case 'realTimeData': return {
        ...state,
        realTimeDataList: tempList,
      };
      case 'event': return {
        ...state,
        eventList: tempList,
      };
      case 'dataItemAttr': return {
        ...state,
        dataItemAttrList: tempList,
      };
      case 'controlProp': return {
        ...state,
        controlPropList: tempList,
      };
      // case 'controlProp': {
      //   state.page.creatControlPropList(action.payload.res);
      //   break;
      // }

    }
  },

  GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST_BACK: (state, action) => {
    // ;
    var tempList = [];
    action.payload.map(
      item => {
        tempList.push({value: item.paramCode, text: item.paramName})
      }
    );
    return {
      ...state,
      graphEntityExtraList: tempList,
    };
  },

  DO_REFLASH_TABLE: (state, action) => {
    // ;
    state.page.reloadTable();
    return state;
  },

}, initalState);

const processMenuTree = (menu, selectedMenus = []) => {
  const list = menu.map((item) => {
    let subMenu = {
      id: item.id,
      expanded: true
    };

    let selected = false;
    for (const funId of selectedMenus) {
      if (subMenu.id == funId) {
        selected = true;
        // console.log("*** " + funId + ' ' + subMenu.id);
        break
      }
    }

    const checkBox = selected ?
      "<input id='checkBox' style='margin-left: 2px; margin-right: 2px;' type=\"checkbox\" name=\"checkbox-inline\" checked />" :
      "<input id='checkBox' style='margin-left: 2px; margin-right: 2px;' type=\"checkbox\" name=\"checkbox-inline\" />"
    if (item.children && item.children.length > 0) {
      subMenu.children = processMenuTree(item.children, selectedMenus);
      subMenu.content = "<span>\
                          <i id='arrow' class=\"fa fa-chevron-down\"></i>"
        + checkBox +
        "<i id='folder' class=\"fa fa-lg fa-folder-open\"></i>" + item.data.funName +
        "</span>";
    }

    if (!subMenu.content) {
      // console.log('#### ' + subMenu.selected);
      subMenu.content = "<span>"
        + checkBox +
        "<i></i>"
        + item.data.funName +
        "</span>";
    }

    // console.log(subMenu.content);
    return subMenu
  });

  return list;
}

const configSelectedFunction = (editRole, selectedFunctionId) => {
  let index = -1;
  for (let i = 0; i < editRole.funIdList.length; ++i) {
    const item = editRole.funIdList[i]
    if (item == selectedFunctionId) {
      index = i;
      break;
    }
  }
  if (index > -1) {
    editRole.funIdList.splice(index, 1);
  } else {
    editRole.funIdList.push(selectedFunctionId);
  }
  return editRole;
}

export default SDNBaseConfigReducer;

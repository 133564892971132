

export const PowerStatistic_initPage = 'PowerStatistic_initPage';

export function SDNPowerStatisticReducer(
    state = {
        page: null,
    },
    action) {
    switch (action.type) {

        case PowerStatistic_initPage: {
            return {
                ...state,
                page:action.page,
            }
        }
        default:
            return state;
    }
}
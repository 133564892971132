import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const CalculateCfg = Loadable({
  loader: () => import("./CalculateCfg/"),
  loading: Loading
});

const DisplayCfg = Loadable({
  loader: () => import("./DisplayCfg/"),
  loading: Loading
});

const FormsCfg = Loadable({
  loader: () => import("./FormsCfg/"),
  loading: Loading
});

const AnalysisCfg = Loadable({
  loader: () => import("./AnalysisCfg/"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNStatisticsCfg/CalculateCfg",
    exact: true,
    component: CalculateCfg,
    name: "CalculateCfg"
  },
  {
    path: "/SDNStatisticsCfg/DisplayCfg",
    exact: true,
    component: DisplayCfg,
    name: "DisplayCfg"
  },
  {
    path: "/SDNStatisticsCfg/FormsCfg",
    exact: true,
    component: FormsCfg,
    name: "FormsCfg"
  },
  {
    path: "/SDNStatisticsCfg/AnalysisCfg",
    exact: true,
    component: AnalysisCfg,
    name: "AnalysisCfg"
  },
];

import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNUserInfo = Loadable({
  loader: () => import("./components/SDNUserInfo"),
  loading: Loading
});

const SDNResetPwd = Loadable({
  loader: () => import("./components/SDNResetPwd"),
  loading: Loading
});

const SDNOperateRecords = Loadable({
  loader: () => import("./components/SDNOperateRecords"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNUserAdmin/SDNUserInfo",
    exact: true,
    component: SDNUserInfo,
    name: "SDNUserInfo"
  },

  {
    path: "/SDNUserAdmin/SDNResetPwd",
    exact: true,
    component: SDNResetPwd,
    name: "SDNResetPwd"
  },

  {
    path: "/SDNUserAdmin/SDNOperateRecords",
    exact: true,
    component: SDNOperateRecords,
    name: "SDNOperateRecords"
  }
];

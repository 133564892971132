import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNLedgerDetail = Loadable({
    loader: () => import("./SDNLedgerDetail"),
    loading: Loading
});

const SDNMonitorsite = Loadable({
    loader: () => import("./components/SDNMonitorsite"),
    loading: Loading
});

const SDNParameter = Loadable({
    loader: () => import("./components/SDNParameter"),
    loading: Loading
});

const SDNTemplate = Loadable({
  loader: () => import("./components/SDNTemplate"),
  loading: Loading
});

const SDNShotPlan = Loadable({
  loader: () => import("./components/SDNShotPlan"),
  loading: Loading
});

const SDNGageNet = Loadable({
  loader: () => import("./components/SDNGageNet"),
  loading: Loading
});

const SDNGageList = Loadable({
  loader: () => import("./components/SDNGageList"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNEquipment/SDNLedgerDetail",
    exact: true,
    component: SDNLedgerDetail,
    name: "SDNLedgerDetail"
  },
  {
    path: "/SDNPowerAdmin/SDNMonitorsite",
    exact: true,
    component: SDNMonitorsite,
    name: "SDNMonitorsite"
  },
  {
    path: "/SDNPowerAdmin/SDNParameter",
    exact: true,
    component: SDNParameter,
    name: "SDNParameter"
  },
  {
    path: "/SDNPowerAdmin/SDNTemplate",
    exact: true,
    component: SDNTemplate,
    name: "SDNTemplate"
  },
  {
    path: "/SDNEquipment/SDNShotPlan",
    exact: true,
    component: SDNShotPlan,
    name: "SDNShotPlan"
  },
  {
    path: "/SDNEquipment/SDNGageNet",
    exact: true,
    component: SDNGageNet,
    name: "SDNGageNet"
  },
  {
    path: "/SDNEquipment/SDNGageList",
    exact: true,
    component: SDNGageList,
    name: "SDNGageList"
  }
];

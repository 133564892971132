import { handleActions } from 'redux-actions';
import $ from "jquery";

const initalState = {
  page: {},
  showEditUserAmin: false,
  groupInfo: {},

  userInfo: {},
  empClassifyId: "",
  associateEnterprises: [],
  roles: [],

  enterpriseInfo: {},
  adminAreas: [],
  industryData: [],
  industrySeletedTab: 0,
  groups: [],
  enterpriseTypes: [],

  newsTypes: [],
  newsInfo: {},
  newsFileRecord: {},

  isShowUpdatePassword: false,
}

const SDNOperationAdminReducer = handleActions({
  OPERATION_INIT_PAGE: (state, action) => {
    return {
      ...state,
      page: action.page
    };
  },

  /** ======================================== 集团管理 =====================================*/

  OPERATION_GROUP_GET_GROUPINFO_SUCCESS: (state, action) => {
    return {
      ...state,
      groupInfo: action.payload
    };
  },

  OPERATION_GROUP_ADD_SUCCESS: (state, action) => {
    state.page.success('新建成功');
    return state;
  },

  OPERATION_GROUP_EDIT_SUCCESS: (state, action) => {
    state.page.success('修改成功');
    return state;
  },

  OPERATION_GROUP_DELETE_SUCCESS: (state, action) => {
    state.page.success('删除成功');
    return state;
  },

  /** ======================================== 企业管理 =====================================*/

  OPERATION_ENTERPRISE_GET_ENTERPRISEINFO_SUCCESS: (state, action) => {
    return {
      ...state,
      enterpriseInfo: action.payload
    };
  },

  OPERATION_ENTERPRISE_GET_ADMIN_AREAS_SUCCESS: (state, action) => {
    return {
      ...state,
      adminAreas: getAdminAreas(action.payload)
    };
  },

  OPERATION_ENTERPRISE_GET_INDUSTRYS_SUCCESS: (state, action) => {
    return {
      ...state,
      industryData: getIndustrys(state.industryData, action.payload)
    };
  },

  OPERATION_ENTERPRISE_GET_GROUPS_SUCCESS: (state, action) => {
    if (action.payload instanceof Array) {
      if (action && action.payload && !action.payload["error"]) {
        return {
          ...state,
          groups: action.payload
        };
      } else {
        return state;
      }
    } else {
      return state;
    }
  },

  OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES_SUCCESS: (state, action) => {
    if (action.payload instanceof Array) {
      return {
        ...state,
        enterpriseTypes: getEnterpriseTypes(action.payload)
      };
    } else {
      return state;
    }
  },

  OPERATION_ENTERPRISE_ADD_SUCCESS: (state, action) => {
    state.page.success('新建成功');
    return state;
  },

  OPERATION_ENTERPRISE_EDIT_SUCCESS: (state, action) => {
    state.page.success('修改成功');
    return state;
  },

  OPERATION_ENTERPRISE_DELETE_SUCCESS: (state, action) => {
    state.page.success('删除成功');
    return state;
  },

  /** ======================================== 用户管理 =====================================*/

  OPERATION_USER_GET_USERINFO_SUCCESS: (state, action) => {
    return {
      ...state,
      userInfo: action.payload
    };
  },

  OPERATION_USER_GET_EMP_CLASSIFYID_SUCCESS: (state, action) => {
    return {
      ...state,
      empClassifyId: getEmpClassifyId(action.payload)
    };
  },

  OPERATION_USER_GET_ASSOCIATE_ENTERPRISES_SUCCESS: (state, action) => {
    if (action.payload instanceof Array) {
      return {
        ...state,
        associateEnterprises: getAssociateEnterprises(action.payload)
      };
    } else {
      return state;
    }
  },

  OPERATION_USER_GET_ROLES_SUCCESS: (state, action) => {
    if (action.payload instanceof Array) {
      return {
        ...state,
        roles: action.payload
      };
    } else {
      return state;
    }
  },

  OPERATION_USER_ADD_SUCCESS: (state, action) => {
    state.page.success('新建成功');
    return state;
  },

  OPERATION_USER_EDIT_SUCCESS: (state, action) => {
    state.page.success('修改成功');
    return state;
  },

  OPERATION_USER_UPDATE_PASSWORD_SUCCESS: (state, action) => {
    state.page.success('修改密码成功');
    return state;
  },

  OPERATION_USER_DELETE_SUCCESS: (state, action) => {
    state.page.success('删除成功');
    return state;
  },

  /** ======================================== 行业资讯 =====================================*/

  OPERATION_NEWS_GET_NEWS_TYPES_SUCCESS: (state, action) => {
    if (action.payload instanceof Array) {
      return {
        ...state,
        newsTypes: action.payload
      };
    } else {
      return state;
    }
  },

  OPERATION_NEWS_GET_NEWSINFO_SUCCESS: (state, action) => {
    return {
      ...state,
      newsInfo: action.payload
    };
  },

  OPERATION_NEWS_GET_FILERECORD_SUCCESS: (state, action) => {
    return {
      ...state,
      newsFileRecord: action.payload
    };
  },

  OPERATION_NEWS_ADD_SUCCESS: (state, action) => {
    state.page.success('新建成功');
    state.page.editSuccess();
    return state;
  },

  OPERATION_NEWS_EDIT_SUCCESS: (state, action) => {
    state.page.success('修改成功');
    state.page.editSuccess();
    return state;
  },

  OPERATION_NEWS_DELETE_SUCCESS: (state, action) => {
    state.page.success('删除成功');
    return state;
  },

  OPERATION_SHOW_EDIT_USER_ADMIN: (state, action) => {
    return { ...state, showEditUserAmin: action.payload };
  },

  OPERATION_USER_SHOW_UPDATE_PASSWORD: (state, action) => {
    return { ...state, isShowUpdatePassword: action.payload };
  }

}, initalState);

const getEmpClassifyId = (array) => {
  for (let index in array) {
    let dict = array[index];
    if (dict) {
      let dictId = dict["dictId"];
      if (dictId) {
        return dictId;
      }
    }
  }

  return "";
}

const getAssociateEnterprises = (array) => {
  let associateEnterprises = [];
  for (let index in array) {
    let dict = array[index];
    if (dict) {
      let state = dict["orgId"];
      if (state.length > 2) {
        associateEnterprises.push(dict);
      }
    }
  }
  return associateEnterprises;
}

const getAdminAreas = (dict) => {
  let adminAreas = [];

  for (let key in dict) {
    let provinceValue = dict[key];
    let provinceData = provinceValue["data"];
    let provinceItems = provinceValue["items"];

    let items = [];
    for (let itemkey in provinceItems) {
      let itemValue = provinceItems[itemkey];
      let itemData = itemValue["data"];
      items.push(itemData);
    }
    provinceData["items"] = items;

    adminAreas.push(provinceData);
  }

  return adminAreas;
}

const getIndustrys = (industryData, threeIndustryData) => {
  $.each(threeIndustryData, function (index, item) {
    var firstData = {
      "industryCode": item.data.industryCode,
      "industryId": item.data.industryId,
      "industryName": item.data.industryName
    };
    $.each(item.items, function (index, item1) {
      var twoData = {
        "industryCode": item1.data.industryCode,
        "industryId": item1.data.industryId,
        "industryName": item1.data.industryName,
        "parentIndustry": firstData
      };
      if (JSON.stringify(item1.items) != '{}') {
        $.each(item1.items, function (index, item2) {
          var three = {
            "industryCode": item2.data.industryCode,
            "industryId": item2.data.industryId,
            "industryName": item2.data.industryName,
            "parentIndustry": twoData
          };
          industryData.push(three)
        });
      }
      industryData.push(twoData)
    });
    industryData.push(firstData)
  });

  return industryData;
}

const getEnterpriseTypes = (array) => {

  let enterprises = [];

  if (array.length > 0) {
    enterprises = array;
  }

  return enterprises;
}

export default SDNOperationAdminReducer;

import * as actionType from "./SDNEquipmentActionTypes";
import { handleActions } from 'redux-actions';
import { archive } from "../../service";
import * as am4core from "@amcharts/amcharts4/core";

export default function SDNEquipmentReducer(
  state = {
    page: {},
    menu: [],
    menuTree: [],
    enterpriseData: [],
    selGroup: {},
    selSite: {},
    siteList: [],
    baseInfo: [],
    extendInfo: [],
    personalityInfo: [],
    personality: [],
    dataItemlist: [],
    renderObjData: [],
    selConsumer: {},
    consumerTree: [],
    titleInfo: [],
    templateTree: [],
    selDataType: '',
    primaryList: [],
    selPrimary: {},
    relationDataItem: [],
    imgData: [],
    fileData: [],
    templateOption: [],
    relationTreeData: [],
    dataItemWithTree: [],
    shotSchemes: [],
    shotScheme: {},
    monitorItemList: [],
    deviceAndAmmeter: [],
    monitorObjectList: [],
    ammeterList: [],
    sjzxAmmerterList: [],
    dataType: '',
    listByPAmmeter: [],
    changeKey: [],
    addableNodeType: [], //可添加下级类型
    showBatchAddMonitorSites: false,
    addingBatchMonitorSites: false,
    checkedMonitorSites: [],
    comDeviceList: [], //通讯管理机列表
    comBindDevicesList: [], //通讯管理机绑定设备

    showMonitorDataChart: false,
    ualaData: {}
  },

  action
) {
  switch (action.type) {
    case actionType.INIT_PAGE:
      return {
        ...state,
        page: action.payload
      };

    case actionType.SITE_TREE_SUCCESS:
      return {
        ...state,
        menu: action.payload,
      };

    case actionType.ENTERPRISE_LIST_SUCCESS:
      return {
        ...state,
        enterpriseData: action.payload
      };

    case actionType.SITE_LIST_BY_ID_SUCCESS: {
      return {
        ...state,
        siteList: action.payload,
      };
    }

    case actionType.SELECT_GROUP: {
      return {
        ...state,
        selGroup: action.payload,
      }
    }

    case actionType.SELET_SITE: {
      return {
        ...state,
        selSite: action.payload,
      }
    }

    case actionType.SELECT_PRIMARY: {
      return {
        ...state,
        selPrimary: action.payload,
      }
    }

    case actionType.BASE_INFO_SUCCESS: {
      return {
        ...state,
        baseInfo: action.payload.res,
        dataType: action.payload.dataType
      };
    }

    case actionType.EXTEND_INFO_SUCCESS: {
      return {
        ...state,
        extendInfo: action.payload
      };
    }

    case actionType.PERSONALITY_INFO_SUCCESS: {
      return {
        ...state,
        personalityInfo: action.payload
      };
    }

    case actionType.SUBMIT_ADD_PERSONALITY_SUCCESS: {
      state.page.addSuccess();
      return {
        ...state,
        personality: action.payload
      };
    }

    case actionType.SUBMIT_EDIT_PERSONALITY_SUCCESS: {
      state.page.editSuccess();
      return {
        ...state,
        personality: action.payload
      };
    }

    case actionType.GET_SELECT_DATAITEMLIST_SUCCESS: {//
      //let selDataType = getDataType(action.payload[0].dataType);
      return { ...state, dataItemlist: action.payload };
    }

    case actionType.GET_DATATYPE_SUCCESS: {//
      let selDataType = getDataType(action.payload);
      return {
        ...state,
        selDataType: selDataType
      };
    }

    case actionType.GET_PRIMARY_BY_SITE_SUCCESS: {//
      return {
        ...state,
        primaryList: action.payload
      };
    }

    case actionType.DATAITEM_BY_PRIMARY_SUCCESS: {//
      return {
        ...state,
        relationDataItem: action.payload
      };
    }

    case actionType.ADD_RELATION_SUCCESS: {//
      //state.page.reloadTbl();
      state.page.addSuccess();
      return {
        ...state
      };
    }

    case actionType.DEL_DITE_SUCCESS: {//
      state.page.reloadTbl();
      state.page.removeSuccess();
      return {
        ...state
      };
    }

    case actionType.ADD_SITE_SUCCESS: {//
      state.page.reloadTbl();
      state.page.addSuccess();
      return {
        ...state
      };
    }

    case actionType.EDIT_SITE_SUCCESS: {//
      state.page.reloadTbl();
      state.page.editSuccess();
      return {
        ...state
      };
    }

    case actionType.SELECT_OBJECT_SUCCESS: {//
      return {
        ...state,
        renderObjData: action.payload
      };
    }

    case actionType.SELECTED_CONSUMER: {////
      return {
        ...state,
        selConsumer: action.payload,
      }
    }

    case actionType.CONSUMER_TREE_SUCCESS: {
      return {
        ...state,
        consumerTree: action.payload,
      };
    }

    case actionType.TITILE_INFO_SUCCESS: {////
      return {
        ...state,
        titleInfo: action.payload,
      };
    }

    case actionType.CHOOSE_NODE_SUCCESS: {//
      return {
        ...state,
        templateTree: action.payload,
      };
    }

    case actionType.ADD_TEMPLATE_SUCCESS: {//
      state.page.reloadTbl();
      state.page.addSuccess();
      return {
        ...state,
      };
    }

    case actionType.REMOVE_TEMPLATE_SUCCESS: {//
      state.page.reloadTbl();
      state.page.removeSuccess();
      return {
        ...state,
      };
    }

    case actionType.EDIT_TEMPLATE_SUCCESS: {//
      state.page.reloadTbl();
      state.page.editSuccess();
      return {
        ...state,
      };
    }

    case actionType.IMG_INFO_SUCCESS: {//
      return {
        ...state,
        imgData: action.payload
      };
    }

    case actionType.FILE_INFO_SUCCESS: {//
      return {
        ...state,
        fileData: action.payload
      };
    }

    case actionType.SUBMIT_ADD_DIAGRAM_SUCCESS: {//
      state.page.reloadDiagram();
      state.page.addSuccess();
      return {
        ...state,
      };
    }

    case actionType.DEL_DIAGRAM_SUCCESS: {//
      state.page.reloadDiagram();
      state.page.removeSuccess();
      return {
        ...state,
      };
    }

    case actionType.SUBMIT_EDIT_DIAGRAM_SUCCESS: {//
      state.page.reloadDiagram();
      state.page.editSuccess();
      return {
        ...state,
      };
    }

    case actionType.TEMPLATE_OPTION_SUCCESS: {//
      return {
        ...state,
        templateOption: action.payload
      };
    }
    case actionType.GET_RELATION_TREE_SUCCESS: {//
      return {
        ...state,
        relationTreeData: action.payload
      };
    }

    case actionType.REMOVE_SUB_LEVEL_SUCCESS: {
      state.page.removeSuccess();
      state.page.reloadSubLevelTable();
      return state;
    }

    case actionType.GET_DATAITEM_WITH_TREE_SUCCESS: {
      return {
        ...state,
        dataItemWithTree: action.payload
      };
    }
    case actionType.UPGRADE_DEGRAD_SUB_LEVEL_SUCCESS: {
      state.page.editSuccess();
      state.page.reloadSubLevelTable();
      return state;
    }

    case actionType.ADD_SUB_LEVEL_SUCCESS: {
      state.page.addSuccess();
      state.page.reloadSubLevelTable();
      return state;
    }

    case actionType.GET_ADDABLE_NODE_TYPE_SUCCESS: {
      return {
        ...state,
        addableNodeType: action.payload
      };
    }

    case actionType.SET_SHOOTING_SCHEMES_BY_ID: {
      return {
        ...state,
        shotSchemes: getShotSchemesFormat(action.payload)
      };
    }
    case actionType.SET_MONITOR_ITEM_LIST: {
      return {
        ...state,
        monitorItemList: action.payload
      };
    }
    case actionType.SET_SHOOTING_SCHEME: {
      return {
        ...state,
        shotScheme: action.payload
      };
    }
    case actionType.EDIT_SHOOTING_SCHEME_ITEM: {
      return {
        ...state,
        shotScheme: action.payload
      };
    }
    case actionType.EDIT_SHOOTING_SCHEME_SUCCESS: {
      return {
        ...state,
        shotScheme: {}
      };
    }
    case actionType.GET_DEVICE_AMMETER_SUCCESS: {
      return {
        ...state,
        deviceAndAmmeter: action.payload
      };
    }
    case actionType.GET_LIST_BY_MONITOROBJECT_SUCCESS: {
      return {
        ...state,
        monitorObjectList: action.payload
      };
    }
    case actionType.GET_LIST_BY_COMSUMERID_SUCCESS: {
      return {
        ...state,
        ammeterList: action.payload
      };
    }
    case actionType.GET_SJZXAMMETERS_SUCCESS: {
      return {
        ...state,
        sjzxAmmerterList: action.payload
      };
    }
    case actionType.SUBMIT_ADD_MULTI_SUCCESS: {
      state.page.reloadDiagram();
      state.page.addSuccess();
      return {
        ...state,
      };
    }
    case actionType.DEL_MONITOR_RELATION_SUCCESS: {
      state.page.reloadDiagram();
      state.page.removeSuccess();
      return {
        ...state,
      };
    }
    case actionType.GET_LIST_BY_PAMMETER_SUCCESS: {
      return {
        ...state,
        listByPAmmeter: action.payload
      };
    }
    case actionType.EDIT_RECALCULTION_SUCCESS: {
      state.page.recalculateSuccess();
      return {
        ...state,
      };
    }
    case actionType.CHANGE_TREE_NODE_SUCCESS: {
      return {
        ...state,
        changeKey: [action.payload]
      };
    }
    case actionType.CHNANGE_TITLEINFO_SUCCESS: {
      return {
        ...state
      };
    }

    case actionType.SHOW_BATCH_ADD_MONITOR_SITES: {
      return { ...state, showBatchAddMonitorSites: action.payload };
    }

    case actionType.ADDING_BATCH_MONITOR_SITES: {
      return { ...state, addingBatchMonitorSites: action.payload };
    }

    case actionType.BATCH_CHECKED_MONITOR_SITES: {
      return { ...state, checkedMonitorSites: action.payload };
    }

    case actionType.SHOW_MONITOR_DATA_CHART: {
      return { ...state, showMonitorDataChart: action.payload }
    }

    case actionType.SET_QUERY_UALA: {
      return { ...state, ualaData: action.payload }
    }

    case actionType.SET_COM_DEVICE_LIST: {

      return { ...state, comDeviceList: action.payload.map(item => {
        return { text: item.orgName, value: item.deviceId }
      }) }
    }

    case actionType.SET_COM_DEVICE_BIND_DEVICES_LIST: {
      return { ...state, comBindDevicesList: action.payload }
    }

    default:
      return state;
  }

};
const getShotSchemesFormat = data => {
  let colorSet = new am4core.ColorSet();
  colorSet.saturation = 0.4;
  let chartData = [];
  for (let i = 0; i < data.length; i++) {
    let datum = data[i];
    if (datum && datum.segments && datum.segments.length > 0) {
      for (let j = 0; j < datum.segments.length; j++) {
        let segment = datum.segments[j];
        let chartItem = {
          name: datum.monitorItemName,
          fromDate: "2019-01-01 " + segment.start,
          toDate: "2019-01-01 " + segment.end,
          trip: segment.start + "-" + segment.end,
          color: colorSet.getIndex(i).brighten(0.4)
        };
        chartData.push(chartItem);
      }

    }

  }

  return chartData;
}
const getDataType = (data) => {
  let state = '';
  if (data == 1) {
    state = '遥测';
  } else if (data == 2) {
    state = '遥信';
  } else if (data == 3) {
    state = '遥视';
  } else if (data == 4) {
    state = '遥调';
  } else if (data == 5) {
    state = '遥控';
  }
  return state;
}

/**
 * version  0.1
 * date     2019/8/22 9:44
 * author   caojiaxu
 * desc
 */
import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import { efficiency } from "../../../service";
import { passType } from "./components/PassTableView";
import { getElectricQuality } from "../../../config/SDNSelector";
import moment from "moment";
import antdNotice from "../../../common/Notification/antdNotice";

export const INIT_PAGE = "INIT_PAGE";
export const GET_VOLTAGE_TREND = "GET_VOLTAGE_TREND";
export const SET_VOLTAGE_TREND = "SET_VOLTAGE_TREND";
export const GET_POWER_TREND = "GET_POWER_TREND";
export const SET_POWER_TREND = "SET_POWER_TREND";
export const GET_CURRENT_TREND = "GET_CURRENT_TREND";
export const SET_CURRENT_TREND = "SET_CURRENT_TREND";
export const GET_ELECTRIC_STANDARD = "GET_ELECTRIC_STANDARD";
export const SET_VOLTAGE_STANDARD = "SET_VOLTAGE_STANDARD";
export const SET_CURRENT_STANDARD = "SET_CURRENT_STANDARD";
export const SET_POWER_STANDARD = "SET_POWER_STANDARD";
export const GET_VOLTAGE_OVER = "GET_VOLTAGE_OVER";
export const SET_VOLTAGE_OVER = "SET_VOLTAGE_OVER";
export const GET_POWER_OVER = "GET_POWER_OVER";
export const SET_POWER_OVER = "SET_POWER_OVER";
export const GET_CURRENT_OVER = "GET_CURRENT_OVER";
export const SET_CURRENT_OVER = "SET_CURRENT_OVER";
export const EDIT_EQ_PARAMS = "EDIT_EQ_PARAMS";
export const SET_EQ_PARAMS = "SET_EQ_PARAMS";
export const GET_VOLTAGE_DATA = "GET_VOLTAGE_DATA";
export const GET_POWER_DATA = "GET_POWER_DATA";
export const GET_CURRENT_DATA = "GET_CURRENT_DATA";
export const SET_POWER_STANDARD_LINE = "SET_POWER_STANDARD_LINE";
export const SET_CURRENT_STANDARD_LINE = "SET_CURRENT_STANDARD_LINE";
export const SET_POWER_STANDARD_SUCCESS = "SET_POWER_STANDARD_SUCCESS";
export const EDIT_SHOW_SET_STANDARD = "EDIT_SHOW_SET_STANDARD";
export const EDIT_STANDARD_TYPE = "EDIT_STANDARD_TYPE";
export const SET_STANDARD_INIT_VALUE = "SET_STANDARD_INIT_VALUE";

export function* editEqParams(action) {
  const electricQuality = yield select(getElectricQuality);
  let { node, date, orgId } = action.payload;
  let { eqParams } = electricQuality
  if (node) {
    eqParams.orgId = node.key;
    eqParams.dataAmmeterId = orgId || '';
  }
  if (date) {
    eqParams.date = date;
  }
  yield put({ type: SET_EQ_PARAMS, payload: { ...eqParams, date: date } });
}


export function* getVoltageData(action) {
  let payload = { ...action.payload, passType: passType.VOLTAGE };
  yield put({ type: GET_VOLTAGE_TREND, payload });
  yield put({ type: GET_ELECTRIC_STANDARD, payload });
  yield put({ type: GET_VOLTAGE_OVER, payload });
}

export function* getPowerData(action) {
  let payload = { ...action.payload, passType: passType.POWER };
  yield put({ type: GET_POWER_TREND, payload });
  yield put({ type: GET_ELECTRIC_STANDARD, payload });
  yield put({ type: GET_POWER_OVER, payload });

}

export function* getCurrentData(action) {
  let payload = { ...action.payload, passType: passType.CURRENT };
  
  yield put({ type: GET_CURRENT_TREND, payload });
  yield put({ type: GET_ELECTRIC_STANDARD, payload });
  yield put({ type: GET_CURRENT_OVER, payload });

}

/**
 * @desc 日电压偏差值趋势
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getVoltageTrend(action) {
  let params = action.payload;
  try {
    const res = yield call(efficiency.QualityService.voltageTrendByAmmeterId, params.orgId, params.date);

    yield put({
      type: SET_VOLTAGE_TREND, payload: res.code ? {
        todayTrend: [],
        yesterdayTrend: []
      } : res
    });
  } catch (e) {
    yield put({
      type: SET_VOLTAGE_TREND, payload: {
        todayTrend: [],
        yesterdayTrend: []
      }
    });
  }

}

/**
 * @desc 日功率因数趋势
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPowerTrend(action) {
  let params = action.payload;
  try {
    const res = yield call(efficiency.QualityService.powerTrendByAmmeterId, params.dataAmmeterId, params.date);

    yield put({
      type: SET_POWER_TREND, payload: res.code ? {
        todayTrend: [],
        yesterdayTrend: []
      } : res
    });
  } catch (e) {
    yield put({
      type: SET_POWER_TREND, payload: {
        todayTrend: [],
        yesterdayTrend: []
      }
    });
  }

}

/**
 * @desc 日功率合格线设置
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* setPowerStandard(action) {
  let params = action.payload;
  try {

    const res = yield call(efficiency.QualityService.powerSetStandardByAmmeterId, params.dataAmmeterId, params.standard);
    if (res.code == 200) {
      antdNotice.info("设置合格线成功");
      yield put({ type: EDIT_SHOW_SET_STANDARD, payload: false });
      yield put({ type: GET_POWER_TREND, payload: { dataAmmeterId: params.dataAmmeterId, date: params.date } });
    }
  } catch (e) {

  }

}

/**
 * @desc 三相电流合格线设置
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* setCurrentStandard(action) {
  let params = action.payload;
  
  try {

    const res = yield call(efficiency.QualityService.balanceSetStandardByAmmeterId, params.dataAmmeterId, params.standard);
    if (res.code == 200) {
      antdNotice.info("设置合格线成功");
      
      yield put({ type: EDIT_SHOW_SET_STANDARD, payload: false });
      yield put({ type: GET_CURRENT_TREND, payload: { orgId: params.dataAmmeterId, date: params.date } });
    }
  } catch (e) {

  }

}

/**
 * @desc 三相电流不平衡趋势
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getCurrentTrend(action) {
  let params = action.payload;
  
  try {
    const res = yield call(efficiency.QualityService.balanceTrendByAmmeterId, params.orgId, params.date);

    yield put({
      type: SET_CURRENT_TREND, payload: res.code ? {
        todayTrend: [],
        yesterdayTrend: []
      } : res
    });
  } catch (e) {
    yield put({
      type: SET_CURRENT_TREND, payload: {
        todayTrend: [],
        yesterdayTrend: []
      }
    });
  }

}

/**
 * @desc 电压超限值统计
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getVoltageOver(action) {
  try {
    let params = action.payload;
    const res = yield call(efficiency.QualityService.voltageOverByAmmeterId, params.orgId, params.dateStart, params.dateEnd, params.type, params.pageSize, params.current);
    // const res = yield call(efficiency.QualityService.voltageOver, params.orgId, params.dateStart, params.dateEnd, params.type,params.pageSize,params.current);
    yield put({
      type: SET_VOLTAGE_OVER,
      payload: res.code ? { list: [], pagination: defPagination } : res
    });

  } catch (e) {

    yield put({
      type: SET_VOLTAGE_OVER,
      payload: { list: [], pagination: defPagination }
    });
  }
}

/**
 * @desc 功率值统计
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPowerOver(action) {
  try {
    let params = action.payload;
    const res = yield call(efficiency.QualityService.powerOverByAmmeterId, params.orgId, params.dateStart, params.dateEnd, params.pageSize, params.current);
    yield put({
      type: SET_POWER_OVER,
      payload: res.code ? { list: [], pagination: defPagination } : res
    });

  } catch (e) {

    yield put({
      type: SET_POWER_OVER,
      payload: { list: [], pagination: defPagination }
    });
  }
}

/**
 * @desc 三相电流值统计
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getCurrentOver(action) {
  //TODO pageSize和current在三相电流会置空，空默认10和1
  try {
    let params = action.payload;
    const res = yield call(efficiency.QualityService.balanceOverByAmmeterId, params.orgId || "", params.dateStart, params.dateEnd, params.pageSize || 10, params.current || 1);


    yield put({
      type: SET_CURRENT_OVER,
      payload: res.code ? { list: [], pagination: defPagination } : res
    });

  } catch (e) {

    yield put({
      type: SET_CURRENT_OVER,
      payload: { list: [], pagination: defPagination }
    });
  }
}

/**
 * @desc 合格率
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getStandard(action) {
  let params = action.payload;
  switch (params.passType) {

    case passType.VOLTAGE: {
      try {

        const res = yield call(efficiency.QualityService.voltageStandardByAmmeterId, params.orgId, params.dateStart, params.dateEnd);

        yield put({
          type: SET_VOLTAGE_STANDARD,
          payload: res.code ? { deviant: [], standard: [] } : res
        });
      } catch (e) {
        yield put({
          type: SET_VOLTAGE_STANDARD,
          payload: { deviant: [], standard: [] }
        });
      }
      break;
    }
    case passType.POWER: {
      try {

        const res = yield call(efficiency.QualityService.powerStandardByAmmeterId, params.orgId, params.dateStart, params.dateEnd);
        yield put({
          type: SET_POWER_STANDARD,
          payload: res.code ? { deviant: [], standard: [] } : res
        });
      } catch (e) {
        yield put({
          type: SET_POWER_STANDARD,
          payload: { deviant: [], standard: [] }
        });
      }
      break;
    }
    case passType.CURRENT: {
      try {

        const res = yield call(efficiency.QualityService.balanceStandardByAmmeterId, params.orgId, params.dateStart, params.dateEnd);
        yield put({
          type: SET_CURRENT_STANDARD,
          payload: res.code ? { deviant: [], standard: [] } : res
        });
      } catch (e) {
        yield put({
          type: SET_CURRENT_STANDARD,
          payload: { deviant: [], standard: [] }
        });
      }
      break;
    }
  }

}

export const electricQualitySagas = [
  takeLatest(EDIT_EQ_PARAMS, editEqParams),
  takeLatest(GET_VOLTAGE_DATA, getVoltageData),
  takeLatest(GET_VOLTAGE_TREND, getVoltageTrend),
  takeLatest(GET_VOLTAGE_OVER, getVoltageOver),
  takeLatest(GET_ELECTRIC_STANDARD, getStandard),
  takeLatest(GET_POWER_DATA, getPowerData),
  takeLatest(GET_POWER_TREND, getPowerTrend),
  takeLatest(GET_POWER_OVER, getPowerOver),
  takeLatest(SET_POWER_STANDARD_LINE, setPowerStandard),
  takeLatest(SET_CURRENT_STANDARD_LINE, setCurrentStandard),
  takeLatest(GET_CURRENT_DATA, getCurrentData),
  takeLatest(GET_CURRENT_TREND, getCurrentTrend),
  takeLatest(GET_CURRENT_OVER, getCurrentOver),
];
export const FORMAT_DAY = "YYYY-MM-DD"

const defPagination = {
  total: 0,
  current: 1,
  pageSize: 10,
  size: "middle",
  showSizeChanger: true,
  showQuickJumper: true,
};

//reducer
export function SDNElectricQualityReducer(
  state = {
    page: {},
    node: {},//树节点
    standardType: 0,
    voltageTrend: {
      top: 0,
      floor: 0,
      todayTrend: [],
      yesterdayTrend: [],
    },//日电压偏差值趋势
    powerTrend: {
      standard: 0,
      todayTrend: [],
      yesterdayTrend: [],
    },//日功率偏差值趋势
    currentTrend: {
      standard: 0,
      todayTrend: [],
      yesterdayTrend: [],
    },//三相电流偏差值趋势
    voltagePassPercent: {
      deviant: [],
      standard: []
    },//电压合格率
    powerPassPercent: {
      deviant: [],
      standard: []
    },//功率合格率
    currentPassPercent: {
      deviant: [],
      standard: []
    },//电流合格率
    voltageStatistic: {
      pagination: defPagination
    },//电压統計
    powerStatistic: {
      pagination: defPagination
    },//功率統計
    currentStatistic: {
      pagination: defPagination
    },//三相电流統計,
    eqParams: {
      orgId: "",
      dataAmmeterId: "",
      date: moment().format(FORMAT_DAY),
      dateStart: moment().startOf('month').format(FORMAT_DAY),
      dateEnd: moment().format(FORMAT_DAY),
      type: 2,
      pageSize: 10,
      current: 1
    },
    initStandardValue: null,
    showSetStandardLine: false,

  },
  action
) {

  switch (action.type) {
    case INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case SET_VOLTAGE_STANDARD: {
      return {
        ...state,
        voltagePassPercent: action.payload
      };
    }
    case SET_POWER_STANDARD: {

      return {
        ...state,
        powerPassPercent: action.payload
      };
    }
    case SET_CURRENT_STANDARD: {
      return {
        ...state,
        currentPassPercent: action.payload
      };
    }
    case SET_VOLTAGE_TREND: {

      return {
        ...state,
        voltageTrend: action.payload
      };
    }
    case SET_POWER_TREND: {

      return {
        ...state,
        powerTrend: action.payload
      };
    }
    case SET_CURRENT_TREND: {

      return {
        ...state,
        currentTrend: action.payload
      };
    }
    case SET_VOLTAGE_OVER: {
      let pagination = {
        ...action.payload.pagination,
        size: "middle",
        showSizeChanger: true,
        showQuickJumper: true,
      }
      return {
        ...state,
        voltageStatistic: { ...action.payload, pagination: pagination }
      };
    }
    case SET_POWER_OVER: {
      let pagination = {
        ...action.payload.pagination,
        size: "middle",
        showSizeChanger: true,
        showQuickJumper: true,
      }
      return {
        ...state,
        powerStatistic: { ...action.payload, pagination: pagination }
      };
    }
    case SET_CURRENT_OVER: {
      let pagination = {
        ...action.payload.pagination,
        size: "middle",
        showSizeChanger: true,
        showQuickJumper: true,
      }
      return {
        ...state,
        currentStatistic: { ...action.payload, pagination: pagination }
      };
    }
    case SET_EQ_PARAMS: {
      return {
        ...state,
        eqParams: action.payload
      };
    }
    case EDIT_SHOW_SET_STANDARD: {
      return {
        ...state,
        showSetStandardLine: action.payload
      };
    }
    case EDIT_STANDARD_TYPE: {
      return {
        ...state,
        standardType: action.payload
      };
    }
    case SET_POWER_STANDARD_SUCCESS: {
      return {
        ...state,
      };
    }
    case SET_STANDARD_INIT_VALUE: {
      return {
        ...state,
        initStandardValue: action.payload
      };
    }

    default:
      return state;
  }
}

import React from "react";
import { Icon, Button, Avatar } from 'antd';
import SDNUserMenu from "./SDNUserMenu";
import { connect } from "react-redux";
import { serverUrl } from '../../service';
import Flex, { FlexItem } from 'styled-flex-component';
import { SHOW_SIDER_BAR } from "../LayoutActions";

class LoginInfo extends React.Component {
  componentWillMount() {}

  imgOnError = (e)=>{
    let img = e.target;
    img.src = "assets/img/avatars/1.png";
  }

  handleShowSiderBar = show => {
    this.props.handleShowSiderBar(show);
  }

  render() {
    const { user, selectedFirm } = this.props;
    const title = user.accountType == 1 ? '深电能科技集团有限公司' : selectedFirm.name || '';
    const imageUrl = serverUrl + (user.filePath || '');
    if (this.props.showSiderBar === false) {
      return (
        <Flex column alignCenter style={{marginTop:10}}>
          <Avatar size="large" icon="user" src={imageUrl} shape={'square'}/>
          <Button 
            icon={'right'}
            style={{ background: 'transparent', color: '#aaa', marginTop: 10, border: 'none', width: 45, height: 27 }}
            onClick={() => this.handleShowSiderBar(true)}
          >
          </Button>
        </Flex>
      )
    }
    return (
      <Flex column style={{background: '#001529', padding:'15px 0 10px 15px'}}>
          <Flex style={{flex: 1}} justifyBetween>
          <Avatar size="large" icon="user" src={imageUrl} />
          <Button 
            icon={'left'}
            onClick={() => this.handleShowSiderBar(false)} 
            style={{ marginBottom: 16, background: 'transparent', color: '#aaa', border: 'none', width: 45, height: 27 }}
          >
          </Button>
          </Flex>
          <SDNUserMenu />
          <FlexItem style={{fontSize: 12, color: '#FFF', marginTop: 5}}>{title}</FlexItem>
      </Flex>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.system.user,
    selectedFirm: state.system.selectedFirm,
    showSiderBar: state.layout.showSiderBar
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleShowSiderBar: (show) => dispatch({ type: SHOW_SIDER_BAR, payload: show })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginInfo);

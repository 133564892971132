import React from 'react';
import { Select } from 'antd';

export default class SDNDropDownMenu extends React.Component {

  title = '';

  static defaultProps = {
    defaultValue: undefined,
    value: undefined,
    items: []
  }
  constructor (props) {
    super(props);
  }

  componentWillReceiveProps (props) {
  }

  handleClick = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render () {
    const {
      defaultValue,
      value,
      items
    } = this.props;

    let props = defaultValue !== undefined ? { defaultValue: defaultValue } : { value: value };
    //
    return (
      <Select defaultValue={defaultValue} value={value} onChange={value => this.handleClick(value)}>
        {
          items.map((item, index) => {
            return <Select.Option value={item.value} key={index}>{item.title}</Select.Option>
          })
        }
      </Select>
    )
  }
}

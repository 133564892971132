import { sys, archive } from "../../service";
import { put, select, takeLatest, call } from 'redux-saga/effects';
import * as actionType from './SDNUserAdminActionTypes';
import { successBox } from "../../common";
import { getSystem } from '../../config';
import { GET_USER_INFO } from "../../common/system";

export function* resetPwd (action) {
  const system = yield select(getSystem);
  const { user } = system;
  yield put({type: 'FETCH_REQUEST' });
  const {
    pwd,
    newPwd
  } = action.payload;
  const ret = yield call(sys.OrgEmployeeService.updatePassword, user.orgId, pwd, newPwd);
  if (ret.code == 200) {
    successBox('密码修改成功');
    yield put({ type: actionType.RESET_PWD_SUCCESS, payload: ret });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

export function* getGroupFirmList (action) {
  yield put({type: 'FETCH_REQUEST' });
  const ret = yield call(archive.ConsumerService.getConsumerAndGroupByEmp);
  if (!ret.code) {
    yield put({ type: actionType.GET_GROUP_FIRM_LIST_SUCCESS, payload: ret });
  }
  yield put({type: 'FETCH_SUCCESS'});
}

const userAdminSagas = [
  takeLatest(actionType.RESET_PWD, resetPwd),
  takeLatest(actionType.GET_GROUP_FIRM_LIST, getGroupFirmList)
];

export default userAdminSagas;

import { BaseService } from '../SDNRequest';

const alarm = {
	    //综合告警事件配置	    
        EventConfigGrafanaService :{
			
			//增加综合告警事件配置对象
			add : function(mapParams) {
						return BaseService.post('/api/alarm/eventConfigGrafana/add',mapParams);
			},
			
			//删除综合告警事件配置对象
			del : function(id) {
				return BaseService.post('/api/alarm/eventConfigGrafana/del',{
						'id':id
					});
			},
			
			//修改综合告警事件配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/alarm/eventConfigGrafana/edit',mapParams);
			},
			
			//修改综合告警事件配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/alarm/eventConfigGrafana/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出综合告警事件配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/alarm/eventConfigGrafana/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取综合告警事件配置对象
			get : function(id) {
				return BaseService.get('/api/alarm/eventConfigGrafana/get',{
						'id':id
					});
			},
			
			//查询所有综合告警事件配置对象
			getAll : function() {
				return BaseService.get('/api/alarm/eventConfigGrafana/getall');
			},
			
			//查询所有综合告警事件配置对象
			getBySiteDataTable : function(dataTable, siteId) {
				return BaseService.get('/api/alarm/eventConfigGrafana/getBySiteDataTable',{
						'dataTable':dataTable, 'siteId':siteId
					});
			},
			
			//DataTable查询综合告警事件配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/alarm/eventConfigGrafana/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示综合告警事件配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/alarm/eventConfigGrafana/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //联动记录	    
        LinkageRecordService :{
			
			//增加联动记录对象
			add : function(mapParams) {
						return BaseService.post('/api/alarm/linkageRecord/add',mapParams);
			},
			
			//删除联动记录对象
			del : function(id) {
				return BaseService.post('/api/alarm/linkageRecord/del',{
						'id':id
					});
			},
			
			//修改联动记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/alarm/linkageRecord/edit',mapParams);
			},
			
			//修改联动记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/alarm/linkageRecord/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出联动记录对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/alarm/linkageRecord/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取联动记录对象
			get : function(id) {
				return BaseService.get('/api/alarm/linkageRecord/get',{
						'id':id
					});
			},
			
			//查询所有联动记录对象
			getAll : function() {
				return BaseService.get('/api/alarm/linkageRecord/getall');
			},
			
			//根据告警记录ID获取联动记录对象列表
			getByAlarmRecordId : function(alarmRecordId) {
				return BaseService.get('/api/alarm/linkageRecord/getByAlarmRecordId',{
						'alarmRecordId':alarmRecordId
					});
			},
			
			//DataTable查询联动记录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/alarm/linkageRecord/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示联动记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/alarm/linkageRecord/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据实体条件获取联动记录对象列表
			search : function(linkageRecord) {
				return BaseService.get('/api/alarm/linkageRecord/search',{
						'linkageRecord':linkageRecord
					});
			},
		},
	    //告警事件配置	    
        EventConfigService :{
			
			//增加告警事件配置对象
			add : function(mapParams) {
						return BaseService.post('/api/alarm/eventConfig/add',mapParams);
			},
			
			//删除告警事件配置对象
			del : function(id) {
				return BaseService.post('/api/alarm/eventConfig/del',{
						'id':id
					});
			},
			
			//修改告警事件配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/alarm/eventConfig/edit',mapParams);
			},
			
			//修改告警事件配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/alarm/eventConfig/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出告警事件配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/alarm/eventConfig/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取告警事件配置对象
			get : function(id) {
				return BaseService.get('/api/alarm/eventConfig/get',{
						'id':id
					});
			},
			
			//查询所有告警事件配置对象
			getAll : function() {
				return BaseService.get('/api/alarm/eventConfig/getall');
			},
			
			//DataTable查询告警事件配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/alarm/eventConfig/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询事件配置（若没有从iot获取）
			getEventFromIot : function(deviceId, isCheckAndUpdate) {
				return BaseService.get('/api/alarm/eventConfig/getEventFromIot',{
						'deviceId':deviceId, 'isCheckAndUpdate':isCheckAndUpdate
					});
			},
			
			//查询事件配置（若没有从iot获取）
			getEventFromIotDataTable : function(dataTable, monitorObjectId, isCheckAndUpdate) {
				return BaseService.get('/api/alarm/eventConfig/getEventFromIotDataTable',{
						'dataTable':dataTable, 'monitorObjectId':monitorObjectId, 'isCheckAndUpdate':isCheckAndUpdate
					});
			},
			
			//分页显示告警事件配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/alarm/eventConfig/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //联动配置	    
        LinkageConfigService :{
			
			//增加联动配置对象
			add : function(mapParams) {
						return BaseService.post('/api/alarm/linkageConfig/add',mapParams);
			},
			
			//增加联动配置对象
			copy : function(linkageId) {
				return BaseService.post('/api/alarm/linkageConfig/copy',{
						'linkageId':linkageId
					});
			},
			
			//删除联动配置对象
			del : function(id) {
				return BaseService.post('/api/alarm/linkageConfig/del',{
						'id':id
					});
			},
			
			//修改联动配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/alarm/linkageConfig/edit',mapParams);
			},
			
			//修改联动配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/alarm/linkageConfig/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出联动配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/alarm/linkageConfig/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取联动配置对象
			get : function(id) {
				return BaseService.get('/api/alarm/linkageConfig/get',{
						'id':id
					});
			},
			
			//查询所有联动配置对象
			getAll : function() {
				return BaseService.get('/api/alarm/linkageConfig/getall');
			},
			
			//DataTable查询联动配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/alarm/linkageConfig/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable 通过事件查询
			getDataTableByEvent : function(eventId, dataTable) {
				return BaseService.get('/api/alarm/linkageConfig/getDataTableByEvent',{
						'eventId':eventId, 'dataTable':dataTable
					});
			},
			
			//分页显示联动配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/alarm/linkageConfig/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //告警记录	    
        RecordService :{
			
			//增加告警记录对象
			add : function(mapParams) {
						return BaseService.post('/api/alarm/record/add',mapParams);
			},
			
			//告警记录确认
			confirm : function(alarmRecordId, confirmInfo) {
				return BaseService.post('/api/alarm/record/confirm',{
						'alarmRecordId':alarmRecordId, 'confirmInfo':confirmInfo
					});
			},
			
			//删除告警记录对象
			del : function(id) {
				return BaseService.post('/api/alarm/record/del',{
						'id':id
					});
			},
			
			//修改告警记录对象
			edit : function(mapParams) {
						return BaseService.post('/api/alarm/record/edit',mapParams);
			},
			
			//修改告警记录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/alarm/record/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出告警记录对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/alarm/record/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取告警记录对象
			get : function(id) {
				return BaseService.get('/api/alarm/record/get',{
						'id':id
					});
			},
			
			//查询所有告警记录对象
			getAll : function() {
				return BaseService.get('/api/alarm/record/getall');
			},
			
			//获取告警数量
			getCount : function(mapParams) {
						return BaseService.get('/api/alarm/record/getCount',mapParams);
			},
			
			//DataTable查询告警记录对象
			getDataTable : function(dataTable, startTime, endTime, dateType, enterpriseId, siteId, eventType, alarmStatus, process) {
				return BaseService.get('/api/alarm/record/getdatatable',{
						'dataTable':dataTable, 'startTime':startTime, 'endTime':endTime, 'dateType':dateType, 'enterpriseId':enterpriseId, 'siteId':siteId, 'eventType':eventType, 'alarmStatus':alarmStatus, 'process':process
					});
			},
			
			//DataTable查询告警记录对象
			getDataTablePageAnt : function(startTime, endTime, enterpriseId, siteId, monitorId, process, current, pageSize, sorter, ascend) {
				return BaseService.get('/api/alarm/record/getDataTablePageAnt',{
						'startTime':startTime, 'endTime':endTime, 'enterpriseId':enterpriseId, 'siteId':siteId, 'monitorId':monitorId, 'process':process, 'current':current, 'pageSize':pageSize, 'sorter':sorter, 'ascend':ascend
					});
			},
			
			//分页显示告警记录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/alarm/record/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//统计数据查询
			getStatistics : function(type, enterpriseId, siteId) {
				return BaseService.get('/api/alarm/record/getStatistics',{
						'type':type, 'enterpriseId':enterpriseId, 'siteId':siteId
					});
			},
			
			//获取前n条记录
			getTop : function(enterpriseId, count) {
				return BaseService.get('/api/alarm/record/getTop',{
						'enterpriseId':enterpriseId, 'count':count
					});
			},
		},
 
}

export default alarm;
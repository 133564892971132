import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const SDNDataItem = Loadable({
  loader: () => import("./components/SDNDataItem"),
  loading: Loading
});

const SDNDataItemConfig = Loadable({
  loader: () => import("./components/SDNDataItemConfig"),
  loading: Loading
});

const SDNAlarmCompose = Loadable({
  loader: () => import("./components/SDNAlarmCompose"),
  loading: Loading
});

const SDNDeviceType = Loadable({
  loader: () => import("./components/SDNDeviceType"),
  loading: Loading
});

const SDNMonitorObjectType = Loadable({
  loader: () => import("./components/SDNMonitorObjectType"),
  loading: Loading
});

const SDNMonitorObjectTypeExtra = Loadable({
  loader: () => import("./components/SDNMonitorObjectTypeExtra"),
  loading: Loading
});

const SDNMonitorObjectTypeConfig = Loadable({
  loader: () => import("./components/SDNMonitorObjectTypeConfig"),
  loading: Loading
});

const SDNUnitType = Loadable({
  loader: () => import("./components/SDNUnitType"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNBaseConfig/SDNDataItem",
    exact: true,
    component: SDNDataItem,
    name: "SDNDataItem"
  },
  {
    path: "/SDNBaseConfig/SDNDataItemConfig",
    exact: true,
    component: SDNDataItemConfig,
    name: "SDNDataItemConfig"
  },
  {
    path: "/SDNBaseConfig/SDNAlarmCompose",
    exact: true,
    component: SDNAlarmCompose,
    name: "SDNAlarmCompose"
  },
  {
    path: "/SDNBaseConfig/SDNDeviceType",
    exact: true,
    component: SDNDeviceType,
    name: "SDNDeviceType"
  },
  {
    path: "/SDNBaseConfig/SDNMonitorObjectType",
    exact: true,
    component: SDNMonitorObjectType,
    name: "SDNMonitorObjectType"
  },
  {
    path: "/SDNBaseConfig/SDNMonitorObjectTypeExtra",
    exact: true,
    component: SDNMonitorObjectTypeExtra,
    name: "SDNMonitorObjectTypeExtra"
  },
  {
    path: "/SDNBaseConfig/SDNMonitorObjectTypeConfig",
    exact: true,
    component: SDNMonitorObjectTypeConfig,
    name: "SDNMonitorObjectTypeConfig"
  },
  {
    path: "/SDNBaseConfig/SDNUnitType",
    exact: true,
    component: SDNUnitType,
    name: "SDNUnitType"
  },
];

import { efficiency } from "../../../service";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import moment from 'moment';
import { Utils } from "../../../common";

export const GET_COULOMETRY_CHARGE_DAY_DATA = 'GET_COULOMETRY_CHARGE_DAY_DATA';
export const GET_COULOMETRY_CHARGE_DAY_DATA_SUCCESS = 'GET_COULOMETRY_CHARGE_DAY_DATA_SUCCESS';
export const GET_COULOMETRY_CHARGE_MONTH_DATA = 'GET_COULOMETRY_CHARGE_MONTH_DATA';
export const GET_COULOMETRY_CHARGE_MONTH_DATA_SUCCESS = 'GET_COULOMETRY_CHARGE_MONTH_DATA_SUCCESS';
export const GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA = 'GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA';
export const GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA_SUCCESS = 'GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA_SUCCESS';
export const GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA = 'GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA';
export const GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA_SUCCESS = 'GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA_SUCCESS';
export const GET_COULOMETRY_CHARGE_YEAR_DATA = 'GET_COULOMETRY_CHARGE_YEAR_DATA';
export const GET_COULOMETRY_CHARGE_YEAR_DATA_SUCCESS = 'GET_COULOMETRY_CHARGE_YEAR_DATA_SUCCESS';
export const GET_COULOMETRY_CHARGE_CUSTOM_DATA = 'GET_COULOMETRY_CHARGE_CUSTOM_DATA';
export const GET_COULOMETRY_CHARGE_CUSTOM_DATA_SUCCESS = 'GET_COULOMETRY_CHARGE_CUSTOM_DATA_SUCCESS';

export const SET_MONTH_PP_TREND_DAY = 'SET_MONTH_PP_TREND_DAY';
export const SET_MONTH_CZ_TREND_DAY = 'SET_MONTH_CZ_TREND_DAY';

const SHOW_DAY_LOADING = 'SHOW_COULOMETRY_DAY_LOADING';
const SHOW_MONTH_LOADING = 'SHOW_COULOMETRY_MONTH_LOADING';
const SHOW_SPPV_TREND_LOADING = 'SHOW_COULOMETRY_SPPV_TREND_LOADING';
const SHOW_SPPV_DAY_OF_MONTH_LOADING = 'SHOW_COULOMETRY_SPPV_DAY_OF_MONTH_LOADING';
const SHOW_YEAR_LOADING = 'SHOW_COULOMETRY_YEAR_LOADING';
const SHOW_CUSTOM_LOADING = 'SHOW_COULOMETRY_CUSTOM_LOADING';

const dataCode = 'PP,PP1,PP2,PP3,PP4,CZ,CZ1,CZ2,CZ3,CZ4';

export function* getDayData(action) {
    const dataAmmeterId = action.payload;
    if (Utils.empty(dataAmmeterId)) {
        yield put({
            type: GET_COULOMETRY_CHARGE_DAY_DATA_SUCCESS,
            payload: {
                chart: {},
                period: [],
                statistic: [],
                table: []
            }
        });
        return;
    }
    yield put({ type: SHOW_DAY_LOADING, payload: true });
    const [chartRet, statisticRet, tableRet, periodRet] = yield all([
        call(
            efficiency.ElectricityAnalysisService.getElectricityChargeChart,
            dataAmmeterId,
            201,
            [1, 2, 3, 4].toString(),
            [1].toString()
        ),
        call(
            efficiency.ElectricityAnalysisService.getElectricityChargeStatistics,
            dataAmmeterId,
            [1, 2, 3, 4].toString(),
            [1, 2].toString()
        ),
        call(
            efficiency.ElectricityAnalysisService.getDetailDatasByDay,
            dataAmmeterId
        ),
        call(efficiency.ElectricityAnalysisService.getPeriodSchemes)
    ]);
    yield put({ type: SHOW_DAY_LOADING, payload: false });

    if (!statisticRet.code) {
        for (let item of statisticRet) {
            if (item.dtType === 1) {
                item.dtType = '今日';
            }
            else if (item.dtType === 2) {
                item.dtType = '昨日';
            }
            else if (item.dtType === 3) {
                item.dtType = '上周同日';
            }
            else if (item.dtType === 4) {
                item.dtType = '上月同日';
            }
        }
    }

    let period = [];
    if (!periodRet.code) {
        for (const item of periodRet) {
            period.push([item.beginTime, item.endTime, item.value, item.name]);
        }
    }
    let chart = { dtType1: [], dtType2: [], dtType3: [], dtType4: [] };
    if (!tableRet.code) {
        for (const key in chartRet.PP) {
            if (key !== 'dt') {
                const element = chartRet.PP[key];
                for (let i = 0; i < element.length; ++i) {
                    chart[key].push([i, element[i] || '-']);
                }
            }
        }
    }

    const payload = {
        chart: chart,
        statistic: statisticRet.code ? [] : statisticRet,
        table: tableRet.code ? [] : tableRet,
        period: period
    };

    yield put({
        type: GET_COULOMETRY_CHARGE_DAY_DATA_SUCCESS,
        payload: payload
    });
}

export function* getMonthData(action) {
    const dataAmmeterId = action.payload;
    
    if (Utils.empty(dataAmmeterId)) {
        yield put({
            type: GET_COULOMETRY_CHARGE_MONTH_DATA_SUCCESS,
            payload: {
                chart: {},
                statistic: [],
                thisMonthSPPV: {},
                lastMonthSPPV: {},
                lastYearMonthSPPV: {}
            }
        });
        return;
    }
    yield put({ type: SHOW_MONTH_LOADING, payload: true });
    const [chartRet, statisticRet, thisMonthSPPVRet, lastMonthSPPVRet, lastYearMonthSPPVRet] = yield all([
        //电量电费曲线
        call(
            efficiency.ElectricityAnalysisService.getElectricityChargeChart,
            dataAmmeterId,
            301,
            [5, 6, 7].toString(),
            [1, 2].toString()
        ),
        //电费电量统计数据
        call(
            efficiency.ElectricityAnalysisService.getElectricityChargeStatistics,
            dataAmmeterId,
            [5, 10, 12].toString(),
            [1, 2].toString()
        ),
        //本月峰平谷结构
        call(
            efficiency.EdataInterfaceService.getData,
            dataAmmeterId,
            501,
            dataCode,
            moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
        ),
        //上月峰平谷结构
        call(
            efficiency.EdataInterfaceService.getData,
            dataAmmeterId,
            501,
            dataCode,
            moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss')
        ),
        //去年同月峰平谷结构
        call(
            efficiency.EdataInterfaceService.getData,
            dataAmmeterId,
            501,
            dataCode,
            moment().startOf('month').subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss')
        )
    ]);
    yield put({ type: SHOW_MONTH_LOADING, payload: false });
    if (!statisticRet.code) {
        for (let item of statisticRet) {
            if (item.dtType === 5) {
                item.title = '本月';
            }
            else if (item.dtType === 10) {
                item.title = '同比值';
            }
            else if (item.dtType === 12) {
                item.title = '环比值';
            }
        }
    }

    let payload = {
        chart: chartRet.code ? {} : chartRet,
        statistic: statisticRet.code ? [] : statisticRet,
        thisMonthSPPV: thisMonthSPPVRet.code ? [] : thisMonthSPPVRet,
        lastMonthSPPV: lastMonthSPPVRet.code ? [] : lastMonthSPPVRet,
        lastYearMonthSPPV: lastYearMonthSPPVRet.code ? [] : lastYearMonthSPPVRet
    };

    yield put({
        type: GET_COULOMETRY_CHARGE_MONTH_DATA_SUCCESS,
        payload: payload
    });
}

export function* getSPPVTrendMonthData(action) {
    const {
        dataAmmeterId,
        date
    } = action.payload;
    if (Utils.empty(dataAmmeterId)) {
        yield put({
            type: GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA_SUCCESS,
            payload: {}
        });
        return;
    }
    yield put({ type: SHOW_SPPV_TREND_LOADING, payload: true });
    const ret = yield call(
        efficiency.EdataInterfaceService.getDataList,
        dataAmmeterId,
        301,
        dataCode,
        date.startOf('month').format('YYYY-MM-DD'),
        date.endOf('month').format('YYYY-MM-DD'),
        1
    );
    yield put({ type: SHOW_SPPV_TREND_LOADING, payload: false });
    let data = {
        PP1: [],
        PP2: [],
        PP3: [],
        PP4: [],
        CZ1: [],
        CZ2: [],
        CZ3: [],
        CZ4: []
    }
    if (!ret.code) {
        for (const item of ret) {
            // console.log(item);
            //
            data.PP1.push([moment(item.dt).valueOf(), Utils.empty(item.PP1) ? null : item.PP1]);
            data.PP2.push([moment(item.dt).valueOf(), Utils.empty(item.PP2) ? null : item.PP2]);
            data.PP3.push([moment(item.dt).valueOf(), Utils.empty(item.PP3) ? null : item.PP3]);
            data.PP4.push([moment(item.dt).valueOf(), Utils.empty(item.PP4) ? null : item.PP4]);
            data.CZ1.push([moment(item.dt).valueOf(), Utils.empty(item.CZ1) ? null : item.CZ1]);
            data.CZ2.push([moment(item.dt).valueOf(), Utils.empty(item.CZ2) ? null : item.CZ2]);
            data.CZ3.push([moment(item.dt).valueOf(), Utils.empty(item.CZ3) ? null : item.CZ3]);
            data.CZ4.push([moment(item.dt).valueOf(), Utils.empty(item.CZ4) ? null : item.CZ4]);
        }
    }
    //
    yield put({
        type: GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA_SUCCESS,
        payload: data
    });
    yield put({
        type: GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA,
        payload: { dataAmmeterId: action.payload.dataAmmeterId, date: date.startOf('month') }
    })
}

export function* getSPPVDayOfMonthData(action) {
    const {
        dataAmmeterId,
        date,
        code
    } = action.payload;
    if (Utils.empty(dataAmmeterId)) {
        yield put({
            type: GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA_SUCCESS,
            payload: {}
        });
        return;
    }
    yield put({ type: SHOW_SPPV_DAY_OF_MONTH_LOADING, payload: true });
    const ret = yield call(
        efficiency.EdataInterfaceService.getData,
        dataAmmeterId,
        301,
        code || dataCode,
        date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
    );
    yield put({ type: SHOW_SPPV_DAY_OF_MONTH_LOADING, payload: false });
    yield put({
        type: GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA_SUCCESS,
        payload: ret.code ? {} : ret
    });
}

export function* getYearData(action) {
    const dataAmmeterId = action.payload;
    if (Utils.empty(dataAmmeterId)) {
        yield put({
            type: GET_COULOMETRY_CHARGE_YEAR_DATA_SUCCESS,
            payload: {
                chart: {},
                statistic: [],
                thisYearSPPV: {},
                yoySPPV: {},
                lastYearSPPV: {},
                thisYearSPPVChart: {},
                lastYearSPPVChart: {}
            }
        });
        return;
    }
    yield put({ type: SHOW_YEAR_LOADING, payload: true });
    const [
        chartRet,
        statisticRet,
        thisYearSPPVRet,
        yoySPPVRet,
        lastYearSPPVRet,
        thisYearSPPVChartRet,
        lastYearSPPVChartRet
    ] = yield all([
        call(
            efficiency.ElectricityAnalysisService.getElectricityChargeChart,
            dataAmmeterId,
            501,
            [8, 9].toString(),
            [1, 2].toString()
        ),
        call(
            efficiency.ElectricityAnalysisService.getElectricityChargeStatistics,
            dataAmmeterId,
            [8, 11, 9].toString(),
            [1, 2].toString()
        ),
        //本年峰平谷结构
        call(
            efficiency.EdataInterfaceService.getData,
            dataAmmeterId,
            601,
            dataCode,
            moment().startOf('year').format('YYYY-MM-DD HH:mm:ss')
        ),
        //去年同期峰平谷结构
        call(
            efficiency.EdataInterfaceService.getData,
            dataAmmeterId,
            601,
            dataCode,
            moment().startOf('year').subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss')
        ),
        //去年峰平谷结构
        call(
            efficiency.EdataInterfaceService.getData,
            dataAmmeterId,
            601,
            dataCode,
            moment().startOf('year').subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss')
        ),
        //本年峰平谷曲线
        call(
            efficiency.EdataInterfaceService.getDataList,
            dataAmmeterId,
            501,
            dataCode,
            moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'),
            1
        ),
        //去年峰平谷曲线
        call(
            efficiency.EdataInterfaceService.getDataList,
            dataAmmeterId,
            501,
            dataCode,
            moment().startOf('year').subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('year').subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss'),
            1
        ),
    ]);
    yield put({ type: SHOW_YEAR_LOADING, payload: false });

    if (!statisticRet.code) {
        for (let item of statisticRet) {
            if (item.dtType === 8) {
                item.title = '本年';
            }
            else if (item.dtType === 9) {
                item.title = '去年';
            }
            else if (item.dtType === 11) {
                item.title = '去年同期';
            }
        }
    }

    let thisYearSPPVChart = {
        dt: [],
        PP1: [],
        PP2: [],
        PP3: [],
        PP4: [],
        CZ1: [],
        CZ2: [],
        CZ3: [],
        CZ4: []
    }

    if (!thisYearSPPVChartRet.code) {
        for (const item of thisYearSPPVChartRet) {
            thisYearSPPVChart.PP1.push([moment(item.dt).valueOf(), (item.PP1 / item.PP * 100).toFixed(0)]);
            thisYearSPPVChart.PP2.push([moment(item.dt).valueOf(), (item.PP2 / item.PP * 100).toFixed(0)]);
            thisYearSPPVChart.PP3.push([moment(item.dt).valueOf(), (item.PP3 / item.PP * 100).toFixed(0)]);
            thisYearSPPVChart.PP4.push([moment(item.dt).valueOf(), (item.PP4 / item.PP * 100).toFixed(0)]);
            thisYearSPPVChart.CZ1.push([moment(item.dt).valueOf(), (item.CZ1 / item.CZ * 100).toFixed(0)]);
            thisYearSPPVChart.CZ2.push([moment(item.dt).valueOf(), (item.CZ2 / item.CZ * 100).toFixed(0)]);
            thisYearSPPVChart.CZ3.push([moment(item.dt).valueOf(), (item.CZ3 / item.CZ * 100).toFixed(0)]);
            thisYearSPPVChart.CZ4.push([moment(item.dt).valueOf(), (item.CZ4 / item.CZ * 100).toFixed(0)]);
        }
    }
    let lastYearSPPVChart = {
        dt: [],
        PP1: [],
        PP2: [],
        PP3: [],
        PP4: [],
        CZ1: [],
        CZ2: [],
        CZ3: [],
        CZ4: [],
    }
    if (!lastYearSPPVChartRet.code) {
        for (const item of lastYearSPPVChartRet) {
            lastYearSPPVChart.PP1.push([moment(item.dt).valueOf(), (item.PP1 / item.PP * 100).toFixed(0)]);
            lastYearSPPVChart.PP2.push([moment(item.dt).valueOf(), (item.PP2 / item.PP * 100).toFixed(0)]);
            lastYearSPPVChart.PP3.push([moment(item.dt).valueOf(), (item.PP3 / item.PP * 100).toFixed(0)]);
            lastYearSPPVChart.PP4.push([moment(item.dt).valueOf(), (item.PP4 / item.PP * 100).toFixed(0)]);
            lastYearSPPVChart.CZ1.push([moment(item.dt).valueOf(), (item.CZ1 / item.CZ * 100).toFixed(0)]);
            lastYearSPPVChart.CZ2.push([moment(item.dt).valueOf(), (item.CZ2 / item.CZ * 100).toFixed(0)]);
            lastYearSPPVChart.CZ3.push([moment(item.dt).valueOf(), (item.CZ3 / item.CZ * 100).toFixed(0)]);
            lastYearSPPVChart.CZ4.push([moment(item.dt).valueOf(), (item.CZ4 / item.CZ * 100).toFixed(0)]);
        }
    }

    const payload = {
        chart: chartRet.code ? {} : chartRet,
        statistic: statisticRet.code ? [] : statisticRet,
        thisYearSPPV: thisYearSPPVRet.code ? {} : thisYearSPPVRet,
        yoySPPV: yoySPPVRet.code ? {} : yoySPPVRet,
        lastYearSPPV: lastYearSPPVRet.code ? {} : lastYearSPPVRet,
        thisYearSPPVChart: thisYearSPPVChart,
        lastYearSPPVChart: lastYearSPPVChart
    };

    yield put({
        type: GET_COULOMETRY_CHARGE_YEAR_DATA_SUCCESS,
        payload: payload
    });
}

export function* getCustomData(action) {
    const {
        dataAmmeterId,
        interval,
        format,
        begin,
        end
    } = action.payload;

    if (Utils.empty(dataAmmeterId)) {
        yield put({
            type: GET_COULOMETRY_CHARGE_CUSTOM_DATA_SUCCESS,
            payload: {
                sumPP: 0,
                sumCZ: 0,
                chart: {
                    dt: [],
                    PP: [],
                    CZ: []
                },
                pieData: {
                },
                tableData: []
            }
        });
        return;
    }

    yield put({ type: SHOW_CUSTOM_LOADING, payload: true });
    const customRet = yield call(
        efficiency.EdataInterfaceService.getDataList,
        dataAmmeterId,
        interval,
        'PP,PP1,PP2,PP3,PP4,CZ,CZ1,CZ2,CZ3,CZ4',
        begin.format(format),
        end.format('YYYY-MM-DD HH:mm'),
        1
    );
    yield put({ type: SHOW_CUSTOM_LOADING, payload: false });
    let chart = {
        dt: [],
        PP: [],
        CZ: []
    };
    let pieData = {
    }
    let sumCZ = 0;
    let sumPP = 0;
    if (!customRet.code) {
        for (const item of customRet) {
            sumPP += (item.PP || 0);
            sumCZ += (item.CZ || 0);
            chart.dt.push(item.dt);
            chart.PP.push([moment(item.dt).valueOf(), item.PP]);
            chart.CZ.push([moment(item.dt).valueOf(), item.CZ]);
            if (item.PP1) {
                if (!pieData.PP1) {
                    pieData.PP1 = 0;
                }
                pieData.PP1 += item.PP1;
            }
            if (item.PP2) {
                if (!pieData.PP2) {
                    pieData.PP2 = 0;
                }
                pieData.PP2 += item.PP2;
            }
            if (item.PP3) {
                if (!pieData.PP3) {
                    pieData.PP3 = 0;
                }
                pieData.PP3 += item.PP3;
            }
            if (item.PP4) {
                if (!pieData.PP4) {
                    pieData.PP4 = 0;
                }
                pieData.PP4 += item.PP4;
            }
            if (item.CZ1) {
                if (!pieData.CZ1) {
                    pieData.CZ1 = 0;
                }
                pieData.CZ1 += item.CZ1;
            }
            if (item.CZ2) {
                if (!pieData.CZ2) {
                    pieData.CZ2 = 0;
                }
                pieData.CZ2 += item.CZ2;
            }
            if (item.CZ3) {
                if (!pieData.CZ3) {
                    pieData.CZ3 = 0;
                }
                pieData.CZ3 += item.CZ3;
            }
            if (item.CZ4) {
                if (!pieData.CZ4) {
                    pieData.CZ4 = 0;
                }
                pieData.CZ4 += item.CZ4;
            }
        }
    }

    yield put({
        type: GET_COULOMETRY_CHARGE_CUSTOM_DATA_SUCCESS,
        payload: {
            sumPP: sumPP,
            sumCZ: sumCZ,
            chart: chart,
            pieData: pieData,
            tableData: !customRet.code ? customRet : []
        }
    });
}

export const coulometryChargeSagas = [
    takeLatest(GET_COULOMETRY_CHARGE_DAY_DATA, getDayData),
    takeLatest(GET_COULOMETRY_CHARGE_MONTH_DATA, getMonthData),
    takeLatest(GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA, getSPPVTrendMonthData),
    takeLatest(GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA, getSPPVDayOfMonthData),
    takeLatest(GET_COULOMETRY_CHARGE_YEAR_DATA, getYearData),
    takeLatest(GET_COULOMETRY_CHARGE_CUSTOM_DATA, getCustomData)
];

//reducer
export function SDNCoulometryChargeReducer(
    state = {
        showDayLoading: false,
        showMonthLoading: false,
        showSPPVTrendLoading: false,
        showSPPVDayOfMonthLoading: false,
        showYearLoading: false,
        showCustomLoading: false,

        selectedPPTrendDay: moment(),
        selectedCZTrendDay: moment(),
        dayData: {
            chart: {},
            period: [],
            statistic: [],
            table: []
        },
        monthData: {
            chart: {},
            statistic: [],
            thisMonthSPPV: {},
            lastMonthSPPV: {},
            lastYearMonthSPPV: {}
        },
        monthSPPVTrendData: {},
        dayOfMonthSPPVData: {},
        yearData: {
            chart: {},
            statistic: [],
            thisYearSPPV: {},
            yoySPPV: {},
            lastYearSPPV: {},
            thisYearSPPVChart: {},
            lastYearSPPVChart: {}
        },
        customData: {
            sumPP: 0,
            sumCZ: 0,
            chart: {
                dt: [],
                PP: [],
                CZ: []
            },
            pieData: {
            },
            tableData: []
        }
    },
    action
) {
    switch (action.type) {

        case GET_COULOMETRY_CHARGE_DAY_DATA_SUCCESS: {
            const dayData = { ...state.dayData, ...action.payload };
            return {
                ...state,
                dayData: dayData
            }
        }

        case GET_COULOMETRY_CHARGE_MONTH_DATA_SUCCESS: {
            return {
                ...state,
                monthData: action.payload
            }
        }

        case GET_COULOMETRY_CHARGE_MONTH_SPPV_TREND_DATA_SUCCESS: {
            return {
                ...state,
                monthSPPVTrendData: action.payload
            }
        }

        case GET_COULOMETRY_CHARGE_DAY_OF_MONTH_SPPV_DATA_SUCCESS: {
            const data = { ...state.dayOfMonthSPPVData }
            for (const key in action.payload) {
                data[key] = action.payload[key];
            }
            return {
                ...state,
                dayOfMonthSPPVData: data
            }
        }

        case GET_COULOMETRY_CHARGE_YEAR_DATA_SUCCESS: {
            return {
                ...state,
                yearData: action.payload
            }
        }

        case GET_COULOMETRY_CHARGE_CUSTOM_DATA_SUCCESS: {
            return {
                ...state,
                customData: action.payload
            }
        }

        case SHOW_DAY_LOADING: {
            return { ...state, showDayLoading: action.payload };
        }

        case SHOW_MONTH_LOADING: {
            return { ...state, showMonthLoading: action.payload };
        }

        case SHOW_SPPV_TREND_LOADING: {
            return { ...state, showSPPVTrendLoading: action.payload };
        }

        case SHOW_SPPV_DAY_OF_MONTH_LOADING: {
            return { ...state, showSPPVDayOfMonthLoading: action.payload };
        }

        case SHOW_YEAR_LOADING: {
            return { ...state, showYearLoading: action.payload };
        }

        case SHOW_CUSTOM_LOADING: {
            return { ...state, showCustomLoading: action.payload };
        }

        case SET_MONTH_PP_TREND_DAY: {
            return { ...state, selectedPPTrendDay: action.payload };
        }

        case SET_MONTH_CZ_TREND_DAY: {
            return { ...state, selectedCZTrendDay: action.payload };
        }

        default:
            return state;
    }
}

import { archive, sys, user, redirectTo, pageUrl } from "../../service";
import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import * as actionType from "./SDNSystemActionTypes";

import { getSystem, accountType } from "../../config";
import { GET_ALARM_COUNT } from "../activities/ActivitiesActionTypes";
import { GET_SITE_LIST_BY_ID } from "../../views/SDNEventAlert/SDNEventAlertAcitonType";
import { hgStorage, Utils } from '../utils';

export function* startSystem(action) {
  const ret = hgStorage.get(actionType.USER_INFO);
  if (Utils.empty(ret)) {
    redirectTo(pageUrl.login);
  }
  else {
    const user = JSON.parse(ret);
    yield put({ type: actionType.GET_USER_INFO_SUCCESS, payload: user });
    yield put({ type: actionType.GET_USER_INFO });
    yield put({ type: actionType.LOAD_NAVIGATION_MENU });
    yield put({ type: actionType.GET_USE_POWER_LIST });
    // console.log('##### ' + user.accountType);
    if (user.accountType == accountType.normal) {
      yield put({ type: actionType.GET_USER_FIRMS });
    }
  }
}

//获取所有系统信息
export function* getAllSytemInfo(action) {



  // yield put({ type: actionType.GET_USE_POWER_LIST });
  // const type = hgStorage.get(actionType.USER_INFO);
  // if (!type || type == accountType.normal) {
  //   return;
  // }
  // const ret = yield call(archive.ConsumerService.getAllEffective);

  // if (!ret.code) {
  //   yield put({ type: actionType.GET_ALL_EFFECTIVE_SUCCESS, payload: ret });
  //   if (ret.length > 0) {
  //     const node = ret[0];
  //     yield put({ type: actionType.GET_OBJECT_TREE, payload: node.orgId });
  //   }
  // }

  // yield put({ type: actionType.GET_USER_INFO });
  // yield put({ type: actionType.GET_GROUP_FIRM_TREE});

  // if (ret.employee.accountType == accountType.normal) {
  //   yield put({ type: actionType.GET_CONSUMER_LIST });
  // }
  // yield put({ type: GET_GROUP_FIRM_LIST });
}

export function* userLogin(action) {
  yield put({ type: actionType.USER_LOGIN_LOADING, payload: true });
  const ret = yield call(sys.LoginService.login, action.payload.username, action.payload.password, 'hxy');
  yield put({ type: actionType.USER_LOGIN_LOADING, payload: false });
  if (!ret.code) {
    yield put({ type: actionType.GET_USE_POWER_LIST });
    yield put({ type: actionType.USER_LOGIN_SUCCESS, payload: ret.employee });
    yield put({ type: actionType.LOAD_NAVIGATION_MENU });
    yield put({ type: actionType.GET_USER_INFO });
    if (ret.employee.accountType == accountType.normal) {
      yield put({ type: actionType.GET_USER_FIRMS });
    }
  }
}

export function* getUserInfo(action) {
  const system = yield select(getSystem);
  const { orgId } = system.user;
  const ret = yield call(sys.OrgEmployeeService.getEmpMap, orgId || '');
  if (!ret.code) {
    yield put({ type: actionType.GET_USER_INFO_SUCCESS, payload: ret });;
  }
}

export function* getConsumerListByEmp(action) {
  const system = yield select(getSystem);
  const { orgId, accountType } = system.user;
  const ret = yield call(user.UserLoginService.getConsumerListByEmp, orgId || '');
  if (!ret.code) {
    yield put({ type: actionType.GET_USER_FIRMS_SUCCESS, payload: ret });
    if (ret.length > 0) {
      const firm = ret[0];
      yield put({ type: GET_ALARM_COUNT, payload: { enterpriseId: firm.orgId, alarmStatus: 1, progress: 0 } });
      yield put({ type: actionType.GET_FIRM_SUB_TREE, payload: firm.orgId });
      yield put({ type: GET_SITE_LIST_BY_ID, payload: firm.orgId });
    }

    // yield put({type: GET_ALARM_COUNT, payload: {enterpriseId:selectedCompany.id,alarmStatus:1,progress:0}});
  }
}

export function* editUserInfo(action) {
  const system = yield select(getSystem);
  const { user } = system;
  const {
    fieldName,
    fieldValue,
    complete
  } = action.payload;
  const ret = yield call(sys.OrgEmployeeService.editField, user.orgId, fieldName, fieldValue, '');
  if (ret.code == 200) {
    yield put({ type: actionType.EDIT_USER_INFO_SUCCESS, payload: { fieldName, fieldValue } });
    complete();
  }
}

export function* uploadUserPortrait(action) {
  const ret = yield call(sys.OrgEmployeeService.uploadEmpIcon, action.payload);
  if (!ret.code) {
    yield put({ type: actionType.GET_USER_INFO_SUCCESS, payload: ret });
    yield put({ type: actionType.GET_USER_INFO });
  }
}

export function* getGroupFirmTree(action) {
  // 
  const ret = yield call(archive.ConsumerService.getConsumerAndGroup);
  if (!ret.code) {
    yield put({ type: actionType.GET_GROUP_FIRM_TREE_SUCCESS, payload: ret });
    const system = yield select(getSystem);
    yield put({ type: actionType.GET_FIRM_SUB_TREE, payload: system.selectedFirm.id });
  }
}

//获取菜单
function* loadNavigationMenu(action) {
  // return; //todo
  const [menu, displayMenu] = yield all([
    call(sys.LoginService.getAllMenus), //所有菜单
    call(sys.LoginService.getShiroMenus) //用户可见菜单
  ]);
  // 
  yield put({ type: actionType.SET_NAVIGATION_MENU, payload: { menu: menu, displayMenu: displayMenu } });
}

//获取用电类型
export function* getUsePowerListByType(action) {
  yield put({ type: 'FETCH_REQUEST' });
  const ret = yield call(archive.DataClassifyService.getListByDataType, 1);
  if (!ret.code) {
    for (let item of ret) {
      item.title = item.dataSubTypeName;
    }
    yield put({ type: actionType.GET_USE_POWER_LIST_SUCCESS, payload: ret });
  }
  yield put({ type: 'FETCH_SUCCESS' });
}

//根据ID获取用户树
export function* getObjectNodeTree(action) {
  const system = yield select(getSystem);
  const firmId = Utils.empty(action.payload) ? system.selectedFirm.id : action.payload
  if (Utils.empty(firmId)) {
    return;
  }
  yield put({ type: actionType.GET_FIRM_SUB_TREE_BEGIN });
  // 
  let ret = null;
  if (system.showSubDevice) {
    ret = yield call(sys.OrgRelationService.getConsumerList, firmId, '41');
  }
  else {
    ret = yield call(archive.MonitorObjectService.getConsumerTreeByClass, firmId);
  }
  // 
  yield put({ type: actionType.GET_FIRM_SUB_TREE_END });
  if (!ret.code) {
    yield put({ type: actionType.CLEAR_FIRM_SUB_TREE });
    let tree = null;
    // 
    if (!system.showSubDevice) {
      tree = { key: ret.id, title: ret.name, classifiedType: ret.classifiedType, dataAmmeterId: ret.dataAmmeterId, isConsumer: ret.isConsumer };
      if (ret.children && ret.children.length > 0) {
        tree.children = processObjectNodeTree(ret.children);
      }
    }
    else {
      tree = { key: ret.orgId, title: ret.orgName, type: ret.orgType };
      if (ret.data && ret.data.length > 0) {
        tree.children = processObjectNodeTree(ret.data);
      }
    }

    yield put({ type: actionType.GET_FIRM_SUB_TREE_SUCCESS, payload: tree });
    yield put({ type: actionType.CHECK_OBJECT_NODE, payload: [tree.key] });
  }
}

//查询所有角色对象
export function* getAllRoles() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.OrgRoleService.getAll);
    yield put({ type: actionType.GET_ALL_ROLES_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

const processObjectNodeTree = node => {
  const tree = node.map((item, index) => {
    let subTree = { key: item.id || item.orgId, title: item.name || item.orgName, classifiedType: item.classifiedType, dataAmmeterId: item.dataAmmeterId, isConsumer: item.isConsumer };
    if (!Utils.empty(item.children)) {
      subTree.children = processObjectNodeTree(item.children);
    }
    else if (!Utils.empty(item.data)) {
      subTree.children = processObjectNodeTree(item.data);
    }

    return subTree;
  });

  return tree;
}

const sysInfoSagas = [
  takeLatest(actionType.USER_LOGIN, userLogin),
  takeLatest(actionType.EDIT_USER_INFO, editUserInfo),
  takeLatest(actionType.SYSTEM_START, startSystem),
  takeLatest(actionType.LOAD_NAVIGATION_MENU, loadNavigationMenu),
  takeLatest(actionType.GET_GROUP_FIRM_TREE, getGroupFirmTree),
  takeLatest(actionType.GET_USER_FIRMS, getConsumerListByEmp),
  takeLatest(actionType.GET_ALL_ROLES, getAllRoles),
  takeLatest(actionType.GET_USE_POWER_LIST, getUsePowerListByType),
  takeLatest(actionType.GET_FIRM_SUB_TREE, getObjectNodeTree),
  takeLatest(actionType.GET_USER_INFO, getUserInfo),
  takeLatest(actionType.UPLOAD_USER_PORTRAIT, uploadUserPortrait),
];

export default sysInfoSagas;

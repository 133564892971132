import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

export default class SDNInput extends React.Component {

  static defaultProps = {
    width: 300,
    title: '',
    type: 'text',
    defaultValue: '',
    placeholder: '',
    minLength: 6,
    maxLength: 300,
    validateLabel: '',
    required: false
  }

  constructor (props) {
    super(props);
    this.state = {
      validateLabel: this.props.validateLabel
    }
  }

  handleChange = e => {
    const text = e.target.value;
    if (this.props.onChange) {
      this.props.onChange(text);
    }
    const {
      minLength,
      maxLength
    } = this.props;
    if (text.length < minLength) {
       this.setState({ validateLabel: `最少输入${minLength}个字符`})
    }
    else if (text.length > maxLength) {
       this.setState({ validateLabel: `最多输入${maxLength}个字符`})
    }
    else {
      this.setState({ validateLabel: ''})
    }
  }

  renderValidateLabel = () => {
    let { validateLabel } = this.state;
    if (this.props.validateLabel.length > 0) {
      validateLabel = this.props.validateLabel;
    }
    let validateLabelStyle = {...style.validateLabel, display:validateLabel.length > 0 ? 'block' : 'none'};
    return (
      <FlexItem style={validateLabelStyle}>
        {validateLabel}
      </FlexItem>
    )
  }

  render () {
    const {
      type,
      width,
      title,
      placeholder,
      maxLength,
      defaultValue,
      required
    } = this.props;
    let star = required ? <span style={style.redStar}> *</span> : null;
    return (
      <Flex column style={{width: width}}>
        <FlexItem style={style.label}>{title}{ star }</FlexItem>
        <input
          className='input-sm'
          type={type}
          maxLength={maxLength}
          placeholder={placeholder} defaultValue={defaultValue}
          onChange={e => this.handleChange(e)}
        />
        { this.renderValidateLabel() }
      </Flex>
    )
  }
}

const style = {
  label: {
    display: 'block',
    marginBottom: 6,
    lineHeight: '19px',
    fontWeight: 400,
    fontSize: 12,
    color: '#333',
    textAlign: 'left',
    whiteSpace: 'normal'
  },

  redStar: {
  	color: '#dd4f59'
  },

  validateLabel: {
    display: 'block',
    marginTop: 6,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    color: '#D56161'
  }
}

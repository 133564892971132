import React from "react";
import { Breadcrumb } from 'antd';
import { connect } from "react-redux";

class SDNBreadcrumbs extends React.Component {

  getItems = () => {    
    let route = this.props.route;
    if (route.indexOf("/SDNDataUpload/SDNDataUploadRecordDetail") != -1) {
      route = "/SDNDataUpload/SDNDataUploadRecord";
    } else if (route.indexOf("/HunanDataUpload/SDNDataUploadRecordDetail") != -1) {
      route = "/HunanDataUpload/SDNDataUploadRecord";
    } else if (route.indexOf("/SDNOperationAdmin/SDNEditNews") != -1) {
      route = "/SDNOperationAdmin/SDNNews";
    }    

    if (!this.props.displayMenu) {
      return [];
    }
    const { chain, found } = getPath(route, this.props.displayMenu);
    return chain;
  }

  render() {
    const items = this.getItems();
    return (
      <Breadcrumb style={{ padding: '10px 16px' }}>
        {
          items.map((item, index) => {
            if (item.route && item.route.length > 0 && index != items.length - 1) {
              return <Breadcrumb.Item key={index}><a href={item.route}>{item.title}</a></Breadcrumb.Item>
            }
            else {
              return <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
            }
          })
        }
      </Breadcrumb>
    );
  }
}

const getPath = (route, items) => {
  for (const item of items) {
    if (item.route === route) {
      return { chain: [{ title: item.title, route: item.route}], found: true };
    }
    else if (item.items && item.items.length > 0) {
      const { chain, found } = getPath(route, item.items);
      if (found === true) {
        chain.splice(0, 0, { title: item.title, route: item.route});
        return { chain: chain, found: true };
      }
    }
  }

  return { chain: [], found: false };
}

const mapStateToProps = (state, ownProps) => {
  return {
    displayMenu: state.system.displayMenu,
  }
};

export default connect(mapStateToProps)(SDNBreadcrumbs);

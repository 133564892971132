import * as actionType from "./SDNUserAdminActionTypes";
import { handleActions } from 'redux-actions';
import store from "../../config/configureStore";
import { redirectTo, pageUrl, getRoute } from "../../service";

const SDNUserAdminReducer = (
  state = {
    groupFirms: {}
  },
  action
) => {
  switch (action.type) {

    case actionType.RESET_PWD_SUCCESS:
    {
      redirectTo(pageUrl.login);
      return state;
    }

    case actionType.GET_GROUP_FIRM_LIST_SUCCESS:
    {
      return {...state, groupFirms:action.payload};
    }

    default:
      return state;
  }
}

export default SDNUserAdminReducer;

import React from 'react';
import { DatePicker, Button } from 'antd';
import Flex, { FlexItem } from 'styled-flex-component';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
const { RangePicker} = DatePicker;

export const dateRangePickerType = { min15: 0, hour: 1, day: 2, month: 3, year: 4 };

export default class SDNDateRangePicker extends React.Component {

  date = moment();
  dateString = moment().format('YYYY-MM-DD');

  static defaultProps = {
    dateType: dateRangePickerType.hour,
  }

  constructor (props) {
    super(props);
    this.state = {
      isOpen: false,
      value: [this.props.begin || moment().subtract(1, 'hours'), this.props.end || moment()],
      mode: ['time', 'time'],
      dateType: props.dateType
    };
  }

  componentDidMount () {
    if (this.props.onChange) {
      this.props.onChange(this.state.value, '');
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.begin != nextProps.begin || this.props.end != nextProps.end) {
      this.setState({value: [nextProps.begin, nextProps.end]});
    }
    if (this.props.dateType != nextProps.dateType) {
      this.setState({dateType: nextProps.dateType});
    }
  }

  handleChange = (value, dateString) => {
    if (this.props.onChange) {
      this.props.onChange(value,dateString);
    }
    this.setState({ value });
  }

  handleTimeChange = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.setState({ value });
  }

  handlePanelChange = (value, mode) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.setState({ value});
  };

  handleOpenChange = (status) => {
    if (status) {
      this.setState({ isOpen: true })
    } else {
      this.setState({ isOpen: false })
    }
  }

  render () {

    let { value, isOpen, dateType } = this.state;
    let props = {
      locale: locale,
      value: value,
      open: isOpen,
      onOpenChange: this.handleOpenChange,
      onOk: this.handleTimeChange,
      onChange: this.handleChange,
      onPanelChange: this.handlePanelChange,
    }
    // 
    if (dateType === dateRangePickerType.day) {
      props.format = 'YYYY-MM-DD';
      props.mode = ['date','date'];
    }
    else if (dateType === dateRangePickerType.month) {
      props.format = 'YYYY-MM';
      props.mode = ['month','month'];
    }
    else if (dateType === dateRangePickerType.year) {
      props.format = 'YYYY';
      props.mode = ['year','year'];
    }
    else {
      props.value = [value[0].minute(0), value[1].minute(0)];
      props.format = 'YYYY-MM-DD HH:mm';
      props.showTime = { format: 'HH' };
    }
    return (
      <RangePicker { ...props } />
    )
  }
}

import React from "react";
import { Tree } from 'antd';
import { Utils } from "../../utils";
const { TreeNode } = Tree;

export default class TreeView extends React.Component {

  static defaultProps = {
    defaultCheckedKeys: [],
    defaultSelectedKeys: [],
    checkedKeys: [],
    selectedKeys:[],
    checkStrictly: true,
    selectable: false,
    checkable: false,
    editable: false,
	}

  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: this.props.selectedKeys,
      checkedKeys: this.props.checkedKeys
    }
  }
  onAdd = item => {
    if (this.props.onAdd) {
      this.props.onAdd(item);
    }
  }

  onEdit = item => {
    if (this.props.onEdit) {
      this.props.onEdit(item);
    }
  }

  onDel = item => {
    if (this.props.onDel) {
      this.props.onDel(item);
    }
  }

  onChecked = (keys, e) => {

    if (this.props.onChecked) {
      this.props.onChecked(keys, e);
    }
    this.setState({ checkedKeys: keys });
  }

  onSelected = (key,e) => {
    this.setState({
      selectedKeys: key
    })
    if (this.props.onSelected) {
      this.props.onSelected(key, e);
    }
  }

  renderSwitcherIcon = e => {
    if (e.isLeaf) {
      return null;
    }

    return (
      <span
        className={e.expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'}
        style={{marginLeft: 2, marginTop: 2, background: 'white'}}
      />
    )
  }

  renderItem = item => {
    return (
      <span>
        <span title={item.title || item.name}>{item.title || item.name}</span>
        <span className='fa fa-plus-square-o' title="增加" style={{marginLeft: 5}} onClick={() => this.onAdd(item)}></span>
        <span className='fa fa-edit' title="修改" style={{marginLeft: 5}} onClick={() => this.onEdit(item)}></span>
        <span className='fa fa-trash-o' title="删除" style={{marginLeft: 5}} onClick={() => this.onDel(item)}></span>
      </span>
    );
  }

  renderIcon = e => {
    let className = e.expanded ? 'fa fa-folder-open': 'fa fa-folder';
    if (e.children==null||e.children.length == 0) {
      className = 'fa fa-list-alt';
    }
    return <span className={className}/>
  }

  renderNewIncon = e => {
      let className = '';
      if (e.isConsumer==1) {
        className = 'fa fa-user'
      }else if(e.classifiedType==0){
        className = 'fa fa-map-marker'
      }else if(e.classifiedType==1){
        className = 'fa fa-arrows-alt'
      }else if(e.classifiedType==2){
        className = 'fa fa-tasks'
      }else if(e.classifiedType==3){
        className = 'fa fa-tachometer'
      }else{
        className = 'fa fa-tasks'
      }

      return <span className={className}/>
  }

  loopNode = (data,diyIcon) => {
    if (!data) {
      return null;
    }
    const { editable } = this.props;
    if (data instanceof Array) {
      return data.map((item) => {
        if (item.children) {
          return (
            <TreeNode
              key={item.key}
              diyIcon={diyIcon}
              icon={diyIcon?this.renderNewIncon:this.renderIcon}
              title={editable ? this.renderItem(item) : (item.title || item.name)}
              classifiedType={item.classifiedType}
              id={item.id}
              name={item.name}
              ammeterId={item.ammeterId}
              dataAmmeterId={item.dataAmmeterId}
              monitorObjectTypeId={item.monitorObjectTypeId}
              siteId={item.siteId}
              switcherIcon={this.renderSwitcherIcon}
              isConsumer={item.isConsumer}
              monitorType={item.monitorType}
              checkStrictly={false}
            >
              {this.loopNode(item.children,diyIcon)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.key} title={item.title || item.name} dataAmmeterId={item.dataAmmeterId}  classifiedType={item.classifiedType} ammeterId={item.ammeterId} monitorObjectTypeId={item.monitorObjectTypeId}/>;
      });
    }

    if (data.children) {
      return (
        <TreeNode
          key={data.key}
          diyIcon={diyIcon}
          icon={diyIcon?this.renderNewIncon:this.renderIcon}
          title={editable ? this.renderItem(data) : data.title || data.name}
          classifiedType={data.classifiedType}
          ammeterId={data.ammeterId}
          dataAmmeterId={data.dataAmmeterId}
          monitorObjectTypeId={data.monitorObjectTypeId}
          siteId={data.siteId}
          switcherIcon={this.renderSwitcherIcon}
          isConsumer={data.isConsumer}
          monitorType={data.monitorType}
          checkStrictly={false}
        >
          {this.loopNode(data.children,diyIcon)}
        </TreeNode>
      );
    }
    return <TreeNode key={data.key} title={data.title} />;
  }

  render() {
    const {
      defaultSelectedKeys,
      defaultCheckedKeys,
      checkedKeys,
      selectedKeys,
      selectable,
      checkable,
      checkStrictly,
      data,
      icon
    } = this.props;

    if (Utils.empty(data)) {
      return null;
    }
    return (
      <Tree
        showIcon={icon}
        // autoExpandParent={true}
        defaultSelectedKeys={defaultSelectedKeys}
        defaultCheckedKeys={defaultCheckedKeys}
        defaultExpandedKeys={selectedKeys}
        selectable={selectable}
        checkable={checkable}
        checkStrictly={checkStrictly}
        checkedKeys={checkedKeys}
        selectedKeys={selectedKeys? selectedKeys: this.state.selectedKeys}
        // defaultExpandAll={true}
        // icon={this.props.icon?this.renderNewIncon:this.renderIcon}
        // switcherIcon={this.renderSwitcherIcon}
        onCheck={(keys, e) => this.onChecked(keys, e)}
        onSelect={(keys, e) => this.onSelected(keys, e)}
        onAdd={(key) => this.onAdd(key)}
      >
        { this.loopNode(data, icon) }
      </Tree>
    );
  }
}

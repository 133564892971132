import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertBox } from '../../common/utils/functions/message';
import { USER_LOGOUT } from "../../common/system";
import { Menu, Dropdown, Icon } from 'antd';

class SDNUserMenuView extends React.Component {

  onLogout = () => {
    alertBox({title:'警告', content:'确认退出？', confirm: () => {

      this.props.logout();
    }});
  }

  renderMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link
            to="/SDNUserAdmin/SDNUserInfo"
          >
            <i className="iconfont icon-personal"></i> 资料设置
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/SDNUserAdmin/SDNResetPwd"
          >
            <i className="iconfont icon-password"></i> 修改密码
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/SDNUserAdmin/SDNOperateRecords"
          >
            <i className="iconfont icon-record"></i> 操作记录
          </Link>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onLogout()}>
            <i className="iconfont icon-switch"></i> 安全退出
          </a>
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    return (
      <Dropdown overlay={this.renderMenu()}>
        <div className="ant-dropdown-link" style={{color: '#FFF'}}>
          {this.props.orgName || ''} <Icon type="down" />
        </div>
      </Dropdown>
    );
  }
}

export default connect(
  state => state.system.user,
  dispatch => { return {logout: () => dispatch({type:USER_LOGOUT})} }
)(SDNUserMenuView);

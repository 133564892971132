import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import antdNotice from "../../../common/Notification/antdNotice";
import { efficiency, archive } from "../../../service";

export const DATAINPUT_getImportListByPowerType = 'DATAINPUT_getImportListByPowerType';
export const DATAINPUT_setImportDataTypeList = 'DATAINPUT_setImportDataTypeList';
export const DATAINPUT_getRecordListByPowerType = 'DATAINPUT_getRecordListByPowerType';
export const DATAINPUT_setRecordDataTypeList = 'DATAINPUT_setRecordDataTypeList';
export const DATAINPUT_getConsumerUnitByConsumer = 'DATAINPUT_getConsumerUnitByConsumer';
export const DATAINPUT_setConsumerUnitByConsumer = 'DATAINPUT_setConsumerUnitByConsumer';
export const DATAINPUT_getConsumerUnitByConsumerAndDataClassify = 'DATAINPUT_getConsumerUnitByConsumerAndDataClassify';
export const DATAINPUT_setConsumerUnitByConsumerAndDataClassify = 'DATAINPUT_setConsumerUnitByConsumerAndDataClassify';
export const DATAINPUT_getImportDataAnt = 'DATAINPUT_getImportDataAnt';
export const DATAINPUT_setImportDataAnt = 'DATAINPUT_setImportDataAnt';
export const DATAINPUT_updateImportDataAnt = 'DATAINPUT_updateImportDataAnt';
export const DATAINPUT_insertSingleData = 'DATAINPUT_insertSingleData';
export const DATAINPUT_importDataFile = 'DATAINPUT_importDataFile';
export const DATAINPUT_delData = 'DATAINPUT_delData';
export const DATAINPUT_refreshTable = 'DATAINPUT_refreshTable';
export const DATAINPUT_setIsClearImportData = 'DATAINPUT_setIsClearImportData';
export const DATAINPUT_setImportModalVsible = 'DATAINPUT_setImportModalVsible';
export const DATAINPUT_setStatistics = 'DATAINPUT_setStatistics';

export const dataInputSagas = [
    takeLatest(DATAINPUT_getImportListByPowerType, getImportListByPowerType),
    takeLatest(DATAINPUT_getRecordListByPowerType, getRecordListByPowerType),
    takeLatest(DATAINPUT_getConsumerUnitByConsumer, getConsumerUnitByConsumer),
    takeLatest(DATAINPUT_getConsumerUnitByConsumerAndDataClassify, getConsumerUnitByConsumerAndDataClassify),
    takeLatest(DATAINPUT_getImportDataAnt, getImportDataAnt),
    takeLatest(DATAINPUT_insertSingleData, insertSingleData),
    takeLatest(DATAINPUT_importDataFile, importDataFile),
    takeLatest(DATAINPUT_delData, delData),
    takeLatest(DATAINPUT_setStatistics, setStatistics),
];

export function SDNDataInputReducer(
    state = {
        importDataAntList: [], //导入记录列表
        importDataTypeList: [], //数据录入计量数据类型
        recordDataTypeList: [], //导入记录计量数据类型
        consumerUnitByDataClassifyIdList: [], //数据录入管理单元，对应相应的dataClassifyId
        consumerUnitList: null, //录入记录管理单元，全部数据
        isNeedRefresh: false,
        isClearImportData: false,
        importModalVsible: false,
    },
    action) {
    switch (action.type) {

        case DATAINPUT_setImportDataTypeList: {
            if (action.res.code) {
                return {
                    ...state,
                    importDataTypeList: [],
                }
            } else {
                return {
                    ...state,
                    importDataTypeList: action.res,
                }
            }
        }

        case DATAINPUT_setRecordDataTypeList: {
            if (action.res.code) {
                return {
                    ...state,
                    recordDataTypeList: [],
                }
            } else {
                return {
                    ...state,
                    recordDataTypeList: action.res,
                }
            }
        }

        case DATAINPUT_setConsumerUnitByConsumerAndDataClassify: {
            if (action.res.code) {
                return {
                    ...state,
                    consumerUnitByDataClassifyIdList: [],
                }
            } else {
                return {
                    ...state,
                    consumerUnitByDataClassifyIdList: action.res,
                }
            }
        }

        case DATAINPUT_setConsumerUnitByConsumer: {
            if (action.res.code) {
                return {
                    ...state,
                    consumerUnitList: [],
                }
            } else {
                return {
                    ...state,
                    consumerUnitList: action.res,
                }
            }
        }

        case DATAINPUT_setImportDataAnt: {
            if (!action.res || action.res.code) {
                return {
                    ...state,
                    importDataAntList: { list: [], pagination: { total: 0 } },
                    isNeedRefresh: false,
                }
            } else {
                return {
                    ...state,
                    importDataAntList: action.res,
                    isNeedRefresh: false,
                }
            }
        }

        case DATAINPUT_updateImportDataAnt: {
            if (action.id) {
                let list = state.importDataAntList.list;
                let pagination = state.importDataAntList.pagination;
                if (action.id && list.length > 0) {
                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        if (action.id == item.importDataId) {
                            item.status = 2;
                            break;
                        }
                    }
                }

                return {
                    ...state,
                    importDataAntList: { list: list, pagination: pagination },
                }
            } else {
                return {
                    ...state,
                }
            }
        }

        case DATAINPUT_refreshTable: {
            return {
                ...state,
                isNeedRefresh: true,
            }
        }

        case DATAINPUT_setIsClearImportData: {
            return {
                ...state,
                isClearImportData: action.isClearImportData,
            }
        }

        case DATAINPUT_setImportModalVsible: {
            return {
                ...state,
                importModalVsible: action.res,
            }
        }

        default:
            return state;
    }
}

/** 根据计量能源类型获得计量数据类型集合 */
export function* getImportListByPowerType(action) {
    try {
        const { powerType } = action.params;
        if (powerType == "") {
            yield put({ type: DATAINPUT_setImportDataTypeList, res: [] });
        } else {
            const res = yield call(archive.DataClassifyService.getListByDataType, powerType);
            yield put({ type: DATAINPUT_setImportDataTypeList, res: res });
        }
    } catch (e) {

    }
}

/** 根据计量能源类型获得计量数据类型集合 */
export function* getRecordListByPowerType(action) {
    try {
        const { powerType } = action.params;
        if (powerType == "") {
            yield put({ type: DATAINPUT_setRecordDataTypeList, res: [] });
        } else {
            const res = yield call(archive.DataClassifyService.getListByDataType, powerType);
            yield put({ type: DATAINPUT_setRecordDataTypeList, res: res });
        }
    } catch (e) {

    }
}

/** 数据录入-管理单元 */
export function* getConsumerUnitByConsumerAndDataClassify(action) {
    try {
        const { consumerId, dataClassifyId } = action.params;
        const res = yield call(archive.ConsumerUnitService.getByConsumer, consumerId, dataClassifyId);
        yield put({ type: DATAINPUT_setConsumerUnitByConsumerAndDataClassify, res: res });
    } catch (e) {

    }
}

/** 录入记录-管理单元 */
export function* getConsumerUnitByConsumer(action) {
    try {
        const { consumerId } = action.params;
        const res = yield call(archive.ConsumerUnitService.getByConsumer, consumerId, "");
        yield put({ type: DATAINPUT_setConsumerUnitByConsumer, res: res });
    } catch (e) {

    }
}

/** 单条数据录入 */
export function* insertSingleData(action) {
    try {
        const res = yield call(efficiency.ImportDataService.insertSingleData, action.params);
        if (res.code == 200) {
            antdNotice.success(res.message || "录入成功");
            yield put({ type: DATAINPUT_refreshTable, res: null });
            yield put({ type: DATAINPUT_setIsClearImportData, isClearImportData: true });
        }
    } catch (e) {

    }
}

/** 录入记录列表 */
export function* getImportDataAnt(action) {
    try {
        const { consumerId, orgId, dataType, dataClassifyId, interval, startTime, endTime, current, pageSize } = action.params;
        const res = yield call(efficiency.ImportDataService.getImportDataAnt, consumerId, orgId, dataType, dataClassifyId, interval, startTime, endTime, current, pageSize);
        yield put({ type: DATAINPUT_setImportDataAnt, res: res });
    } catch (e) {

    }
}

/** 导入数据 */
export function* importDataFile(action) {
    try {
        const { consumerId, upFile } = action.params;
        const res = yield call(efficiency.ImportDataService.importDataFile, consumerId, upFile);
        if (res.code == 200) {
            antdNotice.success(res.message || "导入成功");

            yield put({ type: DATAINPUT_setImportModalVsible, res: true });
            yield put({ type: DATAINPUT_refreshTable, res: null });
        }
    } catch (e) {

    }
}

/** 删除数据 */
export function* delData(action) {
    try {
        const { id } = action.params;
        const res = yield call(efficiency.ImportDataService.delData, id);
        if (res.code == 200) {
            antdNotice.success(res.message || "删除成功");
            yield put({ type: DATAINPUT_updateImportDataAnt, id: id });
        }
    } catch (e) {

    }
}

/** 删除数据 */
export function* setStatistics(action) {
    try {
        const { consumerId, dataType, dt, dtType } = action.params;
        const res = yield call(efficiency.ImportDataService.statistics, consumerId, dataType, dt, dtType);
        if (res.code == 200) {
            antdNotice.success(res.message || "开始执行统计");
        }
    } catch (e) {

    }
}
import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const Overview = Loadable({
  loader: () => import("./components/Overview"),
  loading:Loading
});

const SiteOverview = Loadable({
    loader: () => import("./components/SiteOverview"),
  loading:Loading
});

const MonitoringChart = Loadable({
    loader: () => import("./SDNMonitorChart/MonitoringChart"),
  loading:Loading
});

const SDNEnergy = Loadable({
  loader: () => import("./SDNEnergy/SDNEnergyPage"),
  loading:Loading
});

const ht2DEditor = Loadable({
  loader: () => import("./components/ht2DEditor"),
  loading:Loading
});

const ht3DEditor = Loadable({
  loader: () => import("./components/ht3DEditor"),
  loading:Loading
});

export const routes = [
  {
    path: "/SDNMonitoring/Overview",
    exact: true,
    component: Overview,
    name: "Overview"
  },
  {
    path: "/SDNMonitoring/SiteOverview",
    exact: true,
    component: SiteOverview,
    name: "SiteOverview"
  },
  {
    path: "/SDNMonitoring/MonitoringChart",
    exact: true,
    component: MonitoringChart,
    name: "MonitoringChart"
  },
  {
    path: "/SDNMonitoring/SDNEnergy",
    exact: true,
    component: SDNEnergy,
    name: "SDNEnergy"
  },
  {
    path: "/SDNMonitoring/ht2DEditor",
    exact: true,
    component: ht2DEditor,
    name: "ht2DEditor"
  },
  {
    path: "/SDNMonitoring/ht3DEditor",
    exact: true,
    component: ht3DEditor,
    name: "ht3DEditor"
  }
];

import $ from "jquery";
import { antdNotice } from '../../Notification';
import { Modal } from 'antd';
import "smartadmin-plugins/es6/SmartNotification.es6";

export function successBox(msg) {
  antdNotice.success(msg)
}

export function errorBox(msg) {
  antdNotice.error(msg)
}

export function warningBox(msg) {
  antdNotice.warning(msg)
}

export function infoBox(msg) {
  antdNotice.info(msg)
}

export function alertBox({
  title:title='',
  content: content='',
  confirm: confirm,
  cancel: cancel
}) {
  Modal.confirm({
    title: title,
    content: content,
    onOk() {
      if (confirm) {
        confirm();
      }
    },
    onCancel() {
      if (cancel) {
        cancel();
      }
    },
  });
}

export function SmartMessageBox(data, cb) {
  $.SmartMessageBox(data, cb);
}

export function alertEditBox({
  title:title='',
  content: content='',
  buttons: buttons="[取消][确认]",
  placeholder: placeholder='',
  inputValue: inputValue='',
  confirm: confirm,
  cancel: cancel
}) {
  SmartMessageBox(
    {
      title: title,
      content:content,
      input:"text",
      inputValue:"",
      placeholder:placeholder,
      buttons:buttons
    },
    function(ButtonPressed,value) {
      if (ButtonPressed === "确认" && confirm) {
        confirm(value);
      }
      if (ButtonPressed === "取消" && cancel) {
        cancel();
      }
    }
  )
}

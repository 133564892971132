/**
 * Created by wangli on 2019/6/4 0004.
 */

const pageUrl = {
  "login": "/login",
  "home": "/",
  "lockedScreen": "/lock",
  "register": "/register",
  "forgot": "/forgot",
  "FlotChart": "/graphs/flot",
  /******** BEGIN OF 综合监控 **********/
  "overview": "/SDNMonitoring/overview",
  "energy": "/SDNMonitoring/energy",
  "ht2DEditor": "/SDNMonitoring/ht2DEditor",
  "ht3DEditor": "/SDNMonitoring/ht3DEditor",
  /******** BEGIN OF 综合监控 **********/

  // --事件告警start--
  "SDNEventAlert": "/SDNEventAlert/SDNEventAlert",
  "SDNAlertConfig": "/SDNAlertConfig/SDNAlertConfig",
  "SDNAlertGroup": "/SDNAlertConfig/SDNAlertGroup",
  // --事件告警end--

  // --运维管理--
  "SDNGroupAdmin": "/SDNOperationAdmin/SDNGroupAdmin",
  "SDNUserAdmin": "/SDNOperationAdmin/SDNUserAdmin",
  "SDNCustomerAdmin": "/SDNOperationAdmin/SDNCustomerAdmin",
  "SDNNews": "/SDNOperationAdmin/SDNNews",
  "SDNEditNews": "/SDNOperationAdmin/SDNEditNews",

  // --系统管理start--
  "SDNMenuAdmin": "/SDNSysAdmin/SDNMenuAdmin",
  "SDNCharAdmin": "/SDNSysAdmin/SDNCharAdmin",
  "SDNAccountsAdmin": "/SDNSysAdmin/SDNAccountsAdmin",
  "SDNDataDictionary": "/SDNSysAdmin/SDNDataDictionary",
  "SDNSystemLogs": "/SDNSysAdmin/SDNSystemLogs",
  "SDNConfigDataDic": "/SDNSysAdmin/SDNConfigDataDic/",
  // --系统管理end--

  //台账管理
  "SDNMonitorsite": "/SDNEquipment/SDNMonitorsite",
  "SDNTemplate": "/SDNEquipment/SDNTemplate",
  "SDNParameter": "/SDNEquipment/SDNParameter",
  "SDNShotPlan": "/SDNEquipment/SDNShotPlan",

  //  --基础配置--  ↓
  "SDNDataItem": "/SDNBaseConfig/SDNDataItem",
  "SDNDataItemConfig": "/SDNBaseConfig/SDNDataItemConfig",
  "SDNDeviceType": "/SDNBaseConfig/SDNDeviceType",
  "SDNAlarmCompose": "/SDNBaseConfig/SDNAlarmCompose",
  "SDNMonitorObjectType": "/SDNBaseConfig/SDNMonitorObjectType",
  "SDNMonitorObjectTypeExtra": "/SDNBaseConfig/SDNMonitorObjectTypeExtra",
  "SDNMonitorObjectTypeConfig": "/SDNBaseConfig/SDNMonitorObjectTypeConfig",
  "SDNUnitType": "/SDNBaseConfig/SDNUnitType",
  //  --基础配置--  ↑

  //个人信息管理
  "SDNUserInfo": "/SDNUserAdmin/SDNUserInfo"

};

const dataUrl = {
  "login": "/api/sys/login",
  "getAllMenus": "/api/sys/getAllMenus",

  "getOperateRecords": "/api/sys/operLog/searchByDataTable", //操作记录
  "getPowerAnalysisTableData": "/api/efficiency/energyAnalysis/tableDatas", //能效分析表数据
  "getCompareAnalysisTableData": "/api/efficiency/compare/getConsumptionInfo", //对比分析表数据

  "getMenuAdmin": "/api/sys/fun/getCFunDataTable", //菜单管理datatable
  "getOgRoleTableData": "/api/sys/orgRole/getdatatable", //角色管理datatable
  "getOrgEmployee": "/api/sys/orgEmployee/getdatatable", //账号管理datatable
  "getDataDictionary": "/api/sys/dictionaryCategory/getdatatable", //数据字典datatable
  "getSystemLogs": "/api/sys/log/getDataTableByDate", //系统日志datatable
  "getDataDicByProperty": "/api/sys/dictionary/searchdatatableByProperties", ////条件查询数据字典对象,dataTable

  "getDataItemData": "/api/archive/dataItem/getdatatable",//获取数据项配置datatable
  "getMonitorObjectTypeExtraData": "/api/archive/monitorTypeExtend/getDatatableByObjectType",//获取监控对象类型扩展信息datatable
  "getMonitorObjectTypeConfigData": "/api/archive/monitorTypeData/getdatatable",//获取监控对象类型配置信息datatable
  "getMonitorObjectTypeDataTableUnselected": "/api/archive/monitorTypeData/getDataTableUnselected",//获取监控对象类型配置信息datatable
  "getGraphEntityParamTableUnselected": "/api/archive/graphEntityParam/getDataTableUnselected",//
  "getMonitorObjectTypeData": "/api/archive/monitorObjectType/getdatatable",//获取监控对象类型datatable
  "getMonitorObjectTypeGraphEntity": "/api/archive/graphEntityParam/getdatatable",//获取监控对象类型datatable
  "getDeviceModelData": "/api/archive/deviceModel/getdatatable",//获取设备型号datatable
  "getUnitTypeData": "/api/archive/unitType/getdatatable",//获取设备型号datatable
  "getAlertRecordTableData": "/api/alarm/record/getdatatable",//告警查询datatable
  "getAlarmConfigDataTable": "/api/alarm/eventConfig/getEventFromIotDataTable",//告警事件配置datatable
  "getDataTableByMonitorItemId": "/api/alarm/linkageConfig/getDataTableByEvent",//告警联动配置datatable
  "getGrafanaDataTableById": "/api/alarm/eventConfigGrafana/getBySiteDataTable",//告警配置综合查询datatable
  "linkageControlDetail": "/api/alarm/linkageRecord/getdatatable",//DataTable 根据告警事件记录获取控制联动信息
  "getAlarmDataTable": "/api/event/alarm/getAlarmDataTable",//组合告警查询datatable
  "getHomeDocuments": "/api/sys/home/getDocuments?docTypeId=0",
  "getConsumersAndEmpDataTable": "/api/archive/consumer/getConsumersAndEmpDataTable", //用户管理datatable
  "getGroupDataTable": "/api/archive/group/getdatatable",  //集团管理datatable
  "getConsumerDataTable": "/api/archive/consumer/getConsumerDataTable", //企业管理datatable
  "getNewsDataTable": "/api/service/news/getdatatable",  //公告资讯datatable
  "getTemplateDataTable": "/api/archive/template/getdatatable",//模板管理datatable,
  "getShotPlanTable": "/api/archive/shootingScheme/getDateTableByMonitorObject",//拍摄方案datatable,
  "getMonitorsiteTable": "/api/archive/monitorItem/getListBySecondaryDeviceDataTable",//监控点管理datable,
  "getPageByNameOrCode": "/api/archive/monitorObjectType/getPageByNameOrCode",//根据名称或编码查询对象类型,
  "getDatatableByMonitorSite": "/api/archive/monitorDiagram/getDatatableByMonitorSite",//站点下的监控图列表
  "getDatatableByObjectType": "/api/archive/monitorTypeData/getDatatableByObjectType",//获取关联监控点列表数据
  "getEquipmentSubLevelTableData": "/api/archive/monitorObject/getSubObjectByDataTable", //台账下级关系对象列表"
  "getPageByConsumerAndDataType": "/api/archive/ammeter/getPageByConsumerAndDataType",//添加计量
  "getGageListDatatable": "/api/yunshui/gaugeConfigure/getdatatable",//查询计量器具台账
  "downloadGageImportTpl": "/template/yunshui/云水_导入模板_用能单位计量器具信息.xlsx",//下载计量器具台账导入模板
  "statConsumerUnitRelationGetDataTable": "/api/stat/consumerUnitRelation/getdatatable",//系统配置-统计分析配置-统计计算配置-表格接口
  "statExtParamGetDataTable": "/api/stat/extParam/getdatatable",//系统配置-统计分析配置-统计计算配置-外部参数配置-表格接口
  "statStdataItemGetDataTable": "/api/stat/stdataItem/getdatatable",//系统配置-统计分析配置-统计计算配置-统计数据项定义-表格接口
  "statEeFormulaGetDataTable": "/api/stat/eeFormula/getdatatable",//系统配置-统计分析配置-统计计算配置-公式定义-表格接口
  "statGetDisplayDataTable": "/api/stat/outputConfig/getDisplayDataTable",//系统配置-统计分析配置-统计展示配置--报表列表表格接口
  "statGetReportDataTable": "/api/stat/outputConfig/getReportDataTable",//系统配置-统计分析配置-统计报表配置--报表管理表格接口
  "statOutputConfigDataGetdatatable": "/api/stat/outputConfigData/getdatatable",//系统配置-统计分析配置-统计展示配置/统计报表配置--配置表格接口
  "unitStdataItemGetDataTable": "/api/stat/unitItemSdvalue/getdatatable",//系统配置-统计分析配置-对标分析配置--表格接口
  "getBenchmarkingResultDataTable": "/api/stat/unitItemSdvalue/getBenchmarkingResultDataTable",//系统配置-统计分析配置-对标分析配置--表格接口
  "reviewDataGetdatatable": "/api/yunshui/reviewData/getdatatable",//系统配置/数据配置/审查数据导入--表格接口
};

const getRoute = (view) => {
  if (!view) return '';

  switch (view) {
    case 'hxy/event/alarmRecord/index.html':
      return pageUrl.SDNEventAlert;
    case 'hxy/event/config/index.html':
      return pageUrl.SDNAlertConfig;
    case 'hxy/event/objAlarm/index.html':
      return pageUrl.SDNAlertGroup;

    //系统管理  start
    case 'hxy/sys/org/fun/index.html':
      return pageUrl.SDNMenuAdmin;
    case 'hxy/sys/org/role/index.html':
      return pageUrl.SDNCharAdmin;
    case 'hxy/sys/org/employee/index.html':
      return pageUrl.SDNAccountsAdmin;
    case 'hxy/sys/dictionary/index_category.html':
      return pageUrl.SDNDataDictionary;
    case 'hxy/sys/log/index.html':
      return pageUrl.SDNSystemLogs;
    case 'hxy/sys/dictionary/index.html':
      return pageUrl.SDNConfigDataDic;
    //系统管理  end

    case 'hxy/operation/demand/index.html':
      return pageUrl.SDNUserAdmin;

    case 'hxy/operation/schema/index.html':
      return pageUrl.SDNCustomerAdmin;

    case 'hxy/operation/tactics/index.html':
      return pageUrl.SDNNews;

    case 'hxy/operation/faultOrder/index.html':
      return pageUrl.SDNUserAdmin;

    case 'hxy/operation/inspectionTask/index.html':
      return pageUrl.SDNCustomerAdmin;

    case 'hxy/operation/siteManagement/index.html':
      return pageUrl.SDNNews;

    case 'hxy/config/dataItem/index.html':
      return pageUrl.SDNDataItem;
    case '/SDNBaseConfig/SDNDataItemConfig':
      return pageUrl.SDNDataItemConfig;
    case 'hxy/config/monitorType/index.html':
      return pageUrl.SDNMonitorObjectType;
    case '/SDNBaseConfig/SDNMonitorObjectTypeExtra':
      return pageUrl.SDNMonitorObjectTypeExtra;
    case '/SDNBaseConfig/SDNMonitorObjectTypeConfig':
      return pageUrl.SDNMonitorObjectTypeConfig;
    case 'hxy/config/deviceModel/index.html':
      return pageUrl.SDNDeviceType;
    case 'hxy/config/unitType/index.html':
      return pageUrl.SDNUnitType;
    case 'hxy/config/alarm/index.html':
      return pageUrl.SDNAlarmCompose;
    case 'hxy/archive/index.html':
      return pageUrl.SDNMonitorsite;
    case 'hxy/archive/device.html':
      return pageUrl.SDNTemplate;
    case 'hxy/archive/monitorSite.html':
      return pageUrl.SDNParameter;

    //个人信息管理
    case 'hxy/personal/information/index.html':
      return pageUrl.SDNUserInfo;

    default:
      return view;
  }
};

export { pageUrl, dataUrl, getRoute };
export const powerClassList = [
    { title: '电', value: 1, unit: 'kW·h' },
    { title: '水', value: 2, unit: 't' },
    { title: '再生水', value: 9, unit: 't' },
    { title: '天然气', value: 4, unit: 'm³' },
    { title: '人工煤气', value: 7, unit: 'm³' },
    { title: '蒸汽', value: 11, unit: 'm³' },
    { title: '压缩空气', value: 12, unit: 'm³' },
    { title: '原煤', value: 13, unit: 't' },
    { title: '煤粉', value: 14, unit: 't' },
    { title: '产量', value: 15, unit: 't' },
    { title: '柴油', value: 8, unit: 't' },
    { title: '原料', value: 16, unit: 't' },
    { title: '热力', value: 17, unit: 'GJ' },
    { title: '指标', value: 18, unit: '' },
    { title: '系数', value: 19, unit: '' },
    { title: '其他', value: 10, unit: '自填' },
];

export const PowerTypes = [
    { name: "电(kW·h)", text: "电(kW·h)", title: "电(kW·h)", value: 1, unit: 'kW·h' },
    { name: "水(t)", text: "水(t)", title: "水(t)", value: 2, unit: 't' },
    { name: "再生水(t)", text: "再生水(t)", title: "再生水(t)", value: 9, unit: 't' },
    { name: "天然气(m³)", text: "天然气(m³)", title: "天然气(m³)", value: 4, unit: 'm³' },
    { name: "人工煤气(m³)", text: "人工煤气(m³)", title: "人工煤气(m³)", value: 7, unit: 'm³' },
    { name: "蒸汽(m³)", text: "蒸汽(m³)", title: "蒸汽(m³)", value: 11, unit: 'm³' },
    { name: "压缩空气(m³)", text: "压缩空气(m³)", title: "压缩空气(m³)", value: 12, unit: 'm³' },
    { name: "原煤(t)", text: "原煤(t)", title: "原煤(t)", value: 13, unit: 't' },
    { name: "煤粉(t)", text: "煤粉(t)", title: "煤粉(t)", value: 14, unit: 't' },
    { name: "产量(t)", text: "产量(t)", title: "产量(t)", value: 15, unit: 't' },
    { name: "柴油(t)", text: "柴油(t)", title: "柴油(t)", value: 8, unit: 't' },
    { name: "原料(t)", text: "原料(t)", title: "原料(t)", value: 16, unit: 't' },
    { name: "热力(GJ)", text: "热力(GJ)", title: "热力(GJ)", value: 17, unit: 'GJ' },
    { name: "指标", text: "指标", title: "指标", value: 18, unit: '' },
    { name: "系数", text: "系数", title: "系数", value: 19, unit: '' },
    { name: "其它(tce)", text: "其它(tce)", title: "其它(tce)", value: 10, unit: 'tce' }
];

export const monitorType = [
    { value: '3', text: "通讯管理机", powerTypes: [] },
    { value: '4', text: "电表", powerTypes: [1] },
    { value: '6', text: "水表", powerTypes: [2, 9] },
    { value: '7', text: "气表", powerTypes: [4, 7, 11, 12] },
    { value: '12', text: "热量表", powerTypes: [17] },
    { value: '8', text: "煤耗计量表", powerTypes: [13, 14] },
    { value: '9', text: "产量计量表", powerTypes: [15] },
    { value: '10', text: "原料计量表", powerTypes: [16] },
];

export const totalGroup = { value: "11", text: "总加组", powerTypes: [1, 2, 9, 4, 7, 11, 12, 13, 14, 15, 8, 16, 17] };
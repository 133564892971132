import * as actionType from "./SDNSystemActionTypes";
import { redirectTo, pageUrl } from "../../service";
import { hgStorage, Utils } from '../utils';
import { SET_SEL_NODE } from "../../views/SDNEquipment/SDNLedgerDetail/model";

export function SDNSystemReducer(
  state = {
    user: {},
    loginLoading: false,
    userMenuOpen: false,
    displayMenu: [],
    menu: [],
    menuFromRemote: [],
    currentRoute: null,
    selNode: null,

    showSubDevice: false, //是否显示二次设备
    firmList: [], //普通用户公司列表
    groupFirmTree: [], //管理员集团公司列表
    selectedFirm: {}, //当前选中的公司

    objectTreeLoading: false,
    selectedObjectTree: {},
    selectedNodeId: '',
    checkedKeys: [],
    usePowerTypeList: [], //用电类型
    roles: []
  },
  action
) {
  switch (action.type) {
    case actionType.USER_LOGIN_SUCCESS: {
      redirectTo(pageUrl.home);
      hgStorage.set(actionType.USER_INFO, JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload
      };
    }

    case actionType.USER_LOGIN_LOADING:
      return { ...state, loginLoading: action.payload }

    case actionType.USER_LOGOUT: {
      hgStorage.del(actionType.USER_INFO);
      redirectTo('/login');
      return { ...state, user: {} };
    }

    case actionType.GET_USER_INFO_SUCCESS: {
      const user = { ...state.user, ...action.payload };
      return { ...state, user: user };
    }

    case actionType.SET_SHOW_SUB_DEVICE:
      return { ...state, showSubDevice: action.payload }

    case actionType.GET_USER_FIRMS_SUCCESS: {
      const firm = action.payload.length > 0 ? action.payload[0] : null;
      const selectedFirm = firm ?
        {
          name: firm.orgName,
          id: firm.orgId,
          ammeterId: firm.ammeterId || firm.dataAmmeterId,
          imgPath: firm.conFilePath,
          area: firm.areaFullName
        } : {
          name: '',
          id: '',
          ammeterId: '',
          imgPath: '',
          area: ''
        };
      return {
        ...state,
        firmList: action.payload,
        selectedFirm: selectedFirm
      };
    }

    case actionType.EDIT_USER_INFO_SUCCESS: {
      const { fieldValue } = action.payload
      return { ...state, fieldName: fieldValue };
    }

    case actionType.GET_GROUP_FIRM_TREE_SUCCESS: {
      if (!action.payload) return state;

      const tree = action.payload;
      const firmList = tree.length > 0 ? tree[0] : []
      const firm = (firmList.children && firmList.children.length > 0) ? firmList.children[0] : null;
      let selectedFirm = state.selectedFirm;

      if (Utils.empty(selectedFirm) && !Utils.empty(firm)) {
        selectedFirm = {
          name: firm.orgName,
          id: firm.orgId,
          ammeterId: firm.ammeterId || firm.dataAmmeterId,
          imgPath: firm.conFilePath,
          area: firm.areaFullName
        };
      }

      return {
        ...state,
        groupFirmTree: tree,
        selectedFirm: selectedFirm
      };
    }

    case actionType.SET_SELECTED_FIRM: {
      return {
        ...state,
        selectedFirm: {
          name: action.payload.orgName,
          id: action.payload.orgId,
          ammeterId: action.payload.ammeterId || action.payload.dataAmmeterId,
          imgPath: action.payload.conFilePath,
          area: action.payload.areaFullName
        }
      };
    }

    case actionType.SET_SELECTED_MONI_UNIT: {
      return {
        ...state,
        selectedMoniUnit: {
          name: action.payload.orgName,
          id: action.payload.orgId,
          ammeterId: action.payload.ammeterId || action.payload.dataAmmeterId,
          imgPath: action.payload.conFilePath,
          area: action.payload.areaFullName
        }
      };
    }

    case actionType.SET_NAVIGATION_MENU: {
      const { menu, displayMenu } = action.payload;
      const list = processMenuList(menu, displayMenu);
      return {
        ...state,
        menu: menu,
        displayMenu: list
      };
    }

    case actionType.SET_CURRENT_NAVIGATION_ROUTE:
      return { ...state, currentRoute: action.payload };

    case actionType.GET_USE_POWER_LIST_SUCCESS:
      return { ...state, usePowerTypeList: action.payload };

    case actionType.GET_FIRM_SUB_TREE_BEGIN:
      return { ...state, objectTreeLoading: true };

    case actionType.GET_FIRM_SUB_TREE_END:
      return { ...state, objectTreeLoading: false };

    case actionType.CLEAR_FIRM_SUB_TREE:
      return { ...state, selectedObjectTree: {} };

    case actionType.GET_FIRM_SUB_TREE_SUCCESS:
      return { ...state, selectedObjectTree: action.payload };

    case actionType.SELECT_OBJECT_NODE:
      return { ...state, selectedNodeId: action.payload }

    case actionType.CHECK_OBJECT_NODE:
      return { ...state, checkedKeys: action.payload }

    case actionType.GET_ALL_ROLES_SUCCESS:
      return { ...state, roles: action.payload }

    case SET_SEL_NODE:
      return { ...state, selNode: action.payload };

    default:
      return state;
  }
}

const processMenuList = (data, displayMenu) => {
  let showMenuIds = [];
  Object.keys(displayMenu).forEach(key => showMenuIds.push(displayMenu[key].funCode));

  if (!data) return [];

  let menuData = (data => {
    let item = { "isEmpty": true };
    for (let i = 0; i < data.length; i++) {
      item = data[i];
      if (item.data.funCode === 'hxyweb') {
        item = data[i].children;
        break;
      }
    }
    if (item.isEmpty) return item;
    let recursion = (dataArr) => {
      let items = [], item = {}, data = {};
      for (let i = 0; i < dataArr.length; i++) {
        data = {};
        item = dataArr[i];
        let show = false;
        for (const funCode of showMenuIds) {
          if (item.data.orgName === '系统日志') {
          }
          if (item.data.funCode === funCode) {
            show = true;
            break;
          }
        }

        if (show === false) continue;

        if (item.data.className !== 'hide') {
          data.key = item.data.funId;
          data.route = item.data.url||'';
          data.id = item.data.funId;
          data.icon = item.data.funCode === "hxy.home" ? "iconfont icon-knowledge font-60" : (item.data.iconName || '');
          data.title = item.data.funName;

          if (item.children.length) {
            data.items = recursion(item.children);
          }

          items.push(data);
        }
      }

      return items;
    };
    return recursion(item);
  })(data);

  if (menuData.isEmpty) return [];

  return menuData;
};

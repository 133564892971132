import React from 'react';
import {Layout, Col, Row, Affix, Table, Select, Form, Divider, Typography} from 'antd';
import {accountType} from "../../../../config";
import {GET_GROUP_FIRM_TREE} from "../../../../common/system";
import {connect} from "react-redux";
import "../style.css";

const {Title} = Typography;
const FormItem = Form.Item
const {Option} = Select;
const {Content} = Layout;

const VOLTAGE=101;
const CURRENT=102;
const POWER=103;
export const passType={VOLTAGE,CURRENT,POWER};
class PassTableView extends React.Component {
    static defaultProps={
        data:{
            todayDeviant: 0,    // 当天偏差均值
            todayStandard: 0,    // 当天合格率
            yesterdayDeviant: 0,    // 昨天偏差均值
            yesterdayStandard: 0,    // 昨天合格
            deviantTitle:""
        }
    }


    render() {
        let dataSource = [
            {
                key: '1',
                dt: '当日',
                standard: this.props.data.todayStandard,
                deviant: this.props.data.todayDeviant,
            },
            {
                key: '2',
                dt: '前一日',
                standard: this.props.data.yesterdayStandard,
                deviant: this.props.data.yesterdayDeviant,
            },
        ];

        let columns = [
            {
                title: '时间',
                dataIndex: 'dt',
                key: 'dt',
                align: "center",
                width: 100
            },
            {
                title: '合格率',
                dataIndex: 'standard',
                key: 'standard',
                align: "center",
                render: (text, record) => (
                    <text>{text!=null?(text+ "%"):""}</text>
                ),
            },
            {
                title: this.props.deviantTitle,
                dataIndex: 'deviant',
                key: 'deviant',
                align: "center",
                render: (text, record) => (
                    <text>{text?(text):""}</text>
                ),
            },
        ];
        return (
                    <Col span={8}><Table dataSource={dataSource} columns={columns}
                                          pagination={false} bordered={true}/></Col>

        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(PassTableView);




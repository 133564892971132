/**
 * version  0.1
 * date     2019/6/12 10:12
 * author   caojiaxu
 * desc
 */
import {handleActions} from 'redux-actions';
import alarm from "../../service/API/alarm";
import {getRequestAxios} from "../../service/SDNRequest";
import {GRAFANA_REMOVED, SET_GRAFANA_URL} from "./SDNEventAlertAcitonType";

const initalState = {
  page: {},
  comPage: {},
  comAlarmConfig: {
    visible: false,
    title: '',
    rowData: {}
  },
  linkConfig: {
    eventId: "1",
    eventType: 0,
    linkageType: 1,
  },
  selDataItem: {
    dataType: 0
  },
  eventConfig: {},
  cameraItems: [],
  controlItems: [],
  editAlert: {},
  deviceTree: [],
  groupList: [],
  selGroup: {},
  selSite: {},
  monitorItems: [],
  siteList: [],
  allSite: [],
  alertCount: {},
  linkageDeal: {},
  opreateDemand: {},
  alertParam: {
    alarm: {
      alarmlId: '',
    },
    ix: '',
    paramType: '',
    dataItem: {
      dataItemId: '',
    },
    paramCode: '',
    paramName: '',
    paramValue: '',
  },
  dataItemList: [],
  rowData: {},
  zhMonitorList: [],
  zhSecondList: [],
  grafanaUrl: {},
}

const SDNAlertReducer = handleActions({
  INIT_PAGE: (state, action) => {
    return {
      ...state,
      page: action.payload
    };
  },
  SET_SITE_LIST: (state, action) => {
    return {
      ...state,
      allSite: action.payload,
    };
  },
  SET_ALERT_COUNT: (state, action) => {
    return {
      ...state,
      alertCount: action.payload,
    };
  },
  SET_GROUP_LIST: (state, action) => {
    return {
      ...state,
      groupList: action.payload,
    };
  },
  SET_EVENT_CONFIG_BY_ID: (state, action) => {
    return {
      ...state,
      eventConfig: action.payload,
    };
  },
  SET_MONITOR_ITEMS: (state, action) => {
    return {
      ...state,
      monitorItems: action.payload,
    };
  },
  SET_SITE_LIST_BY_ID: (state, action) => {
    return {
      ...state,
      siteList: action.payload,
    };
  },
  SET_DEVICE_TREE: (state, action) => {
    return {
      ...state,
      deviceTree: action.payload,
    };
  },
  SET_ALERT_BY_ID: (state, action) => {
    return {
      ...state,
      editAlert: action.payload,
    };
  },
  SET_DATA_ITEM: (state, action) => {
    return {
      ...state,
      dataItemList: action.payload,
    };
  },
  EDIT_ALERT: (state, action) => {
    return {
      ...state,
      editAlert: action.payload
    }
  },

  CONFIRM_ALERT_SUCCESS: (state, action) => {
    state.page.reloadTable();
    return {
      ...state,
    }
  },
  SUBMIT_EDIT_EVENT_CONFIG_SUCCESS: (state, action) => {
    state.page.editSuccess();
    return {
      ...state,
      eventConfig: {}
    }
  },
  SUBMIT_ADD_ALERT_SUCCESS: (state, action) => {
    state.page.addSuccess();
    return {
      ...state,
      editAlert: {}
    }
  },
  SUBMIT_EDIT_ALERT_SUCCESS: (state, action) => {
    state.page.editSuccess();
    return {
      ...state,
      editAlert: {}
    }
  },

  // -------------综合告警配置↓-------------//
  INIT_COM_PAGE: (state, action) => {
    return {
      ...state,
      comPage: action.payload
    };
  },
  SUBMIT_ADD_COM_ALARM_SUCCESS: (state, action) => {
    state.comPage.addSuccess();
    return {
      ...state,
      editAlert: {}
    }
  },
  SET_COM_VIEW_CONFIG: (state, action) => {
    
    return {
      ...state,
      comAlarmConfig: action.payload
    }
  },
  SUBMIT_EDIT_COM_ALARM_SUCCESS: (state, action) => {
    state.comPage.editSuccess();
    return {
      ...state,
      editAlert: {}
    }
  },
  SUBMIT_EDIT_LINKAGE_SUCCESS: (state, action) => {
    state.page.reloadLinkTable();
    return {
      ...state,
      linkConfig: {}
    }
  },
  SET_GRAFANA_URL: (state, action) => {
    return {
      ...state,
      grafanaUrl: action.payload
    }
  },
  // -------------综合告警配置↑-------------//
  SUBMIT_ADD_LINKAGE_SUCCESS: (state, action) => {
    state.page.reloadLinkTable();
    return {
      ...state,
      linkConfig: {}
    }
  },

  SHOW_EDIT_ALERT_GROUP: (state, action) => {
    return {
      ...state,
      showEditView: action.payload
    };
  },
  ALERT_REMOVED: (state, action) => {
    state.page.removeSuccess();
    return state;
  },
  GRAFANA_REMOVED: (state, action) => {
    state.comPage.removeSuccess();
    return state;
  },
  LINK_REMOVED: (state, action) => {
    state.page.removeSuccess();
    return state;
  },
  // -------------告警配置↓-------------//
  EDIT_EVENT_CONFIG: (state, action) => {
    return {
      ...state,
      eventConfig: action.payload
    }
  },
  EDIT_EVENT_CONFIG_FIELD_SUCCESS: (state, action) => {
    state.page.reloadTable();
    return state;
  },
  EDIT_LINK: (state, action) => {
    return {
      ...state,
      linkConfig: action.payload
    }
  },
  SET_LINKAGE_CONFIG: (state, action) => {
    return {
      ...state,
      linkConfig: action.payload
    }
  },
  SET_SET_DATA_TYPE: (state, action) => {
    return {
      ...state,
      selDataItem: action.payload
    }
  },
  SET_CAMERA_ITEM: (state, action) => {
    return {
      ...state,
      cameraItems: action.payload,
    };
  },
  SET_CONTROL_ITEM: (state, action) => {
    return {
      ...state,
      controlItems: action.payload,
      selDataItem: getDefalutSelItem(action.payload),
    };
  },
  // -------------告警配置↑-------------//

  EDIT_ALERT_PARAMS: (state, action) => {
    return {
      ...state,
      alertParam: action.payload,
    }
  },
  SEL_GROUP_ITEM: (state, action) => {
    return {
      ...state,
      selGroup: action.payload,
    }
  },
  SEL_SITE_ITEM: (state, action) => {
    return {
      ...state,
      selSite: action.payload,
    }
  },
  SET_MONITOR_BY_SITE: (state, action) => {
    return {
      ...state,
      zhMonitorList: action.payload,
    }
  },
  SET_SECOND_BY_SITE: (state, action) => {
    return {
      ...state,
      zhSecondList: action.payload,
    }
  },
}, initalState);

const getDefalutSelItem = payload => {
  if (payload) {
    if (payload.length > 0) {
      return payload[0];
    }
  }
}

export default SDNAlertReducer;
